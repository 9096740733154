import React, { useState } from "react";
// import EuroIcon from '@mui/icons-material/Euro';

interface SliderProps {
  label?: string;
  className?: string;
  [key: string]: any; // Allows for additional props to be passed
  value: number;
  onChange: (value: number) => void;
}

const Slider = ({
  label,
  className,
  value,
  onChange,
  ...props
}: SliderProps) => {
  // const [isChecked, setIsChecked] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(value);

  // const toggleChecked = () => {
  //   setIsChecked(!isChecked);
  // };

  return (
    <div className="w-full flex flex-col gap-1 max-lg:gap-1 ">
      {label && (
        <label
          htmlFor="toggle"
          className="text-[#999AA2] max-md:text-[13px] max-2xl:text-lg 2xl:text-lg"
        >
          {label}
        </label>
      )}
      {/* flex w-full  gap-x-[1.5rem] xl:flex-col */}
      <div className=" flex items-center gap-2 font-semibold">
        <input
          type="range"
          min={0}
          step={100}
          max={20000}
          value={price}
          className="w-[70%] max-sm:w-[70%]"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPrice(parseInt(e.target.value, 10));
            // setSelectedOption((prevState:any) => ({
            //         ...prevState,
            //         ["price"]: price,
            //       }));
            onChange(parseInt(e.target.value, 10));
            // onChange(price)
            // toggleChecked()
          }}
        />
        <span className=" text-[14px] text-gray-800">
          {"\u20AC"}
          {"  "}
          {`${price}`}
        </span>
        {/* <label className="flex-grow  font-semibold text-[#1B191B]  "> 
            <span className="justify-center"><EuroIcon /></span>
            {price}
        </label> */}
      </div>
    </div>
  );
};

export default Slider;
