import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";

import axios from "axios";

import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import KingBedIcon from "@mui/icons-material/KingBed";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
// import FileCopyIcon from '@mui/icons-material/FileCopy';
import BathtubIcon from "@mui/icons-material/Bathtub";
import ShareIcon from "@mui/icons-material/Share";
// import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import CachedIcon from "@mui/icons-material/Cached";

import MiniDrawer from "rc-app/components/MiniDrawer";
import Button from "rc-app/components/Button";
import UserService from "rc-app/services/UserService";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createVirtualToor,
  getAgentByEmail,
  getDigitalTwinById,
  getVirtualTourByAgent,
} from "../Home";
import Footer from "rc-app/components/Footer";
import MapView from "rc-app/components/Map";

// import QuiltedImageList from "rc-app/components/QuiltedImageList";

// import { ImageCarousel } from "rc-app/components/Image Carousel";
// import { Carousel } from "flowbite-react";
// import PdfViewer from "rc-app/components/Pdf Viewer";

// import axios from 'axios';

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NetworkPropertyCardSquare from "rc-app/components/NetworkPropertySquare";

// import Modal from '@mui/material/Modal';
import PDFViewer from "rc-app/components/Pdf Viewer";
import { toPascalCase } from "rc-app/utils";
// import { CommissionAmount } from "rc-app/components/CommissionAmount";

// import {
//    Paper, Typography,
// } from '@mui/material';

// import pdf '/pdf/dummy_1.pdf'

export const Details = () => {
  const [pdfUrl, setPdfUrl] = useState([]);
  console.log("pdf", pdfUrl.length);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const digitalTwinId = queryParams.get("digitalTwinId");
  const propertyId = queryParams.get("propertyId");
  const tab = queryParams.get("tab");
  const { email } = UserService.getTokenParsed();
  const [reveal_address] = useState(true);
  console.log("propertyID", propertyId);

  const [mode, setMode] = useState("host");

  const navigate = useNavigate();

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [activeTab, setActiveTab] = useState(tab ? tab : "digitalTwin");

  const [iframeSrc, setIframeSrc] = useState(`https://my.matterport.com/show/?m=${propertyId}`);

  const [isTourCreated, setTourCreated] = useState(false);

  const [digitalTwinDetails, setDigitalTwinDetails] = useState({
    property_id: "",
    agency_id: "",
    matterport_id: "",
    address: {
      address_line1: "",
      address_line2: "",
      landmark: "",
      postal_code: "",
      city: "",
      province: "",
      country: "",
    },
    capture_ref_id: "",
    captured_by: "",
    captured_with: "",
    qc_approved_by: "",
    name: "",
    title: "",
    description: "",
    characteristics: {
      reference_number: "",
      listing_date: "",
      contract_type: "",
      type_of_property: "",
      lifestyle: "",
      total_building_floors: 0,
      floor: 0,
      num_parking_spaces: 0,
      parking_spaces: "",
      num_bedrooms: 0,
      num_bathrooms: 0,
      area_sqm: 0,
      other_characteristics: {
        has_elevator: null,
        has_car_parking: null,
        has_central_heating: null,
        has_air_conditioning: null,
        has_terrace: null,
        has_balcony: null,
        has_garden: null,
      },
      total_virtual_tours: 0,
      shareable_link: "",
    },
    pricing: {
      currency: "EUR",
      rent_per_month: 0,
      fee_frequency: 0,
      deposit: 0,
      price: 0,
      condominium_expenses: 0,
    },
    property_visibility: "",
    property_images: [],
    property_videos: [],
    sale_commission_config: {
      owner_commission_percent: 0,
      buyer_commission_percent: 0,
      agency_to_agent_commission_percent: 0,
      seller_agency_to_buyer_agency_percent: 0,
      buyer_agency_to_buyer_agent_percent: 0,
      agent_commission: 0,
      agency_commission: 0,
      seller_agency_commission: 0,
      buyer_agent_commission: 0,
      buyer_agency_commission: 0,
    },
    rent_commission_config: {
      owner_commission_percent: 0,
      buyer_commission_percent: 0,
      agency_to_agent_commission_percent: 0,
      seller_agency_to_buyer_agency_percent: 0,
      buyer_agency_to_buyer_agent_percent: 0,
      agent_commission: 0,
      agency_commission: 0,
      seller_agency_commission: 0,
      buyer_agent_commission: 0,
      buyer_agency_commission: 0,
    },
    energy_efficiency: {
      year_of_construction: 0,
      state_of_construction: "",
      heating: "",
      energy_efficiency_rating: "",
      energy_efficiency_value_kWh_per_sqm_year: 0,
    },
    property_thumbnail: "",
    geo_location: ["45.464664", "9.188540"],
    is_public: null,
    is_starred: null,
  });
  const [digitaltwinLoading, setDigitaltwinLoading] = useState(false);

  const [agentCommission, setAgentCommission] = useState(0);

  // const [sharableLink, setSharableLink] = useState()


  // Function to extract query parameters
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      digitalTwinId: params.get("digitalTwinId"),
      propertyId: params.get("propertyId"),
    };
  };

  useLayoutEffect(() => {
    const { digitalTwinId, propertyId } = getQueryParams();
    if (digitalTwinId && propertyId) {
      setIframeSrc(`https://my.matterport.com/show/?m=${propertyId}`)
      getDigitalTwinById(digitalTwinId, reveal_address)
        .then((res) => {
          setDigitalTwinDetails(res);

          res.property_floor_plan !== null &&
            setPdfUrl(res.property_floor_plan);

          console.log("digital", res);

          res.property_visibility === "exclusive" &&
            setAgentCommission(
              res.characteristics.contract_type === "sale" &&
                res.sale_commission_config
                ? res.sale_commission_config.agent_commission || 0
                : res.characteristics.contract_type === "rent" &&
                  res.rent_commission_config
                ? res.rent_commission_config.buyer_agent_commission || ""
                : 0
            );

          res.property_visibility === "network" &&
            setAgentCommission(
              res.characteristics.contract_type === "sale" &&
                res.sale_commission_config
                ? res.sale_commission_config.buyer_agent_commission || 0.0
                : res.characteristics.contract_type === "rent" &&
                  res.rent_commission_config
                ? res.rent_commission_config.buyer_agent_commission || 0.0
                : 0.0
            );

          setDigitaltwinLoading(true);
          window.scrollTo(0, 0);
        })
        .catch((err) => console.log(err));
    }
  }, [location.search]);

  console.log("qq", digitalTwinDetails);

  const [agentId, setAgentId] = useState();
  const [virtualTour, setVirtualTour] = useState({
    is_active: null,
    _id: "",
    agent_id: "",
    matterport_id: "",
    start_time: new Date(),
    end_time: new Date(),
    join_code: "",
    channel: "",
    agent_details: {},
  });

  const isVirtualTour = () => {
    getAgentByEmail(email).then((data) => {
      const agent = data.agent;
      setAgentId(agent._id);
      getVirtualTourByAgent(email, agent._id).then((data) => {
        const filterdSessions = ongoingSessions(data);
        console.log(filterdSessions, "filterdSessions");
        filterdSessions.length > 0 && setTourCreated(true);
        setVirtualTour(filterdSessions[0]);
        // setChannelName(filterdSessions[0].channel)
      });
    });
  };

  useEffect(() => {
    isVirtualTour();
  }, [virtualTour]);

  console.log("virtualTour", virtualTour);

  function isCurrentTimeBetween(start: any, end: any, current: any) {
    start = new Date(start).getTime();
    end = new Date(end).getTime();
    console.log("startTime1", end);
    current = new Date(current).getTime();
    return start <= current && current <= end;
  }

  const ongoingSessions = (sessions: any[]) => {
    const currentTime1 = new Date();
    var utc_currentTime1 = new Date(
      currentTime1.getTime() + currentTime1.getTimezoneOffset() * 60000
    );
    return sessions.filter((session: any) => {
      console.log("startTime1", utc_currentTime1);
      return isCurrentTimeBetween(
        session.start_time,
        session.end_time,
        utc_currentTime1
      );
    });
  };
  console.log(agentId, digitalTwinDetails);
  const handleCreateVirtualTour = () => {
    createVirtualToor(agentId, propertyId).then((res) => {
      console.log(res.virtual_tour, "res1234");
      setVirtualTour(res.virtual_tour);
      isVirtualTour();
    });
  };
  const handleJoinNow = () => {
    // window.history.pushState({}, '', `/virtual-tour/${propertyId}?channel=${virtualTour.channel}`);
    // eventUpdateStream('JoinTour', agentId,  email, 'click_join_virtual_tour', 'true')
    navigate(
      `/virtual-tour?propertyId=${propertyId}&channel=${virtualTour.channel}&mode=${mode}&digitalTwin=${digitalTwinId}`
    );
  };

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const path = url.pathname;
  console.log("path", path, agentCommission);

  // const [carouselItem, setCarouselItem] = useState([])

  // const [carouselItem,] = useState([
  //   "https://storage.googleapis.com/oneverse-dev/realestate/floor_plan_1.png",
  //   "https://storage.googleapis.com/oneverse-dev/realestate/floor_plan_2.png",
  //   "https://storage.googleapis.com/oneverse-dev/realestate/dummy.pdf",
  //   "https://storage.googleapis.com/oneverse-dev/realestate/floor_plan_1.png",
  //   "https://storage.googleapis.com/oneverse-dev/realestate/floor_plan_2.png",
  //   "https://storage.googleapis.com/oneverse-dev/realestate/dummy.pdf",

  // ])

  //   const [itemData1,] = React.useState(carouselItem.map((item, index)=>(
  //     {
  //         img: item,
  //         title: 'Image'+index,
  //         rows: (index+1)%4,
  //         cols: (index+2)%4,
  //     }
  // )))
  // console.log("itemData", itemData1);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);
  // const [intervalId, setIntervalId] = useState<number | null>(null);

  // useEffect(() => {
  //   const API_URL = `https://realestate.dev.1verse.live/api/v1/realestate/views/landing/carousel_properties?sort_by=created_at&sort_order=-1&page=0&limit=20`
  //   axios.get(API_URL).then(response => {
  //     console.log("rrr", response.data.re_carousel_properties_list);
  //     setCarouselItem(response.data.re_carousel_properties_list)
  //   }).catch(err => console.log(err))
  // }, [])

  // const startAutoSlide = () => {
  //   const id = window.setInterval(() => {
  //     setCurrentSlide((prevSlide) => (prevSlide === carouselItem.length - 1 ? 0 : prevSlide + 1));
  //   }, 7000);
  //   setIntervalId(id);
  // };

  // const stopAutoSlide = () => {
  //   if (intervalId !== null) {
  //     window.clearInterval(intervalId);
  //     setIntervalId(null);
  //   }
  // };

  // const nextSlide = () => {
  //   stopAutoSlide();
  //   setCurrentSlide((prevSlide) =>
  //     prevSlide === pdfUrl.length - 1 ? 0 : prevSlide + 1
  //   );
  // };

  // const prevSlide = () => {
  //   stopAutoSlide();
  //   setCurrentSlide((prevSlide) =>
  //     prevSlide === 0 ? pdfUrl.length - 1 : prevSlide - 1
  //   );
  // };

  // const [currentSlide, setCurrentSlide] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  console.log(isFullScreen);

  // Move to the previous slide
  const prevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev === 0 ? pdfUrl.length - 1 : prev - 1));
  }, [pdfUrl]);

  // Move to the next slide
  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev === pdfUrl.length - 1 ? 0 : prev + 1));
  }, [pdfUrl]);

  // Fullscreen event listener
  const handleFullScreenChange = () => {
    setIsFullScreen(document.fullscreenElement != null);
  };

  // Keydown event listener for navigation
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        prevSlide();
      } else if (e.key === "ArrowRight") {
        nextSlide();
      }
    },
    [prevSlide, nextSlide]
  );

  // Add event listeners for full-screen change and keydown for arrow keys
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  // useEffect(() => {
  //   startAutoSlide();
  //   return () => stopAutoSlide();
  // }, []);

  const handlePropertyLinkClick = () => {
    if (digitalTwinDetails.characteristics.shareable_link !== null) {
      window.open(digitalTwinDetails.characteristics.shareable_link, "_blank");
    } else {
      alert("Shareble link for this Propety in not available");
    }
  };

  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const handleCopyButtonClick = () => {
    // const sharable_link =  digitalTwinDetails.characteristics.shareable_link && digitalTwinDetails.characteristics.shareable_link
    console.log("sharable_link", window.location.origin);

    navigator.clipboard
      .writeText(
        window.location.origin +
          `${
            digitalTwinDetails.characteristics.shareable_link
              ? digitalTwinDetails.characteristics.shareable_link
              : ""
          }`
      )
      .then(() => {
        setIsLinkCopied(true);
        setTimeout(() => setIsLinkCopied(false), 3000);
      })
      .catch((error) => console.error("Failed to copy property link:", error));
  };

  // const pages = ["properties", "vr-tour", "vr-showcase"];

  UserService.role();
  const [selectMode, setSelectMode] = useState(false);

  const [networkProperties, setNetworkProperties] = useState([]);
  useEffect(() => {
    async function fetchProperties() {
      try {
        const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/mp_digital_twin/network_properties?sort_by=created_at&sort_order=-1&page=0&limit=4&reveal_address=false`;

        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${UserService.getToken()}`,
            "Content-Type": "application/json",
          },
        });
        setNetworkProperties(response.data.mp_digital_twin_list);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    }

    fetchProperties();
  }, []);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  // const [selectedImage, setSelectedImage] = useState<string | null>(null);
  // const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isImageModalOpen) {
        if (event.key === "ArrowLeft") {
          setCurrentImage((prev) =>
            prev > 0
              ? prev - 1
              : digitalTwinDetails["property_images"].length - 1
          );
        } else if (event.key === "ArrowRight") {
          setCurrentImage((prev) =>
            prev < digitalTwinDetails["property_images"].length - 1
              ? prev + 1
              : 0
          );
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isImageModalOpen, digitalTwinDetails["property_images"]]);

  const [isFloorPlanModalOpen, setIsFloorPlanModalOpen] = useState(false);

  const handleDetailButton = (
    digital_twin_id: string,
    matterport_id: string
  ) => {
    navigate(
      `/property_details?digitalTwinId=${digital_twin_id}&propertyId=${matterport_id}`
    );
    // setTimeout(() => {
    //   window.location.reload();
    // }, 10);
  };

  return (
    <>
      <div className="flex container max-w-screen-2xl bg-[#F6F6F8] overflow-x-auto">
        <div>
          <MiniDrawer />
        </div>

        <div className="flex flex-col pt-[5rem] md:pt-[5rem] lg:pt-[6rem] px-2 lg:px-3  ">
          {digitaltwinLoading ? (
            <>
              <div className=" flex gap-4 px-2 max-lg:flex-col ">
                <div className="w-full lg:w-1/3  lg:order-2 relative">
                  <div className="w-full flex  h-60vh bg-white border-2 border-divider rounded">
                    <div className="flex w-full flex-col mx-3 my-4 ">
                      {/* <div
                        className={`${
                          digitalTwinDetails.property_visibility == "exclusive"
                            ? "border-[#CA8D16] 2xl:text-lg bg-[radial-gradient(ellipse_at_center,#FFE8BA,#FFD787,#FCC046)] max-md:text-[8px] text-[10px] p-1 px-2 rounded border absolute top-25 right-10"
                            : "border-[#ca2961] 2xl:text-lg bg-[radial-gradient(ellipse_at_center,#F9B6CE,#FC7EAB,#FE518F)] max-md:text-[8px] text-[10px] p-1 px-2 rounded border absolute top-25 right-10"
                        } `}
                      >
                        {digitalTwinDetails.property_visibility == "exclusive"
                          ? "EXCLUSIVE"
                          : "NETWORK"}
                      </div> */}
                      <div className="mb-2 font-bold xs:text-[12px] sm:text-[14px] md:text-[16px] lg:text-[20px] xl:text-[26px] text-bold text-[#1B191B]">
                        <div className="">{digitalTwinDetails.name}</div>
                        <div
                          className={` w-[28%] text-center justify-self-end${
                            digitalTwinDetails.property_visibility ==
                            "exclusive"
                              ? "border-[#CA8D16] 2xl:text-lg bg-[radial-gradient(ellipse_at_center,#FFE8BA,#FFD787,#FCC046)] max-md:text-[8px] text-[10px] p-1 px-2 rounded border "
                              : "border-[#ca2961] 2xl:text-lg bg-[radial-gradient(ellipse_at_center,#F9B6CE,#FC7EAB,#FE518F)] max-md:text-[8px] text-[10px] p-1 px-2 rounded border "
                          } `}
                        >
                          {digitalTwinDetails.property_visibility == "exclusive"
                            ? "EXCLUSIVE"
                            : "NETWORK"}
                        </div>
                      </div>

                      <div className="mb-2 flex gap-1 font-semibold text-[#001CDD] ">
                        <div className="w-[40%]">
                          <span className="xs:text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] ">
                            {digitalTwinDetails.property_id}
                          </span>
                        </div>

                        <div className="w-[60%] text-[#FF307A] xs:text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] ">
                          <button
                            className="text-[#FF307A] cursor-pointer focus:outline-none "
                            onClick={handlePropertyLinkClick}
                          >
                            Property Link
                          </button>
                          {/* <span className=" "><ShareIcon />{process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL+digitalTwinDetails.characteristics.shareable_link}</span> */}
                          <button
                            className="ml-1 text-[#FF307A] cursor-pointer focus:outline-none"
                            onClick={() => {
                              handleCopyButtonClick();
                              console.log("test");
                            }}
                          >
                            <ShareIcon />
                            {isLinkCopied && (
                              <span className="absolute top-10 right-2 mt-1  text-[#FF307A] ">
                                Copied !
                              </span>
                            )}
                          </button>
                        </div>
                      </div>

                      <div>
                        <div className="grid grid-cols-2  mb-4 md:justify-start">
                          <div className=" flex  items-center mb-2 xs:text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px]">
                            <span className="opacity-50 ">
                              <LocationOnIcon />
                            </span>
                            <span className="ml-1 text-[#283BBE] ">
                              {toPascalCase(digitalTwinDetails.address.city)}
                            </span>
                          </div>
                          <div className=" flex  items-center mb-2 xs:text-[9px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[17px]">
                            <span className="opacity-50 ">
                              <HomeIcon />
                            </span>
                            <span className="ml-1 text-[#283BBE] ">
                              {toPascalCase(
                                digitalTwinDetails.characteristics.contract_type
                              )}
                            </span>
                          </div>
                          <div className="flex items-center mb-2 xs:text-[9px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[17px]">
                            <span className="opacity-50 ">
                              <KingBedIcon />
                            </span>
                            <span className="ml-1 text-[#283BBE] ">
                              {digitalTwinDetails.characteristics.num_bedrooms}{" "}
                              Bedrooms
                            </span>
                          </div>
                          <div className=" flex  items-center mb-2 xs:text-[9px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[17px]">
                            <span className="opacity-50 ">
                              {" "}
                              <OpenInFullIcon />
                            </span>
                            <span className="ml-1 text-[#283BBE] ">
                              {digitalTwinDetails.characteristics.area_sqm} Sq.
                              Mtr
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className=" flex w-full gap-4 items-center lg:justify-center lg:flex-col xl:flex-row">
                        {/* <div className="w-1/2 lg:w-full  flex items-center lg:justify-center xl:justify-start">
                          <span className="border-[#283BBE] border rounded-full px-3 py-1 font-semibold max-lg:text-[12px] text-[16px] text-center">
                            <span className="text-[#283BBE]">Commision</span>{" "}
                            <span className="text-[#283BBE]">
                              {agentCommission.toFixed(2)}
                            </span>
                          </span>
                        </div> */}
                        <div className="w-1/2 lg:w-full flex items-center lg:justify-center xl:justify-start ">
                          <span className="bg-[#FF639A]  text-white border-[#FF307A] font-bold border rounded-full px-6 py-1 max-lg:text-[12px] text-[16px] text-center gap-3">
                            {digitalTwinDetails.characteristics
                              .contract_type === "sale"
                              ? "Price"
                              : "Rent"}{" "}
                            <span className="font-semibold">
                              {" "}
                              {"\u20AC"}{" "}
                              {digitalTwinDetails.pricing.price
                                ? digitalTwinDetails.pricing.price
                                : 0}
                            </span>
                          </span>
                        </div>
                      </div>

                      <div className=" flex w-full h-0.5 bg-[#D8D8D8] my-3"></div>

                      {selectMode && (
                        <div className="flex flex-col w-full">
                          <div className="flex justify-center items-center">
                            Select A Role
                          </div>
                          <div className="flex gap-3 mb-3">
                            <button
                              className={`border-2 text-[#283BBE] ${
                                mode == "host"
                                  ? " border-[#283BBE] text-[#283BBE] font-semibold"
                                  : "border-[#919194] text-[#757577]"
                              }  px-2 h-[40px] w-full text-[0.938rem]`}
                              onClick={() => {
                                setMode("host");
                              }}
                            >
                              {" "}
                              Lead meeting
                            </button>
                            <button
                              className={`border-2 ${
                                mode !== "host"
                                  ? " border-[#283BBE] text-[#283BBE] font-semibold"
                                  : "border-[#919194] text-[#757577]"
                              }  px-2 h-[40px] w-full text-[0.938rem]`}
                              onClick={() => {
                                setMode("client");
                              }}
                            >
                              {" "}
                              Follow meeting
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="flex w-full items-center">
                        {isTourCreated ? (
                          !selectMode ? (
                            <Button
                              children="Join Now"
                              className="bg-[#283BBE] rounded-md px-2 h-[40px] w-full text-[0.938rem]"
                              onClick={() => {
                                // handleJoinNow()
                                setSelectMode(true);
                              }}
                            />
                          ) : (
                            <Button
                              children="Continue"
                              className="bg-[#283BBE] rounded-md px-2 h-[40px] w-full text-[0.938rem]"
                              onClick={() => {
                                handleJoinNow();
                                // setSelectMode(true)
                              }}
                            />
                          )
                        ) : (
                          <Button
                            children="Schedule Tour"
                            className="bg-[#283BBE] rounded-md px-2 h-[40px] w-full text-[0.938rem]"
                            onClick={handleCreateVirtualTour}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" w-full lg:w-[68%] relative lg:order-1 ">
                  <div className="mt-[0.25rem] bg-white ">
                    {activeTab === "images" && (
                      <div
                        className="flex  relative group overflow-hidden min-h-[500px] lg:h-[294px] xl:h-[560px] border-2 bg-[#F6F6F8]"
                        onMouseMove={(e) => {
                          const rect = e.currentTarget.getBoundingClientRect();
                          const x = e.clientX - rect.left;
                          const y = e.clientY - rect.top;
                          const image = e.currentTarget.querySelector("img");

                          // Calculate percentage of cursor position within the image container
                          const xPercent = (x / rect.width) * 100;
                          const yPercent = (y / rect.height) * 100;

                          // Set the transform origin based on cursor position
                          image.style.transformOrigin = `${xPercent}% ${yPercent}%`;
                        }}
                      >
                        {digitalTwinDetails["property_images"] !== null ? (
                          <div>
                            <div>
                              {digitalTwinDetails["property_images"].map(
                                (item: any, index: any) => (
                                  <div
                                    key={index}
                                    className={`transition-opacity ${
                                      index === currentImage
                                        ? "opacity-100 z-10"
                                        : "opacity-0 z-10"
                                    } absolute w-full`}
                                  >
                                    <img
                                      src={item}
                                      className="w-full object-contain min-h-[500px] lg:h-[294px] xl:h-[560px] transition-transform duration-300 transform group-hover:scale-150"
                                      onMouseMove={(e) => {
                                        const rect =
                                          e.currentTarget.getBoundingClientRect();
                                        const x = e.clientX - rect.left;
                                        const y = e.clientY - rect.top;
                                        const xPercent = (x / rect.width) * 100;
                                        const yPercent =
                                          (y / rect.height) * 100;

                                        e.currentTarget.style.transformOrigin = `${xPercent} ${yPercent}`;

                                        e.currentTarget.style.transform =
                                          "scale(1.9)";
                                      }}
                                      onMouseLeave={(e) => {
                                        e.currentTarget.style.transform =
                                          "scale(1)";
                                        e.currentTarget.style.transformOrigin =
                                          "center";
                                      }}
                                      onClick={(e) => {
                                        // setSelectedImage(item);
                                        setIsImageModalOpen(true);
                                      }}
                                    />
                                  </div>
                                )
                              )}

                              <button
                                onClick={() => {
                                  setCurrentImage((prevIndex) =>
                                    prevIndex === 0
                                      ? digitalTwinDetails["property_images"]
                                          .length - 1
                                      : prevIndex - 1
                                  );
                                }}
                                className="absolute top-1/2 left-5 z-20 bg-[#F6F6F8] w-12 h-12 rounded-full flex items-center justify-center"
                                style={{ top: "45%" }}
                              >
                                <ArrowBackIosIcon className="font-bold text-[#001CDD]" />
                              </button>

                              <button
                                onClick={() => {
                                  setCurrentImage(
                                    (prevIndex) =>
                                      (prevIndex + 1) %
                                      digitalTwinDetails["property_images"]
                                        .length
                                  );
                                }}
                                className="absolute top-1/2 right-5 z-20 bg-[#F6F6F8] w-12 h-12 rounded-full flex items-center justify-center"
                                style={{ top: "45%" }}
                              >
                                <ArrowForwardIosIcon className="font-bold text-[#001CDD]" />
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="w-full h-full max-h-52 2xl:max-h-80 overflow-hidden rounded-md">
                            <img
                              src={
                                "https://storage.googleapis.com/oneverse-dev/realestate/no_property_image.jpg"
                              }
                              alt="house"
                              className="object-cover w-full h-full"
                            />
                          </div>
                        )}

                        {isImageModalOpen && (
                          <div className="fixed inset-1 bg-black bg-opacity-50 flex items-center justify-center z-30">
                            <div className="relative bg-white p-4 rounded-md shadow-lg w-[89%] h-[74%] max-w-full max-h-full">
                              <button
                                onClick={() => setIsImageModalOpen(false)}
                                className="absolute top-2 right-2 text-white bg-red-500 rounded-full w-8 h-8 flex items-center justify-center z-20"
                              >
                                X
                              </button>

                              {digitalTwinDetails["property_images"] !==
                                null && (
                                <div className="relative w-full h-full">
                                  <div className="relative w-full h-[500px] max-h-full">
                                    {digitalTwinDetails["property_images"].map(
                                      (item: any, index: any) => (
                                        <div
                                          key={index}
                                          className={`transition-opacity ${
                                            index === currentImage
                                              ? "opacity-100 z-10"
                                              : "opacity-0 z-10"
                                          } absolute w-full h-full`}
                                        >
                                          <img
                                            src={item}
                                            className="w-full h-full object-cover"
                                            onClick={(e) => {
                                              setIsImageModalOpen(true);
                                            }}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {/* Render other content based on activeTab */}
                    {activeTab === "digitalTwin" && (
                      <div>
                        {/* Render digital twin content here */}
                        <div className="flex items-center justify-center mt-1 w-full">
                          <iframe
                            src={iframeSrc}
                            width="100%"
                            height={525}
                            title="wall_iframe"
                            allow="xr-spatial-tracking"
                            allowFullScreen
                          />
                        </div>
                      </div>
                    )}
                    {activeTab === "videos" && (
                      <div>
                        {/* Render videos content here */}
                        <div className="flex items-center justify-center mt-1 w-full">
                          {/* <iframe src={iframeSrc} width="100%" height={525} title="wall_iframe" allow="xr-spatial-tracking" allowFullScreen /> */}
                          {/* <iframe
                            className="w-full aspect-video "
                            // src="https://www.youtube.com/embed/EvkcUBy2REI?si=haFaOdlMHD7zINox"
                            src={digitalTwinDetails.property_videos[0]}
                          ></iframe> */}

                          <iframe
                            className="w-full aspect-video "
                            // width="560"
                            // height="315"
                            // src="https://www.youtube.com/embed/tJ28g-YSyyY?si=u_Mvv5vNjBS7ro6c"
                            src={
                              digitalTwinDetails.property_videos &&
                              digitalTwinDetails.property_videos[0]
                            }
                            title=" video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          ></iframe>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col max-md:w-[100%] md:w-[50%] flex-grow bg-white p-2">
                    <div
                      className={`flex items-center justify-between border border-[#283BBE] rounded-[4px] opacity-1 py-1 px-4`}
                    >
                      <Button
                        onClick={() => setActiveTab("digitalTwin")}
                        className={`px-3 xs:text-[7px] sm:text-[8px] md:text-[10px] lg:text-[12px] xl:text-[14px] xs:rounded xl:rounded-md ${
                          activeTab === "digitalTwin"
                            ? "bg-[#283BBE] text-white"
                            : "text-[#283BBE]"
                        }`}
                        textColor="white"
                      >
                        Digital Twin
                      </Button>
                      <Button
                        onClick={() => setActiveTab("images")}
                        className={`px-4 xs:text-[7px] sm:text-[8px] md:text-[10px] lg:text-[12px] xl:text-[14px] xs:rounded xl:rounded-md ${
                          activeTab === "images"
                            ? "bg-[#283BBE] text-white "
                            : "text-[#283BBE]"
                        }`}
                        textColor="white"
                      >
                        Images
                      </Button>
                      <Button
                        onClick={() => setActiveTab("videos")}
                        className={`px-4 xs:text-[7px] sm:text-[8px]  md:text-[10px] lg:text-[12px] xl:text-[14px] xs:rounded xl:rounded-md ${
                          activeTab === "videos"
                            ? "bg-[#283BBE] text-white "
                            : "text-[#283BBE]"
                        }`}
                        textColor="white"
                      >
                        Videos
                      </Button>
                    </div>
                  </div>

                  <div
                    className="mt-5 text-[#FF307A] font-bold xs:text-[12] sm:text-[14px] md:text[16px] lg:text-[18px] xl:text-[24px] "
                    style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
                  >
                    <span>Description</span>
                  </div>

                  <div className="mt-3 text-[#2A2A2A] xs:text-[10] sm:text-[12px] md:text[14px] lg:text-[16px] xl:text-[20px]">
                    <span
                      className="block text-justify"
                      style={{ fontFamily: "Inter, sans-serif" }}
                    >
                      {digitalTwinDetails.description}
                    </span>
                  </div>

                  {/* Property Info */}
                  <div
                    className="mt-5 text-[#2A2A2A] xs:text-[10] sm:text-[11px] md:text[12px] lg:text-[14px] xl:text-[20px]"
                    style={{
                      fontFamily: "Inter, sans-serif",
                      fontWeight: "500",
                    }}
                  >
                    <div className="grid grid-cols-2  sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 mb-4 md:justify-center">
                      <div className=" flex  items-center mb-2">
                        <span className="opacity-50 mr-2">
                          <HomeIcon />
                        </span>
                        <span className="ml-1 text-[#283BBE] ">
                          {" "}
                          {digitalTwinDetails.characteristics.contract_type
                            ? toPascalCase(
                                digitalTwinDetails.characteristics.contract_type
                              )
                            : "Not Available"}
                        </span>
                      </div>
                      <div className="flex items-center mb-2">
                        <span className="opacity-50 mr-2">
                          <KingBedIcon />
                        </span>
                        <span className="ml-1 text-[#283BBE] ">
                          {" "}
                          {digitalTwinDetails.characteristics.num_bedrooms
                            ? digitalTwinDetails.characteristics.num_bedrooms +
                              "Bedrooms"
                            : "Not Available"}{" "}
                        </span>
                      </div>
                      <div className="flex items-center mb-2">
                        <span className="opacity-50 mr-2">
                          <BathtubIcon />
                        </span>
                        <span className="ml-1 text-[#283BBE] ">
                          {" "}
                          {digitalTwinDetails.characteristics.num_bathrooms
                            ? digitalTwinDetails.characteristics.num_bathrooms +
                              " Bathrooms"
                            : "Not Available"}{" "}
                        </span>
                      </div>
                      <div className=" flex  items-center mb-2">
                        <span className="opacity-50 mr-2">
                          {" "}
                          <OpenInFullIcon />
                        </span>
                        <span className="ml-1 text-[#283BBE] ">
                          {digitalTwinDetails.characteristics.area_sqm
                            ? digitalTwinDetails.characteristics.area_sqm +
                              "Sq.Mtr"
                            : "Not Available"}{" "}
                        </span>
                      </div>
                      <div className=" flex  items-center mb-2">
                        <span className="opacity-50 mr-2">Type</span>
                        <span className="ml-1 text-[#283BBE] ">
                          {digitalTwinDetails.characteristics.type_of_property
                            ? toPascalCase(
                                digitalTwinDetails.characteristics
                                  .type_of_property
                              )
                            : "Not Available"}
                        </span>
                      </div>
                      <div className=" flex  items-center mb-2">
                        <span className="opacity-50 mr-2">Lifestyle</span>
                        <span className="ml-1 text-[#283BBE] ">
                          {digitalTwinDetails.characteristics.lifestyle
                            ? toPascalCase(
                                digitalTwinDetails.characteristics.lifestyle
                              )
                            : "Not Available"}{" "}
                        </span>
                      </div>
                      <div className=" flex  items-center mb-2  col-span-2 sm:col-span-1">
                        <span className="opacity-50 mr-2">Listed On</span>
                        <span className="ml-1 text-[#283BBE] ">
                          {digitalTwinDetails.characteristics.listing_date
                            ? digitalTwinDetails.characteristics.listing_date
                            : "Not Available"}
                        </span>
                      </div>
                      <div className=" flex  items-center mb-2  col-span-2 sm:col-span-1">
                        <span className="opacity-50 mr-2">Country</span>
                        <span className="ml-1 text-[#283BBE] ">
                          {digitalTwinDetails.address.country
                            ? toPascalCase(digitalTwinDetails.address.country)
                            : "Not Available"}
                        </span>
                      </div>
                      <div className=" flex  items-center mb-2  col-span-2 sm:col-span-1">
                        <span className="opacity-50 mr-2">City</span>
                        <span className="ml-1 text-[#283BBE] ">
                          {digitalTwinDetails.address.city
                            ? toPascalCase(digitalTwinDetails.address.city)
                            : "Not Available"}
                        </span>
                      </div>
                      <div className=" flex  items-center mb-2 col-span-2 sm:col-span-1 ">
                        <span className="opacity-50 mr-2">Region</span>
                        <span className="ml-2 sm:ml-1 text-[#283BBE]  ">
                          {digitalTwinDetails.address.province
                            ? toPascalCase(digitalTwinDetails.address.province)
                            : "Not Available"}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* location */}
                  <div className="mt-3 bg-white text-[2rem] p-6 shadow-sm	border border-1">
                    <div className="w-full flex items-center ">
                      <div className="flex flex-start w-1/2  ">
                        <span
                          className="text-[#FF307A] font-bold xs:text-[12] sm:text-[14px] md:text[16px] lg:text-[18px] xl:text-[24px]"
                          style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
                        >
                          Location
                        </span>
                      </div>
                    </div>
                    <div className=" flex w-1/3 h-0.5 bg-[#D8D8D8] mb-3"></div>
                    <div className="flex items-center justify-center mt-1 w-full overflow-auto ">
                      <MapView
                        geo_location={
                          digitalTwinDetails.geo_location &&
                          digitalTwinDetails.geo_location &&
                          digitalTwinDetails.geo_location
                        }
                      />
                    </div>
                  </div>

                  {/* Floor Plan */}
                  <div className=" mt-3 bg-white p-6 shadow-sm	border border-1">
                    <div className="w-full flex items-center ">
                      <div
                        className="flex flex-start w-1/2  "
                        style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
                      >
                        <span className="text-[#FF307A] font-bold xs:text-[12] sm:text-[14px] md:text[16px] lg:text-[18px] xl:text-[24px]">
                          Floor Plan
                        </span>
                      </div>
                      {/* <div className="flex justify-end w-1/2 ">
                          <Button children={`Matterport PDF `} textColor="black" onClick={handleOpen}
                            className="text-[#283BBE] text-[16px] border border-[#283BBE] rounded-full px-4 h-[45px] sm:h-[40px]" />
                        </div> */}
                    </div>
                    <div className=" flex w-1/3 h-0.5 bg-[#D8D8D8] mb-3"></div>
                    {pdfUrl !== null && pdfUrl.length !== 0 ? (
                      <div
                        className="flex  relative group overflow-hidden min-h-[500px] lg:h-[294px] xl:h-[560px] border-2 bg-[#F6F6F8]"
                        onMouseMove={(e) => {
                          const rect = e.currentTarget.getBoundingClientRect();
                          const x = e.clientX - rect.left;
                          const y = e.clientY - rect.top;
                          const image = e.currentTarget.querySelector("img");

                          // Calculate percentage of cursor position within the image container
                          const xPercent = (x / rect.width) * 100;
                          const yPercent = (y / rect.height) * 100;

                          // Set the transform origin based on cursor position
                          image.style.transformOrigin = `${xPercent}% ${yPercent}%`;
                        }}
                      >
                        {
                          <div>
                            {pdfUrl.map((item, index: any) => (
                              <div
                                key={index}
                                className={`transition-opacity ${
                                  index === currentSlide
                                    ? "opacity-100 z-10"
                                    : "opacity-0 z-10"
                                } absolute w-full `}
                              >
                                {!item.endsWith(".pdf") && (
                                  <img
                                    // src={item.property_thumbnail}
                                    // alt={item.title}
                                    alt={`Slide ${index}`}
                                    src={item}
                                    className="w-full object-contain min-h-[500px] lg:h-[294px] xl:h-[560px] transition-transform duration-300 transform group-hover:scale-150"
                                    onMouseMove={(e) => {
                                      const rect =
                                        e.currentTarget.getBoundingClientRect();
                                      const x = e.clientX - rect.left;
                                      const y = e.clientY - rect.top;
                                      const xPercent = (x / rect.width) * 100;
                                      const yPercent = (y / rect.height) * 100;

                                      e.currentTarget.style.transformOrigin = `${xPercent}% ${yPercent}%`;

                                      e.currentTarget.style.transform =
                                        "scale(1.9)";
                                    }}
                                    onMouseLeave={(e) => {
                                      e.currentTarget.style.transform =
                                        "scale(1)";
                                      e.currentTarget.style.transformOrigin =
                                        "center";
                                    }}
                                    onClick={(e) => {
                                      setIsFloorPlanModalOpen(true);
                                    }}
                                  />
                                )}
                                {item.endsWith(".pdf") && (
                                  <div className="overflow-auto lg:h-[294px] xl:h-[560px] ">
                                    <PDFViewer pdfUrl={item} />
                                  </div>
                                )}
                              </div>
                            ))}

                            <button
                              onClick={prevSlide}
                              className="absolute top-1/2 left-5 z-20 bg-[#F6F6F8] w-12 h-12 rounded-full flex items-center justify-center"
                              style={{ top: "45%" }}
                            >
                              <ArrowBackIosIcon className="font-bold text-[#001CDD]" />
                            </button>
                            <button
                              onClick={nextSlide}
                              className="absolute top-1/2 right-5 z-20  bg-[#F6F6F8] w-12 h-12 rounded-full flex items-center justify-center"
                              style={{ top: "45%" }}
                            >
                              <ArrowForwardIosIcon className="font-bold text-[#001CDD]" />
                            </button>
                          </div>
                        }
                      </div>
                    ) : (
                      <div> Not Available</div>
                    )}

                    {isFloorPlanModalOpen && (
                      <div className="fixed inset-3 bg-black bg-opacity-50 flex items-center justify-center z-30">
                        <div className="relative bg-white p-4 rounded-md shadow-lg w-[89%] h-[80%] max-w-full max-h-full overflow-hidden">
                          <button
                            onClick={() => setIsFloorPlanModalOpen(false)}
                            className="absolute top-2 right-2 text-white bg-red-500 rounded-full w-8 h-8 flex items-center justify-center z-20"
                          >
                            X
                          </button>

                          {pdfUrl.map((item, index: any) => (
                            <div
                              key={index}
                              className={`transition-opacity ${
                                index === currentSlide
                                  ? "opacity-100 z-10"
                                  : "opacity-0 z-10"
                              } absolute w-full h-full flex justify-center items-center`}
                            >
                              {!item.endsWith(".pdf") && (
                                <img
                                  alt={`Slide ${index}`}
                                  src={item}
                                  className="w-full h-full max-w-full max-h-full object-contain"
                                  onClick={() => setIsFloorPlanModalOpen(true)}
                                />
                              )}
                              {item.endsWith(".pdf") && (
                                <div className="overflow-auto lg:h-[294px] xl:h-[560px] w-full">
                                  <PDFViewer pdfUrl={item} />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Other Features */}
                  <div className="mt-5 text-[#2A2A2A] bg-white p-6 shadow-sm	border border-1 ">
                    <div
                      className=" text-[#FF307A] font-bold xs:text-[12] xs:text-[10] sm:text-[12px] md:text[14px] lg:text-[16px] xl:text-[20px]"
                      style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
                    >
                      Other Features
                    </div>
                    <div className=" flex w-1/3 h-0.5 bg-[#D8D8D8] mb-3"></div>
                    <div className="grid grid-cols-2  sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 mb-4 md:justify-center">
                      <div className=" flex  items-center mb-2">
                        <span className="opacity-50 mr-2">Year Built</span>
                        <span className="ml-1 text-[#2A2A2A] font-semibold">
                          {" "}
                          {
                            digitalTwinDetails.energy_efficiency
                              .year_of_construction
                          }
                        </span>
                      </div>
                      <div className="flex items-center mb-2">
                        <span className="opacity-50 mr-2">Floor</span>
                        <span className="ml-1 text-[#2A2A2A] font-semibold">
                          {" "}
                          {digitalTwinDetails.characteristics.floor} out{" "}
                          {
                            digitalTwinDetails.characteristics
                              .total_building_floors
                          }{" "}
                          floor
                        </span>
                      </div>
                      <div className="flex items-center mb-2">
                        <span className="opacity-50 mr-2">Parking Spaces</span>
                        <span className="ml-1 text-[#2A2A2A] font-semibold">
                          {
                            digitalTwinDetails.characteristics
                              .num_parking_spaces
                          }
                        </span>
                      </div>
                      <div className="flex items-center mb-2">
                        <span className="opacity-50 mr-2">Lift</span>
                        <span className="ml-1 text-[#2A2A2A] font-semibold">
                          {digitalTwinDetails.characteristics
                            .other_characteristics.has_elevator
                            ? "Available"
                            : "Not Available"}
                        </span>
                      </div>
                      <div className=" flex flex-wrap items-center mb-2">
                        <span className="opacity-50 mr-2">
                          HOA/Maintenance Fee
                        </span>
                        <span className="ml-1 text-[#2A2A2A] font-semibold">
                          €{digitalTwinDetails.pricing.condominium_expenses}
                        </span>
                      </div>
                      <div className=" flex  items-center mb-2">
                        <span className="opacity-50 mr-2">
                          Energy Efficiency
                        </span>
                        <span className="ml-1 text-[#2A2A2A] font-semibold">
                          {
                            digitalTwinDetails.energy_efficiency
                              .energy_efficiency_rating
                          }{" "}
                        </span>
                      </div>
                      <div className=" flex  items-center mb-2  col-span-2 sm:col-span-1">
                        <span className="opacity-50 mr-2">Heating</span>
                        <span className="ml-1 text-[#2A2A2A] font-semibold">
                          {digitalTwinDetails.energy_efficiency.heating}
                        </span>
                      </div>
                      <div className=" flex  items-center mb-2  col-span-2 sm:col-span-1">
                        <span className="opacity-50 mr-2">Fee Frequency</span>
                        <span className="ml-1 text-[#2A2A2A]  font-semibold">
                          {digitalTwinDetails.pricing.fee_frequency
                            ? digitalTwinDetails.pricing.fee_frequency
                            : "Not Available"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-0 w-full bg-slate-000 py-4 sm:py-8 px-2 md: pt-8 ">
                <h2
                  className="text-[#FF307A] xs:text-[12px] sm:text-[14px] md:text-[18px] lg:text-[28px] xl:text-[32px] text-start leading-tight font-bold mb-5 "
                  style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
                >
                  Network Properties
                </h2>
                {/* <div className="grid grid-cols-3 gap-4 "> */}
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8 ">
                  {networkProperties.map((networkProperty, index) => (
                    <NetworkPropertyCardSquare
                      key={index}
                      // imageUrl="https://storage.googleapis.com/oneverse-dev/realestate/LandingPageIntroImage.jpeg"
                      title={networkProperty.title}
                      imageUrl={networkProperty.property_thumbnail}
                      // className="w-[441px] h-[325px]  rounded-lg w-full md:w-auto rounded-l"
                      className="w-full md:w-auto rounded-l "
                      commission={
                        networkProperty.characteristics.contract_type ===
                          "sale" && networkProperty.sale_commission_config
                          ? networkProperty.sale_commission_config
                              .buyer_agent_commission || 0.0
                          : networkProperty.characteristics.contract_type ===
                              "rent" && networkProperty.rent_commission_config
                          ? networkProperty.rent_commission_config
                              .buyer_agent_commission || 0.0
                          : 0.0
                      }
                      bedrooms={networkProperty.characteristics.num_bedrooms}
                      area={networkProperty.characteristics.area_sqm}
                      city={networkProperty.address.city}
                      contractType={
                        networkProperty.characteristics.contract_type
                      }
                      price={
                        networkProperty.characteristics.contract_type === "sale"
                          ? networkProperty.pricing.price
                          : networkProperty.pricing.rent_per_month
                      }
                      digitalTwinId={networkProperty._id}
                      matterportId={networkProperty.matterport_id}
                      handleDetailButton={handleDetailButton}
                    />
                  ))}
                </div>
                <div className="flex justify-center ">
                  <button
                    className="outline outline-offset-2 outline-1 bg-transparent p-2 rounded-lg text-[#283BBE] border border-#283BBE font-inter font-bold ..."
                    onClick={() => {
                      navigate(`/network_list`);
                    }}
                  >
                    <CachedIcon /> Load More
                  </button>
                </div>
              </div>

              <div className="w-full">
                <footer className="bg-[#000630] relative">
                  <Footer />
                </footer>
              </div>
            </>
          ) : (
            <div>Loading........</div>
          )}
        </div>
      </div>
    </>
  );
};
