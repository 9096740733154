// DataContext.js
import React, { createContext, useContext, useState, ReactNode } from 'react';
interface DataProviderProps {
  children: ReactNode;
}
const DataContext = createContext<any>(null);

// export const DataProvider = ({ children }) => {
  export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const [sharedData, setSharedData] = useState(null);

  return (
    <>
    <DataContext.Provider value={{ sharedData, setSharedData }}>
      {children}
    </DataContext.Provider>
    </>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
