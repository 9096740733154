import React, { useState } from "react";

interface FilterSelectProps {
  options: string[];
  label?: string;
  className?: string;
  [key: string]: any; // Allows for additional props to be passed
  handleChange:(event: React.ChangeEvent<HTMLSelectElement>) => void;
}

function FilterSelect({ options, label, className, value, handleChange, ...props }: FilterSelectProps) {
  const [selectedOption,setSelectedOption ] = useState<string>(value);
  const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("target", options);
    
    setSelectedOption(event.target.value);
    handleChange(event)
  };
  console.log("test", label);
  

  const id = Math.random().toString(36).substring(7); // Generate a unique id
  return (
    <div className="flex items-start flex-col">
      {label && (
        <label htmlFor={id} className={`${label!=='Contract Type' ?  'xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px]' : ' xs:text-[8px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px]'} text-gray-400 font-semibold`}>
          {label}
        </label>
      )}

      <select
        {...props}
        id={id}
        className={`px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 w-full xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px] ${className} `}
        value={selectedOption}
        onChange={handleChangeSelect}
      >
        {options?.map((option) => (
          <option key={option} value={option} className="text-black xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]">
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FilterSelect;