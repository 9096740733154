import React from 'react';

import './CardComponent.css';

import {
  useNavigate,
  // Link
} from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


interface CardComponentProps {
  matterport_id: string;
  index: number;
  digitalTwinId: string;
  title: string;
  description: string;
  thumbnail: string
}
// export let digitalTwinDetails={}
const CardComponent: React.FC<CardComponentProps> = ({ matterport_id, index, digitalTwinId, title, description, thumbnail }) => {

  // const handleCardClick = () => {
  //   // Replace 'https://example.com' with the URL you want to open
  //   window.location.href = `/property/${item}`;
  // };
  const navigate = useNavigate()
  const handleJoinVrTour = () => {
    navigate(`/property_details?digitalTwinId=${digitalTwinId}&propertyId=${matterport_id}`);
  }

  return (
    <Card
    // sx={{ maxWidth: 345, minWidth:345 }}
    >
      <CardMedia
        sx={{ height: 140 }}
        image={thumbnail}
        title={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {/* {description} */}
          {`${description.split(/\s+/).slice(0, 11).join(" ")} ...`}

        </Typography>
        <Typography variant="body1" style={{ marginTop: '8px' }}>
                                    
         </Typography>
      </CardContent>
      <CardActions>
        {/* <Link to={ className="card-link"> */}
        <Button size="small" onClick={handleJoinVrTour}>Details</Button>
        {/* </Link> */}
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>

  );
}

export default CardComponent;
