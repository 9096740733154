import React, { useState } from "react";

export const Tabs = ({ tabs, handleTabButton, digital_twin_id, matterport_id }:any) => {
  const [activeTab, setActiveTab] = useState(tabs[1].label);

  return (
    <div className="grid grid-cols-3 border-[2px] border-[#283BBE] items-center rounded-md p-[1px]">
      {tabs.map((tab : any) => (
        <span
          key={tab.label}
          className={`text-center cursor-pointer max-md:text-[13px] text-xs py-1 ${
            activeTab === tab.label ? "bg-[#283BBE] text-white rounded-md" : ""
          }`}
          onClick={() =>{ setActiveTab(tab.label),
            handleTabButton(digital_twin_id, matterport_id, tab.label==="Images" ? 'images' : tab.label==="Video(s)" ? 'videos' : 'digitalTwin')
          }}
        >
          {tab.label}
        </span>
      ))}
    </div>
  );
};
