import React from "react"

const LandingPageOurStory = () => {
    return (
        <>
            <div className="bg-[#FFCFE0] opacity-1 p-4 md:p-6 lg:p-16">
                <h1 className="text-center text-[#FF307A] opacity-1 font-bold text-2xl md:text-3xl lg:text-4xl pt-8 " style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }}>
                    Numbers tell our story
                </h1>
                <div className="flex flex-col md:flex-row justify-between items-center pt-8 md:pt-12 lg:pt-16">
                    <div className="flex flex-col justify-center items-center mb-8 md:mb-0">
                        <img src="https://storage.googleapis.com/oneverse-dev/realestate/image4.png" alt="logo" className="h-24 w-24 md:h-32 md:w-32 lg:h-48 lg:w-48" />
                        <h2 className="text-[#283BBE] text-xl md:text-2xl lg:text-3xl font-semibold tracking-[0.1px] pt-5"style={{ fontFamily: 'Inter, sans-serif' }}>
                            200+ Properties
                        </h2>
                        <span className="text-[#283BBE] text-lg md:text-xl lg:text-2xl tracking-[0.06px] opacity-1 pt-3" style={{ fontFamily: 'Inter, sans-serif' }}>
                            listed in metaverse
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center mb-8 md:mb-0">
                        <img src="https://storage.googleapis.com/oneverse-dev/realestate/image5.png" alt="logo" className="h-24 w-24 md:h-32 md:w-32 lg:h-48 lg:w-48" />
                        <h2 className="text-[#283BBE] text-xl md:text-2xl lg:text-3xl font-semibold tracking-[0.1px] pt-5" style={{ fontFamily: 'Inter, sans-serif' }}>
                            30+ Agents
                        </h2>
                        <span className="text-[#283BBE] text-lg md:text-xl lg:text-2xl tracking-[0.06px] opacity-1 pt-3" style={{ fontFamily: 'Inter, sans-serif' }}>
                            across globe
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center mb-8 md:mb-0">
                        <img src="https://storage.googleapis.com/oneverse-dev/realestate/image6.png" alt="logo" className="h-24 w-24 md:h-32 md:w-32 lg:h-48 lg:w-48" />
                        <h2 className="text-[#283BBE] text-xl md:text-2xl lg:text-3xl font-semibold tracking-[0.1px] pt-5" style={{ fontFamily: 'Inter, sans-serif' }}>
                            10+ Cities
                        </h2>
                        <span className="text-[#283BBE] text-lg md:text-xl lg:text-2xl tracking-[0.06px] opacity-1 pt-3" style={{ fontFamily: 'Inter, sans-serif' }}>
                            Presence across Italy
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center mb-8 md:mb-0">
                        <img src="https://storage.googleapis.com/oneverse-dev/realestate/image7.png" alt="logo" className="h-24 w-24 md:h-32 md:w-32 lg:h-48 lg:w-48" />
                        <h2 className="text-[#283BBE] text-xl md:text-2xl lg:text-3xl font-semibold tracking-[0.1px] pt-5" style={{ fontFamily: 'Inter, sans-serif' }}>
                            15M+ Euros
                        </h2>
                        <span className="text-[#283BBE] text-lg md:text-xl lg:text-2xl tracking-[0.06px] opacity-1 pt-3" style={{ fontFamily: 'Inter, sans-serif' }}>
                            inventory for sales
                        </span>
                    </div>
                </div>
            </div>



            {/* <div className="bg-[#FFCFE0] opacity-1">
                <h1 className="text-center text-[#FF307A] opacity-1 font-semibold text-4xl pt-[63px]">
                    Numbers tell our story
                </h1>
                <div className=" flex items-center justify-between  ml-[130px] mr-[146px] pt-[62px] pb-[75px] ">
                    <div className=" flex flex-col justify-center items-center ">
                    <img src="/images/image4.png" alt="logo" height={200} width={200} />
                        <h1 className="text-[#7E41FF] text-3xl font-semibold tracking-[0.1px] pt-[21px]">
                            200+ Properties
                        </h1>
                        <span className="text-[#7E41FF] text-xl tracking-[0.06px] opacity-1 pt-[10px]">
                            listed in metaverse
                        </span>
                    </div>

                    <div className=" flex flex-col  justify-center items-center ">
                    <img src="/images/image5.png" alt="logo" height={200} width={200} />
                        <h1 className="text-[#7E41FF] text-3xl font-semibold tracking-[0.1px] pt-[21px]">
                            30+ Agents
                        </h1>
                        <span className="text-[#7E41FF] text-xl tracking-[0.06px] opacity-1 pt-[10px] ">
                            across globe
                        </span>
                    </div>

                    <div className=" flex flex-col  justify-center items-center ">
                        <img src="/images/image6.png" alt="logo" height={200} width={200} />
                        <h1 className="text-[#7E41FF] text-3xl font-semibold tracking-[0.1px] pt-[21px]">
                            10+ Cities
                        </h1>
                        <span className="text-[#7E41FF] text-xl tracking-[0.06px] opacity-1 pt-[10px]">
                            Presence across Italy
                        </span>
                    </div>

                    <div className=" flex flex-col  justify-center items-center ">
                    <img src="/images/image7.png" alt="logo" height={200} width={200} />
                        <h1 className="text-[#7E41FF] text-3xl font-semibold tracking-[0.1px] pt-[21px]">
                            15M+ Euros
                        </h1>
                        <span className="text-[#7E41FF] text-xl tracking-[0.06px] opacity-1 pt-[10px]">
                            inventory for sales
                        </span>
                    </div>
                </div>
            </div> */}


        </>
    )
}


export default LandingPageOurStory
