import React, { useState, ChangeEvent, FormEvent } from "react";
import { useLocation } from "react-router-dom";
import {
  // Container,
  // Paper,
  Typography,
  TextField,
  // Button,
} from "@mui/material";

import UserService from "rc-app/services/UserService";
import { eventUpdateStream, getAgentByEmail } from "rc-app/Pages/Home";

interface Props {
  propertyId: string;
  onModelSet: (sid: string, name: string) => void;
  message: string;
  selectAnotherProperty?:boolean
  setSelectAnotherProperty?: (value: boolean)=> void

  handleExploreButton?: (
    digital_twin_id: String,
    matterport_id: String
  ) => void;
}

interface State {
  name: string;
  sid: string;
}

export const SetModel: React.FC<Props> = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const channel = queryParams.get("channel");
  console.log("selectAnotherProperty",props);
  

  const [state, setState] = useState<State>({
    name: channel,
    sid: props.propertyId,
  });

  // private email: string = UserService.getTokenParsed().email || '';

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    const { email } = UserService.getTokenParsed();
    event.preventDefault();
    props.onModelSet(state.sid, state.name);
    // console.log('hostState', props);
    

    getAgentByEmail(email).then((data) => {
      const agent = data.agent;
      // setAgentId(agent._id)
      if (props.message === undefined) {
        console.log("photon111", props.message);
        eventUpdateStream(
          "PhotonSessoin",
          agent["_id"],
          email,
          "join_photon",
          "true"
        );
      }
    });

    // props.handleExploreButton && props.handleExploreButton('65f7119b6c4a87ddc41be7b7','AcGaCsA1oax')
  };

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, name: event.target.value });
  };

  const onSidChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, sid: event.target.value });
  };
  
  

  return (
    <>
      {/* <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h5" align="center" gutterBottom>
          HOST A SESSION
        </Typography>
        <form onSubmit={onSubmit}>
          <div className="mb-6"></div>

          <TextField
            id="session-name"
            label="Session Name"
            value={state.name}
            onChange={onNameChanged}
            placeholder="Set Session Name"
            fullWidth
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id="model-sid"
            label="Model SID"
            value={state.sid}
            onChange={onSidChanged}
            disabled
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="set-model-button"
            fullWidth
          >
            START
          </Button>
        </form>
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          className="set-model-message"
        >
          {props.message}
        </Typography>
      </Paper>
    </Container> */}

      <div className=" flex flex-col gap-6 w-1/2 rounded-md overflow-auto justify-center bg-white  shadow-md p-4 ">
        <form onSubmit={onSubmit}>
          <div className="w-full flex flex-col gap-2">
            <div className={`flex items-center justify-center`}>
              <h1 className="font-bold text-black text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5">
                HOST A SESSION
              </h1>
            </div>
          </div>
          <div className="w-full flex flex-col gap-3">
            <div className="flex justify-center">
              <TextField
                id="session-name"
                label="Session Name"
                value={state.name}
                onChange={onNameChanged}
                placeholder="Set Session Name"
                fullWidth
                margin="normal"
                variant="outlined"
                disabled
              />
            </div>
            <div className="flex justify-center">
              <TextField
                id="model-sid"
                label="Model SID"
                value={state.sid}
                onChange={onSidChanged}
                disabled
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                onClick={()=>{
                  // props.setSelectAnotherProperty(true)
                }}
                className=" bg-[#283BBE]  w-full text-white  font-semibold border-2 border-[#283BBE] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]"
              >
                Continue
              </button>
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                className=" bg-[#FF307A]  w-full text-white  font-semibold border-2 border-[#EC0000] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]"
              >
                Share
              </button>
            </div>
            <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              className="set-model-message"
            >
              {props.message}
            </Typography>
          </div>

          {/* 
                <div className="flex flex-row items-center gap-2 w-full py-4 " >
                  
                </div>

                <div className={`flex `} >
                </div> 
              */}
        </form>
      </div>
    </>
  );
};

// import React, { ChangeEvent, Component, FormEvent } from 'react';

// import { TextField, Button, Typography,
//   Container,  Paper,
// } from '@mui/material';
// import { useLocation } from 'react-router-dom';

// interface Props {
//   message?: string;
//   onModelSet?: (sid: string, session: string) => void;
//   // property?: string;
//   propertyId?: string
// }

// interface State {
//   name: string;
//   sid: string;
// }

// export class SetModel extends Component<Props, State> {

//   constructor(props: Props) {

//     super(props);
//     const location = useLocation()
//   const queryParams = new URLSearchParams(location.search);
//   const channel = queryParams.get('channel');
//     console.log("length",this.props.propertyId.length );
//     this.state = {
//       name: channel,
//       // sid: this.props.propertyId.length >1 ? this.props.propertyId : this.props.property
//       sid: this.props.propertyId
//     };
//     this.onNameChanged = this.onNameChanged.bind(this);
//     this.onSidChanged = this.onSidChanged.bind(this);
//     this.onSubmit = this.onSubmit.bind(this);
//   }

//   private onSubmit(event: FormEvent<HTMLFormElement>) {
//     event.preventDefault();
//     this.props.onModelSet(this.state.sid, this.state.name);
//   }

//   private onNameChanged(event: ChangeEvent<HTMLInputElement>) {
//     this.setState({ name: event.target.value });
//   }

//   private onSidChanged(event: ChangeEvent<HTMLInputElement>) {
//     this.setState({ sid: event.target.value });
//   }

//   render(): JSX.Element {
//     return (
//       <Container maxWidth="sm">
//       <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
//         <Typography variant="h5" align="center" gutterBottom>
//           HOST A SESSION
//         </Typography>
//         <form onSubmit={this.onSubmit}>
//           <div className="mb-6">
//           </div>

//           <TextField
//             id="session-name"
//             label="Session Name"
//             value={this.state.name}
//             onChange={this.onNameChanged}
//             placeholder="Set Session Name"
//             fullWidth
//             margin="normal"
//             variant="outlined"
//             disabled
//           />
//           <TextField
//             id="model-sid"
//             label="Model SID"
//             value={this.state.sid}
//             onChange={this.onSidChanged}
//             disabled
//             fullWidth
//             margin="normal"
//             variant="outlined"
//           />
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             className="set-model-button"
//             fullWidth
//           >
//             START
//           </Button>
//         </form>
//         <Typography variant="body1" color="textSecondary" align="center" className="set-model-message">
//           {this.props.message}
//         </Typography>
//       </Paper>
//     </Container>
//       // <div className="set-model-container" >
//       //   <div className="set-model-title">HOST A SESSION</div>
//       //   <form className="set-model-form" onSubmit={this.onSubmit}>
//       //     <div className="mb-6">
//       //         <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Large input</label>
//       //         <input type="text" id="large-input" className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
//       //     </div>
//       //     <TextField
//       //       required
//       //       id="outlined-required"
//       //       label="Required"
//       //       defaultValue="Hello World"
//       //     />
//       //     {/* <FloatingLabel variant="outlined" label="Default Outlined" /> */}
//       //     {/* <TextInput id="email1" type="email" placeholder="name@flowbite.com" required /> */}
//       //     <label>
//       //       Session Name
//       //       <input type="text" value={this.state.name}
//       //         onChange={this.onNameChanged} placeholder='Set Session Name'
//       //       ></input>
//       //     </label>
//       //     <label>
//       //       Model SID
//       //       <input type="text" value={this.state.sid}
//       //         onChange={this.onSidChanged} disabled
//       //       ></input>
//       //     </label>
//       //     <input className="set-model-button" type="submit" value="START"></input>
//       //   </form>
//       //   <div className="set-model-message">{this.props.message}</div>
//       // </div>
//     );
//   }
// }
