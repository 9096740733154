import React, { useEffect, useState } from "react";

import axios from "axios";

// import { getAgentById, getDigitalTwinById } from "../Home";

import { useNavigate } from "react-router-dom";

import MiniDrawer from "rc-app/components/MiniDrawer";
import FilterSelect from "rc-app/components/Filter Select";
// import Slider from "rc-app/components/Slider";
import SearchBar from "rc-app/components/Search Bar";
// import PropertyCardLarge from "rc-app/components/PropertyCardLarge";
// import PaginationRounded from "rc-app/components/Pagination";
import Button from "rc-app/components/Button";

import { toPascalCase } from "rc-app/utils";
import UserService from "rc-app/services/UserService";

// import Modal from "@mui/material/Modal";

// import HomeIcon from "@mui/icons-material/Home";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import KingBedIcon from "@mui/icons-material/KingBed";
// import OpenInFullIcon from "@mui/icons-material/OpenInFull";
// import DataTable from "rc-app/components/DataTable";

import { Tabs } from "rc-app/components/Card/tabs";
import { PropertyCard } from "rc-app/components/Card/propertyCard";
import { CommissionAmount } from "rc-app/components/CommissionAmount";
import { ToursCompleted } from "rc-app/components/ToursCompleted";


import { CircularProgress } from "@mui/material";
import PriceFilter from "rc-app/components/PriceFilter";

export const PropertiesShortlist = () => {
  const navigate = useNavigate();

  // const [reveal_address] = useState(true);
  // const [customer, setCustomer] = useState({});
  // const [shortlistPropertyIds, setShortlistPropetyIds] = useState([]);
  // const [digitalTwinDetailList, setDigitalTwinDetailList] = useState([]);

  const [properties, setProperties] = useState([]);
  const [searchedDigitalTwin, setSearchDigitalTwin] = useState([]);

  // const [agentId, setAgentId] = useState("");
  // const [agentEmail, setAgentEmail] = useState("");

  const [loading, setLoading] = useState(true)

  const [region, setRegion] = useState<{ [key: string]: string }>({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  let pageLimit = 10;


  const [selectedOption, setSelectedOption] = useState({
    region: "all",
    lifestyle: "all",
    type: "all",
    contract_type: "all",
    // price: 0,
  });

  // const region: { [key: string]: string } = {
  //   Abruzzo: "abruzzo",
  //   "Aosta Valley": "aosta_valley",
  //   Puglia: "puglia",
  //   Calabria: "calabria",
  //   Campania: "campania",
  //   Lazio: "lazio",
  //   Liguria: "liguria",
  //   Marche: "marche",
  //   Sicily: "sicily",
  // };
  const lifestyle: { [key: string]: string } = {
    History: "history",
    Beach: "beach",
    Waterfront: "waterfront",
    Lakefront: "lakefront",
    Metropolitan: "metropolitan",
    "Country Living": "country_living",
    "Wine And Vineyards": "wine_and_vineyards",
    "Eco Friendly": "eco_friendly",
    Mountain: "mountain",
  };

  const type: { [key: string]: string } = {
    Apartment: "apartment",
    Castle: "castle",
    Estate: "estate",
    "Free Standing House": "free_standing_house",
    Office: "office",
    Farmhouse: "farmhouse",
    Palace: "palace",
    Penthouse: "penthouse",
    Shop: "shop",
    Studio: "studio",
    Villa: "villa",
  };
  const contract_type: { [key: string]: string } = {
    Sale: "sale",
    Rent: "rent",
    Lease: "lease",
  };

  async function fetchProperties(minPrice = 0, maxPrice = 0) {
    try {
      let regionKey = "";
      let typeKey = "";
      let lifestyleKey = "";
      let contractTypeKey = "";
      // let priceMinQueryParam = "";
      // if (selectedOption.price > 0) {
      //   priceMinQueryParam = `&price=${selectedOption.price}`;
      // }

      console.log("Selected region:", selectedOption.region);

      // if (selectedOption.region !== "all" && region[selectedOption.region]) {
      //   regionKey = region[selectedOption.region];
      //   console.log("Region key:", regionKey);
      // }

      if (selectedOption.region !== "all" || "All") {
        regionKey = selectedOption.region;
      } else if (regionKey === undefined) {
        regionKey = "";
      } else {
        regionKey = "";
      }

      if (selectedOption.type !== "all" && type[selectedOption.type]) {
        typeKey = type[selectedOption.type];
      }

      if (
        selectedOption.lifestyle !== "all" &&
        lifestyle[selectedOption.lifestyle]
      ) {
        lifestyleKey = lifestyle[selectedOption.lifestyle];
      }

      if (
        selectedOption.contract_type !== "all" &&
        contract_type[selectedOption.contract_type]
      ) {
        contractTypeKey = contract_type[selectedOption.contract_type];
      }

      const API_URL = `${
        process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL
      }/api/v1/realestate/views/re_customer/profile/${UserService.getUseremail()}/property_shortlist?sort_by=created_at&sort_order=-1&page=${
        currentPage - 1
      }&limit=${pageLimit}&${
        regionKey !== "All" && regionKey !== "all" && regionKey !== ""
          ? `region=${regionKey}`
          : ""
        // regionKey == (undefined || "") ? "all" : regionKey
      }&type_of_property=${typeKey}&lifestyle=${lifestyleKey}&contract_type=${contractTypeKey}&${
        minPrice != 0 ? `min_price=${minPrice}` : ""
      }&${maxPrice != 0 ? `max_price=${maxPrice}` : ""}`;
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      setProperties(response.data);
      setSearchDigitalTwin(response.data);
      setTotalPages(response.data);
      setLoading(false)
      console.log("9891", response.data);



      console.log("Selected region:", selectedOption.region);
      

      console.log(contractTypeKey, lifestyleKey, typeKey);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  }

  useEffect(() => {
    fetchProperties();
  }, [
    selectedOption.region,
    selectedOption.type,
    selectedOption.lifestyle,
    selectedOption.contract_type,
    currentPage,
  ]);


  useEffect(() => {
    async function fetchRegion() {
      try {
        const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_region_details?sort_by=created_at&sort_order=-1&page=0&limit=20`;

        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${UserService.getToken()}`,
            "Content-Type": "application/json",
          },
        });
        const convertedObject: { [key: string]: string } = {};
        response.data.re_region_details_list.forEach((item: any) => {
          convertedObject[item.region] = item.country_id;
        });
        setRegion({ ...convertedObject });
        console.log("regionxx", convertedObject);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    }

    fetchRegion();
  }, []);

  // async function fetchgetCustomerByEmail(email: any) {
  //   try {
  //     const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_customer/profile/${email}`;
  //     const response = await axios.get(API_URL, {
  //       headers: {
  //         Authorization: `Bearer ${UserService.getToken()}`,
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     // setAgentId(response.data.customer.agent_id[0]);
  //     // getAgentById(agentId).then((res) => {
  //     //   setAgentEmail(res.agent.email_id);
  //     // });
  //     setCustomer(response.data.customer);
  //     setShortlistPropetyIds(
  //       response.data.customer.property_shortlist.map(
  //         (property: any) => property.mp_digital_twin_id
  //       )
  //     );

  //     interface PropertyShortlistItem {
  //       mp_digital_twin_id: string;
  //     }

  //     const propertyShortlist: PropertyShortlistItem[] =
  //       response.data.customer.property_shortlist;

  //     const promises = propertyShortlist.map((property) => {
  //       const digitalTwinId = property.mp_digital_twin_id;
  //       return getDigitalTwinById(digitalTwinId, reveal_address);
  //     });

  //     const digitalTwinDetails = await Promise.all(promises);
  //     setDigitalTwinDetailList(digitalTwinDetails);
  //     setSearchDigitalTwin(digitalTwinDetails);
  //     setLoading(false)
  //   } catch (error) {
  //     console.error("Error fetching properties:", error);
  //   }
  // }

  // console.log("customer", customer);
  // console.log("customer1", digitalTwinDetailList);
  // console.log("customer2", shortlistPropertyIds);

  // useEffect(() => {
  //   fetchgetCustomerByEmail(UserService.getUseremail());
  // }, []);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectName = event.target.name;
    setSelectedOption((prevState) => ({
      ...prevState,
      [selectName]: selectedValue,
    }));
  };

  // const handlePriceChange = (newValue: number) => {
  //   !open &&
  //     setSelectedOption((prevState) => ({
  //       ...prevState,
  //       ["price"]: newValue,
  //     }));
  //   open &&
  //     setSelectedOptionForTable((prevState) => ({
  //       ...prevState,
  //       ["price"]: newValue,
  //     }));
  // };
  const handleSearch = (searchTerm: string) => {
    console.log("searchTerm", searchTerm);
    // Perform any action you want with the search term
    const searchTermLowerCase = searchTerm.toLowerCase();
    const filtered = properties.filter(
      (item) =>
        item.mp_digital_twin.title.toLowerCase().includes(searchTermLowerCase) ||
        item.mp_digital_twin.matterport_id.toString().includes(searchTermLowerCase) ||
        (item.mp_digital_twin.num_bathrooms &&
          item.mp_digital_twin.num_bathrooms.toString().includes(searchTermLowerCase))
      // ||
      // (item.num_bathrooms &&
      //     item.num_bathrooms.toString().includes(searchTermLowerCase))) &&
      // (selectedCategory === '' || item.num_bathrooms === selectedCategory)
      // ||
      // item.description.toLowerCase().includes(searchTermLowerCase)
    );

    setSearchDigitalTwin(filtered);
  };

  console.log("filter1", searchedDigitalTwin);

  const handleDetailButton = (
    digital_twin_id: string,
    matterport_id: string
  ) => {
    navigate(
      `/property_details?digitalTwinId=${digital_twin_id}&propertyId=${matterport_id}`
    );
  };
  function handleTabButton(
    digital_twin_id: string,
    matterport_id: string,
    tab: string
  ) {
    navigate(
      `/property_details?digitalTwinId=${digital_twin_id}&propertyId=${matterport_id}&tab=${tab}`
    );
  }

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // const [filterDigitalTwinRows, setFilterDigitalTwinRows] = useState([]);
  // const [agency, setAgency] = useState<{ [key: string]: string }>({});
  // const [selectionModel, setSelectionModel] = React.useState([]);

  // const [selectedOptionForTable, setSelectedOptionForTable] = useState({
  //   agency: "all",
  //   visibility: "all",
  //   location: "all",
  //   rooms: "all",
  //   price: 0,
  // });

  // const visibility: { [key: string]: string } = {
  //   Exclusive: "exclusive",
  //   Network: "network",
  // };
  // const rooms: { [key: string]: number } = {
  //   "2 Bedrooms": 2,
  //   "3 Bedrooms": 3,
  //   "4 Bedrooms": 4,
  //   "5 Bedrooms": 5,
  //   "6 Bedrooms": 6,
  // };
  // const location: { [key: string]: string } = {
  //   Abruzzo: "abruzzo",
  //   "Aosta Valley": "aosta_valley",
  //   Puglia: "puglia",
  //   Calabria: "calabria",
  //   Campania: "campania",
  //   Lazio: "lazio",
  //   Liguria: "liguria",
  //   Marche: "marche",
  //   Sicily: "sicily",
  // };

  // useEffect(() => {
  //   async function fetchAgency() {
  //     try {
  //       const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_agency?sort_by=created_at&sort_order=-1`;
  //       const response = await axios.get(API_URL, {
  //         headers: {
  //           Authorization: `Bearer ${UserService.getToken()}`,
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       const convertedObject: { [key: string]: string } = {};
  //       response.data.re_agency_list.forEach((item: any) => {
  //         convertedObject[item.name] = item._id;
  //       });
  //       setAgency(convertedObject);
  //       console.log("agency", convertedObject);
  //     } catch (error) {
  //       console.error("Error fetching properties:", error);
  //     }
  //   }

  //   fetchAgency();
  // }, []);

  

  // async function fetchProperties() {
  //   try {
  //     let agencyKey = "";
  //     let visibilityKey = "";
  //     let roomsKey = 0;
  //     let locationKey = "";
  //     let priceMinQueryParam = "";
  //     if (selectedOptionForTable.price > 0) {
  //       priceMinQueryParam = `&price=${selectedOptionForTable.price}`;
  //     }

  //     if (
  //       selectedOptionForTable.agency !== "all" &&
  //       selectedOptionForTable.agency
  //     ) {
  //       agencyKey = selectedOptionForTable.agency;
  //     }

  //     console.log("agencyKey", selectedOptionForTable.agency);

  //     if (
  //       selectedOptionForTable.visibility !== "all" &&
  //       visibility[selectedOptionForTable.visibility]
  //     ) {
  //       visibilityKey = visibility[selectedOptionForTable.visibility];
  //     }

  //     if (
  //       selectedOptionForTable.rooms !== "all" &&
  //       rooms[selectedOptionForTable.rooms]
  //     ) {
  //       roomsKey = rooms[selectedOptionForTable.rooms];
  //     }
  //     console.log("visi", roomsKey);

  //     if (
  //       selectedOptionForTable.location !== "all" &&
  //       location[selectedOptionForTable.location]
  //     ) {
  //       locationKey = location[selectedOptionForTable.location];
  //     }

  //     // if (selectedOptionForTable.contract_type !== 'all' && contract_type[selectedOptionForTable.contract_type]) {
  //     //     contractTypeKey = contract_type[selectedOptionForTable.contract_type];
  //     // }

  //     console.log(locationKey, roomsKey);

  //     const API_URL = `${
  //       process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL
  //     }/api/v1/realestate/views/mp_digital_twin/agent/properties/${agentEmail}?sort_by=created_at&sort_order=-1&page=0&limit=100&agency_id=${agencyKey}&province=${locationKey}&property_visibility=${
  //       visibilityKey === "network" || visibilityKey === "exclusive"
  //         ? visibilityKey
  //         : "all"
  //     }&num_bedrooms=${
  //       roomsKey === 0 ? "all" : roomsKey
  //     }${priceMinQueryParam}&`;
  //     // &property_visibility=${visibilityKey}&province=${locationKey}&num_bedrooms=${roomsKey}
  //     const response = await axios.get(API_URL, {
  //       headers: {
  //         Authorization: `Bearer ${UserService.getToken()}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     // setProperties(response.data.mp_digital_twin_list);
  //     const digitalTwinRows = response.data.mp_digital_twin_list.map(
  //       (item: any) => {
  //         return {
  //           id: item._id,
  //           property_id: item.property_id,
  //           title: item.title,
  //           visibility: item.property_visibility,
  //           location: item.address.city,
  //           aria: item.characteristics.area_sqm,
  //           rooms: item.characteristics.num_bedrooms,
  //           price: item.pricing.price,
  //           commision: item.pricing.condominium_expenses,
  //           mp_digital_twin_id: item._id,
  //           seller_agency_id: item.agency?._id,
  //           buyer_agent_id: agentId,
  //           added_by:
  //             UserService.findRole("agent")[0] == "agent"
  //               ? "agent"
  //               : "customer",
  //         };
  //       }
  //     );

  //     setFilterDigitalTwinRows(digitalTwinRows);

  //   } catch (error) {
  //     console.error("Error fetching properties:", error);
  //   }
  // }

  // useEffect(() => {
  //   fetchProperties();
  // }, [
  //   selectedOptionForTable.agency,
  //   selectedOptionForTable.visibility,
  //   selectedOptionForTable.location,
  //   selectedOptionForTable.rooms,
  //   selectedOptionForTable.price,
  // ]);

  // const handleSelectionChange = (newSelection: any) => {
  //   setSelectionModel(
  //     newSelection.map((item: any, index: any) => {
  //       console.log("rows", filterDigitalTwinRows[index]);
  //       return {
  //         mp_digital_twin_id: filterDigitalTwinRows[index].mp_digital_twin_id,
  //         seller_agency_id: filterDigitalTwinRows[index].seller_agency_id,
  //         buyer_agent_id: filterDigitalTwinRows[index].buyer_agent_id,
  //         added_by: filterDigitalTwinRows[index].added_by,
  //       };
  //     })
  //   );
  // };



  const handleFilterChange = (minPrice: string, maxPrice: string) => {
    const minPriceValue = minPrice ? parseInt(minPrice) : 0;
    const maxPriceValue = maxPrice ? parseInt(maxPrice) : 0;
    if (parseInt(minPrice) > parseInt(maxPrice)) {
      alert("Min price should not be greater than max price.");
      return;
    } else if (parseInt(maxPrice) < parseInt(minPrice)) {
      alert("Max price should not be less than min price.");
      return;
    } else {
      fetchProperties(minPriceValue, maxPriceValue);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  console.log(handlePageChange, totalPages);
  

  return (
    <>
      <div className="container max-w-screen-2xl flex bg-[#F6F6F8] min-h-screen overflow-y-auto">
        <div>
          <MiniDrawer />
        </div>
        <div className="flex-grow pt-[5rem] md:pt-[7rem] lg:pt-[6rem] px-2 lg:px-3 ">
          {/* all the page content here */}
          
        
          <div className="flex flex-col flex-start  gap-2 ">
            <div className="flex flex-row   ">
              <div       
                className="flex  w-2/3 items-center text-[18px] sm:text-[22px] md:text-[28px] lg:text-[32px] xl:text-[36px] gap-2"
                style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
              >
                <span className="font-extrabold text-[#1B0039] ">
                  Properties Shortlist
                </span>
                <span className="font-extrabold text-[#283BBE]">{searchedDigitalTwin.length}</span>
              </div>
              {/* <div className="flex w-1/3 justify-end">
                <Button
                  children={`+ Add Properties to Shortlist`}
                  className="bg-[#FF307A] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[40px]"
                  onClick={handleOpen}
                />
              </div> */}
            </div>
          </div>
                          
          <div className="w-full flex flex-col  flex-start md:items-center gap-1 p-4   px-1.5        shadow-md bg-white rounded-md mb-3">
            <div className="w-full xl:w-full ">
                      
              <div className="w-full flex flex-wrap flex-col      justify-center           sm:flex-row md:flex-row">
                                
                <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                  <FilterSelect
                    name="region"
                    label="Region"
                    options={["All", ...Object.keys(region)]}
                    value={selectedOption["region"]}
                    className="bg-white text-black"
                    handleChange={handleSelectChange}
                  />
                </div>

                <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                  <FilterSelect
                    name="type"
                    label="Type"
                    options={["All", ...Object.keys(type)]}
                    value={selectedOption.type}
                    className="bg-white text-black"
                    handleChange={handleSelectChange}
                  />
                </div>

                <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                  <FilterSelect
                    name="lifestyle"
                    label="Lifestyle"
                    options={["All", ...Object.keys(lifestyle)]}
                    value={selectedOption["lifestyle"]}
                    className="bg-white text-black"
                    handleChange={handleSelectChange}
                  />
                </div>

                <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                  <FilterSelect
                    name="contract_type"
                    label="Contract Type"
                    options={["All", ...Object.keys(contract_type)]}
                    value={selectedOption["contract_type"]}
                    className="bg-white text-black"
                    handleChange={handleSelectChange}
                  />
                </div>
              </div>
            </div>

            {/* <div className="w-full md:w-full lg:w-full xl:w-1/2 flex flex-wrap flex-col sm:flex-row md:flex-row md:items-center sm:flex-start md:justify-between px-1 md:px-2">
              <div className="flex flex-wrap sm:w-[100%] md:w-[100%] lg:w-[60%] xl:w-1/2 sm:pl-2 md:pl-0 justify-center">
                <Slider
                  name="price"
                  label="Price of property"
                  value={selectedOption["price"]}
                  onChange={handlePriceChange}
                />
              </div>
              <div className="flex flex-grow  sm:w-[100%] md:w-[100%] lg:w-[40%] xl:w-1/2 sm:pl-2">
                <SearchBar onSearch={handleSearch} />
              </div>
            </div> */}
            
            <div className="w-full md:w-full lg:w-full xl:w-full max-sm:gap-2 flex flex-col sm:flex-row md:flex-row  xl:flex-row md:items-center sm:flex-start md:justify-between pr-1 md:pr-2">
                <div className="flex  w-full flex-grow sm:w-[100%] md:w-[65%] lg:w-[65%] xl:w-1/2 sm:pl-2">
                  {/* flex-wrap sm:w-[100%] md:w-[50%] lg:w-[50%] xl:w-1/2 */}
                  <PriceFilter onFilterChange={handleFilterChange} />
                </div>
                <div className="flex flex-grow  sm:w-[100%] md:w-[35%] lg:w-[35%] xl:w-1/2 sm:pl-2">
                  <SearchBar onSearch={handleSearch} />
                </div>
              </div>
          </div>

          {loading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            searchedDigitalTwin && (
              <div className="grid gap-3 max-xl:grid-cols-2 max-md:grid-cols-1">
                {searchedDigitalTwin.map((property, index) => (
                  <div id={`${index}`} className="bg-white shadow-md grid   max-xl:grid-cols-1 max-2xl:grid-cols-7 2xl:grid-cols-7  p-4 gap-3 rounded-md">
                    <div className="  max-xl:col-span-1  max-2xl:col-span-2 2xl:col-span-2 flex flex-col gap-2">
                      <Tabs
                        tabs={[
                          { label: "Digital Twin" },
                          { label: "Images" },
                          { label: "Video(s)" },
                        ]}
                        handleTabButton={handleTabButton}
                        matterport_id={property.mp_digital_twin.matterport_id}
                        digital_twin_id={property.mp_digital_twin._id}
                      />
                      <div className="w-full h-full max-h-52 2xl:max-h-80 overflow-hidden rounded-md">
                        <img
                          src={
                            property.mp_digital_twin.property_thumbnail === null
                              ? "https://storage.googleapis.com/oneverse-dev/realestate/no_property_image.jpg"
                              : // "https://storage.googleapis.com/oneverse-dev/realestate/06.11.2024_17.25.09--999.jpg"
                                property.mp_digital_twin.property_thumbnail
                          }
                          alt="house"
                          className="object-cover w-full h-full"
                        />
                      </div>
                    </div>
                    <div className="  max-xl:col-span-1 max-2xl:col-span-5 2xl:col-span-5 flex flex-col justify-between 2xl:gap-8 gap-5">
                      <PropertyCard
                        propertyInfo={{
                          title: property.mp_digital_twin.title ? property.mp_digital_twin.title : "",
                          propertyId: property.mp_digital_twin.property_id
                            ? property.mp_digital_twin.property_id
                            : "",
                          propertyLink: property.mp_digital_twin.characteristics.shareable_link
                            ? property.mp_digital_twin.characteristics.shareable_link
                            : "",
                          description: property.mp_digital_twin.description
                            ? property.mp_digital_twin.description
                            : "",
                          location: property.mp_digital_twin.address.city
                            ? property.mp_digital_twin.address.city
                            : "",
                          contractType: property.mp_digital_twin.characteristics.contract_type
                            ? property.mp_digital_twin.characteristics.contract_type
                            : "",
                          bedrooms: property.mp_digital_twin.characteristics.num_bedrooms
                            ? property.mp_digital_twin.characteristics.num_bedrooms +
                              " Bedrooms"
                            : 0,
                          area: property.mp_digital_twin.characteristics.area_sqm
                            ? property.mp_digital_twin.characteristics.area_sqm + " Sq. Mtr"
                            : 0,
                          type: property.mp_digital_twin.characteristics.type_of_property
                            ? toPascalCase(
                                property.mp_digital_twin.characteristics.type_of_property
                              )
                            : "",
                          lifestyle: property.mp_digital_twin.characteristics.lifestyle
                            ? toPascalCase(property.mp_digital_twin.characteristics.lifestyle)
                            : "",
                          listedOn: property.mp_digital_twin.characteristics.listing_date
                            ? property.mp_digital_twin.characteristics.listing_date
                            : "",
                          price: property.mp_digital_twin.pricing.price
                            ? property.mp_digital_twin.pricing.price
                            : 0,
                          isExclusive: true,
                          // Exclusive: "NETWORK",
                          roperty_visibility: property.mp_digital_twin.property_visibility,
                          digital_twin_id: property.mp_digital_twin._id,
                          matterport_id: property.mp_digital_twin.matterport_id,
                        }}
                        handleDetailButton={handleDetailButton}
                      />

                      <div className="flex items-center justify-between max-xl:items-end  max-xl:flex-col  max-xl:gap-2">
                        <div className="flex items-center gap-4 max-xl:justify-between max-xl:items-start  max-xl:w-full">
                          <CommissionAmount
                            amount={
                              property.mp_digital_twin.pricing.price
                                ? property.mp_digital_twin.pricing.price
                                : 0
                            }
                            contractType={
                              property.mp_digital_twin.characteristics.contract_type
                            }
                          />
                          <ToursCompleted
                            tours={property.mp_digital_twin.characteristics.total_virtual_tours}
                            clients="16"
                          />
                        </div>
                        {UserService.hasRole("agent") && (<div className="flex max-xl:w-full">
                          <Button
                            children="Details"
                            className="w-full bg-[#283BBE] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[35px] "
                            onClick={() => {
                              handleDetailButton(
                                property.mp_digital_twin._id,
                                property.mp_digital_twin.matterport_id
                              );
                            }}
                          />
                        </div>)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
          )}
        </div>

        {/* <div className="flex top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="fixed top-[10%] left-[15%] w-[70%] h-[80%] overflow-auto justify-center bg-white border-2 border-black shadow-md p-4 ">
                <div className={`flex `}>
                  <h1 className="font-bold text-[#FF307A] text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5">
                    Client Details
                  </h1>
                </div>

                <div className={`flex `}>
                  <h1 className="font-bold text-[#FF307A] text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5">
                    Client property shortlist
                  </h1>
                </div>

                <div className="w-full flex flex-col xl:flex-row flex-start md:items-center gap-1 p-4 shadow-md bg-white rounded-md ">
                  <div className="w-full xl:w-1/2 ">
                    <div className="flex flex-wrap flex-col sm:flex-row md:flex-row">
                      <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2">
                        <FilterSelect
                          name="agency"
                          label="Agency"
                          options={["All", ...Object.keys(agency)]}
                          value={selectedOptionForTable["agency"]}
                          className="bg-white text-black"
                          handleChange={handleSelectChange}
                        />
                      </div>

                      <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2">
                        <FilterSelect
                          name="visibility"
                          label="Visibility"
                          options={["All", ...Object.keys(visibility)]}
                          value={selectedOptionForTable.visibility}
                          className="bg-white text-black"
                          handleChange={handleSelectChange}
                        />
                      </div>

                      <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2">
                        <FilterSelect
                          name="location"
                          label="Location"
                          options={["All", ...Object.keys(location)]}
                          value={selectedOptionForTable["location"]}
                          className="bg-white text-black"
                          handleChange={handleSelectChange}
                        />
                      </div>

                      <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2">
                        <FilterSelect
                          name="rooms"
                          label="Rooms"
                          options={["All", ...Object.keys(rooms)]}
                          value={selectedOptionForTable["rooms"]}
                          className="bg-white text-black"
                          handleChange={handleSelectChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-full lg:w-full xl:w-1/2 flex flex-wrap flex-col sm:flex-row md:flex-row md:items-center sm:flex-start md:justify-between px-1 md:px-2">
                    <div className="flex flex-wrap sm:w-[100%] md:w-[100%] lg:w-[60%] xl:w-1/2 sm:pl-2 md:pl-0 justify-center">
                      <Slider
                        name="price"
                        label="Price of property"
                        value={selectedOptionForTable["price"]}
                        onChange={handlePriceChange}
                      />
                    </div>
                    <div className="flex flex-grow  sm:w-[100%] md:w-[100%] lg:w-[40%] xl:w-1/2 sm:pl-2">
                      <SearchBar onSearch={handleSearch} />
                    </div>
                  </div>
                </div>

                <div className="flex mt-4">
                  <DataTable
                    rows={filterDigitalTwinRows}
                    handleSelectionChange={handleSelectionChange}
                  />
                </div>

                <div className=" mt-4 flex items-center justify-center flex-col  sm:flex-row gap-3 w-full  ">
                  <div
                    className="flex w-1/3 cursor-pointer text-white items-center justify-center  bg-[#283BBE] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] "
                    onClick={() => {
                      // handleCreateCustomer()
                    }}
                  >
                    {`Save & Send via Email `}
                  </div>

                  <div
                    className="flex w-1/3 cursor-pointer items-center justify-center text-black  border border-[#20B038] bg-[#20B038] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]  "
                    onClick={() => {
                      // handleCreateCustomer()
                      console.log("clikced");
                    }}
                  >
                    Save & Send via Whatsapp
                  </div>

                 
                </div>
              </div>
            </Modal>
          </div>
        </div> */}
      </div>
    </>
  );
};
