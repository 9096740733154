import React, {
  // useState,
} from "react"



import { 
  FaVrCardboard,  FaInfinity, FaGlobeEurope, FaArrowAltCircleUp, FaUserTie,

 } from "react-icons/fa"




// import { useLocation, useNavigate } from "react-router-dom";








export const SellWithUs = () => {


  return (
    <>
      <div className="w-full h-full flex container max-w-screen-2xl bg-[#F6F6F8]">
        <div className="flex w-full flex-col items-center py-4 md:py-6  gap-3 md:gap-8 ">
          <div className="w-[100%] flex flex-col md:flex-row container max-w-screen-lg gap-3 md:gap-8  ">
            <div className="w-full md:w-1/3   flex flex-col items-center md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaGlobeEurope className="icon-outlined text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Access to Global markets</span>
              </div>
              <div className="text-center text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words just">Access to Global markets through our global Buy side agent network.</span>
              </div>
            </div>
            <div className="w-full md:w-1/3  flex flex-col items-center md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaArrowAltCircleUp className="icon-outlined text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Better ROI</span>
              </div>
              <div className="text-center text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">Competitive scanning and platform costs to give to better ROI.</span>
              </div>
            </div>   
            <div className="w-full md:w-1/3   flex flex-col items-center md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaInfinity className="icon-outlined fa-regular text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">No Limit</span>
              </div>
              <div className="text-center text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">We believe in abundance: no limit on scans listed on your account.</span>
              </div>
            </div>         
          </div>

          <div className="w-[100%] flex flex-col md:flex-row container max-w-screen-lg gap-2 md:gap-8  ">
            
            <div className="w-full md:w-1/2   flex flex-col items-center md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaUserTie className="icon-outlined text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Dedicated Consultants</span>
              </div>
              <div className="text-center text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">We are just not a platform, our consultants will work with you to increase your transactions and revenue.</span>
              </div>
            </div>
            <div className="w-full md:w-1/2   flex flex-col items-center md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaVrCardboard className="icon-outlined fa-regular text-4xl text-[#283BBE]" />                
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">AR / VR / MR</span>
              </div>
              <div className="text-center text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">We understand AR / VR/ MR well and are focused on building solutions to address real life problems.</span>
              </div>
            </div>
            <div className="w-full md:w-1/2   flex flex-col items-center md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="py-4 px-7 bg-[#E3E7FF] rounded-md">
                 <div className=" text-[#283BBE] font-semibold text-3xl " style={{ fontFamily: 'Inter, sans-serif' }}>
                  <span className="break-words">1</span>
              </div>
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">We are FIRST</span>
              </div>
              <div className="text-center text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">Among the First to bring integrated solution combining hardware to view VR and Platform to encourage VR assisted solutions to clients around the world.</span>
              </div>
            </div>            
          </div>



          
          
          
        </div>
      </div>
    </>
  )
}