import { Mode } from "rc-app/types";
import React, { Component } from "react";

import { HostController } from "./HostController";
import { ObserverController } from "./ObserverController";
import { SelectMode } from "./SelectMode";

interface State {
  mode: Mode;
}

interface Props {
  // property :string;
  propertyId: string;
  email: string;
  joinCode: string;
  channel: string;
  agentId: string;
  selectAnotherProperty: boolean;
  setSelectAnotherProperty: (value: boolean) => void;

  open?: boolean;
  handleOpen?: () => void;
  handleClose?: () => void;
  // setOpen?:(value:boolean)=> void
}

export class Main extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: Mode.Idle,
    };
    this.onSelected = this.onSelected.bind(this);
    this.onModeFinished = this.onModeFinished.bind(this);
  }

  private onSelected(mode: Mode) {
    console.log("propsx", Mode.Idle);
    switch (this.state.mode) {
      case Mode.Idle:
        this.setState({ mode: mode });
        console.log("mode", mode);
        break;

      case Mode.HostMode:
        if (mode === Mode.Idle) {
          this.setState({ mode });
        }
        break;

      case Mode.ObserverMode:
        if (mode === Mode.Idle) {
          this.setState({ mode });
        }
        break;
    }
  }

  private onModeFinished() {
    this.setState({ mode: Mode.Idle });
  }

  render() {
    switch (this.state.mode) {
      case Mode.Idle:
        // if(!this.props.selectAnotherProperty){
        return (
          <div className="flex w-full items-center justify-center p-3">
            <SelectMode
              onSelected={this.onSelected}
              joinCode={this.props.joinCode}
              email={this.props.email}
              agentId={this.props.agentId}
            />
          </div>
        );

      // }
      // if(this.props.selectAnotherProperty){
      //   <HostController
      //     // property= {this.props.property}
      //     propertyId={this.props.propertyId}
      //     onFinished={this.onModeFinished}
      //     selectAnotherProperty= {this.props.selectAnotherProperty}
      //     setSelectAnotherProperty={this.props.setSelectAnotherProperty}
      //   />;
      // }

      case Mode.HostMode:
        return (
          <div className="w-full">
            <HostController
              // property= {this.props.property}
              propertyId={this.props.propertyId}
              onFinished={this.onModeFinished}
              selectAnotherProperty={this.props.selectAnotherProperty}
              setSelectAnotherProperty={this.props.setSelectAnotherProperty}
              open={this.props.open}
              // setOpen={this.props.setOpen}
              handleOpen={this.props.handleOpen}
              handleClose={this.props.handleClose}
            />
          </div>
        );

      case Mode.ObserverMode:
        return (
          <div className="w-full">
            <ObserverController joinCode={this.props.joinCode} channel={this.props.channel} onFinished={this.onModeFinished} />
          </div>
        );
    }
    return null;
  }
}
