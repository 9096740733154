import React from "react"

import { Box } from "@mui/material"
import ContactForm from "rc-app/components/ContactForm"

export const Contact = () => {
    return (
        <Box
        sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
        >
            <ContactForm />
        </Box>

    )
}