import React from "react";
import Button from "../Button";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="text-center pt-16">
        <h1 className="text-white text-4xl md:text-5xl mb-7 font-semibold">
          Want to Buy or Sell Your Property?
        </h1>
        <p className="text-white px-6 md:px-60 leading-8 opacity-80">
          Lorem ipsum dolor sit amet,Stet clita kasd gubergren, no sea takimata
          sanctus est is the lorem ipsum dolor sit amet. sed diam nonumy ut
          labore et dolore s the magna aliquyam.
        </p>
        <div className="flex flex-col md:flex-row items-center justify-center my-20 px-6 md:px-12">
          <Button className="bg-[#283BBE] p-3 " children="Buying Property" />
          <hr className="border-b border-[#283BBE] w-20 mx-0 md:mx-10 my-6 xs:hidden md:flex" />
          <Button className="bg-[#283BBE] p-3" children="Selling Property" />
        </div>
      </div>
      <hr className="border-[#283BBE] mx-6 md:mx-44" />

      <div className="border-b-[#373636] flex items-center justify-center">
        <div className="footer-content py-14 justify-between">
          <div className="w-full grid grid-cols-1 lg:grid-cols-5 gap-10 md:gap-16 px-6 md:px-12 ">
            <div className="">
              <div className=" footer-widget mb-10">
                <div className="footer-logo">
                  <img
                    src="https://storage.googleapis.com/oneverse-dev/realestate/1verse-logo-2.png"
                    alt=""
                    className="h-12 w-12 mb-4"
                  />
                  <span className="text-white font-bold text-xl">1Verse</span>
                </div>
                <div className="footer-social-icon mt-4">
                  <div className="flex items-center gap-3">
                    <img
                      src="https://storage.googleapis.com/oneverse-dev/realestate/fb.png"
                      alt=""
                    />
                    <img
                      src="https://storage.googleapis.com/oneverse-dev/realestate/x.png"
                      alt=""
                    />
                    <img
                      src="https://storage.googleapis.com/oneverse-dev/realestate/google+.png"
                      alt=""
                    />
                    <img
                      src="https://storage.googleapis.com/oneverse-dev/realestate/insta.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-widget">
              <div className="footer-widget-heading mb-4 md:mb-0">
                <h3>Useful Links</h3>
              </div>
              <ul className="flex flex-col">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a
                    href="https://italia.1verse.live/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm hover:underline"
                  >
                    About Us
                  </a>
                  {/* <Link to="/about">About Us</Link> */}
                </li>
                <li>
                  <a
                    href="https://italia.1verse.live/pages/pricing-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm hover:underline"
                  >
                    Pricing
                  </a>
                  {/* <Link to="/pricing">Pricing</Link> */}
                </li>
                <li>
                  <a
                    href="https://italia.1verse.live/pages/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm hover:underline"
                  >
                    Contact
                  </a>
                  {/* <Link to="/about">Contact Us</Link> */}
                </li>
              </ul>
            </div>

            {/* <div className="footer-widget">
              <div className="footer-widget-heading mb-4 md:mb-0">
                <h3>Sitemap</h3>
              </div>
              <ul className="flex flex-col">
                <li><a href="#">Our Process</a></li>
                <li><a href="#">What We Do</a></li>
                <li><a href="#">Location</a></li>
                <li><a href="#">Sell</a></li>
                <li><a href="#">Purchase</a></li>
              </ul>
            </div> */}

            <div className="footer-widget">
              <div className="footer-widget-heading mb-4 md:mb-0">
                <h3>Discover</h3>
              </div>
              <ul className="flex flex-col">
                <li>
                  <Link to="/privacy_policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms_and_conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="#">Owners</Link>
                </li>
                <li>
                  <Link to="#">Houses</Link>
                </li>
              </ul>
            </div>

            <div className="col-span-2 col-xl-4 col-lg-4 col-md-6 mb-4 md:mb-0 ">
              <div className="footer-widget ">
                <div className="footer-widget-heading">
                  <h3>Subscribe</h3>
                </div>
                <div className="subscribe-form">
                  <form action="#" className="w-[65%]">
                    <input type="text" placeholder="Email" />
                  </form>
                  <button className="w-[35%]">subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area text-center py-6">
        <p className="text-white">
          Copyright © 2024 1Verse. All rights reserved.
        </p>
      </div>
    </>
  );
}

export default Footer;

// import * as React from 'react';
// import { Footer } from 'flowbite-react';
// import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from 'react-icons/bs';

// function FooterComponent() {
//   return (
//     <Footer bgDark>
//       <div className="w-full">
//         <div className="grid w-full grid-cols-2 gap-8 px-6 py-8 md:grid-cols-4">
//           <div>
//             <Footer.Title title="Company" />
//             <Footer.LinkGroup col>
//               <Footer.Link href="#">About</Footer.Link>
//               <Footer.Link href="#">Careers</Footer.Link>
//               <Footer.Link href="#">Brand Center</Footer.Link>
//               <Footer.Link href="#">Blog</Footer.Link>
//             </Footer.LinkGroup>
//           </div>
//           <div>
//             <Footer.Title title="help center" />
//             <Footer.LinkGroup col>
//               <Footer.Link href="#">Discord Server</Footer.Link>
//               <Footer.Link href="#">Twitter</Footer.Link>
//               <Footer.Link href="#">Facebook</Footer.Link>
//               <Footer.Link href="#">Contact Us</Footer.Link>
//             </Footer.LinkGroup>
//           </div>
//           <div>
//             <Footer.Title title="legal" />
//             <Footer.LinkGroup col>
//               <Footer.Link href="#">Privacy Policy</Footer.Link>
//               <Footer.Link href="#">Licensing</Footer.Link>
//               <Footer.Link href="#">Terms &amp; Conditions</Footer.Link>
//             </Footer.LinkGroup>
//           </div>
//           <div>
//             <Footer.Title title="download" />
//             <Footer.LinkGroup col>
//               <Footer.Link href="#">iOS</Footer.Link>
//               <Footer.Link href="#">Android</Footer.Link>
//               <Footer.Link href="#">Windows</Footer.Link>
//               <Footer.Link href="#">MacOS</Footer.Link>
//             </Footer.LinkGroup>
//           </div>
//         </div>
//         <div className="w-full bg-gray-700 px-4 py-6 sm:flex sm:items-center sm:justify-between">
//           <Footer.Copyright href="#" by="Flowbite™" year={2022} />
//           <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
//             <Footer.Icon href="#" icon={BsFacebook} />
//             <Footer.Icon href="#" icon={BsInstagram} />
//             <Footer.Icon href="#" icon={BsTwitter} />
//             <Footer.Icon href="#" icon={BsGithub} />
//             <Footer.Icon href="#" icon={BsDribbble} />
//           </div>
//         </div>
//       </div>
//     </Footer>
//   );
// }

// export default FooterComponent

// // import React from 'react';
// // import { Footer } from 'flowbite-react';

// // export default function Component() {
// //   return (
// //     <Footer container>
// //       <Footer.Copyright href="#" by="Flowbite™" year={2023} />
// //       <Footer.LinkGroup>
// //         <Footer.Link href="#">About</Footer.Link>
// //         <Footer.Link href="#">Privacy Policy</Footer.Link>
// //         <Footer.Link href="#">Licensing</Footer.Link>
// //         <Footer.Link href="#">Contact</Footer.Link>
// //       </Footer.LinkGroup>
// //     </Footer>
// //   );
// // }

// // '/images/3D_Showcase_1.jpeg'

// import React from 'react';
// import {
//   Box,
//   Typography,
//   Link,
//   Container,
//   ThemeProvider,
//   createTheme,
//   // Avatar,
//   // Theme,
// } from '@mui/material';

// interface FooterProps {
//   darkMode: boolean;
// }

// const Footer: React.FC<FooterProps> = ({ darkMode }) => {
//   const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//       background: {
//         default: '#333', // Set the background color for dark mode
//       },
//     },
//   });

//   return (
//     <ThemeProvider theme={darkMode ? darkTheme : undefined}>
//       <Box
//         component="footer"
//         sx={{
//           backgroundColor: darkMode ? '#333' : '#f8f9fa',
//           color: darkMode ? 'white' : 'inherit',
//           padding: '20px 0',
//           marginTop: 'auto',
//           textAlign: 'center',
//         }}
//       >
//         <Container>
//           <Box
//             sx={{
//               display: 'flex',
//               justifyContent: 'space-between',
//               alignItems: 'center',
//               flexDirection: { xs: 'column', sm: 'row' },
//             }}
//           >
//             {/* Logo */}
//             <Link href="/">
//               {/* <Avatar
//                 alt="1verse"
//                 src="/images/1verse-logo-2.png"
//                 sx={{
//                   // height: '50px',
//                   // width: '50px',
//                   // display: { xs: 'flex', md: 'none' }, mr: .5
//                 }}
//               /> */}

//               <Typography
//                 variant="h5"
//                 noWrap
//                 component="a"
//                 href="/"
//                 sx={{
//                   mr: 2,
//                   // display: { xs: 'flex', md: 'none' },
//                   flexGrow: 1,
//                   fontFamily: 'monospace',
//                   fontWeight: 700,
//                   letterSpacing: '.1rem',
//                   color: 'white',
//                   textDecoration: 'none',
//                 }}
//               >
//                 1verse
//               </Typography>
//             </Link>

//             <Box mt={{ xs: 2, sm: 0 }} flexShrink={0}>
//               <Link href="#" color="inherit" sx={{ marginRight: 2 }}>
//                 About
//               </Link>
//               <Link href="#" color="inherit" sx={{ marginRight: 2 }}>
//                 Privacy Policy
//               </Link>
//               <Link href="#" color="inherit" sx={{ marginRight: 2 }}>
//                 Licensing
//               </Link>
//               <Link href="/contact-us" color="inherit">
//                 Contact
//               </Link>
//             </Box>
//           </Box>

//           {/* Divider */}
//           <Box mt={2}>
//             <hr style={{ border: 0, borderTop: `1px solid ${darkMode ? '#555' : '#ddd'}` }} />
//           </Box>

//           <Typography variant="body2" color="textSecondary" mt={2}>
//             <Link href="#" color="inherit">
//               1verse™
//             </Link>{' '}
//             &copy; 2023
//           </Typography>
//         </Container>
//       </Box>
//     </ThemeProvider>
//   );
// };

// export default Footer;
