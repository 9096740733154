import * as React from "react";
import { useState, useEffect } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
// import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Header } from "../Header";
import { Link } from "react-router-dom";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FilterListIcon from "@mui/icons-material/FilterList";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CopyrightIcon from "@mui/icons-material/Copyright";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MobileDrawer from "../MobileDrawer";
import UserService from "rc-app/services/UserService";
// import { Container } from '@mui/material';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(true);

  // const handleDrawerOpen = () => {
  //   setOpen(!open);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const path = url.pathname;
  console.log("path", path);

  const [selectedItem, setSelectedItem] = useState("My Agency List");

  useEffect(() => {
    const pathname = location.pathname;
    const openPaths = [
      // menu path for agents
      "/agency_list",
      "/network_list",
      "/clients",
      "/earnings",
      "/vr_tour_list",

      // common path
      "/profile",

      //menu path for customers
      "my_agent",
      "/properties_shortlist",
    ];
    if (openPaths.includes(pathname)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    console.log("clicked path", pathname);

    switch (pathname) {
      case "/":
      case "/agency_list":
        setSelectedItem("My Agency Listings");
        break;
      case "/network_list":
        setSelectedItem("Network Listings");
        break;
      case "/clients":
        setSelectedItem("My Clients");
        break;
      case "/earnings":
        setSelectedItem("My Earnings");
        break;
      case "/vr_tour_list":
        setSelectedItem("My Virtual Tours");
        break;

      case "/profile":
        setSelectedItem("My Profile");
        break;

      case "/my_agent":
        setSelectedItem("My Agent");
        break;
      case "/properties_shortlist":
        setSelectedItem("Properties Shortlist");
        break;

      case "/join_vr_tour":
        setSelectedItem("Properties Shortlist");
        break;

      default:
        setSelectedItem("");
    }
  }, [location.pathname]);

  const handleMenuItemClick = (item: any, path: any) => {
    setSelectedItem(item);
    navigate(path); // Assuming `navigate` is accessible here
  };

  // const maxWidth = theme.breakpoints.values.xl ;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth < 1023);
    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  console.log("UserService", UserService.hasRole("agent"));

  return (
    // <Container style={{maxWidth:"1536px"}} >
    <Box sx={{ display: "flex", position: "relative", maxWidth: "1536px" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar style={{ backgroundColor: "#000524" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setOpen(!open);
            }}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Header drawerOpen={open} />
        </Toolbar>
      </AppBar>
      {!isMobile && (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader sx={{ paddingY: "1.6rem" }}>
            <div>
              <Link to="/" className="flex items-center">
                {/* display on large and md */}
                <img
                  alt="1verse"
                  src="https://storage.googleapis.com/oneverse-dev/realestate/1verse-logo-2.png"
                  className="h-12 w-12 hidden md:flex  mr-2"
                />
                <h5 className=" text-[#1B191B] text-2xl font-bold hidden md:flex">
                  1verse
                </h5>
                <img
                  alt="1verse"
                  src="https://storage.googleapis.com/oneverse-dev/realestate/1verse-logo-2.png"
                  className="h-12 w-12 flex md:hidden mr-2"
                />
                <h5 className=" text-[#1B191B] text-2xl font-bold flex md:hidden">
                  1verse
                </h5>
              </Link>
            </div>
            <IconButton
              onClick={() => {
                setOpen(!open);
              }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <List className="opacity-80">
            {UserService.hasRole("agent") && (
              <>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedItem === "My Agency Listings"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      "&.Mui-selected": {
                        backgroundColor: "#E3E7FF",
                      },
                      position: "relative",
                    }}
                    onClick={() =>
                      handleMenuItemClick("My Agency Listings", "/agency_list")
                    }
                  >
                    {selectedItem === "My Agency Listings" && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          width: "4px",
                          background: "#283BBE",
                        }}
                      />
                    )}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedItem === "My Agency Listings"
                            ? "#283BBE"
                            : "inherit",
                      }}
                    >
                      <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="My Agency Listings"
                      sx={{ opacity: open ? 1 : 0 }}
                      className={
                        selectedItem === "My Agency Listings"
                          ? "text-[#283BBE]"
                          : ""
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedItem === "Network Listings"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      "&.Mui-selected": {
                        backgroundColor: "#E3E7FF",
                      },
                      position: "relative",
                    }}
                    onClick={() =>
                      handleMenuItemClick("Network Listings", "/network_list")
                    }
                  >
                    {selectedItem === "Network Listings" && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          width: "4px",
                          background: "#283BBE", // Divider color
                        }}
                      />
                    )}

                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedItem === "Network Listings"
                            ? "#283BBE"
                            : "inherit",
                      }}
                    >
                      <FilterListIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Network Listings"
                      sx={{ opacity: open ? 1 : 0 }}
                      className={
                        selectedItem === "Network Listings"
                          ? "text-[#283BBE]"
                          : ""
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedItem === "My Clients"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      "&.Mui-selected": {
                        backgroundColor: "#E3E7FF",
                      },
                      position: "relative",
                    }}
                    onClick={() =>
                      handleMenuItemClick("My Clients", "/clients")
                    }
                  >
                    {selectedItem === "My Clients" && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          width: "4px",
                          background: "#283BBE",
                        }}
                      />
                    )}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedItem === "My Clients" ? "#283BBE" : "inherit",
                      }}
                    >
                      <PeopleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="My Clients"
                      sx={{ opacity: open ? 1 : 0 }}
                      className={
                        selectedItem === "My Clients" ? "text-[#283BBE]" : ""
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedItem === "My Earnings"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      "&.Mui-selected": {
                        backgroundColor: "#E3E7FF",
                      },
                      position: "relative",
                    }}
                    onClick={() =>
                      handleMenuItemClick("My Earnings", "/earnings")
                    }
                  >
                    {selectedItem === "My Earnings" && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          width: "4px",
                          background: "#283BBE", // Divider color
                        }}
                      />
                    )}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedItem === "My Earnings"
                            ? "#283BBE"
                            : "inherit",
                      }}
                    >
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="My Earnings"
                      sx={{ opacity: open ? 1 : 0 }}
                      className={
                        selectedItem === "My Earnings" ? "text-[#283BBE]" : ""
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedItem === "My Virtual Tours"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      "&.Mui-selected": {
                        backgroundColor: "#E3E7FF",
                      },
                      position: "relative",
                    }}
                    onClick={() =>
                      handleMenuItemClick("My Virtual Tours", "/vr_tour_list")
                    }
                  >
                    {selectedItem === "My Virtual Tours" && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          width: "4px",
                          background: "#283BBE", // Divider color
                        }}
                      />
                    )}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedItem === "My Virtual Tours"
                            ? "#283BBE"
                            : "inherit",
                      }}
                    >
                      <PhotoCameraFrontIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="My Virtual Tours"
                      sx={{ opacity: open ? 1 : 0 }}
                      className={
                        selectedItem === "My Virtual Tours"
                          ? "text-[#283BBE]"
                          : ""
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </>
            )}
            {UserService.hasRole("customer") && (
              <>
                
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedItem === "Properties Shortlist"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      "&.Mui-selected": {
                        backgroundColor: "#E3E7FF",
                      },
                      position: "relative",
                      "&:hover": {
                        backgroundColor: "#E3E7FF",
                      },
                    }}
                    onClick={() =>
                      handleMenuItemClick(
                        "Properties Shortlist",
                        "/properties_shortlist"
                      )
                    }
                  >
                    {selectedItem === "Properties Shortlist" && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          width: "4px",
                          background: "#283BBE", // Divider color
                        }}
                      />
                    )}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedItem === "Properties Shortlist"
                            ? "#283BBE"
                            : "inherit",
                      }}
                    >
                      <HomeOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Properties Shortlist"
                      sx={{ opacity: open ? 1 : 0 }}
                      className={
                        selectedItem === "Properties Shortlist"
                          ? "text-[#283BBE]"
                          : ""
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedItem === "Join VR Tour"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      "&.Mui-selected": {
                        backgroundColor: "#E3E7FF",
                      },
                      position: "relative",
                      "&:hover": {
                        backgroundColor: "#E3E7FF",
                      },
                    }}
                    onClick={() =>
                      handleMenuItemClick("Join VR Tour", "/join_vr_tour")
                    }
                  >
                    {selectedItem === "Join VR Tour" && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          width: "4px",
                          background: "#283BBE", // Divider color
                        }}
                      />
                    )}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedItem === "Join VR Tour"
                            ? "#283BBE"
                            : "inherit",
                      }}
                    >
                      <PhotoCameraFrontIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Join VR Tour"
                      sx={{ opacity: open ? 1 : 0 }}
                      className={
                        selectedItem === "Join VR Tour" ? "text-[#283BBE]" : ""
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedItem === "My Agent"}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      "&.Mui-selected": {
                        backgroundColor: "#E3E7FF",
                      },
                      position: "relative",
                      "&:hover": {
                        backgroundColor: "#E3E7FF",
                      },
                    }}
                    onClick={() => handleMenuItemClick("My Agent", "/my_agent")}
                  >
                    {selectedItem === "My Agent" && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          width: "4px",
                          background: "#283BBE", // Divider color
                        }}
                      />
                    )}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          selectedItem === "My Agent" ? "#283BBE" : "inherit",
                      }}
                    >
                      <SupportAgentIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="My Agent"
                      sx={{ opacity: open ? 1 : 0 }}
                      className={
                        selectedItem === "My Agent" ? "text-[#283BBE]" : ""
                      }
                    />
                  </ListItemButton>
                </ListItem>

              </>
            )}

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={selectedItem === "My Profile"}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&.Mui-selected": {
                    backgroundColor: "#E3E7FF",
                  },
                  position: "relative",
                  "&:hover": {
                    backgroundColor: "#E3E7FF",
                  },
                }}
                onClick={() => handleMenuItemClick("My Profile", "/profile")}
              >
                {selectedItem === "My Profile" && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      width: "4px",
                      background: "#283BBE", // Divider color
                    }}
                  />
                )}
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color:
                      selectedItem === "My Profile" ? "#283BBE" : "inherit",
                  }}
                >
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText
                  primary="My Profile"
                  sx={{ opacity: open ? 1 : 0 }}
                  className={
                    selectedItem === "My Profile" ? "text-[#283BBE]" : ""
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>

          <Divider />
          <div
            className={` absolute bottom-[10px]  ${
              open ? " inset-x-0 flex items-center justify-center flex-col" : ""
            } gap-2 p-2.5 mb-4`}
          >
            {open ? (
              <div className="flex items-center ">
                <span className="text-[0rem] text-[#555564]">
                  {" "}
                  <CopyrightIcon />{" "}
                </span>
                <span className="text-[#555564] text-[1rem]"> Powered by </span>
              </div>
            ) : null}
            <div className="flex items-center ">
              {open ? (
                <>
                  <img
                    src="https://storage.googleapis.com/oneverse-dev/realestate/1verse-logo-2.png"
                    height={30}
                    width={30}
                    className=""
                  />
                  <span className=" ml-1 text-[#1B191B] font-bold text-[1rem] md:text-[1.563rem] ">
                    1verse
                  </span>
                </>
              ) : (
                <img
                  src="https://storage.googleapis.com/oneverse-dev/realestate/1verse-logo-2.png"
                  height={40}
                  width={40}
                  className="p-2"
                />
              )}
            </div>
          </div>
        </Drawer>
      )}
      {isMobile && <MobileDrawer open={open} setOpen={setOpen} />}
    </Box>

    // </Container>
  );
}
