import React, { useState, useEffect } from "react";

// import { Box } from "@mui/material";
import MiniDrawer from "rc-app/components/MiniDrawer";
import PropertyCardLarge from "rc-app/components/PropertyCardLarge";
import Button from "rc-app/components/Button";
import axios from "axios";
import UserService from "rc-app/services/UserService";

import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import KingBedIcon from "@mui/icons-material/KingBed";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "rc-app/components/DataTable";

import { getDigitalTwinById } from "../Home";

import Modal from "@mui/material/Modal";
import SearchBar from "rc-app/components/Search Bar";
// import Slider from "rc-app/components/Slider";
import FilterSelect from "rc-app/components/Filter Select";
import {
  addPropertyToShortlist,
  getAgentByEmail,
  // getDigitalTwinByAgent
} from "../Home";
import ClientCard from "rc-app/components/Card/clientCard";
import { Tabs } from "rc-app/components/Card/tabs";
import { toPascalCase } from "rc-app/utils";
import { PropertyCard } from "rc-app/components/Card/propertyCard";
import { Messages } from "rc-app/components/Messages";
import PriceFilter from "rc-app/components/PriceFilter";

export const ClientDetails = () => {
  const [customer, setCustomer] = useState({
    _id: "",
    first_name: "",
    last_name: "",
    email_id: "",
    mobile_number: "",
    address: {
      address_line1: "",
      address_line2: "",
      landmark: "",
      postal_code: "",
      city: "",
      province: "",
      country: "",
    },
    joined_date: "",
    property_shortlist: [],
  });
  const [digitalTwinDetailList, setDigitalTwinDetailList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [activeTab, setActiveTab] = useState("clientShortListProperty");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customerId = queryParams.get("customerId");
  const [reveal_address] = useState(true);
  const navigate = useNavigate();

  //   function handleTabButton(digital_twin_id:string, matter_port_id:string, tab:string){
  //     navigate(`/property_details?digitalTwinId=${digital_twin_id}&propertyId=${matter_port_id}&tab=${tab}`)
  // }

  const [agency, setAgency] = useState<{ [key: string]: string }>({});
  const [selectedOptionForTable, setSelectedOptionForTable] = useState({
    agency: "all",
    visibility: "all",
    region: "all",
    rooms: "all",
    minPrice: 0,
    maxPrice: 0,
    // price: 0,
  });
  const visibility: { [key: string]: string } = {
    Exclusive: "exclusive",
    Network: "network",
  };
  const rooms: { [key: string]: number } = {
    "2 Bedrooms": 2,
    "3 Bedrooms": 3,
    "4 Bedrooms": 4,
    "5 Bedrooms": 5,
    "6 Bedrooms": 6,
  };
  const region: { [key: string]: string } = {
    Abruzzo: "abruzzo",
    "Aosta Valley": "aosta_valley",
    Puglia: "puglia",
    Calabria: "calabria",
    Campania: "campania",
    Lazio: "lazio",
    Liguria: "liguria",
    Marche: "marche",
    Sicily: "sicily",
  };

  useEffect(() => {
    async function fetchAgency() {
      try {
        const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_agency?sort_by=created_at&sort_order=-1`;
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${UserService.getToken()}`,
            "Content-Type": "application/json",
          },
        });
        // setProperties(response.data.mp_digital_twin_list);
        const convertedObject: { [key: string]: string } = {};
        response.data.re_agency_list.forEach((item: any) => {
          convertedObject[item.name] = item._id;
        });
        setAgency(convertedObject);
        // setTotalPages(response.data.total_mp_digital_twin);
        console.log("agency", convertedObject);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    }

    fetchAgency();
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectName = event.target.name;
    setSelectedOptionForTable((prevState) => ({
      ...prevState,
      [selectName]: selectedValue,
    }));
  };

  // const handlePriceChange = (newValue: number) => {
  //   setSelectedOptionForTable((prevState) => ({
  //     ...prevState,
  //     ["price"]: newValue,
  //   }));
  //   console.log("Slider value:", newValue);
  // };

  const [digitalTwin, setDigitalTwin] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [agentId, setAgentId] = useState("");
  const [isAddPropertyShotlistSuccess, setIsAddPropertyShotlistSuccess] =
    useState(false);
  const [isAddPropertyShotlistFailed, setIsAddPropertyShotlistFailed] =
    useState(true);

  const [shortlistPropertyIds, setShortlistPropetyIds] = useState([]);
  const [filterDigitalTwinRows, setFilterDigitalTwinRows] = useState([]);

  async function fetchProperties(minPrice = 0, maxPrice = 0) {
    try {
      let agencyKey = "";
      let visibilityKey = "";
      let roomsKey = 0;
      let regionKey = "";

      if (
        selectedOptionForTable.agency !== "all" &&
        agency[selectedOptionForTable.agency]
      ) {
        agencyKey = agency[selectedOptionForTable.agency];
      }

      if (
        selectedOptionForTable.visibility !== "all" &&
        visibility[selectedOptionForTable.visibility]
      ) {
        visibilityKey = visibility[selectedOptionForTable.visibility];
        console.log("visi", visibilityKey);
      }

      if (
        selectedOptionForTable.rooms !== "all" &&
        rooms[selectedOptionForTable.rooms]
      ) {
        roomsKey = rooms[selectedOptionForTable.rooms];
      }

      if (
        selectedOptionForTable.region !== "all" &&
        region[selectedOptionForTable.region]
      ) {
        regionKey = region[selectedOptionForTable.region];
      }

      const API_URL = `${
        process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL
      }/api/v1/realestate/views/mp_digital_twin/agent/properties/${UserService.getUseremail()}?sort_by=created_at&sort_order=-1&page=0&limit=100&agency_id=${agencyKey}&property_visibility=${
        visibilityKey === "network" || visibilityKey === "exclusive"
          ? visibilityKey
          : "all"
      }&num_bedrooms=${
        roomsKey === 0 ? "all" : roomsKey
      }&province=${regionKey}&${minPrice != 0 ? `min_price=${minPrice}` : ""}&${
        maxPrice != 0 ? `max_price=${maxPrice}` : ""
      }`;
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });

      const digitalTwinRows = response.data.mp_digital_twin_list.map(
        (item: any) => {
          return {
            id: item._id,
            property_id: item.property_id,
            title: item.title,
            visibility: item.property_visibility,
            location: item.address.city,
            aria: item.characteristics.area_sqm,
            rooms: item.characteristics.num_bedrooms,
            price: item.pricing.price,
            commision: item.pricing.condominium_expenses,
            mp_digital_twin_id: item._id,
            seller_agency_id: item.agency?._id,
            buyer_agent_id: agentId,
            added_by:
              UserService.findRole("agent")[0] == "agent"
                ? "agent"
                : "customer",
          };
        }
      );

      setDigitalTwin(digitalTwinRows);

      console.log("digitalTwinRows", digitalTwinRows);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  }
  useEffect(() => {
    getAgentByEmail(UserService.getUseremail()).then((data) => {
      setAgentId(data.agent._id);
      fetchProperties();
    });
  }, [
    selectedOptionForTable.agency,
    selectedOptionForTable.visibility,
    selectedOptionForTable.region,
    selectedOptionForTable.rooms,
    // selectedOptionForTable.price,
    // filterDigitalTwinRows,
    // digitalTwin
  ]);

  console.log("kkkkk", digitalTwin);

  console.log("filteredDigi111", agentId);

  const handleSelectionChange = (newSelection: any) => {
    setSelectionModel(
      newSelection.map((item: any, index: any) => {
        console.log("rows", filterDigitalTwinRows[index]);
        return {
          mp_digital_twin_id: filterDigitalTwinRows[index].mp_digital_twin_id,
          seller_agency_id: filterDigitalTwinRows[index].seller_agency_id,
          buyer_agent_id: filterDigitalTwinRows[index].buyer_agent_id,
          added_by: filterDigitalTwinRows[index].added_by,
        };
      })
    );
  };


  console.log("selectionModel", selectionModel);
  


  const handleAddPropertyToShortlist = () => {
    addPropertyToShortlist(customerId, selectionModel)
      .then((res) => {
        console.log("addPropertyToShortlist", res);

        setIsAddPropertyShotlistSuccess(res.success);
        setIsAddPropertyShotlistFailed(res.success);

        setTimeout(() => {
          setIsAddPropertyShotlistSuccess(false);
          setIsAddPropertyShotlistFailed(true);
          setTimeout(() => {
            fetchProperties();
            handleClose();
            // window.location.reload();
          }, 2000);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function fetchClients() {
    try {
      const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_customer/${customerId}`;
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      setCustomer(response.data.customer);
      setShortlistPropetyIds(
        response.data.customer.property_shortlist.map(
          (property: any) => property.mp_digital_twin_id
        )
      );

      interface PropertyShortlistItem {
        mp_digital_twin_id: string;
      }

      const propertyShortlist: PropertyShortlistItem[] =
        response.data.customer.property_shortlist;

      const promises = propertyShortlist.map((property) => {
        const digitalTwinId = property.mp_digital_twin_id;
        return getDigitalTwinById(digitalTwinId, reveal_address);
      });

      const digitalTwinDetails = await Promise.all(promises);
      setDigitalTwinDetailList(digitalTwinDetails);

      console.log("ll", digitalTwinDetails);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  }

  useEffect(() => {
    if (customerId) {
      fetchClients();
    }
  }, [customerId, reveal_address, digitalTwinDetailList]);

  console.log("customerff", customer);

  const handleDetailButton = (
    digital_twin_id: String,
    matterport_id: String
  ) => {
    navigate(
      `/property_details?digitalTwinId=${digital_twin_id}&propertyId=${matterport_id}`
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const filteredDigitaltwin = digitalTwin.filter(
    (obj) => !shortlistPropertyIds.includes(obj.id)
  );
  console.log("filteredDigitaltwin", filteredDigitaltwin);
  console.log("filteredDigitaltwin", shortlistPropertyIds);

  const handleSearch = (searchTerm: string) => {
    console.log("searchTerm", searchTerm);

    const searchTermLowerCase = searchTerm.toLowerCase();

    const filtered = filteredDigitaltwin.filter(
      (item) =>
        item.title.toLowerCase().includes(searchTermLowerCase) ||
        // || item.matterport_id.toString().includes(searchTermLowerCase)
        (item.rooms && item.rooms.toString().includes(searchTermLowerCase))
    );
    setFilterDigitalTwinRows(filtered);
  };
  console.log("setFiltered", filterDigitalTwinRows);

  function handleTabButton(
    digital_twin_id: string,
    matterport_id: string,
    tab: string
  ) {
    navigate(
      `/property_details?digitalTwinId=${digital_twin_id}&propertyId=${matterport_id}&tab=${tab}`
    );
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [customerList, setCustomerList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  let pageLimit = 10;
  console.log(setCurrentPage, totalPages, customerList);

  async function fetchClientList() {
    try {
      const API_URL = `${
        process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL
      }/api/v1/realestate/views/re_customer/agent/customer/${UserService.getUseremail()}?sort_by=created_at&sort_order=-1&page=${
        currentPage - 1
      }&limit=${pageLimit}`;
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      setCustomerList(response.data.re_customer_list);

      setTotalPages(response.data.total_re_customers);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  }

  useEffect(() => {
    fetchClientList();
  }, []);

  const handleFilterChange = (minPrice: string, maxPrice: string) => {
    console.log("Min Price:", minPrice);
    console.log("Max Price:", maxPrice);
    const minPriceValue = minPrice ? parseInt(minPrice) : 0;
    const maxPriceValue = maxPrice ? parseInt(maxPrice) : 0;
    if (parseInt(minPrice) > parseInt(maxPrice)) {
      alert("Min price should not be greater than max price.");
      return;
    } else if (parseInt(maxPrice) < parseInt(minPrice)) {
      alert("Max price should not be less than min price.");
      return;
    } else {
      fetchProperties(minPriceValue, maxPriceValue);
    }
  };


  // const [rowsData, setRowsData]= useState([])
  // useEffect(()=>{
  //   setRowsData(filterDigitalTwinRows)
  // },[digitalTwin])


  useEffect(()=>{
    const filteredDigitaltwin = digitalTwin.filter(
      (obj) => !shortlistPropertyIds.includes(obj.id)
    );
    setFilterDigitalTwinRows(filteredDigitaltwin);
  },[digitalTwin])

  return (
    <>
      <div className="flex container max-w-screen-2xl bg-[#F6F6F8] min-h-screen overflow-y-auto">
        <div>
          <MiniDrawer />
        </div>
        <div className="flex w-full flex-grow pt-[5rem] md:pt-[7rem] px-2 md:px-6 justify-center pb-2">
          <div className=" w-full flex flex-col max-w-screen-xl ">
            <div className="w-full mt-3 flex flex-col items-center md:gap-5 p-2 md:p-2 shadow-md bg-white rounded-md">
              <ClientCard
                isProperty={false}
                title={customer.first_name + " " + customer.last_name}
                isClient={true}
                isClientDetails={true}
                client_id="C3254236"
                customer_id={customerId}
                property_shortlisted={customer.property_shortlist.length}
                // handleDetailButton={handleDetailButton}
                // handleTabButton={handleTabButton}
                data={[
                  { value: customer.email_id, icon: "Email" },
                  { value: customer.mobile_number, icon: "Phone Number" },
                  { value: customer.address.city, icon: "City" },
                  { value: customer.address.province, icon: "Region" },
                  { value: customer.address.country, icon: "Country" },
                  // { value: "Not Available", icon: "Client Last Login" },
                  // { value: customer.joined_date, icon: "Client Joined Date" },
                ]}
              />
            </div>

            <div className="w-full mt-5 flex items-center md:gap-5  bg-[#F6F6F8] rounded-md">
              <div className="flex flex-col w-full  flex-grow ">
                <div className="flex flex-row w-full  flex-grow gap-3">
                  <div
                    className={`flex items-center justify-center px-2 py-2 w-1/4 shadow-md rounded-t-lg ${
                      activeTab === "clientShortListProperty"
                        ? "bg-[#283BBE] text-white"
                        : "text-[#283BBE] bg-white"
                    }`}
                  >
                    <Button
                      onClick={() => {
                        setActiveTab("clientShortListProperty");
                      }}
                      className={` text-[13px] sm:text-[12px] md:text-[13px] lg:text-[15px] xl:text-[16px] rounded-[3px]  `}
                      textColor="white"
                    >
                      Client Property Shortlist
                    </Button>
                  </div>
                  <div
                    className={`flex items-center justify-center px-2 py-2 w-1/4 shadow-md rounded-t-lg ${
                      activeTab === "clientPurchases"
                        ? "bg-[#283BBE] text-white "
                        : "text-[#283BBE]  bg-white"
                    }`}
                  >
                    <Button
                      onClick={() => {
                        // setActiveTab("clientPurchases")
                      }}
                      className={` text-[13px] sm:text-[12px] md:text-[13px] lg:text-[15px] xl:text-[16px] rounded-[3px]  `}
                      textColor="white"
                    >
                      Client Purchases
                    </Button>
                  </div>
                  <div
                    className={`flex items-center justify-center px-2 py-2 w-1/4 shadow-md rounded-t-lg ${
                      activeTab === "clientMessages"
                        ? "bg-[#283BBE] text-white "
                        : "text-[#283BBE]  bg-white"
                    }`}
                  >
                    <Button
                      onClick={() => {
                        setActiveTab("clientMessages");
                      }}
                      className={` text-[13px] sm:text-[12px] md:text-[13px] lg:text-[15px] xl:text-[16px] rounded-[3px]   `}
                      textColor="white"
                    >
                      Client Messages
                    </Button>
                  </div>
                </div>

                <div className="w-full md:w-auto rounded-l  bg-white">
                  {activeTab === "clientShortListProperty" && (
                    <div className="w-full shadow-md py-3 px-3 ">
                      <div className="flex justify-end">
                        <Button
                          children={`+ Add property to shortlist`}
                          className="ml-auto bg-[#FF307A]  rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] "
                          onClick={handleOpen}
                        />
                      </div>
                      <div className="grid gap-3 max-xl:grid-cols-2 max-md:grid-cols-1">
                        {digitalTwinDetailList.length > 0 ? (
                          digitalTwinDetailList.map((digitalTwinDetails) => (
                            <div className="bg-white shadow-md grid   max-xl:grid-cols-1 max-2xl:grid-cols-7 2xl:grid-cols-7  p-4 gap-3 rounded-md">
                              <div className="  max-xl:col-span-1  max-2xl:col-span-2 2xl:col-span-2 flex flex-col gap-2">
                                <Tabs
                                  tabs={[
                                    { label: "Digital Twin" },
                                    { label: "Images" },
                                    { label: "Video(s)" },
                                  ]}
                                  handleTabButton={handleTabButton}
                                  matterport_id={
                                    digitalTwinDetails.matterport_id
                                  }
                                  digital_twin_id={digitalTwinDetails._id}
                                />
                                <div className="w-full h-full max-h-52 2xl:max-h-80 overflow-hidden rounded-md">
                                  <img
                                    src={
                                      digitalTwinDetails.property_thumbnail ===
                                      null
                                        ? "https://storage.googleapis.com/oneverse-dev/realestate/no_property_image.jpg"
                                        : // "https://storage.googleapis.com/oneverse-dev/realestate/06.11.2024_17.25.09--999.jpg"
                                          digitalTwinDetails.property_thumbnail
                                    }
                                    alt="house"
                                    className="object-cover w-full h-full"
                                  />
                                </div>
                              </div>
                              <div className="  max-xl:col-span-1 max-2xl:col-span-5 2xl:col-span-5 flex flex-col justify-between 2xl:gap-8 gap-5">
                                <PropertyCard
                                  propertyInfo={{
                                    title: digitalTwinDetails.title
                                      ? digitalTwinDetails.title
                                      : "",
                                    propertyId: digitalTwinDetails.property_id
                                      ? digitalTwinDetails.property_id
                                      : "",
                                    propertyLink: digitalTwinDetails
                                      .characteristics.shareable_link
                                      ? digitalTwinDetails.characteristics
                                          .shareable_link
                                      : "",
                                    description: digitalTwinDetails.description
                                      ? digitalTwinDetails.description
                                      : "",
                                    location: digitalTwinDetails.address.city
                                      ? digitalTwinDetails.address.city
                                      : "",
                                    contractType: digitalTwinDetails
                                      .characteristics.contract_type
                                      ? digitalTwinDetails.characteristics
                                          .contract_type
                                      : "",
                                    bedrooms: digitalTwinDetails.characteristics
                                      .num_bedrooms
                                      ? digitalTwinDetails.characteristics
                                          .num_bedrooms + " Bedrooms"
                                      : 0,
                                    area: digitalTwinDetails.characteristics
                                      .area_sqm
                                      ? digitalTwinDetails.characteristics
                                          .area_sqm + " Sq. Mtr"
                                      : 0,
                                    type: digitalTwinDetails.characteristics
                                      .type_of_property
                                      ? toPascalCase(
                                          digitalTwinDetails.characteristics
                                            .type_of_property
                                        )
                                      : "",
                                    lifestyle: digitalTwinDetails
                                      .characteristics.lifestyle
                                      ? toPascalCase(
                                          digitalTwinDetails.characteristics
                                            .lifestyle
                                        )
                                      : "",
                                    listedOn: digitalTwinDetails.characteristics
                                      .listing_date
                                      ? digitalTwinDetails.characteristics
                                          .listing_date
                                      : "",
                                    price: digitalTwinDetails.pricing.price
                                      ? digitalTwinDetails.pricing.price
                                      : 0,
                                    isExclusive: false,
                                    // Exclusive: "NETWORK",
                                    property_visibility:
                                      digitalTwinDetails.property_visibility,
                                    digital_twin_id: digitalTwinDetails._id,
                                    matterport_id:
                                      digitalTwinDetails.matterport_id,
                                    isClientDetails: true,
                                  }}
                                  handleDetailButton={handleDetailButton}
                                />
                                {/* "flex items-center justify-end gap-5 max-xl:flex-col max-xl:gap-2" */}
                                <div className="flex items-center xl:justify-end max-xl:items-end  max-xl:gap-2 gap-4">
                                  <div className="flex max-xl:w-[55%] items-center flex-col sm:flex-row gap-2 xl:justify-end ">
                                    <Button
                                      children={`Remove from Shortlist`}
                                      textColor="black"
                                      className="text-[#EC0000] w-full font-semibold border-2 border-[#EC0000] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] "
                                      onClick={async () => {
                                        try {
                                          const mpDigitalTwinId =
                                            digitalTwinDetails._id;
                                          const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/agent/remove_property_from_client_property_shortlist/${customerId}?mp_digital_twin_id=${mpDigitalTwinId}`;
                                          const response = await axios.put(
                                            API_URL,
                                            null,
                                            {
                                              headers: {
                                                Authorization: `Bearer ${UserService.getToken()}`,
                                                "Content-Type":
                                                  "application/json",
                                              },
                                            }
                                          );
                                          console.log(
                                            "API call successful",
                                            response.data
                                          );
                                          // window.location.reload();
                                        } catch (error) {
                                          console.error(
                                            "Error while calling API:",
                                            error
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="flex max-xl:w-[45%] xl:justify-end">
                                    <Button
                                      children="Details"
                                      className="max-xl:w-full bg-[#283BBE] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[45px] sm:h-[40px] "
                                      onClick={() => {
                                        handleDetailButton(
                                          digitalTwinDetails._id,
                                          digitalTwinDetails.matterport_id
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>Property Shortlist is empty</div>
                        )}
                      </div>
                    </div>
                  )}

                  {activeTab === "clientPurchases" && (
                    <div className="w-full shadow-md py-1 px-3">
                      <div className="w-full mt-3 flex flex-col items-center md:gap-5 p-1 shadow-md bg-white rounded-md">
                        <PropertyCardLarge
                          property_images="https://storage.googleapis.com/oneverse-dev/realestate/image1.png"
                          contract_type="sale"
                          isAgencyList={false}
                          property_visibility="Exclusive"
                          property_id="P1"
                          property_link="Property Link"
                          title="title"
                          description="description"
                          digital_twin_id=""
                          matterport_id=""
                          isClient={false}
                          isClientDetails={true}
                          isProperty={true}
                          handleDetailButton={handleDetailButton}
                          // handleTabButton={handleTabButton}
                          data={[
                            { value: "Lukcnow", icon: <LocationOnIcon /> },
                            { value: "Rent", icon: <HomeIcon /> },
                            { value: "2 Bedrooms", icon: <KingBedIcon /> },
                            { value: "1200 Sq. Mtr", icon: <OpenInFullIcon /> },

                            { value: "History", icon: "Type" },
                            { value: "Apartment", icon: "Lifestyle" },
                            { value: "DD/MM/YYYY", icon: "Listed On" },
                            { value: "Agneyc Name", icon: "Agency" },
                          ]}
                          detailButton={
                            <Button
                              children="Details"
                              className="bg-[#283BBE] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[40px] "
                              onClick={() => {
                                // handleDetailButton('property._id', 'property.matterport_id')
                                navigate(
                                  `/property_details?digitalTwinId=${"digital_twin_id"}&propertyId=${"matterport_id"}`
                                );
                              }}
                            />
                          }
                          removeButton={
                            <div className="flex w-full items-center flex-col sm:flex-row gap-2 justify-end ">
                              <Button
                                children={`Remove from Shortlist`}
                                textColor="black"
                                className="text-[#EC0000] font-semibold border-2 border-[#EC0000] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] "
                                onClick={() => {
                                  console.log("remove from shortlist");
                                }}
                              />
                            </div>
                          }
                        />
                      </div>
                      <div className="w-full mt-3 flex flex-col items-center md:gap-5 p-1 shadow-md bg-white rounded-md">
                        <PropertyCardLarge
                          property_images="https://storage.googleapis.com/oneverse-dev/realestate/image1.png"
                          contract_type="sale"
                          isAgencyList={false}
                          property_visibility="Exclusive"
                          property_id="P1"
                          property_link="Property Link"
                          title="title"
                          description="description"
                          digital_twin_id=""
                          matterport_id=""
                          isClient={false}
                          isClientDetails={true}
                          isProperty={true}
                          handleDetailButton={handleDetailButton}
                          // handleTabButton={handleTabButton}
                          data={[
                            { value: "Lukcnow", icon: <LocationOnIcon /> },
                            { value: "Rent", icon: <HomeIcon /> },
                            { value: "2 Bedrooms", icon: <KingBedIcon /> },
                            { value: "1200 Sq. Mtr", icon: <OpenInFullIcon /> },

                            { value: "History", icon: "Type" },
                            { value: "Apartment", icon: "Lifestyle" },
                            { value: "DD/MM/YYYY", icon: "Listed On" },
                            { value: "Agneyc Name", icon: "Agency" },
                          ]}
                          detailButton={
                            <Button
                              children="Details"
                              className="bg-[#283BBE] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[40px] "
                              onClick={() => {
                                // handleDetailButton('property._id', 'property.matterport_id')
                                navigate(
                                  `/property_details?digitalTwinId=${"digital_twin_id"}&propertyId=${"matterport_id"}`
                                );
                              }}
                            />
                          }
                          removeButton={
                            <div className="flex w-full items-center flex-col sm:flex-row gap-2 justify-end ">
                              <Button
                                children={`Remove from Shortlist`}
                                textColor="black"
                                className="text-[#EC0000] font-semibold border-2 border-[#EC0000] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] "
                                onClick={() => {
                                  console.log("remove from shortlist");
                                }}
                              />
                            </div>
                          }
                        />
                      </div>
                    </div>
                  )}

                  {activeTab === "clientMessages" && (
                    <div>
                      {/* <Messages customerList={customerList}/> */}
                      <Messages customer={customer} agentId={agentId} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className="fixed top-[10%] left-[15%] w-[70%] h-[80%] overflow-auto justify-center bg-white border-2 border-black shadow-md p-4 ">
                    <div className={`flex `}>
                      <h1 className="font-bold text-[#FF307A] text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5">
                        Client property shortlist
                      </h1>
                    </div>
                    <div className="w-full flex flex-col flex-start md:items-center gap-1 p-4 shadow-md bg-white rounded-md mb-3 ">
                      <div className="w-full xl:w-full ">
                        <div className="w-full flex flex-wrap flex-col sm:flex-row md:flex-row justify-center">
                          <div className="w-full sm:w-1/2 md:w-1/4  px-2 mb-2 mx-auto">
                            <FilterSelect
                              name="agency"
                              label="Agency"
                              options={["All", ...Object.keys(agency)]}
                              value={selectedOptionForTable["agency"]}
                              className="bg-white text-black"
                              handleChange={handleSelectChange}
                            />
                          </div>

                          <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                            <FilterSelect
                              name="visibility"
                              label="Visibility"
                              options={["All", ...Object.keys(visibility)]}
                              value={selectedOptionForTable.visibility}
                              className="bg-white text-black"
                              handleChange={handleSelectChange}
                            />
                          </div>

                          <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                            <FilterSelect
                              name="region"
                              label="Region"
                              options={["All", ...Object.keys(region)]}
                              value={selectedOptionForTable["region"]}
                              className="bg-white text-black"
                              handleChange={handleSelectChange}
                            />
                          </div>

                          <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                            <FilterSelect
                              name="rooms"
                              label="Rooms"
                              options={["All", ...Object.keys(rooms)]}
                              value={selectedOptionForTable["rooms"]}
                              className="bg-white text-black"
                              handleChange={handleSelectChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full md:w-full lg:w-full xl:w-full max-sm:gap-2 flex flex-wrap flex-col sm:flex-row md:flex-row md:items-center sm:flex-start md:justify-between px-1 md:px-2">
                        <div className="w-full md:w-full lg:w-full xl:w-full max-sm:gap-2 flex flex-col sm:flex-row md:flex-row  xl:flex-row md:items-center sm:flex-start md:justify-between pr-1 md:pr-2">
                          <div className="flex  w-full flex-grow sm:w-[100%] md:w-[65%] lg:w-[65%] xl:w-1/2 sm:pl-2">
                            <PriceFilter onFilterChange={handleFilterChange} />
                          </div>
                          <div className="flex flex-grow  sm:w-[100%] md:w-[35%] lg:w-[35%] xl:w-1/2 sm:pl-2">
                            <SearchBar onSearch={handleSearch} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-4">
                      <DataTable
                        rows={filterDigitalTwinRows}
                        handleSelectionChange={handleSelectionChange}
                      />
                    </div>
                    <div className=" mt-4 flex items-center justify-center flex-col  sm:flex-row gap-3 w-full  ">
                      <div
                        className="flex w-1/3 cursor-pointer text-white items-center justify-center  bg-[#283BBE] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] "
                        onClick={() => {
                          if (selectionModel.length>0) {
                            handleAddPropertyToShortlist();
                          }
                        }}
                      >
                        {` ${selectionModel.length == 0 ? 'Select Property' : 'Save'}`}
                      </div>

                      {isAddPropertyShotlistSuccess && (
                        <div className="w-1/3 flex items-center justify-center flex-col  sm:flex-row gap-3 ">
                          <div className="flex cursor-pointer text-white items-center justify-center border border-[##008C27]  bg-[#31B957] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
                            {`Property Shortlisted `}
                          </div>
                        </div>
                      )}
                      {!isAddPropertyShotlistFailed && (
                        <div className="w-1/3 flex items-center justify-center flex-col  sm:flex-row gap-3   ">
                          <div className="flex cursor-pointer text-white items-center justify-center border border-[#6D0000]  bg-[#EC0000] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
                            {`Failed... , Please Retry! `}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
