import React from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    limit: number;
    onPageChange: (page: number) => void;
}

const PaginationRounded: React.FC<PaginationProps> = ({ currentPage, totalPages, limit, onPageChange }) => {
  const handlePrevious = () => {
      onPageChange(Math.max(currentPage - 1, 1));
  };

  const handleNext = () => {
      onPageChange(Math.min(currentPage + 1, totalPages));
  };

  const showPagesBetween = (start: number, end: number) => {
    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          style={{
            padding: "8px 16px",
            margin: "0 4px",
            backgroundColor: currentPage === i ? "#283BBE" : "#ffffff",
            color: currentPage === i ? "#ffffff" : "#283BBE",
            borderRadius: "4px",
            cursor: "pointer",
            border: "1px solid #283BBE"
          }}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  const actualTotalPages = Math.ceil(totalPages / limit);

  const currentPageBlock = Math.ceil(currentPage / limit);

  const showPreviousButton = currentPage !== 1;
  const showNextButton = currentPage !== actualTotalPages;
  
  if (totalPages === 0) {
    return null;
  }
  
  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        {showPreviousButton && (
            <button
                onClick={handlePrevious}
                style={{ padding: "8px 16px", margin: "0 4px", backgroundColor: "#ffffff", color: "#283BBE", borderRadius: "4px", cursor: "pointer", border: "1px solid #283BBE" }}
            >
               Previous
            </button>
        )}

        {totalPages > 0 ? (
            showPagesBetween((currentPageBlock - 1) * limit + 1, Math.min(currentPageBlock * limit, actualTotalPages))
        ) : null}

        {totalPages === 0 && (
            <span>No pages available</span>
        )}

        {showNextButton && (
            <button
                onClick={handleNext}
                style={{ padding: "8px 16px", margin: "0 4px", backgroundColor: "#ffffff", color: "#283BBE", borderRadius: "4px", cursor: "pointer", border: "1px solid #283BBE" }}
            >
                Next
            </button>
        )}
    </div>
  );
};

export default PaginationRounded;
