import React from "react"

import LandingPageOurStory from "rc-app/components/LandingPageOurStory";
// import PropertyCardLarge from "rc-app/components/PropertyCardLarge";
import PropertyCard from "rc-app/components/NetworkPropertySquare";

// import TestCard from "rc-app/components/TestCard";
// import { FaStar, FaHeart } from 'react-icons/fa';

// import { Box } from "@mui/material";
import LandingPageIntro from "rc-app/components/LandingPageIntro";
import LandingPageFeature from "rc-app/components/LandingPageFeature";
import UserService from "rc-app/services/UserService";
import Footer from "rc-app/components/Footer";
// import FooterComponent from "rc-app/components/Footer/footer";

export const IntroPage = () => {
  const pages = ['properties', 'vr-tour', 'vr-showcase', "add" ,'q', 'e' ];
  // const extraInfo = [
  //     { icon: <FaStar />, text: '4.5 stars' },
  //     { icon: <FaHeart />, text: '100 likes' }
  //   ];

  console.log("userService", UserService.getToken());
  

  return (<>
    {/* <Box sx={{ height: '100vh', overflow: "scroll", pb: 6, mb: 2 }} > */}
    <div className="container max-w-screen-2xl" >
      <div className=" mt-15 w-full bg-gradient-to-b from-[#000524] to-[#000F77]">
        <LandingPageIntro />
      </div>
      <div className="w-full bg-[#EDE4FF] px-4 md:px-16 py-2 md:py-8">
        <LandingPageFeature />
      </div>
      
      <LandingPageOurStory />

      <div className="mt-0 w-full bg-slate-000 p-4 sm:p-8 md:pl-24 md:pr-24 md: pt-8 ">
        <h2 className="text-[#FF307A] text-3xl lg:text-4xl text-start leading-tight font-bold mb-5 " style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }}>Network Properties</h2>
        {/* <div className="grid grid-cols-3 gap-4 "> */}
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
        {
          pages.map((page, index)=>(
            <PropertyCard key={index}
              imageUrl="https://storage.googleapis.com/oneverse-dev/realestate/LandingPageIntroImage.jpeg"
              title="image card"
              // className="w-[441px] h-[325px]  rounded-lg w-full md:w-auto rounded-l"
              className="w-full md:w-auto rounded-l"
            />
          ))
        }
        </div>
        <div className="flex justify-center ">
          <button className="outline outline-offset-2 outline-1 bg-transparent p-2 rounded-lg text-[#283BBE] border border-#283BBE font-inter font-bold ...">
            View All Network Properties  
          </button>
        </div>            
      </div>
      

      <footer className="bg-[#000630] relative">
        <Footer />
      </footer>


      {/* <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
        <div className="p-4 border border-gray-200">Content 1</div>
        <div className="p-4 border border-gray-200">Content 2</div>
        <div className="p-4 border border-gray-200">Content 3</div>
        <div className="p-4 border border-gray-200">Content 4</div>
        <div className="p-4 border border-gray-200">Content 5</div>
        <div className="p-4 border border-gray-200">Content 6</div>
        
      </div> */}

      
      {/* <div className="ml-28 my-32">
        <PropertyCardLarge
          imageUrl="/images/image4.jpeg"
          location="france"
          children=""
          sell="800"
          bedrooms="20"
          mtrs="15"
          type="mbhk"
          lifestyle="itly"
          date="25-12-2015"
          price="800$"
          isAgencyList={true}
          property_visibility='Exclusive'
        />
      </div>

      <div className="ml-28 my-32">
        <PropertyCardLarge
          imageUrl="/images/img1.png"
          location="france"
          children=""
          sell="800"
          bedrooms="20"
          mtrs="15"
          type="mbhk"
          lifestyle="itly"
          date="25-12-2015"
          price="800$"
          isAgencyList={false}
          property_visibility='Exclusive'
        />
      </div>

      <div className="grid grid-cols-3 gap-4 ">
        <PropertyCard
          imageUrl="/images/img1.jpeg"
          title="Network property card"
          className="w-full md:w-auto rounded-l"
        />
      </div>
 */}


      {/* <div className="flex flex-col space-y-4 h-80 ">
        <TestCard
          title="Vertical Card"
          description="This is a vertical card."
          imageUrl="https://via.placeholder.com/300"
        />

        <TestCard
          title="Horizontal Card"
          description="This is a horizontal card."
          imageUrl="https://via.placeholder.com/300"
          horizontal
          extraInfo={extraInfo}
        />
        <TestCard
          title="Horizontal Card"
          description="This is a horizontal card."
          imageUrl="https://via.placeholder.com/300"
        //   horizontal
          extraInfo={extraInfo}
        />
      </div> */}
      {/* </Box> */}


    </div>
  </>)
}