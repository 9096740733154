import React, {
  // useState,
} from "react"



import { 
  FaVrCardboard, FaCity, FaInfinity, FaSignal, FaGlobeEurope, FaCalculator, FaHeadset,
 } from "react-icons/fa"




// import { useLocation, useNavigate } from "react-router-dom";








export const ProductFeature = () => {


  return (
    <>
      <div className="flex container max-w-screen-2xl bg-[#F6F6F8]">
        <div className="flex w-full flex-col items-center py-4 md:py-6 px-4 md:px-6 gap-3 md:gap-8 justify-center ">
          <div className=" flex flex-col md:flex-row container max-w-screen-lg gap-3 md:gap-8  ">
            <div className="w-full md:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaVrCardboard className="icon-outlined text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Virtual Reality presentations</span>
              </div>
              <div className="text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">A portal focused primarily on providing solutions enabling Virtual Reality presentations.</span>
              </div>
            </div>
            <div className="w-full md:w-1/2  flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaCity className="icon-outlined text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Without a physical visit</span>
              </div>
              <div className="text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">Show property options to clients without doing a physical visit.</span>
              </div>
            </div>            
          </div>

          <div className=" flex flex-col md:flex-row container max-w-screen-lg gap-2 md:gap-8  ">
            <div className="w-full md:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaInfinity className="icon-outlined fa-regular text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">No limit</span>
              </div>
              <div className="text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">No limit on digital twins and properties hosted per agent account.</span>
              </div>
            </div>
            <div className="w-full md:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaSignal className="icon-outlined text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Know how your peers doing</span>
              </div>
              <div className="text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">Know how your peers are doing in your city, region and country.</span>
              </div>
            </div>            
          </div>

          <div className=" flex flex-col md:flex-row container max-w-screen-lg gap-2 md:gap-8  ">
            <div className="w-full md:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaGlobeEurope className="icon-outlined fa-regular text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Buy around the globe</span>
              </div>
              <div className="text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">Increased access to global marketplace: Buy side agents around the globe</span>
              </div>
            </div>
            <div className="w-full md:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                {/* <FaSignal className="icon-outlined text-4xl text-[#283BBE]" /> */}
                <img src="https://storage.googleapis.com/oneverse-dev/realestate/1verse-logo-2.png" 
                  className="h-9 w-9" alt="1Verse logo" 
                />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Support from 1verse Italia</span>
              </div>
              <div className="text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">A portal focused primarily on providing solutions enabling Virtual Reality presentations.</span>
              </div>
            </div>            
          </div>

          <div className=" flex flex-col md:flex-row container max-w-screen-lg gap-2 md:gap-8  ">
            <div className="w-full md:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaCalculator className="icon-outlined fa-regular text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Competitive pricing</span>
              </div>
              <div className="text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">Show property options to clients without doing a physical visit.</span>
              </div>
            </div>
            <div className="w-full md:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaVrCardboard className="icon-outlined text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Various Solutions</span>
              </div>
              <div className="text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">No limit on digital twins and properties hosted per agent account.</span>
              </div>
            </div>            
          </div>
          <div className=" flex flex-col md:flex-row container max-w-screen-lg gap-2 md:gap-8  ">
            <div className="w-full md:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md bg-white rounded-md">
              <div className="p-4 bg-[#E3E7FF] rounded-md">
                <FaHeadset className="icon-outlined fa-regular text-4xl text-[#283BBE]" />
              </div>
              <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                <span className="break-words">Online support</span>
              </div>
              <div className="text-[11px] font-medium sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                <span className="break-words">Know how your peers are doing in your city, region and country.</span>
              </div>
            </div>                       
          </div>

          
          
          
        </div>
      </div>
    </>
  )
}