import React, { useState } from "react"

import {  useNavigate } from 'react-router-dom';
import {
    Typography, 
    // Grid, 
    Paper,
    Button, TextField
} from '@mui/material';
import { getVirtualtourByJoincode } from "../Home";
import MiniDrawer from "rc-app/components/MiniDrawer";


export const VrTour = () => {
    const [tourCode, setTourCode] = useState('');
    const navigate = useNavigate();
    const handleTourEntry = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleJoinVrTour()
        // Add logic to handle VR tour entry, e.g., validate tour code and initiate VR Tour
        
        console.log('Tour code submitted:', tourCode);
    };

    const handleJoinVrTour=()=>{
        getVirtualtourByJoincode(tourCode).then(res=>{
            console.log("getVirtualtourByJoincode",res);
            const matterport_id= res.virtual_tour['matterport_id']
            navigate(`/virtual-tour?propertyId=${matterport_id}&channel=channel_${tourCode}&mode=client`);
        })
    }

    return (
        <>
            <div className="container max-w-screen-2xl flex bg-[#F6F6F8] ">
                <div><MiniDrawer /></div>
                <div className="flex-grow pt-[5rem] md:pt-[7rem] px-4 md:px-6 min-h-screen">
                    {/* all the page content here */}
                    <div className="flex flex-col flex-start  gap-2 " >
                        <div className="flex items-center text-[18px] sm:text-[22px] md:text-[28px] lg:text-[32px] xl:text-[36px] gap-2" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }}>
                            <span className="font-extrabold text-[#1B0039] ">My Agency Listings</span>
                        </div>
                    </div>
                    <div className=" flex flex-col xl:flex-row justify-center md:items-center gap-1 py-4 px-1.5 shadow-md bg-white rounded-md ">
                        <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                            <Typography variant="h5">Let's set the stage</Typography>
                            <Typography variant="body1">Connect with your agent in the metaverse.</Typography>
                            <form onSubmit={handleTourEntry}>
                                        <TextField
                                            label="Enter Code"
                                            variant="outlined"
                                            value={tourCode}
                                            onChange={(e) => setTourCode(e.target.value)}
                                            style={{ marginTop: '10px' }}
                                            required
                                        />
                                        <Button variant="contained" type="submit" 
                                            style={{ marginTop: '20px', marginLeft: '10px' }}
                                        >
                                            Join VR Tour
                                        </Button>
                                </form>
                        </Paper>
                    </div>
                </div>
            </div>
        </>
    )
}