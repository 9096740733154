import React from "react";

function LandingPageIntro() {
  return (
    <div className="relative">
      <div className="flex items-end justify-end  pl-4 pr-4 md:pr-8 pb-4 md:pb-5">
        <img
          src={`https://storage.googleapis.com/oneverse-dev/realestate/LandingPageIntroImage.jpeg`}
          alt="Your Image"
          className="rounded-[24px]  md:rounded-[48px] w-full md:w-3/4 h-auto"
        />
      </div>

      <div className=" md:absolute top-50 md:top-12 left-0  right-[62%]  md:rounded-r-3xl bg-opacity-100 p-6 shadow-md pl-14 bg-[#fc0356]">
        <h2 className="text-3xl lg:text-4xl text-black text-[#330F3E] leading-tight font-semibold text-center md:text-start mb-3">
          <span className=" ">Sell local</span><br />
          <span className=" "> properties globally</span>
        </h2>
        <p className="text-4xl md:text-6xl text-white text-center md:text-start font-semibold mt-2">
          in metaverse
        </p>
      </div>
    </div>

    // <div className="relative ">
    //   <div className="flex items-end justify-end pr-8 pb-5">
    //     <img
    //       src="images/img1.jpeg"
    //       alt="Your Image"
    //       width="75%"
    //       height="40%"
    //       className="rounded-3xl"
    //     />
    //   </div>

    //   <div className="absolute top-10 left-0 right-[78%] md:right-[62%]  rounded-r-3xl bg-opacity-100 p-6 shadow-md pl-14 bg-[#fc0356] ">
    //     <h2 className="text-lg md:text-4xl text-black text-start leading-tight font-semibold mb-3">
    //       Sell local <br />
    //       <span className=""> properties globally</span>
    //     </h2>
    //     <p className="text-1 md:text-6xl text-white text-start  font-semibold mt-5">
    //       in metaverse
    //     </p>
    //   </div>
    // </div>
  );
}

export default LandingPageIntro;
