import PropTypes from 'prop-types'
import UserService from "rc-app/services/UserService";
// import NotAllowed from "./NotAllowed";

const RenderOnRole = ({ roles, children }) => (
  // UserService.hasRole(roles)) ? children : showNotAllowed ? <NotAllowed/> : null;
  UserService.checkRole(roles)) ? children : null;
  // UserService.hasRole(roles)) ? children : showNotAllowed ? <div> Not Allowed</div> : null;

RenderOnRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RenderOnRole
