import React, { useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

interface PriceFilterProps {
  onFilterChange: (minPrice: string, maxPrice: string) => void;
}

const PriceFilter: React.FC<PriceFilterProps> = ({ onFilterChange }) => {
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMinPrice(value); // Update state
    // onFilterChange(value, maxPrice); // Trigger callback with updated values
    console.log("handle", value);
  };

  const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMaxPrice(value); // Update state
    // onFilterChange(minPrice, value); // Trigger callback with updated values
    console.log("handle2", value);
  };

  const handlePriceChange = () => {
    onFilterChange(minPrice, maxPrice);
  };

  return (
    <div className="flex w-full gap-2 pr-1 max-md:flex-col">
      <div className="relative max-md:w-full w-1/2">
        <div className="flex w-full overflow-hidden">
          <input
            type="text"
            placeholder="Min Price..."
            className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={minPrice}
            // onChange={(e) => setMinPrice(e.target.value)}
            onChange={handleMinPriceChange}
          />
          <span
            className="text-[#283BBE] absolute right-2 top-1/2 transform -translate-y-1/2 hover:font-semibold focus:outline-none lg:right-2 md:right-1 sm:right-1"
            onClick={handlePriceChange}
          >
            <ArrowRightAltIcon />
          </span>
        </div>
      </div>

      {/* Max Price Input */}
      <div className="relative max-md:w-full w-1/2 ">
        <div className="flex w-full  overflow-hidden">
          <input
            type="text"
            placeholder="Max Price..."
            className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={maxPrice}
            // onChange={(e) => setMaxPrice(e.target.value)}
            onChange={handleMaxPriceChange}
          />

          <span
            className="text-[#283BBE] absolute right-2 top-1/2 transform -translate-y-1/2 hover:font-semibold focus:outline-none"
            onClick={handlePriceChange}
          >
            <ArrowRightAltIcon />
          </span>
        </div>
      </div>

    </div>
    // <div className="flex space-x-2">
    //   Min Price Input
    //   <input
    //     type="number"
    //     placeholder="Min Price"
    //     className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
    //     value={minPrice}
    //     onChange={handleMinPriceChange}
    //   />

    //   Max Price Input
    //   <input
    //     type="number"
    //     placeholder="Max Price"
    //     className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
    //     value={maxPrice}
    //     onChange={handleMaxPriceChange}
    //   />
    // </div>
  );
};

export default PriceFilter;
