import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";

import UserService from "rc-app/services/UserService";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Button from "../Button";
import MobileDrawer from "../MobileDrawer";

import { GiHamburgerMenu } from "react-icons/gi";

export function Header(drawerOpen: any) {
  const handleLogout = () => {
    window.history.pushState({}, "", "/");
    UserService.doLogout();
  };

  const handleLogin = () => {
    UserService.doLogin();
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const pathname = window.location.pathname;
  
  const navigate = useNavigate();
  const navItems = [
    {
      name: "Product Features",
      slug: "/product_features",
    },
    {
      name: "Pricing",
      // slug: "/pricing",
      slug: "https://italia.1verse.live/pages/pricing-1",
    },
    {
      name: "Sell with us",
      slug: "/sell",
    },
    {
      name: "About us",
      // slug: "/about",
      slug: "https://italia.1verse.live/",
    },
  ];

  const settings = ["Profile", "LogOut"];
  console.log("drawerOpen", drawerOpen);

  React.useEffect(() => {});

  const [isMobile, setIsMobile] = useState(false);

  React.useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth < 1000);
    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  return (
    <>
      <header className="mb-0 pt-5 pb-5 ">
        {!UserService.isLoggedIn() && (
          <>
            {/* large Screen */}
            <nav className=" justify-between items-center lg:px-10 xl:px-24 hidden lg:flex">
              <div className="mr-4 flex flex-row items-center">
                <div>
                  <Link
                    to="/"
                    className="flex items-center"
                    onClick={() => setSelectedItem("")}
                  >
                    {/* display on large and md */}
                    <img
                      alt="1verse"
                      // src="https://storage.googleapis.com/oneverse-dev/realestate/1verse-logo-2.png"
                      src="https://storage.googleapis.com/oneverse-dev/realestate/1verse_logo_pink.png"
                      className="h-12 w-36 hidden lg:flex"
                    />
                    {/* <h5 className=" text-[#FF307A] text-2xl hidden md:flex">
                        1verse
                      </h5> */}
                  </Link>
                </div>
              </div>
              <ul className="flex">
                {navItems.map((item) => (
                  <li
                    key={item.name}
                    className="font-semibold font-sans hidden lg:flex"
                  >
                    <button
                      onClick={() => {
                        setSelectedItem(item.name);

                        if (item.slug == "/product_features" || "/sell") {
                          navigate(item.slug);
                        } else {
                          window.open(
                            item.slug,
                            "_blank",
                            "noopener noreferrer"
                          );
                        }
                      }}
                      className={`inline-block lg:text-lg  lg:px-2 xl:px-6 ${
                        selectedItem === item.name || pathname === item.slug
                          ? "text-[#FF307A]"
                          : " text-white"
                      }  py-2 duration-200`}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="gap-3 hidden md:flex mr-2 justify-items-end">
                {/* <div className=" border border-gray-400 rounded-lg px-2 ">
                  <Select
                    label="logo"
                    ImgSrc={`https://storage.googleapis.com/oneverse-dev/realestate/img2.png`}
                    options={["English", "French", "Duch"]}
                    className="border-none font-semibold"
                  />
                </div> */}
                <Button
                  children="Login"
                  className="bg-[#FF307A] font-semibold px-6 py-2 rounded-md"
                  onClick={handleLogin}
                />
              </div>
            </nav>

            {/* Smaller Screen */}
            <div className="flex items-center lg:hidden flex-grow mx-4 md:mx-2">
              <div className="flex items-start w-1/2 sm:px-2 md:px-4  ">
                <Link to="/" className="flex items-center">
                  {/* display on xs */}
                  <img
                    alt="1verse"
                    // src="https://storage.googleapis.com/oneverse-dev/realestate/1verse_logo_white.svg"
                    src="https://storage.googleapis.com/oneverse-dev/realestate/1verse_logo_pink.png"
                    className="h-12 w-38 lg:hidden "
                  />
                  {/* <h5 className=" text-[#FF307A] text-2xl  md:hidden">
                      1verse
                    </h5> */}
                </Link>
              </div>
              <div className="flex justify-end  w-1/2 px-2 md:px-4 ">
                {isMobile && (
                  <GiHamburgerMenu
                    className="text-white cursor-pointer"
                    size={28}
                    onClick={() => setMenuOpen(!menuOpen)}
                  />
                )}
                {/* <Button
                  children="Login"
                  className="bg-[#FF307A] font-semibold hidden lg:flex "
                  onClick={handleLogin}
                />
                <span
                  className="flex lg:hidden text-white"
                  onClick={handleLogin}
                >
                  <LoginIcon />
                </span> */}
              </div>
            </div>

            {isMobile && <MobileDrawer open={menuOpen} setOpen={setMenuOpen} />}
          </>
        )}

        {UserService.isLoggedIn() && (
          <nav className="flex justify-between items-center">
            <div className="grid grid-cols-12 items-center">
              <>
                <div
                  className={` ${
                    drawerOpen["drawerOpen"] ? "hidden md:flex" : "flex"
                  } col-span-11 text-white text-[1rem] lg:text-[2rem]`}
                >
                  {" "}
                  Welcome Back{" "}
                </div>
                <div
                  className={`${
                    drawerOpen["drawerOpen"] ? "hidden md:flex" : "flex"
                  } col-span-1 flex justify-self-end`}
                >
                  <ul className="flex">
                    <li className="ml-2 relative ">
                      <button
                        className="text-white"
                        onClick={() => setMenuOpen(!menuOpen)}
                      >
                        <div className="flex items-center gap-2">
                          <div>
                            <AccountCircleIcon />
                            {/* <img src="/images/image5.png" alt="logo" className="h-12 w-12 hidden md:flex " />
                              <img src="/images/image5.png" alt="logo" className="h-10 w-10 md:hidden  " /> */}
                          </div>
                          <div>{` ${UserService.getUsername()}`}</div>
                          <div className="ml-2 md:ml-1">
                            <svg
                              className="w-6 h-6 md:w-4 md:h-4 text-gray-500"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </button>
                      <ul
                        className={`absolute mr-2 top-full bg-white border border-gray-200 mt-1 rounded-md shadow-lg ${
                          menuOpen ? "" : "hidden"
                        } z-10	`}
                        style={{ right: 0 }}
                      >
                        {settings.map((item) => (
                          <li key={item}>
                            {item === "LogOut" && (
                              <button
                                onClick={() => {
                                  handleLogout();
                                  setMenuOpen(false);
                                  console.log("logiut");
                                }}
                                className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                              >
                                {item}
                              </button>
                            )}
                            {item === "Profile" && (
                              <button
                                onClick={() => {
                                  // navigate(item.slug);
                                  setMenuOpen(false);
                                }}
                                className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                              >
                                {item}
                              </button>
                            )}
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </div>
              </>
            </div>
          </nav>
        )}
      </header>
    </>
  );
}
