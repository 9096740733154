// import { Box } from '@mui/material';
import { ClientContext } from 'rc-app/components/Context';
import { IContext } from 'rc-app/types';
import React, { Component, createRef, RefObject, forwardRef, useState, useEffect } from 'react';

import { sdkKey } from '@mp/common';
import UserService from 'rc-app/services/UserService';
import { eventUpdateStream, getAgentByEmail, getProperties } from 'rc-app/Pages/Home';

// import { getAgoraAppId, getRtcToken } from 'rc-app/Pages/Home';
import {
  useLocation,
  useNavigate
} from "react-router-dom";
// import AgoraIOVideoCall from "rc-app/components/Agora";


// import ShareIcon from '@mui/icons-material/Share';
import Modal from '@mui/material/Modal';
import PropertyCard from './NetworkPropertySquare';
// import { Main } from './Main';
import { RCApp } from 'rc-app/RcMain';
import { World } from 'rc-app/Pages/World';

// import Button from './Button';

interface Props {
  sid: string;
  userCount: number;
  host: boolean;
  selectAnotherProperty?: boolean
  setSelectAnotherProperty?: (value: boolean) => void
  onStopped?: () => void;

  message?: String
  propertyId?: String
  onModelSet?: (sid: string, name: string) => void;

  open?: boolean
  handleOpen?: () => void
  handleClose?: () => void
  // setOpen?: (value: boolean) => void
}

const Iframe = (props: any, ref: any) => {
  const navigate = useNavigate()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const channel = queryParams.get('channel');

  // const joinCode = queryParams.get('channel').split('_')[1];
  // const propertyId= queryParams.get('propertyId')

  // const [agoraToken, setAgoraToken] = useState()
  // const { email } = UserService.getTokenParsed();
  // const [agoraAppId, setAgoraAppId] = useState();

  // useEffect(() => {
  //   getRtcToken(channel).then((res) => {
  //     setAgoraToken(res.rtcToken)
  //   }).catch(err => console.log('err', err))
  //   getAgoraAppId('RL-ESTATE').then(res => {
  //     setAgoraAppId(res.network['agora_app_id'])
  //   })
  // }, [])

  console.log("hhhh", props);


  // const [selectAnotherProperty, setSelectAnotherProperty] = useState(false)

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [explore, setExplore] = useState(false)
  const handleExploreOpen = () => setExplore(true);
  const handleExploreClose = () => setExplore(false);

  const handleExploreButton = (digital_twin_id: string, matterport_id: string,) => {

    navigate(`/virtual-tour?propertyId=${matterport_id}&channel=${channel}`);
    console.log(digital_twin_id);
    props.setSelectAnotherProperty(false);

  }

  const [mpDigitalTwinList, setMpDigitalTwinList] = useState([])
  useEffect(() => {
    getProperties().then((res) => {
      console.log('hhh', res.mp_digital_twin_list);
      setMpDigitalTwinList(res.mp_digital_twin_list)

    })
  }, [])





  console.log('handleExploreButton', mpDigitalTwinList);



  return (
    <div className='w-full bg-[#F6F6F8]'>
      <div className='w-full flex gap-3 flex-col'>
        <div className='w-full flex bg-white p-3 h-full'>
          {
            !props.selectAnotherProperty &&
            <iframe
              id="sdk-iframe"
              className="flex w-full h-96"
              src={props.src}
              ref={ref}
              allow="xr-spatial-tracking"
              allowFullScreen={true}
            ></iframe>
          }
          {
            props.selectAnotherProperty &&
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8'>
              {mpDigitalTwinList.map((mpDigitalTwin, index) => (
                <PropertyCard key={index}
                  className="w-full md:w-auto rounded-l"
                  imageUrl={mpDigitalTwin.property_thumbnail}
                  title={mpDigitalTwin.title}
                  bedrooms={mpDigitalTwin.characteristics.num_bedrooms}
                  area={mpDigitalTwin.characteristics.area_sqm}
                  city={mpDigitalTwin.address.city}
                  exploreButton={
                    <button type="button" className="bg-[#283BBE]  w-full text-white  font-semibold border-2 border-[#283BBE] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]"
                      // onClick={props.tourProperty.onStopped}
                      onClick={() => {
                        // console.log('handleExploreButton', );

                        props.tourProperty.onStopped()
                        // handleExploreButton('65f7119b6c4a87ddc41be7b7','AcGaCsA1oax')
                        handleExploreButton(mpDigitalTwin._id, mpDigitalTwin.matterport_id)
                        console.log('explore',);
                        handleExploreOpen()

                      }}
                    >
                      Explore
                    </button>

                  }

                />
              ))}

            </div>


          }

        </div>
        <div className='flex w-1/5'>
          <button type="button" className=" bg-[#283BBE]  w-full text-white  font-semibold border-2 border-[#283BBE] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]"
            onClick={() => {
              props.tourProperty.onStopped()
              navigate('http://localhost:8000/')
            }
            }
          >
            Stop
          </button>

        </div>
        {/* <div className='w-1/4 flex p-3 flex-col bg-white shadow-md rounded-md'>
          <div className='w-full flex flex-col gap-3 '>
            <div className="w-full flex justify-center gap-2 xs:text-[9px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[17px]">
              <span >{props.tourProperty.host ? 'HOSTING' : 'OBSERVING'}</span>
              <span className='text-[#283BBE] font-semibold' >{props.tourProperty.userCount} {props.tourProperty.host && "Client(s)"}</span>
              
            </div>
            <div className='flex w-full  justify-center'>


              <button type="button" className="mr-1 bg-[#FF307A] rounded-md px-2 h-[40px] w-full text-[1rem] text-white"
                onClick={props.tourProperty.onStopped}>
                Share
                <ShareIcon fontSize="small" />
              </button>
            </div>

            <div className='w-full flex flex-col gap-3 '>
              <div className='w-full flex flex-col gap-1 '>
                <span >Property</span>
                <span className='text-[#283BBE] font-semibold' >Property Title</span>
              </div>
              <div className='flex justify-center'>
                <button type="button" className="text-[#EC0000] font-semibold border-2 border-[#EC0000] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]"
                  onClick={()=>{
                    // props.tourProperty.onStopped()
                    // handleOpen()
                    // props.handleOpen()
                    // props.setOpen(true)
                    console.log('handleOpen', props.handleOpen);
                    
                  }}
                >
                  Choose another property
                </button>
              </div>
            </div>
          </div>

          <div className='flex w-full'>
            <AgoraIOVideoCall
              token={agoraToken}
              appId={agoraAppId}
              channel={channel}
              userId={email}
            />
          </div>

        </div> */}

      </div>


      <div className="">

        <div >
          <Modal
            open={props.tourProperty.open}
            onClose={props.tourProperty.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="fixed flex flex-col gap-6 top-[10%] left-[37.5%] w-[25%] h-[35%] rounded-md overflow-auto justify-center bg-white border-2 border-black shadow-md p-4 ">
              <div className='w-full flex flex-col gap-2'>
                <div className={`flex items-center justify-center`} >
                  <h1 className="font-bold text-black text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5">Choose another property</h1>
                </div>
                <div className={`flex items-center justify-center`} >
                  <span className='items-center text-black xs:text-[10] sm:text-[10px] md:text[12px] lg:text-[14px] xl:text-[16px]'>
                    Do you really want to stop current property's virtual tour and choose a new property?</span>
                </div>
              </div>
              <div className='w-full flex flex-col gap-3'>
                <div className='flex justify-center'>
                  <button type="button" className="bg-[#283BBE]  w-full text-white  font-semibold border-2 border-[#283BBE] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]"
                    // onClick={props.tourProperty.onStopped}
                    onClick={() => {
                      // props.tourProperty.onStopped()
                      // console.log("onStopped", props.tourProperty.onStopped());

                      props.setSelectAnotherProperty(true)
                      props.tourProperty.handleClose()
                    }}
                  >
                    Yes
                  </button>
                </div>
                <div className='flex justify-center'>
                  <button type="button" className=" bg-[#FF307A]  w-full text-white  font-semibold border-2 border-[#EC0000] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]"
                    // onClick={props.tourProperty.onStopped}
                    onClick={props.tourProperty.handleClose}
                  >
                    No
                  </button>
                </div>
              </div>

              {/* 
                <div className="flex flex-row items-center gap-2 w-full py-4 " >
                  
                </div>

                <div className={`flex `} >
                </div> 
              */}


            </div>
          </Modal>
        </div>
      </div>
      <div className="">

        <div >
          <Modal
            open={explore}
            onClose={handleExploreClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {/* <Main propertyId={props.tourProperty.propertyId} email={UserService.getUseremail()} joinCode={joinCode} agentId={agentId} 
        selectAnotherProperty ={props.tourProperty.selectAnotherProperty} setSelectAnotherProperty={props.tourProperty.setSelectAnotherProperty} /> */}
            <World Comp={<RCApp />} />
          </Modal>
        </div>
      </div>
    </div>




  );
};

const IFrame2 = forwardRef(Iframe);

export class IFrameOverlay extends Component<Props, {}> {
  // navigate = useNavigate() 
  context: IContext;
  static contextType = ClientContext;
  private iframeRef: RefObject<HTMLIFrameElement>;
  private queryString: string = '';
  private apiHost: string = 'https://my.matterport.com';

  private email: string = UserService.getTokenParsed().email;

  constructor(props: Props) {
    super(props);

    this.state = {};
    this.onStopped = this.onStopped.bind(this);
    this.iframeRef = createRef();

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('m', this.props.sid);


    this.apiHost = urlParams.get('apiHost') || 'https://my.matterport.com';
    console.log(sdkKey, 'urlParams');

    // not needed as a url param.
    urlParams.delete('apiHost');
    urlParams.delete('applicationKey');

    this.queryString = urlParams.toString();

  }

  componentDidMount() {
    if (this.props.host) {
      this.context.sdkHost.start(this.iframeRef.current);
    }
    else {
      this.context.sdkClient.start(this.iframeRef.current);
    }
  }

  componentWillUnmount() {
    if (this.props.host) {
      this.context.sdkHost.stop();
    }
    else {
      this.context.sdkClient.stop();
    }
  }

  onStopped(event: React.MouseEvent<HTMLButtonElement>) {
    if (this.props.onStopped) {
      this.props.onStopped();

      //eventLog
      getAgentByEmail(this.email).then((data: any) => {
        const agent = data.agent
        // console.log("HostClick", agent)
        // setAgentId(agent._id)
        eventUpdateStream('PhotonSession', agent['_id'], this.email, 'leave_photon', 'true')
      })
      // this.props.setSelectAnotherProperty(true)
      console.log("onStopped");
      // this.navigate(`http://localhost:8000`);

    }
  }



  render(): JSX.Element {

    const url = `${this.apiHost}/show?${this.queryString}&play=1&qs=1&applicationKey=${sdkKey}`;
    return (
      <div className="flex flex-col bg-white shadow-md border-md">
        <div className='flex w-full'>
          <IFrame2 ref={this.iframeRef} src={url} tourProperty={this.props}
            selectAnotherProperty={this.props.selectAnotherProperty}
            setSelectAnotherProperty={this.props.setSelectAnotherProperty}
            message={this.props.message}
          ></IFrame2>
        </div>
        {
          !this.props.selectAnotherProperty && (
            <div className='flex w-1/3 px-3 items-start justify-start' >
              <div className="flex w-full ">
                <div className="font-bold text-black text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5">
                  {this.props.host ? 'HOSTING' : 'OBSERVING'}
                </div>
                {/* <div className='font-bold text-black text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5'>
                  {this.props.userCount} user(s)
                </div> */}
              </div>
              {/* <div className='flex w-full'>
                <button type="button" className=" bg-[#283BBE]  w-full text-white  font-semibold border-2 border-[#283BBE] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]"
                  onClick={()=>{
                      // this.onStopped()
                      this.navigate('http://localhost:8000/')
                    }
                  }
                >
                  Stop
                </button>
              </div> */}

            </div>
          )
        }

      </div>
    )


  }
}
