import MiniDrawer from "rc-app/components/MiniDrawer";
import UserService from "rc-app/services/UserService";
import React from "react";

export const TermsOfUse = () => {
  console.log("privacy", UserService.isLoggedIn);
  
  return (
    <>

      {!UserService.isLoggedIn() && (
        <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">General Conditions and Terms of Use</h1>
        <p>
          The website <strong>1verse.live</strong>, including its desktop and mobile versions, as well as any related applications
          (hereinafter referred to as "Site and App"), is owned by <strong>OneVerse Italia Realestate S.p.A.</strong>, headquartered at
          Via Carlo Farini 41, 20159 Milan, Italy, with VAT number P. IVA 08435221000 and REA registration number MI – 1780307
          (hereinafter referred to as "Owner"). By accessing and using the Site and App, and utilizing the services provided therein, users
          are deemed to accept these General Conditions of Use (hereinafter "Conditions"). It is strongly recommended that you carefully read
          and understand these Conditions before using the Site and App for any purpose related to the services offered.
        </p>
  
        <h2 className="text-2xl font-semibold mt-6 mb-2">User Status</h2>
        <p>
          By using any service provided on the Site and App (hereinafter "Service" or "Services"), you automatically become a User
          (hereinafter "User" or "Users"). In general, Users are required to register to gain access to the Services. Registration will follow
          the procedures outlined for each specific Service.
        </p>
        <p>
          Most of the Services offered through the Site and App are free for last-mile customers; however, some features or options require
          a paid subscription or purchase. These are referred to as "Paid Options" and include additional features, described during the
          listing process. For any issues regarding invoicing or changes to billing details, Users should contact the OneVerse Italia
          Realestate S.p.A. administration directly at: <a href="mailto:amministrazione@1verse.live" className="text-blue-600 underline">amministrazione@1verse.live</a>.
        </p>
  
        <h2 className="text-2xl font-semibold mt-6 mb-2">Types of Users</h2>
        <ul className="list-disc pl-6">
          <li>
            <strong>Simple Users</strong>: These are private individuals or professionals who use the Site and App for purposes different
            from Professional Users. They typically use the platform to search for properties and relevant information. While some Services,
            like property search, are available with login credentials, others (such as saving searches or listings) require registration.
          </li>
          <li>
            <strong>Professional Users</strong>: These are real estate agents, construction companies, and other professionals in the real
            estate industry who use the platform to offer services to Simple Users. Professional Users gain access to specialized Services
            only after contacting the Owner to activate their account, which is subject to signing a service contract (hereinafter "Service Contract").
          </li>
        </ul>
        <p>
          The Owner reserves the right to verify the identity and qualification of both Simple and Professional Users before and after
          listing publication. Listings may be removed if the Owner’s verification requests are not met.
        </p>
  
        <h2 className="text-2xl font-semibold mt-6 mb-2">Minors</h2>
        <p>
          Services provided through the Site and App are available only to adults. Minors may use the Services only with prior consent from
          their parents or legal guardians, who assume responsibility for any activity conducted by the minor.
        </p>
  
        <h2 className="text-2xl font-semibold mt-6 mb-2">Permitted and Prohibited Uses</h2>
        <p>
          Commercial use of the Site and App, including access, transfer, or misuse of information contained within, is strictly prohibited
          and will be prosecuted according to the law. Users must comply with applicable laws, public morality, and these Conditions when
          using the Site and App. Specifically, Users are forbidden from transmitting or distributing any material that violates the law,
          public decency, or third-party rights. The Site and App should not be used for illegal or unethical activities.
        </p>
        <p>
          The Owner reserves the right to revoke access to the Site and App for Users who violate these Conditions, without prior notice.
        </p>
  
        <h2 className="text-2xl font-semibold mt-6 mb-2">Intellectual Property</h2>
        <p>
          The Owner is the rightful holder of all intellectual and industrial property rights related to the Site and App, including, but not
          limited to, texts, images, graphics, software, and trademarks. Users may not reproduce, modify, distribute, or otherwise exploit
          these elements without prior authorization from the Owner or other rights holders.
        </p>
  
        {/* Additional Sections */}
        <h2 className="text-2xl font-semibold mt-6 mb-2">Guarantees and Responsibilities</h2>
        {/* Continue adding content similarly for other sections */}
        
        {/* Contact Information */}
        <h2 className="text-2xl font-semibold mt-6 mb-2">Contact Information</h2>
        <p>
          For communications with authorities, please contact <a href="mailto:rossana@1verse.live" className="text-blue-600 underline">rossana@1verse.live</a>.
          For communications with Users, please contact <a href="mailto:alex@1verse.live" className="text-blue-600 underline">alex@1verse.live</a>.
          All communications must be in Italian.
        </p>
      </div>
      )}      

      {UserService.isLoggedIn() && (
        <div className="container max-w-screen-2xl flex bg-[#F6F6F8] min-h-screen overflow-y-auto">
          <div>
            <MiniDrawer />
          </div>
          <div className="flex-grow pt-[4rem] md:pt-[3rem] lg:pt-[4rem] px-2 lg:px-3">
            {/* all the page content here */}
            <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">General Conditions and Terms of Use</h1>
      <p>
        The website <strong>1verse.live</strong>, including its desktop and mobile versions, as well as any related applications
        (hereinafter referred to as "Site and App"), is owned by <strong>OneVerse Italia Realestate S.p.A.</strong>, headquartered at
        Via Carlo Farini 41, 20159 Milan, Italy, with VAT number P. IVA 08435221000 and REA registration number MI – 1780307
        (hereinafter referred to as "Owner"). By accessing and using the Site and App, and utilizing the services provided therein, users
        are deemed to accept these General Conditions of Use (hereinafter "Conditions"). It is strongly recommended that you carefully read
        and understand these Conditions before using the Site and App for any purpose related to the services offered.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">User Status</h2>
      <p>
        By using any service provided on the Site and App (hereinafter "Service" or "Services"), you automatically become a User
        (hereinafter "User" or "Users"). In general, Users are required to register to gain access to the Services. Registration will follow
        the procedures outlined for each specific Service.
      </p>
      <p>
        Most of the Services offered through the Site and App are free for last-mile customers; however, some features or options require
        a paid subscription or purchase. These are referred to as "Paid Options" and include additional features, described during the
        listing process. For any issues regarding invoicing or changes to billing details, Users should contact the OneVerse Italia
        Realestate S.p.A. administration directly at: <a href="mailto:amministrazione@1verse.live" className="text-blue-600 underline">amministrazione@1verse.live</a>.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Types of Users</h2>
      <ul className="list-disc pl-6">
        <li>
          <strong>Simple Users</strong>: These are private individuals or professionals who use the Site and App for purposes different
          from Professional Users. They typically use the platform to search for properties and relevant information. While some Services,
          like property search, are available with login credentials, others (such as saving searches or listings) require registration.
        </li>
        <li>
          <strong>Professional Users</strong>: These are real estate agents, construction companies, and other professionals in the real
          estate industry who use the platform to offer services to Simple Users. Professional Users gain access to specialized Services
          only after contacting the Owner to activate their account, which is subject to signing a service contract (hereinafter "Service Contract").
        </li>
      </ul>
      <p>
        The Owner reserves the right to verify the identity and qualification of both Simple and Professional Users before and after
        listing publication. Listings may be removed if the Owner’s verification requests are not met.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Minors</h2>
      <p>
        Services provided through the Site and App are available only to adults. Minors may use the Services only with prior consent from
        their parents or legal guardians, who assume responsibility for any activity conducted by the minor.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Permitted and Prohibited Uses</h2>
      <p>
        Commercial use of the Site and App, including access, transfer, or misuse of information contained within, is strictly prohibited
        and will be prosecuted according to the law. Users must comply with applicable laws, public morality, and these Conditions when
        using the Site and App. Specifically, Users are forbidden from transmitting or distributing any material that violates the law,
        public decency, or third-party rights. The Site and App should not be used for illegal or unethical activities.
      </p>
      <p>
        The Owner reserves the right to revoke access to the Site and App for Users who violate these Conditions, without prior notice.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">Intellectual Property</h2>
      <p>
        The Owner is the rightful holder of all intellectual and industrial property rights related to the Site and App, including, but not
        limited to, texts, images, graphics, software, and trademarks. Users may not reproduce, modify, distribute, or otherwise exploit
        these elements without prior authorization from the Owner or other rights holders.
      </p>

      {/* Additional Sections */}
      <h2 className="text-2xl font-semibold mt-6 mb-2">Guarantees and Responsibilities</h2>
      {/* Continue adding content similarly for other sections */}
      
      {/* Contact Information */}
      <h2 className="text-2xl font-semibold mt-6 mb-2">Contact Information</h2>
      <p>
        For communications with authorities, please contact <a href="mailto:rossana@1verse.live" className="text-blue-600 underline">rossana@1verse.live</a>.
        For communications with Users, please contact <a href="mailto:alex@1verse.live" className="text-blue-600 underline">alex@1verse.live</a>.
        All communications must be in Italian.
      </p>
    </div>
          </div>
        </div>
      )}

    </>
  );
};
