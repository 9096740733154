import React, { useState, 
  // ChangeEvent, 
  useEffect } from "react";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

const SearchBar = ({ onSearch }: SearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  // const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   setSearchTerm(value);
  // };

  const handleSearchClick = () => {
    onSearch(searchTerm);
    console.log("555",searchTerm);
    
  };


  useEffect(() => {
    const timerId = setTimeout(() => {
      handleSearchClick()
    }, 300); 
    return () => clearTimeout(timerId);
}, [searchTerm]);

  return (
    <div className="relative w-full">
      <div className="flex w-full overflow-hidden">
        <input
          type="text"
          placeholder="Search..."
          className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
       
            <span className="text-[#283BBE] absolute right-2 top-1/2 transform -translate-y-1/2 hover:font-semibold focus:outline-none" onClick={handleSearchClick}><ArrowRightAltIcon /></span>
          
      </div>
    </div>
  );
};

export default SearchBar;



// import React, { useState, ChangeEvent } from "react";
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

// interface SearchBarProps {
//   onSearch: (searchTerm: string) => void;
// }

// const SearchBar = ({ onSearch }: SearchBarProps) => {
//   const [searchTerm, setSearchTerm] = useState("");

//   const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
//     const { value } = event.target;
//     setSearchTerm(value);
//   };

//   const handleSearchClick = () => {
//     onSearch(searchTerm);
//   };

//   return (
//     <div className="flex items-center justify-center mt-4">
//       <input
//         type="text"
//         placeholder="Search..."
//         className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
//         value={searchTerm}
//         onChange={handleSearchChange}
//       />
//         <span className="text-[#283BBE]" onClick={handleSearchClick}><ArrowRightAltIcon /></span>
      
//       {/* <button
//         className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
//         onClick={handleSearchClick}
//       >
//         Search
//       </button> */}
//     </div>
//   );
// };

// export default SearchBar;




