// translations.js

export type Translations = {
    en: {
      'properties.title': string;
      'properties.addText1': string;
      'properties.addText2': string;
      'properties.addText3': string;
      'properties.addButton': string;
      'properties.noInternet': string;
    };
    es: {
        'properties.title': string;
        'properties.addText1': string;
        'properties.addText2': string;
        'properties.addText3': string;
        'properties.addButton': string;
        'properties.noInternet': string;
    };
    it: {
        'properties.title': string;
        'properties.addText1': string;
        'properties.addText2': string;
        'properties.addText3': string;
        'properties.addButton': string;
        'properties.noInternet': string;
    };
  };
  
const translations = {
    en: {
      'properties.title': 'My Properties',
      'properties.addText1': 'You can easily add a ',
      'properties.addText2': 'new property by ',
      'properties.addText3': 'clicking here!',
      'properties.addButton': 'Add New Space',
      'properties.noInternet': 'No Internet Connection',
    },
    es: {
      'properties.title': 'Mis propiedades',
      'properties.addText1': 'Puedes agregar fácilmente una ',
      'properties.addText2': 'nueva propiedad haciendo ',
      'properties.addText3': 'clic aquí!',
      'properties.addButton': 'Agregar nuevo espacio',
      'properties.noInternet': 'Sin conexión a Internet',
    },
    it: {
      'properties.title': 'Le mie proprietà',
      'properties.addText1': 'Puoi facilmente aggiungere una ',
      'properties.addText2': 'nuova proprietà facendo ',
      'properties.addText3': 'clic qui!',
      'properties.addButton': 'Aggiungi nuovo spazio',
      'properties.noInternet': 'Nessuna connessione internet',
    },
    ja: {
      'properties.title': '私のプロパティ',
      'properties.addText1': '簡単に ',
      'properties.addText2': 'こちらをクリックして、',
      'properties.addText3': '新しいプロパティを追加できます！',
      'properties.addButton': '新しいスペースを追加',
      'properties.noInternet': 'インターネット接続がありません',
    }
  };
  
  export default translations;
  