import React, { useState, useRef, useEffect } from "react";

import MiniDrawer from "rc-app/components/MiniDrawer";
// import { useLocation, useNavigate } from "react-router-dom";

import Button from "rc-app/components/Button";
import axios from "axios";
import UserService from "rc-app/services/UserService";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export const ClientProfile = () => {
  // const [customer, setCustomer] = React.useState({
  //   first_name: "",
  //   last_name: "",
  //   email_id: "",
  //   mobile_number: "",

  //   address_line1: "",
  //   address_line2: "",
  //   landmark: "",
  //   postal_code: "",
  //   city: "",
  //   province: "",
  //   country: "",

  //   joined_date: ""
  // });

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const customerId = queryParams.get('customerId');

  // const navigate = useNavigate();

  const [customerDetails, setCustomerDetails] = useState({
    client_id: "",
    email_id: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    gender: "",
    date_of_birth: "",
    profile_pic: "",

    address: {
      address_line1: "",
      address_line2: "",
      landmark: "",
      postal_code: "",
      city: "",
      province: "",
      country: "",
    },

    agent_id: [],
    joined_date: Date,
    created_at: Date,
  });
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [gender, setGender] = useState("");

  const [activeTab, setActiveTab] = useState("profileDetails");
  // const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(customerDetails.profile_pic);
  const fileInputRef = useRef(null);
  console.log("formData", imageUrl);

  const handleImageUpload = async (selectedImage: any, customerId: any) => {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/realestate/gc_upload`;

    const formData = new FormData();
    formData.append("gc_upload_request", selectedImage);

    try {
      axios
        .post(API_URL, formData, {
          headers: {
            Authorization: `Bearer ${UserService.getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          console.log("image uploaded", res);
          // handleSaveImage(res.data.uploaded_url, id)
          try {
            const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/re_customer/${customerId}`;
            const PAYLOAD = { profile_pic: res.data.uploaded_url };
            const response = await axios.put(API_URL, PAYLOAD, {
              headers: {
                Authorization: `Bearer ${UserService.getToken()}`,
                "Content-Type": "application/json",
              },
            });
            fetchCustomerByEmial();
            return response.data;
          } catch (error) {
            console.error("Error fetching properties:", error);
          }
        });
    } catch (error) {
      console.error("file upload fail", error);
    }
  };

  const handleImageChange = (e: any) => {
    // console.log('isEditing1', isEditing);
    const selectedImage = e.target.files[0];
    // setImageUrl(URL.createObjectURL(selectedImage));
    console.log("image", selectedImage.name);
    setIsEditing(true);
    // console.log('isEditing2', isEditing);

    handleImageUpload(selectedImage, customerId);
    // console.log('isEditing3', isEditing);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageDelete = async () => {
    setIsEditing(true);

    try {
      const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/re_customer/${customerId}`;
      const PAYLOAD = {
        profile_pic:
          "https://storage.googleapis.com/oneverse-dev/realestate/no_profile.jpg",
      };
      const response = await axios.put(API_URL, PAYLOAD, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      fetchCustomerByEmial();
      return response.data;
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setIsEditing(false);
    }
    // setImage(null)
    // setImageUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    console.log("isEditing", isEditing);
  };

  async function fetchCustomerByEmial() {
    try {
      const API_URL = `${
        process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL
      }/api/v1/realestate/views/re_customer/profile/${UserService.getUseremail()}`;
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      console.log("customerDetails1", response);

      setCustomerDetails(response.data.customer);
      setCustomerId(response.data.customer._id);
      setGender(response.data.customer.gender);
      setImageUrl(response.data.customer.profile_pic);
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setIsEditing(false);
    }
  }

  useEffect(() => {
    setIsEditing(true);
    fetchCustomerByEmial();
  }, []);

  console.log("customerDetails", customerDetails);

  // const handleGenderChange = (e: any) => {
  //   setCustomerDetails({...customerDetails, [e.target.name]:e.target.value});
  //   console.log('target1',e.target);
  // };

  const handleGenderChange = (e: any) => {
    setGender(e.target.value);
    console.log("target1", e.target);
  };

  const handleTermsChange = (e: any) => {
    setAgreeTerms(e.target.checked);
  };

  const [countryCode, setCountryCode] = useState("+1"); // Default country code

  const handleCountryCodeChange = (e: any) => {
    setCountryCode(e.target.value);
  };

  const onInputChange = (e: any) => {
    const { name, value } = e.target;

    if (
      name !== "address_line1" ||
      "address_line2" ||
      "country" ||
      "landmark" ||
      "city" ||
      "province"
    ) {
      setCustomerDetails({ ...customerDetails, [name]: value });
    }
    if (
      name === "address_line1" ||
      name === "address_line2" ||
      name === "country" ||
      name === "landmark" ||
      name === "city" ||
      name === "province"
    ) {
      setCustomerDetails({
        ...customerDetails,
        address: { ...customerDetails.address, [name]: value },
      });
    }
    console.log("target", name, value, customerId);
  };

  async function editCustomer() {
    try {
      const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/re_customer/${customerId}`;
      const PAYLOAD = { ...customerDetails, gender: gender };
      const response = await axios.put(API_URL, PAYLOAD, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  }

  const handleSaveChanges = () => {
    console.log("save", customerDetails);
    setIsEditing(true);
    editCustomer().then(() => {
      fetchCustomerByEmial().then(() => {
        setIsEditing(false);
      });
    });
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;

    return `${day}-${month}-${year}, ${hours}:${minutes}${ampm}`;
  };

  return (
    <>
      <div className="flex container max-w-screen-2xl bg-[#F6F6F8]">
        <div>
          <MiniDrawer />
        </div>
        <div className="flex-grow pt-[5rem] md:pt-[7rem] md:pb-[3px] px-2 md:px-6 ">
          {/* all the page content here */}
          <div className="flex flex-col flex-start  gap-2 ">
            <div
              className="flex items-center text-[18px] sm:text-[22px] md:text-[28px] lg:text-[32px] xl:text-[36px] gap-2"
              style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
            >
              <span className="font-extrabold text-[#1B0039] ">My Profile</span>
            </div>
          </div>
          <div className="w-full flex flex-col xl:flex-row flex-start md:items-center gap-1 p-4 shadow-md bg-white rounded-sm ">
            <div className="w-full flex items-center md:gap-5  bg-[#F6F6F8] rounded-md">
              <div className="flex flex-col w-full  flex-grow ">
                <div className="flex flex-row w-full  flex-grow gap-3 sm::gap-8 bg-white">
                  <div
                    className={`flex items-center justify-center py-2 border-b-4  w-1/7  ${
                      activeTab === "profileDetails"
                        ? "border-[#283BBE] text-[#2A2A2A] font-bold"
                        : "text-[#2A2A2A] font-medium border-[#F6F6F8]"
                    }`}
                  >
                    <Button
                      onClick={() => {
                        setActiveTab("profileDetails");
                      }}
                      className={` text-[13px] sm:text-[12px] md:text-[13px] lg:text-[15px] xl:text-[16px] rounded-[3px]  `}
                      textColor="white"
                    >
                      Profile details
                    </Button>
                  </div>
                  {/* <div className={`flex items-center justify-center border-b-4 py-2  w-1/7  ${activeTab === "myDocuments" ? "border-[#283BBE] text-[#2A2A2A] font-bold" : "text-[#2A2A2A] font-medium border-[#F6F6F8]"}`} >

                    <Button onClick={() => {
                      // setActiveTab("myDocuments")

                    }
                    }
                      className={` text-[13px] sm:text-[12px] md:text-[13px] lg:text-[15px] xl:text-[16px] rounded-[3px]  `} textColor="white">
                      My documents
                    </Button>

                  </div>
                  <div className={`flex items-center justify-center py-2  border-b-4 w-1/7  ${activeTab === "myProperties" ? "border-[#283BBE] text-[#2A2A2A] font-bold" : "text-[#2A2A2A] font-medium border-[#F6F6F8]"}`} >

                    <Button onClick={() => {
                      // setActiveTab("myProperties")
                    }
                    }
                      className={` text-[13px] sm:text-[12px] md:text-[13px] lg:text-[15px] xl:text-[16px] rounded-[3px]   `} textColor="white">
                      My properties
                    </Button>
                  </div> */}
                </div>

                <div className="w-full md:w-auto rounded-l  bg-white">
                  {activeTab === "profileDetails" && (
                    <div className="flex w-full flex-row py-3 gap-4 ">
                      <div className="flex flex-col w-1/4 py-4 gap-3">
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                        />
                        <div
                          className="text-[#E400A8] font-bold text-[12px] sm:text-[14px] md:text-[14px] lg:text-[16px] xl:text-[18px]"
                          style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
                        >
                          Profile photo
                        </div>
                        <div>
                          <img
                            src={
                              imageUrl === null
                                ? "./images/no-photo.webp"
                                : imageUrl
                            }
                            alt="Your Image"
                            className="w-full h-[212px] "
                          />
                        </div>
                        <div className="flex w-full items-center">
                          <Button
                            children={`Upload New Image`}
                            textColor="black"
                            onClick={handleUploadButtonClick}
                            className="w-full text-[#283BBE] font-semibold border-2 border-[#283BBE] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[14px] px-4 h-[45px] sm:h-[40px] "
                          />
                        </div>
                        <div className="flex w-full justify-center">
                          <Button
                            children={`Delete Photo`}
                            textColor="black"
                            onClick={handleImageDelete}
                            className="text-[#EC0000] pb-[-2px] font-semibold border-dotted border-b-2 border-[#EC0000] text-[10px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[14px]  h-[45px] sm:h-[40px] "
                          />
                        </div>
                      </div>

                      <div className="flex flex-col w-3/4 py-4 gap-3">
                        <div
                          className="text-[#E400A8] font-bold text-[12px] sm:text-[14px] md:text-[14px] lg:text-[16px] xl:text-[18px]"
                          style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
                        >
                          Profile details
                        </div>

                        <div className="flex flex-row w-full gap-4 mb-5">
                          <div className="flex flex-col w-1/2">
                            <span className="opacity-[80%] text-[8px] sm:text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px] text-[#000000] font-semibold">
                              Client ID
                            </span>
                            <span className="text-[12px] sm:text-[14px] md:text-[14px] lg:text-[16px] xl:text-[18px] text-[#000000] font-semibold">
                              {`${customerDetails.client_id}`}{" "}
                            </span>
                          </div>
                          <div className="flex flex-col w-1/2">
                            <span className="opacity-[80%] text-[8px] sm:text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px] text-[#000000] font-semibold">
                              Client listed date
                            </span>
                            <span className="text-[12px] sm:text-[14px] md:text-[14px] lg:text-[16px] xl:text-[18px] text-[#000000] font-semibold">{`${
                              customerDetails.created_at === null
                                ? "Not Available"
                                : formatDate(customerDetails.created_at)
                            }`}</span>
                          </div>
                        </div>

                        <div className="flex flex-col w-full">
                          <form className="flex gap-3 flex-col w-full">
                            <div className="flex flex-row w-full gap-4">
                              <div className="flex flex-col w-1/2">
                                <label
                                  id="email_id_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  Email Id
                                </label>
                                <input
                                  type="text"
                                  id="email_id"
                                  name="email_id"
                                  value={customerDetails.email_id}
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                  onChange={onInputChange}
                                  required
                                  disabled
                                />
                              </div>
                              <div className="flex flex-col w-1/2">
                                <label
                                  id="mobile_number_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  Phone Number
                                </label>
                                <div className="flex flex-row w-full">
                                  <select
                                    value={countryCode}
                                    onChange={handleCountryCodeChange}
                                    className="appearance-none pl-1 border bg-[#8A8B94] border-gray-300 rounded-sm py-2 items-center  focus:ring-indigo-500 focus:border-indigo-500"
                                  >
                                    <option value="+1">+1</option>
                                    <option value="+911">+911</option>
                                    <option value="+44">+44</option>
                                    {/* Add more country codes as needed */}
                                  </select>
                                  <input
                                    type="text"
                                    id="mobile_number"
                                    name="mobile_number"
                                    value={customerDetails.mobile_number}
                                    onChange={onInputChange}
                                    disabled
                                    className=" w-full focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-row w-full gap-4">
                              <div className="flex flex-col w-1/2">
                                <label
                                  id="first_name_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  id="first_name"
                                  name="first_name"
                                  value={customerDetails.first_name}
                                  onChange={onInputChange}
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                />
                              </div>

                              <div className="flex flex-col w-1/2">
                                <label
                                  id="last_name_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  id="last_name"
                                  name="last_name"
                                  value={customerDetails.last_name}
                                  onChange={onInputChange}
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                />
                              </div>
                            </div>

                            <div className="flex flex-row w-full gap-4">
                              <div className="flex flex-col w-1/2">
                                <label className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">
                                  Gender
                                </label>
                                <div className="flex w-full items-center py-3 gap-3">
                                  <div
                                    className="cursor-pointer"
                                    onChange={handleGenderChange}
                                  >
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      value="male"
                                      checked={gender === "male"}
                                      className="mr-2"
                                    />
                                    <label htmlFor="male">Male</label>
                                  </div>
                                  <div
                                    className="cursor-pointer"
                                    onChange={handleGenderChange}
                                  >
                                    <input
                                      type="radio"
                                      id="female"
                                      name="gender"
                                      value="female"
                                      checked={gender === "female"}
                                      className="mr-2"
                                    />
                                    <label htmlFor="female">Female</label>
                                  </div>
                                  {/* <div className="cursor-pointer" onChange={handleGenderChange}>
                                    <input
                                      type="radio"
                                      id="other"
                                      name="gender"
                                      value="other"
                                      checked={gender === null}
                                      onChange={handleGenderChange}
                                      className="mr-2"
                                    />
                                    <label htmlFor="other">Other</label>
                                  </div> */}
                                </div>
                              </div>
                              <div className="flex flex-col w-1/2">
                                <label
                                  id="agency_name_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  My Agency
                                </label>
                                <input
                                  type="text"
                                  id="agency_name"
                                  name="agency_name"
                                  value={"Data Not available"}
                                  onChange={onInputChange}
                                  required
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                />
                              </div>
                            </div>

                            {/* <div className='flex flex-row w-full gap-4'>
                              <div className="flex flex-col w-1/4">
                              <label id="subscription_type_label" className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">Subscription Type</label>
                                <Button children={`Pro`} className="border border-[#8A8B94] text-[#8A8B94] rounded-sm text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[40px]"
                                  // onClick={handleOpen}
                                />
                              </div>
                            </div> */}

                            <div className="flex flex-row w-full gap-4">
                              <div className="flex flex-col w-1/2">
                                <label
                                  id="address_line1_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  Address Line 1
                                </label>
                                <input
                                  type="text"
                                  id="address_line1"
                                  name="address_line1"
                                  value={customerDetails.address.address_line1}
                                  onChange={onInputChange}
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                />
                              </div>
                              <div className="flex flex-col w-1/2">
                                <label
                                  id="address_line2_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  Address Line 2
                                </label>
                                <input
                                  type="text"
                                  id="address_line2"
                                  name="address_line2"
                                  value={customerDetails.address.address_line2}
                                  onChange={onInputChange}
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                />
                              </div>
                            </div>

                            <div className="flex flex-row w-full gap-4">
                              <div className="flex flex-col w-1/2">
                                <label
                                  id="landmark_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  Landmark
                                </label>
                                <input
                                  type="text"
                                  id="landmark"
                                  name="landmark"
                                  value={customerDetails.address.landmark}
                                  onChange={onInputChange}
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                />
                              </div>
                              <div className="flex flex-col w-1/2">
                                <label
                                  id="city_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  City
                                </label>
                                <input
                                  type="text"
                                  id="city"
                                  name="city"
                                  value={customerDetails.address.city}
                                  onChange={onInputChange}
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                />
                              </div>
                            </div>

                            <div className="flex flex-row w-full gap-4">
                              <div className="flex flex-col w-1/2">
                                <label
                                  id="province_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  Region{" "}
                                </label>
                                <input
                                  type="text"
                                  id="province"
                                  name="province"
                                  value={customerDetails.address.province}
                                  onChange={onInputChange}
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                />
                              </div>
                              <div className="flex flex-col w-1/2">
                                <label
                                  id="country_label"
                                  className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
                                >
                                  Country
                                </label>
                                <input
                                  type="text"
                                  id="country"
                                  name="country"
                                  value={customerDetails.address.country}
                                  onChange={onInputChange}
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                />
                              </div>
                            </div>

                            <div className="flex flex-row w-full gap-4">
                              <input
                                type="checkbox"
                                id="agreeTerms"
                                checked={agreeTerms}
                                onChange={handleTermsChange}
                                className="mr-2 "
                              />
                              <label htmlFor="agreeTerms">
                                Send properties updates for email
                              </label>
                            </div>

                            <div className="flex flex-row w-full gap-4">
                              <div className="flex flex-col w-1/3">
                                <Button
                                  children={`Save changes`}
                                  className="bg-[#283BBE] rounded-sm text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[40px]"
                                  onClick={handleSaveChanges}
                                  disabled={isEditing}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      {/* <div className="flex flex-col w-1/4  py-4 gap-3">
                        <div className="text-[#E400A8] font-bold text-[12px] sm:text-[14px] md:text-[14px] lg:text-[16px] xl:text-[18px]" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >Change password</div>
                        <div>
                          <form className="flex flex-col gap-3 ">
                            <div className='flex flex-col w-full gap-4'>
                              <div className="flex flex-col w-full">
                                <label id="old_password_label" className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">Old Password</label>
                                <input type="text" id="old_password" name="old_password_label" value={""}
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                  // onChange={onInputChange} 
                                  required
                                />
                              </div>
                              <div className="flex flex-col w-full">
                                <label id="old_password_label" className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">New Password</label>
                                <input type="text" id="old_password" name="old_password" value={""}
                                  // onChange={onInputChange} required 
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
                                />
                              </div>
                              <div className="flex flex-col w-full">
                                <label id="confirm_password_label" className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">Confirm Password</label>
                                <input type="confirm_password" id="confirm_password" name="confirm_password" value={""}
                                  // onChange={onInputChange} required 
                                  className="focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-sm outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]" />
                              </div>
                              <div className="flex flex-col w-2/3 ">
                                <Button children={`Change password`} className="bg-[#283BBE] rounded-sm text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[40px] cursor-not-allowed"
                                // onClick={handleOpen}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div> */}
                    </div>
                  )}

                  {activeTab === "myDocuments" && (
                    <div className="w-full shadow-md py-1 px-3">
                      <div className="w-full mt-3 flex flex-col items-center md:gap-5 p-1 shadow-md bg-white rounded-md">
                        myDocuments
                      </div>
                      <div className="w-full mt-3 flex flex-col items-center md:gap-5 p-1 shadow-md bg-white rounded-md">
                        myDocuments2
                      </div>
                    </div>
                  )}

                  {activeTab === "myProperties" && <div>myProperties</div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isEditing}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
    </>
  );
};
