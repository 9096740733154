import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import {
  TextField, Button, Container, Box, Paper,
  FormControl, InputLabel, Select, MenuItem,
  Checkbox, FormControlLabel, RadioGroup, Radio, SelectChangeEvent, Typography
} from '@mui/material';
import { getAgencyList, getAgentList } from '../Home';


export const AddNewSpace = () => {
  const [agentList, setAgentList] = useState([])
  const [agencyList, setAgencyList] = useState([])
  const [formData, setFormData] = useState({
    // agent: {
    //   id:'',
    //   name:''
    // },
    agentId:'',
    agencyId: '',
    agencyContactDetails: '',
    hasWhatsApp: false,
    countryCode: '',
    mapsLink: '',
    address: '',
    propertySize: '',
    targetAudience: '',
    availableDates: ['', '', ''],
  });


  useEffect(() => {
    getAgentList().then(res => {
      console.log("agentList", res);
      setAgentList(res.re_agent_list.map((agent: any) => {
        return {
          name: agent.first_name,
          id: agent._id
        }
      }))
    })
      .catch(err => console.log('agentListError', err))
  })
  console.log("agentList", agentList);



  //   const handleChange = (e: ChangeEvent<any> | SelectChangeEvent<string>) => {
  //     const { name, value } = e.target;
  //     if (name) {
  //       if ((e.target as HTMLInputElement).type === 'checkbox') {
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           [name]: (e.target as HTMLInputElement).checked,
  //         }));
  //       } else {
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           [name]: value,
  //         }));
  //       }
  //     }
  //   };


  const handleChange = (e: ChangeEvent<any> | SelectChangeEvent<string>) => {
    const { name, value, type, checked } = e.target;
    console.log('name', name);
    
    if (name) {
      if (type === 'checkbox') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));

      }
      getAgencyList().then(res=>{
        console.log('agencyxxx',res);
        setAgencyList(res.re_agency_list.map((agency: any) => {
          return {
            name: agency.name,
            id: agency._id
          }
        }))
        
      })

    }
  };


  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Validate form fields
    if (
      formData.agentId &&
      formData.agencyId &&
      formData.agencyContactDetails &&
      formData.countryCode &&
      formData.mapsLink &&
      formData.address &&
      formData.propertySize &&
      formData.targetAudience &&
      formData.availableDates.every(date => date)
    ) {
      // Submit form data
      console.log('Form data:', formData);
      // Show success message
      alert('Your capture request is being scheduled. 1Verse support team will reach out to you via phone for the next steps.');
    } else {
      // Show error message for mandatory fields
      alert('Please fill out all fields.');
    }
  };
  console.log('formData', formData);


  return (
    <Box
      sx={{
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        height: "100vh",
        overflow: "scroll",
        padding: 5,
      }}
    >
      <div>
        <Container maxWidth="sm" >
          <Paper elevation={2} sx={{ padding: '20px', mb: '20px' }}>
            <Typography variant='h3' > Add Your New Space</Typography>
            <form onSubmit={handleSubmit}  >
              <FormControl fullWidth margin="normal">
                <InputLabel>Agent Name</InputLabel>
                <Select
                  name='agentId'
                  value={formData.agentId}
                  onChange={handleChange}
                >
                  {agentList.map(agent => (
                    <MenuItem key={agent.id} value={agent.id}>{agent.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Agency Name</InputLabel>
                <Select
                  name="agencyId"
                  value={formData.agencyId}
                  onChange={handleChange}
                  disabled={!formData.agentId}
                >
                  {agencyList.map(agency => (
                    <MenuItem key={agency.id} value={agency.id}>{agency.name}</MenuItem>
                  ))}
                  
                  
                  {/* Add more MenuItem components for each agency */}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                margin="normal"
                label="Agency Contact Details"
                name="agencyContactDetails"
                value={formData.agencyContactDetails}
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="hasWhatsApp"
                    checked={formData.hasWhatsApp}
                    onChange={handleChange}
                  />
                }
                label="This number has WhatsApp"
              />
              <TextField
                fullWidth
                margin="normal"
                label="Country Code"
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Google Maps or Apple Maps Link to Property"
                name="mapsLink"
                value={formData.mapsLink}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Address of the Property"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
              <FormControl component="fieldset" margin="normal">
                <RadioGroup
                  name="propertySize"
                  value={formData.propertySize}
                  onChange={handleChange}
                >
                  <FormControlLabel value="less than 180 sqm" control={<Radio />} label="Less than 180 sqm" />
                  <FormControlLabel value="180 - 280 sqm" control={<Radio />} label="180 - 280 sqm" />
                  <FormControlLabel value="280 - 500 sqm" control={<Radio />} label="280 - 500 sqm" />
                  <FormControlLabel value="above 500 sqm" control={<Radio />} label="Above 500 sqm" />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset" margin="normal">
                <RadioGroup
                  name="targetAudience"
                  value={formData.targetAudience}
                  onChange={handleChange}
                >
                  <FormControlLabel value="local" control={<Radio />} label="Local customers only" />
                  <FormControlLabel value="international" control={<Radio />} label="International customers only" />
                  <FormControlLabel value="everyone" control={<Radio />} label="Everyone" />
                </RadioGroup>
              </FormControl>
              <TextField
                fullWidth
                margin="normal"
                label="Available Dates"
                name="availableDates"
                type="datetime-local"
                value={formData.availableDates[0]}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                margin="normal"
                name="availableDates"
                type="datetime-local"
                value={formData.availableDates[1]}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                margin="normal"
                name="availableDates"
                type="datetime-local"
                value={formData.availableDates[2]}
                onChange={handleChange}
              />
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </form>
          </Paper>
        </Container>
      </div>
    </Box>

  )
}


