import React from "react";

export const ToursCompleted = ({ tours, clients }:any) => {
  console.log(clients);
  
  return (
    <span className="border-[#283BBE] border rounded-full px-3 py-1 font-semibold max-xl:text-[11px] text-[16px] text-center">
      
      {(tours> 0 )&& (<div>
        <span className="text-[#283BBE]">{tours}</span> tours completed
      </div>) }
      {(tours===0) && (<div>
        <span className="text-[#283BBE]">No </span> tours completed
      </div>)  }
      {/* <span className="text-[#283BBE]">{clients}</span> Clients */}
    </span>
  );
};

