import React, { useState, useEffect } from "react";
import axios from "axios";
import UserService from "rc-app/services/UserService";

// import { Box } from "@mui/material";

import MiniDrawer from "rc-app/components/MiniDrawer";
import FilterSelect from "rc-app/components/Filter Select";
import SearchBar from "rc-app/components/Search Bar";
// import PropertyCardLarge from "rc-app/components/PropertyCardLarge";
import PaginationRounded from "rc-app/components/Pagination";
import { Link, useNavigate } from "react-router-dom";

// import HomeIcon from "@mui/icons-material/Home";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import KingBedIcon from "@mui/icons-material/KingBed";
// import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { toPascalCase } from "rc-app/utils";
import Button from "rc-app/components/Button";

import { CircularProgress } from "@mui/material";
// import { Cards } from "rc-app/components/Card";
import { CommissionAmount } from "rc-app/components/CommissionAmount";
import { ToursCompleted } from "rc-app/components/ToursCompleted";
import { PropertyCard } from "rc-app/components/Card/propertyCard";
import { Tabs } from "rc-app/components/Card/tabs";
import PriceFilter from "rc-app/components/PriceFilter";

export const NetworkListing = () => {
  const navigate = useNavigate();

  const [properties, setProperties] = useState([]);
  const [searchDigitalTwin, setSearchDigitalTwin] = useState([]);
  const [agency, setAgency] = useState<{ [key: string]: string }>({});
  const [region, setRegion] = useState<{ [key: string]: string }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(true);

  let pageLimit = 10;

  const [selectedOption, setSelectedOption] = useState({
    agency: "all",
    region: "all",
    lifestyle: "all",
    type: "all",
    contract_type: "all",
    // minPrice:0,
    // maxPrice:0,
    // price: 0,
  });

  // const region: { [key: string]: string } = {
  //   Abruzzo: "abruzzo",
  //   "Aosta Valley": "aosta_valley",
  //   Puglia: "puglia",
  //   Calabria: "calabria",
  //   Campania: "campania",
  //   Lazio: "lazio",
  //   Liguria: "liguria",
  //   Marche: "marche",
  //   Sicily: "sicily",
  // };
  const lifestyle: { [key: string]: string } = {
    History: "history",
    Beach: "beach",
    Waterfront: "waterfront",
    Lakefront: "lakefront",
    Metropolitan: "metropolitan",
    "Country Living": "country_living",
    "Wine And Vineyards": "wine_and_vineyards",
    "Eco Friendly": "eco_friendly",
    Mountain: "mountain",
  };

  const type: { [key: string]: string } = {
    Apartment: "apartment",
    Castle: "castle",
    Estate: "estate",
    "Free Standing House": "free_standing_house",
    Office: "office",
    Farmhouse: "farmhouse",
    Palace: "palace",
    Penthouse: "penthouse",
    Shop: "shop",
    Studio: "studio",
    Villa: "villa",
  };

  const contract_type: { [key: string]: string } = {
    SALE: "sale",
    RENT: "rent",
    LEASE: "lease",
  };

  async function fetchProperties(minPrice = 0, maxPrice = 0) {
    try {
      let agencyKey = "";
      let regionKey = "";
      let typeKey = "";
      let lifestyleKey = "";
      let contractTypeKey = "";
      // let minPriceKey=0;
      // let maxPriceKey=0;
      // let priceMinQueryParam = "";
      // if (selectedOption.price > 0) {
      //   priceMinQueryParam = `&price=${selectedOption.price}`;
      // }

      console.log("Selected region:", selectedOption.region);

      if (selectedOption.type !== "all" && type[selectedOption.type]) {
        typeKey = type[selectedOption.type];
      }

      if (
        selectedOption.lifestyle !== "all" &&
        lifestyle[selectedOption.lifestyle]
      ) {
        lifestyleKey = lifestyle[selectedOption.lifestyle];
      }

      if (
        selectedOption.contract_type !== "all" &&
        contract_type[selectedOption.contract_type]
      ) {
        contractTypeKey = contract_type[selectedOption.contract_type];
      }

      if (selectedOption.agency !== "all" || "All") {
        agencyKey = selectedOption.agency;
        console.log("iii", typeKey);
      } else if (agencyKey === undefined) {
        agencyKey = "";
      } else {
        agencyKey = "";
      }

      if (selectedOption.region !== "all" || "All") {
        regionKey = selectedOption.region;
      } else if (regionKey === undefined) {
        regionKey = "";
      } else {
        regionKey = "";
      }

      // if (selectedOption.region !== "all" && region[selectedOption.region]) {
      //   regionKey = region[selectedOption.region];
      //   console.log("Region key:", regionKey);
      // }

      const API_URL1 = `${
        process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL
      }/api/v1/realestate/views/mp_digital_twin/network_properties?sort_by=created_at&sort_order=-1&page=${
        currentPage - 1
      }&limit=${pageLimit}&${
        regionKey !== "All" && regionKey !== "all" && regionKey !== ""
          ? `province=${regionKey}`
          : ""
      }&property_visibility=network&lifestyle=${lifestyleKey}&${
        typeKey !== "all" && typeKey !== "" ? `type_of_property=${typeKey}` : ""
      }&contract_type=${contractTypeKey}&agency_id=${
        agencyKey == (undefined || "") ? "all" : agencyKey
      }&${
        minPrice != 0 ? `min_price=${minPrice}` : ""
        // regionKey == (undefined || "") ? "all" : regionKey
      }&${
        maxPrice != 0 ? `max_price=${maxPrice}` : ""
        // regionKey == (undefined || "") ? "all" : regionKey
      }`;

      console.log("agencyKey", agencyKey.length);
      console.log("agencyKey", agencyKey);
      console.log("agencyKey", typeof agencyKey);

      const response = await axios.get(API_URL1, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      setProperties(response.data.mp_digital_twin_list);
      setLoading(false);
      setTotalPages(response.data.total_mp_digital_twin);
      setSearchDigitalTwin(response.data.mp_digital_twin_list);

      console.log("Selected region:", selectedOption.region);
      console.log("Region key:", region[selectedOption.region]);
      // console.log("API URL:", API_URL);
    } catch (error) {
      console.error("Error fetching properties:", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProperties();
  }, [
    selectedOption.region,
    // selectedOption.price,
    selectedOption.type,
    selectedOption.lifestyle,
    selectedOption.contract_type,
    currentPage,
    selectedOption.agency,
  ]);

  useEffect(() => {
    async function fetchAgency() {
      try {
        const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_agency?sort_by=created_at&sort_order=-1`;
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${UserService.getToken()}`,
            "Content-Type": "application/json",
          },
        });
        // setProperties(response.data.mp_digital_twin_list);
        const convertedObject: { [key: string]: string } = {};
        response.data.re_agency_list.forEach((item: any) => {
          convertedObject[item.name] = item._id;
        });
        setAgency({ All: "all", ...convertedObject });
        setTotalPages(response.data.total_mp_digital_twin);
        // console.log("agencyxx", convertedObject);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    }

    async function fetchRegion() {
      try {
        const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_region_details?sort_by=created_at&sort_order=-1&page=0&limit=20`;

        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${UserService.getToken()}`,
            "Content-Type": "application/json",
          },
        });

        // setProperties(response.data.mp_digital_twin_list);
        const convertedObject: { [key: string]: string } = {};
        response.data.re_region_details_list.forEach((item: any) => {
          convertedObject[item.region] = item.country_id;
        });
        setRegion({ ...convertedObject });
        // setTotalPages(response.data.total_mp_digital_twin);
        console.log("regionxx", convertedObject);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    }

    fetchAgency();
    fetchRegion();
  }, []);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (searchTerm: string) => {
    console.log("searchTerm", searchTerm);

    const searchTermLowerCase = searchTerm.toLowerCase();

    const filtered = properties.filter(
      (item) =>
        item.title.toLowerCase().includes(searchTermLowerCase) ||
        item.matterport_id.toString().includes(searchTermLowerCase) ||
        (item.num_bathrooms &&
          item.num_bathrooms.toString().includes(searchTermLowerCase))
      // ||
      // (item.num_bathrooms &&
      //     item.num_bathrooms.toString().includes(searchTermLowerCase))) &&
      // (selectedCategory === '' || item.num_bathrooms === selectedCategory)
      // ||
      // item.description.toLowerCase().includes(searchTermLowerCase)
    );
    setSearchDigitalTwin(filtered);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.name === "agency") {
      const selectedValue = event.target.value;
      const selectName = event.target.name;
      setSelectedOption((prevState) => ({
        ...prevState,
        [selectName]: agency[selectedValue],
      }));
    } else {
      const selectedValue = event.target.value;
      const selectName = event.target.name;
      setSelectedOption((prevState) => ({
        ...prevState,
        [selectName]: selectedValue,
      }));
    }

    console.log("name", event.target.name);
  };

  // const handlePriceChange = (newValue: number) => {
  //   setSelectedOption((prevState) => ({
  //     ...prevState,
  //     ["price"]: newValue,
  //   }));
  //   console.log("Slider value:", newValue);
  // };

  console.log("selectedOptionNetwork", selectedOption);

  const handleDetailButton = (
    digital_twin_id: string,
    matter_port_id: string
  ) => {
    navigate(
      `/property_details?digitalTwinId=${digital_twin_id}&propertyId=${matter_port_id}`
    );
  };

  function handleTabButton(
    digital_twin_id: string,
    matter_port_id: string,
    tab: string
  ) {
    navigate(
      `/property_details?digitalTwinId=${digital_twin_id}&propertyId=${matter_port_id}&tab=${tab}`
    );
  }

  const handleFilterChange = (minPrice: string, maxPrice: string) => {
    console.log("Min Price:", minPrice);
    console.log("Max Price:", maxPrice);
    const minPriceValue = minPrice ? parseInt(minPrice) : 0;
    const maxPriceValue = maxPrice ? parseInt(maxPrice) : 0;
    if (parseInt(minPrice) > parseInt(maxPrice)) {
      alert("Min price should not be greater than max price.");
      return;
    } else if (parseInt(maxPrice) < parseInt(minPrice)) {
      alert("Max price should not be less than min price.");
      return;
    } else {
      fetchProperties(minPriceValue, maxPriceValue);
    }
  };

  return (
    <>
      <div className=" container max-w-screen-2xl flex bg-[#F6F6F8] min-h-screen overflow-y-auto">
        <div>
          <MiniDrawer />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex-grow pt-[5rem] md:pt-[5rem] lg:pt-[6rem] px-2 lg:px-3">
            {/* all the page content here */}
            <div
              className="flex flex-col flex-start  gap-2 "
              style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
            >
              <div
                className="flex items-center text-[18px] sm:text-[22px] md:text-[28px] lg:text-[32px] xl:text-[36px] gap-2 "
                style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
              >
                <span className="font-extrabold text-[#1B0039]">
                  Network Listings
                </span>
                <span className="font-extrabold text-[#283BBE]">
                  {searchDigitalTwin.length}
                </span>
              </div>
              <div
                className="flex flex-col text-[#330F3E] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[20px] xl:text-[24px] pb-3"
                style={{ fontFamily: "Inter, sans-serif" }}
              >
                <span className="break-words">
                  These properties are listed by other agency{" "}
                </span>
              </div>
            </div>
            <div className="w-full flex flex-col flex-start md:items-center gap-1 py-4 px-1.5  shadow-md bg-white rounded-md mb-3 ">
              <div className="w-full xl:w-full ">
                <div className="w-full flex flex-wrap flex-col justify-center sm:flex-row md:flex-row ">
                  <div className="w-full sm:w-1/3 md:w-1/3 lg:w-1/5 px-2 mb-2">
                    <FilterSelect
                      name="agency"
                      label="Agency"
                      options={[...Object.keys(agency)]}
                      value={selectedOption["agency"]}
                      className="bg-white text-black"
                      handleChange={handleSelectChange}
                    />
                  </div>

                  <div className="w-full sm:w-1/3 md:w-1/3 lg:w-1/5 px-2 mb-2">
                    <FilterSelect
                      name="region"
                      label="Region"
                      options={["All", ...Object.keys(region)]}
                      value={selectedOption["region"]}
                      className="bg-white text-black"
                      handleChange={handleSelectChange}
                    />
                  </div>
                  <div className="w-full sm:w-1/3 md:w-1/3 lg:w-1/5 px-2 mb-2">
                    <FilterSelect
                      name="type"
                      label="Type"
                      options={["All", ...Object.keys(type)]}
                      value={selectedOption.type}
                      className="bg-white text-black"
                      handleChange={handleSelectChange}
                    />
                  </div>
                  <div className="w-full sm:w-1/3 md:w-1/2 lg:w-1/5 px-2 mb-2">
                    <FilterSelect
                      name="lifestyle"
                      label="Lifestyle"
                      options={["All", ...Object.keys(lifestyle)]}
                      value={selectedOption["lifestyle"]}
                      className="bg-white text-black"
                      handleChange={handleSelectChange}
                    />
                  </div>
                  <div className="w-full sm:w-1/3 md:w-1/2 lg:w-1/5 px-2 mb-2">
                    <FilterSelect
                      name="contract_type"
                      label="Contract Type"
                      options={["All", ...Object.keys(contract_type)]}
                      value={selectedOption["contract_type"]}
                      className=" bg-white text-black"
                      handleChange={handleSelectChange}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full md:w-full lg:w-full xl:w-full max-sm:gap-2 flex flex-wrap flex-col sm:flex-row md:flex-row md:items-center sm:flex-start md:justify-between px-1 md:px-2">
                <div className="w-full md:w-full lg:w-full xl:w-full max-sm:gap-2 flex flex-col sm:flex-row md:flex-row  xl:flex-row md:items-center sm:flex-start md:justify-between pr-1 md:pr-2">
                  <div className="flex  w-full flex-grow sm:w-[100%] md:w-[65%] lg:w-[65%] xl:w-1/2 sm:pl-2">
                    <PriceFilter onFilterChange={handleFilterChange} />
                  </div>
                  <div className="flex flex-grow  sm:w-[100%] md:w-[35%] lg:w-[35%] xl:w-1/2 sm:pl-2">
                    <SearchBar onSearch={handleSearch} />
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              searchDigitalTwin && (
                <div className="grid gap-3 max-xl:grid-cols-2 max-md:grid-cols-1">
                  {searchDigitalTwin.map((property) => (
                    // <Cards
                    //   //   key={index}
                    //   //   visual={true}
                    //   //   title={"Detail"}
                    //   //   path={`/account/agency-detail/${12}`}
                    //   visual={true}
                    //   property_images={
                    //     property.property_thumbnail
                    //       ? property.property_thumbnail
                    //       : ""
                    //   }
                    //   price={property.pricing.price ? property.pricing.price : 0}
                    //   isAgencyList={false}
                    //   property_visibility={
                    //     property.property_visibility
                    //       ? property.property_visibility
                    //       : ""
                    //   }
                    //   property_id={
                    //     property.property_id ? property.property_id : ""
                    //   }
                    //   property_link={
                    //     property.characteristics.shareable_link
                    //       ? property.characteristics.shareable_link
                    //       : ""
                    //   }
                    //   title={property.title ? property.title : ""}
                    //   description={
                    //     property.description ? property.description : ""
                    //   }
                    //   agent_commission={
                    //     property.characteristics.contract_type === "sale" &&
                    //     property.sale_commission_config
                    //       ? property.sale_commission_config
                    //           .buyer_agent_commission || 0.0
                    //       : property.characteristics.contract_type === "rent" &&
                    //         property.rent_commission_config
                    //       ? property.rent_commission_config
                    //           .buyer_agent_commission || 0.0
                    //       : 0.0
                    //   }
                    //   digital_twin_id={property._id}
                    //   matterport_id={property.matterport_id}
                    //   isClient={false}
                    //   isProperty={true}
                    //   handleDetailButton={handleDetailButton}
                    //   handleTabButton={handleTabButton}
                    //   data={[
                    //     {
                    //       value: property.address.city
                    //         ? property.address.city
                    //         : "",
                    //       icon: <LocationOnIcon />,
                    //     },
                    //     {
                    //       value: property.characteristics.contract_type
                    //         ? property.characteristics.contract_type
                    //         : "",
                    //       icon: <HomeIcon />,
                    //     },
                    //     {
                    //       value: property.characteristics.num_bedrooms
                    //         ? property.characteristics.num_bedrooms + " Bedrooms"
                    //         : 0,
                    //       icon: <KingBedIcon />,
                    //     },
                    //     {
                    //       value: property.characteristics.area_sqm
                    //         ? property.characteristics.area_sqm + " Sq. Mtr"
                    //         : 0,
                    //       icon: <OpenInFullIcon />,
                    //     },

                    //     {
                    //       value: property.characteristics.type_of_property
                    //         ? toPascalCase(
                    //             property.characteristics.type_of_property
                    //           )
                    //         : "",
                    //       icon: "Type",
                    //     },
                    //     {
                    //       value: property.characteristics.lifestyle
                    //         ? toPascalCase(property.characteristics.lifestyle)
                    //         : "",
                    //       icon: "Lifestyle",
                    //     },
                    //     {
                    //       value: property.characteristics.listing_date
                    //         ? property.characteristics.listing_date
                    //         : "",
                    //       icon: "Listed On",
                    //     },
                    //     {
                    //       value: property.agency.name ? property.agency.name : "",
                    //       icon: "Agency",
                    //     },
                    //   ]}
                    //   detailButton={
                    //     <Button
                    //       children="Details"
                    //       className="bg-[#283BBE] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[35px]"
                    //       onClick={() => {
                    //         handleDetailButton(
                    //           property._id,
                    //           property.matterport_id
                    //         );
                    //       }}
                    //     />
                    //   }
                    // />

                    <div className="bg-white shadow-md grid   max-xl:grid-cols-1 max-2xl:grid-cols-7 2xl:grid-cols-7  p-4 gap-3 rounded-md">
                      <div className="  max-xl:col-span-1  max-2xl:col-span-2 2xl:col-span-2 flex flex-col gap-2">
                        <Tabs
                          tabs={[
                            { label: "Digital Twin" },
                            { label: "Images" },
                            { label: "Video(s)" },
                          ]}
                          handleTabButton={handleTabButton}
                          matterport_id={property.matterport_id}
                          digital_twin_id={property._id}
                        />
                        <div className="w-full h-full max-h-52 2xl:max-h-80 overflow-hidden rounded-md">
                          <img
                            src={
                              property.property_thumbnail === null
                                ? "https://storage.googleapis.com/oneverse-dev/realestate/no_property_image.jpg"
                                : // "https://storage.googleapis.com/oneverse-dev/realestate/06.11.2024_17.25.09--999.jpg"
                                  property.property_thumbnail
                            }
                            alt="house"
                            className="object-cover w-full h-full"
                          />
                        </div>
                      </div>
                      <div className="  max-xl:col-span-1 max-2xl:col-span-5 2xl:col-span-5 flex flex-col justify-between 2xl:gap-8 gap-5">
                        <PropertyCard
                          propertyInfo={{
                            title: property.title ? property.title : "",
                            propertyId: property.property_id
                              ? property.property_id
                              : "",
                            propertyLink: property.characteristics
                              .shareable_link
                              ? property.characteristics.shareable_link
                              : "",
                            description: property.description
                              ? property.description
                              : "",
                            location: property.address.province
                              ? property.address.province
                              : "",
                            contractType: property.characteristics.contract_type
                              ? property.characteristics.contract_type
                              : "",
                            bedrooms: property.characteristics.num_bedrooms
                              ? property.characteristics.num_bedrooms +
                                " Bedrooms"
                              : 0,
                            area: property.characteristics.area_sqm
                              ? property.characteristics.area_sqm + " Sq. Mtr"
                              : 0,
                            type: property.characteristics.type_of_property
                              ? toPascalCase(
                                  property.characteristics.type_of_property
                                )
                              : "",
                            lifestyle: property.characteristics.lifestyle
                              ? toPascalCase(property.characteristics.lifestyle)
                              : "",
                            listedOn: property.characteristics.listing_date
                              ? property.characteristics.listing_date
                              : "",
                            price: property.pricing.price
                              ? property.pricing.price
                              : 0,
                            isExclusive: true,
                            // Exclusive: "NETWORK",
                            roperty_visibility: property.property_visibility,
                            digital_twin_id: property._id,
                            matterport_id: property.matterport_id,
                          }}
                          handleDetailButton={handleDetailButton}
                        />

                        <div className="flex items-center justify-between max-xl:items-end  max-xl:flex-col  max-xl:gap-2">
                          <div className="flex items-center gap-4 max-xl:justify-between max-xl:items-start  max-xl:w-full">
                            <CommissionAmount
                              // amount={
                              //   property.characteristics.contract_type ===
                              //     "sale" && property.sale_commission_config
                              //     ? parseFloat(property.sale_commission_config
                              //       .buyer_agent_commission).toFixed(3) || 0.0
                              //     : property.characteristics.contract_type ===
                              //         "rent" && property.rent_commission_config
                              //     ? parseFloat(property.rent_commission_config
                              //       .buyer_agent_commission).toFixed(3) || 0.0
                              //     : 0.0
                              // }
                              amount={
                                property.pricing.price
                                  ? property.pricing.price
                                  : 0
                              }
                              contractType={
                                property.characteristics.contract_type
                              }
                            />
                            <ToursCompleted
                              tours={
                                property.characteristics.total_virtual_tours
                              }
                              clients="16"
                            />
                          </div>
                          <div className="flex max-xl:w-full">
                            <Button
                              children="Details"
                              className="w-full bg-[#283BBE] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[35px] "
                              onClick={() => {
                                handleDetailButton(
                                  property._id,
                                  property.matterport_id
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
            )}

            {/* {searchDigitalTwin.length == 0 && <div>No DigitalTwin Found</div>} */}

            <div className="m-5">
              <PaginationRounded
                currentPage={currentPage}
                totalPages={totalPages}
                limit={pageLimit}
                onPageChange={handlePageChange}
              />
            </div>
          </div>

          <div className="flex w-full py-6 flex-col lg:flex-row bg-[#202020] text-white lg:justify-center gap-6 px-2 opacity-85">
            <div className="  lg:text-right">
              <Link to="/privacy_policy">Privacy Policy</Link>
            </div>
            <div className=" lg:text-right">
              <Link to="/terms_and_conditions">Terms & Conditions</Link>
            </div>
            <div className=" lg:text-right">
              <p>Copyright © 2024 1Verse. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>

      {/* <Box sx={{ display: 'flex' }}>
                <div><MiniDrawer /></div>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>THis is Network Listing</div>
                </Box>
            </Box> */}
    </>
  );
};
