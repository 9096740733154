import React, { useState, useEffect } from "react";
import axios from "axios";
import UserService from "rc-app/services/UserService";

import MiniDrawer from "rc-app/components/MiniDrawer";
import FilterSelect from "rc-app/components/Filter Select";
import SearchBar from "rc-app/components/Search Bar";
import PaginationRounded from "rc-app/components/Pagination";
import { Link, useNavigate } from "react-router-dom";

import { toPascalCase } from "rc-app/utils";
import Button from "rc-app/components/Button";

import { Tabs } from "../../components/Card/tabs";
import { PropertyCard } from "rc-app/components/Card/propertyCard";
import { CommissionAmount } from "rc-app/components/CommissionAmount";
import { ToursCompleted } from "rc-app/components/ToursCompleted";
import PriceFilter from "rc-app/components/PriceFilter";

export const AgencyListing = () => {
  console.log("bbb", UserService.hasRole("agent"));

  const navigate = useNavigate();

  const [properties, setProperties] = useState([]);

  const [agencyName, setAgencyName] = useState("");

  const [region, setRegion] = useState<{ [key: string]: string }>({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  let pageLimit = 10;

  const [searchedDigitalTwin, setSearchDigitalTwin] = useState([]);

  const [selectedOption, setSelectedOption] = useState({
    region: "all",
    lifestyle: "all",
    type: "all",
    contract_type: "all",
    // price: 0,
  });


  const lifestyle: { [key: string]: string } = {
    History: "history",
    Beach: "beach",
    Waterfront: "waterfront",
    Lakefront: "lakefront",
    Metropolitan: "metropolitan",
    "Country Living": "country_living",
    "Wine And Vineyards": "wine_and_vineyards",
    "Eco Friendly": "eco_friendly",
    Mountain: "mountain",
  };

  const type: { [key: string]: string } = {
    Apartment: "apartment",
    Castle: "castle",
    Estate: "estate",
    "Free Standing House": "free_standing_house",
    Office: "office",
    Farmhouse: "farmhouse",
    Palace: "palace",
    Penthouse: "penthouse",
    Shop: "shop",
    Studio: "studio",
    Villa: "villa",
  };

  const contract_type: { [key: string]: string } = {
    Sale: "sale",
    Rent: "rent",
    Lease: "lease",
  };

  async function fetchProperties(minPrice = 0, maxPrice = 0) {
    try {
      let regionKey = "";
      let typeKey = "";
      let lifestyleKey = "";
      let contractTypeKey = "";
      // let priceMinQueryParam = "";
      // if (selectedOption.price > 0) {
      //   priceMinQueryParam = `&price=${selectedOption.price}`;
      // }

      console.log("Selected region:", selectedOption.region);

      // if (selectedOption.region !== "all" && region[selectedOption.region]) {
      //   regionKey = region[selectedOption.region];
      //   console.log("Region key:", regionKey);
      // }

      if (selectedOption.region !== "all" || "All") {
        regionKey = selectedOption.region;
      } else if (regionKey === undefined) {
        regionKey = "";
      } else {
        regionKey = "";
      }

      if (selectedOption.type !== "all" && type[selectedOption.type]) {
        typeKey = type[selectedOption.type];
      }

      if (
        selectedOption.lifestyle !== "all" &&
        lifestyle[selectedOption.lifestyle]
      ) {
        lifestyleKey = lifestyle[selectedOption.lifestyle];
      }

      if (
        selectedOption.contract_type !== "all" &&
        contract_type[selectedOption.contract_type]
      ) {
        contractTypeKey = contract_type[selectedOption.contract_type];
      }

      const API_URL = `${
        process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL
      }/api/v1/realestate/views/mp_digital_twin/agent/properties/${UserService.getUseremail()}?sort_by=created_at&sort_order=-1&page=${
        currentPage - 1
      }&limit=${pageLimit}&${
        regionKey !== "All" && regionKey !== "all" && regionKey !== ""
          ? `province=${regionKey}`
          : ""
        // regionKey == (undefined || "") ? "all" : regionKey
      }&type_of_property=${typeKey}&lifestyle=${lifestyleKey}&contract_type=${contractTypeKey}&${
        minPrice != 0 ? `min_price=${minPrice}` : ""
      }&${maxPrice != 0 ? `max_price=${maxPrice}` : ""}`;
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      setProperties(response.data.mp_digital_twin_list);
      setSearchDigitalTwin(response.data.mp_digital_twin_list);
      setTotalPages(response.data.total_mp_digital_twin);

      if (
        response.data.mp_digital_twin_list.length > 0 &&
        response.data.mp_digital_twin_list[0].agency &&
        response.data.mp_digital_twin_list[0].agency.name
      ) {
        setAgencyName(response.data.mp_digital_twin_list[0].agency.name);
      }

      console.log("Selected region:", selectedOption.region);
      console.log("Region key:", region[selectedOption.region]);
      console.log(
        "APIURL:",
        response.data.mp_digital_twin_list.map(
          (item: any) => item.property_floor_plan[0]
        )
      );
      console.log("mmm", response);
      console.log(contractTypeKey, lifestyleKey, typeKey);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  }

  useEffect(() => {
    fetchProperties();
  }, [
    selectedOption.region,
    selectedOption.type,
    selectedOption.lifestyle,
    selectedOption.contract_type,
    currentPage,
  ]);

  useEffect(() => {
    async function fetchRegion() {
      try {
        const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_region_details?sort_by=created_at&sort_order=-1&page=0&limit=20`;

        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${UserService.getToken()}`,
            "Content-Type": "application/json",
          },
        });

        const convertedObject: { [key: string]: string } = {};
        response.data.re_region_details_list.forEach((item: any) => {
          convertedObject[item.region] = item.country_id;
        });

        setRegion({ ...convertedObject });
        console.log("regionxx", convertedObject);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    }

    fetchRegion();
  }, []);

  const handleSearch = (searchTerm: string) => {
    console.log("searchTerm", searchTerm);
    // Perform any action you want with the search term
    const searchTermLowerCase = searchTerm.toLowerCase();
    const filtered = properties.filter(
      (item) =>
        item.title.toLowerCase().includes(searchTermLowerCase) ||
        item.matterport_id.toString().includes(searchTermLowerCase) ||
        (item.num_bathrooms &&
          item.num_bathrooms.toString().includes(searchTermLowerCase))
      // ||
      // (item.num_bathrooms &&
      //     item.num_bathrooms.toString().includes(searchTermLowerCase))) &&
      // (selectedCategory === '' || item.num_bathrooms === selectedCategory)
      // ||
      // item.description.toLowerCase().includes(searchTermLowerCase)
    );

    setSearchDigitalTwin(filtered);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectName = event.target.name;
    setSelectedOption((prevState) => ({
      ...prevState,
      [selectName]: selectedValue,
    }));
  };

  // const handlePriceChange = (newValue: number) => {
  //   console.log("Slider", newValue);
  //   setSelectedOption((prevState) => ({
  //     ...prevState,
  //     ["price"]: newValue,
  //   }));
  //   console.log("selectedOption1w", selectedOption);
  // };

  const handleDetailButton = (
    digital_twin_id: string,
    matterport_id: string
  ) => {
    navigate(
      `/property_details?digitalTwinId=${digital_twin_id}&propertyId=${matterport_id}`
    );
  };

  function handleTabButton(
    digital_twin_id: string,
    matterport_id: string,
    tab: string
  ) {
    navigate(
      `/property_details?digitalTwinId=${digital_twin_id}&propertyId=${matterport_id}&tab=${tab}`
    );
  }

  // console.log("searchedDigitalTwin", UserService.)

  const handleFilterChange = (minPrice: string, maxPrice: string) => {
    const minPriceValue = minPrice ? parseInt(minPrice) : 0;
    const maxPriceValue = maxPrice ? parseInt(maxPrice) : 0;
    if (parseInt(minPrice) > parseInt(maxPrice)) {
      alert("Min price should not be greater than max price.");
      return;
    } else if (parseInt(maxPrice) < parseInt(minPrice)) {
      alert("Max price should not be less than min price.");
      return;
    } else {
      fetchProperties(minPriceValue, maxPriceValue);
    }
  };

  return (
    <>
      <div className="container max-w-screen-2xl flex bg-[#F6F6F8]  min-h-screen overflow-y-auto">
        <div>
          <MiniDrawer />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex-grow pt-[5rem] md:pt-[5rem] lg:pt-[6rem] px-2 lg:px-3">
            {/* all the page content here */}
            <div className="flex flex-col flex-start  gap-2 ">
              <div
                className="flex items-center text-[18px] sm:text-[22px] md:text-[28px] lg:text-[32px] xl:text-[36px] gap-2"
                style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
              >
                <span className="font-extrabold text-[#1B0039] ">
                  My Agency Listings
                </span>
                <span className="font-extrabold text-[#283BBE]">
                  {searchedDigitalTwin.length}
                </span>
              </div>
              <div
                className="flex flex-col text-[#330F3E] font-semibold text-[14px] sm:text-[14px] md:text-[16px] lg:text-[20px] xl:text-[24px] pb-3"
                style={{ fontFamily: "Inter, sans-serif" }}
              >
                <span className="break-words">
                  These are the properties listed by your agency - {agencyName}
                </span>
              </div>
            </div>
            <div className="w-full flex flex-col flex-start md:items-center gap-1 py-4 px-1.5 shadow-md bg-white rounded-md mb-3 ">
              <div className="w-full xl:w-full ">
                <div className="w-full flex flex-wrap flex-col sm:flex-row md:flex-row justify-center">
                  <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                    <FilterSelect
                      name="region"
                      label="Region"
                      options={["All", ...Object.keys(region)]}
                      value={selectedOption["region"]}
                      className="bg-white text-black"
                      handleChange={handleSelectChange}
                    />
                  </div>

                  <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                    <FilterSelect
                      name="type"
                      label="Type"
                      options={["All", ...Object.keys(type)]}
                      value={selectedOption.type}
                      className="bg-white text-black"
                      handleChange={handleSelectChange}
                    />
                  </div>

                  <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                    <FilterSelect
                      name="lifestyle"
                      label="Lifestyle"
                      options={["All", ...Object.keys(lifestyle)]}
                      value={selectedOption["lifestyle"]}
                      className="bg-white text-black"
                      handleChange={handleSelectChange}
                    />
                  </div>

                  <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                    <FilterSelect
                      name="contract_type"
                      label="Contract Type"
                      options={["All", ...Object.keys(contract_type)]}
                      value={selectedOption["contract_type"]}
                      className="bg-white text-black"
                      handleChange={handleSelectChange}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full md:w-full lg:w-full xl:w-full max-sm:gap-2 flex flex-col sm:flex-row md:flex-row  xl:flex-row md:items-center sm:flex-start md:justify-between pr-1 md:pr-2">
                <div className="flex  w-full flex-grow sm:w-[100%] md:w-[65%] lg:w-[65%] xl:w-1/2 sm:pl-2">
                  {/* flex-wrap sm:w-[100%] md:w-[50%] lg:w-[50%] xl:w-1/2 */}
                  <PriceFilter onFilterChange={handleFilterChange} />
                </div>
                <div className="flex flex-grow  sm:w-[100%] md:w-[35%] lg:w-[35%] xl:w-1/2 sm:pl-2">
                  <SearchBar onSearch={handleSearch} />
                </div>
              </div>
            </div>

            {searchedDigitalTwin.length > 0 ? (
              <div className="grid gap-3 max-xl:grid-cols-2 max-md:grid-cols-1">
                {searchedDigitalTwin.map((property) => (
                  <div className="bg-white shadow-md grid   max-xl:grid-cols-1 max-2xl:grid-cols-7 2xl:grid-cols-7  p-4 gap-3 rounded-md">
                    <div className="  max-xl:col-span-1  max-2xl:col-span-2 2xl:col-span-2 flex flex-col gap-2">
                      <Tabs
                        tabs={[
                          { label: "Digital Twin" },
                          { label: "Images" },
                          { label: "Video(s)" },
                        ]}
                        handleTabButton={handleTabButton}
                        matterport_id={property.matterport_id}
                        digital_twin_id={property._id}
                      />
                      <div className="w-full h-full max-h-52 2xl:max-h-80 overflow-hidden rounded-md">
                        <img
                          src={
                            property.property_thumbnail === null
                              ? "https://storage.googleapis.com/oneverse-dev/realestate/no_property_image.jpg"
                              : // "https://storage.googleapis.com/oneverse-dev/realestate/06.11.2024_17.25.09--999.jpg"
                                property.property_thumbnail
                          }
                          alt="house"
                          className="object-cover w-full h-full"
                        />
                      </div>
                    </div>
                    <div className="  max-xl:col-span-1 max-2xl:col-span-5 2xl:col-span-5 flex flex-col justify-between 2xl:gap-8 gap-5">
                      <PropertyCard
                        propertyInfo={{
                          title: property.title ? property.title : "",
                          propertyId: property.property_id
                            ? property.property_id
                            : "",
                          propertyLink: property.characteristics.shareable_link
                            ? property.characteristics.shareable_link
                            : "",
                          description: property.description
                            ? property.description
                            : "",
                          location: property.address.province
                            ? property.address.province
                            : "",
                          contractType: property.characteristics.contract_type
                            ? property.characteristics.contract_type
                            : "",
                          bedrooms: property.characteristics.num_bedrooms
                            ? property.characteristics.num_bedrooms +
                              " Bedrooms"
                            : 0,
                          area: property.characteristics.area_sqm
                            ? property.characteristics.area_sqm + " Sq. Mtr"
                            : 0,
                          type: property.characteristics.type_of_property
                            ? toPascalCase(
                                property.characteristics.type_of_property
                              )
                            : "",
                          lifestyle: property.characteristics.lifestyle
                            ? toPascalCase(property.characteristics.lifestyle)
                            : "",
                          listedOn: property.characteristics.listing_date
                            ? property.characteristics.listing_date
                            : "",
                          price: property.pricing.price
                            ? property.pricing.price
                            : 0,
                          isExclusive: false,
                          // Exclusive: "NETWORK",
                          property_visibility: property.property_visibility,
                          digital_twin_id: property._id,
                          matterport_id: property.matterport_id,
                        }}
                        handleDetailButton={handleDetailButton}
                      />

                      <div className="flex items-center justify-between max-xl:items-end  max-xl:flex-col  max-xl:gap-2">
                        <div className="flex items-center gap-4 max-xl:justify-between max-xl:items-start  max-xl:w-full">
                          <CommissionAmount
                            // amount={
                            //   property.characteristics.contract_type === "sale" &&
                            //   property.sale_commission_config
                            //     ? property.sale_commission_config
                            //         .agent_commission || 0.0
                            //     : property.characteristics.contract_type ===
                            //         "rent" && property.rent_commission_config
                            //     ? property.rent_commission_config
                            //         .buyer_agent_commission || 0.0
                            //     : 0.0
                            // }
                            amount={
                              property.pricing.price
                                ? property.pricing.price
                                : 0
                            }
                            contractType={
                              property.characteristics.contract_type
                            }
                          />
                          <ToursCompleted
                            tours={property.characteristics.total_virtual_tours}
                            clients="16"
                          />
                        </div>
                        <div className="flex max-xl:w-full">
                          <Button
                            children="Details"
                            className="w-full bg-[#283BBE] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[35px] "
                            onClick={() => {
                              handleDetailButton(
                                property._id,
                                property.matterport_id
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>No properties found</div>
            )}

            <div className="m-5">
              <PaginationRounded
                currentPage={currentPage}
                totalPages={totalPages}
                limit={pageLimit}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
          {/* Mini Footer */}
          <div className="flex w-full py-6 flex-col lg:flex-row bg-[#202020] text-white lg:justify-center gap-6 px-2 opacity-85">
            <div className="  lg:text-right">
              <Link to="/privacy_policy">Privacy Policy</Link>
            </div>
            <div className=" lg:text-right">
              <Link to="/terms_and_conditions">Terms & Conditions</Link>
            </div>
            <div className=" lg:text-right">
              <p>Copyright © 2024 1Verse. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
