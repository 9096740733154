import React, {
  // useState 
} from "react"


// import { Box } from "@mui/material";
import MiniDrawer from "rc-app/components/MiniDrawer";



export const GT = () => {
  return (
    <>
      <div className="flex container max-w-screen-2xl">
        <div><MiniDrawer /></div>
        <div className="flex-grow pt-[6.6rem]">
          <div className=" ">This is GT</div>
        </div>
      </div>




      {/* <Box sx={{ display: 'flex' }}>
          <div><MiniDrawer /></div>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="p-16">THis is GT</div>
          </Box>
        </Box> */}

    </>
  )
}