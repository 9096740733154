import React, {
    // useState 
  } from "react"
  
  
  // import { Box } from "@mui/material";
  import MiniDrawer from "rc-app/components/MiniDrawer";
  
  
  export const ComingSoon = () => {
    return (
      <>
        <div className="flex">
          <div><MiniDrawer /></div>
          <div className="w-full min-h-screen flex pt-[6.6rem] items-center justify-center">
            <div className=" flex p-6 shadow-md rounded-md bg-white text-[#4950bc] font-bold" >Stay tuned for our exciting new feature, coming soon!</div>
          </div>
        </div>
  
  
  
  

      </>
    )
  }