// import AgoraIOVideoCall from "rc-app/components/Agora";
import React, {
  FC, 
  // useEffect,
  // useState
} from "react"
// import { useLocation } from "react-router-dom";
// import { getAgoraAppId, getRtcToken } from "../Home";
// import UserService from "rc-app/services/UserService";
import MiniDrawer from "rc-app/components/MiniDrawer";

const agora_appId = process.env.REACT_APP_ONEVERSE_KEYCLOAK_URL;
// const agora_channel = process.env.REACT_APP_AGORA_CHANNEL;
// const agora_token = process.env.REACT_APP_AGORA_TOKEN;
// const agora_token = "007eJxSYDi6wC5wzu8fs4z2qP6yldtYJ+d/hevym/Tv6yPiWzOZXpQqMFgmpZgmmRskWSYZmZuYpphbGhuamRkaJVqkGqYkWhqkHXlbnrqgh4HhlHseEyMDBIL4AgzJGYl5eak58akGlomGppYGDAyAAAAA//+G1CVW"
// const agora_appId= '06be00e80e4644508f42f8bb5f1457d8'
// const agora_channel = 'RL-ESTATE'



export const World: FC<{ Comp: React.ReactNode }> = ({ Comp }) => {


  // const location = useLocation()
  // const queryParams = new URLSearchParams(location.search);
  // const channel = queryParams.get('channel');

  // const [agoraToken, setAgoraToken] = useState()
  // const { email } = UserService.getTokenParsed();
  // const [agoraAppId, setAgoraAppId] = useState();

  // useEffect(() => {
  //   getRtcToken(channel).then((res) => {
  //     setAgoraToken(res.rtcToken)
  //   }).catch(err => console.log('err', err))
  //   getAgoraAppId('RL-ESTATE').then(res => {
  //     setAgoraAppId(res.network['agora_app_id'])
  //   })
  // }, [])

  console.log("env", agora_appId);


  return (
    <>
      <div className="flex container max-w-screen-2xl">
        <div><MiniDrawer /></div>
        <div className="flex-grow pt-[6.6rem]">
          {/* <AgoraIOVideoCall
            token={agoraToken}
            appId={agoraAppId}
            channel={channel}
            userId={email}
          /> */}
          {Comp}

        </div>
      </div>


    </>);
};
