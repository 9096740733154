//CSS
import "./main.css";
import "./style.css";

import React, {
  StrictMode,
  // useState
} from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";

//Pages
// import { Home } from './Pages/Home';
// import { GT } from './Pages/GT';
// import { Apartment } from './Pages/Apartment';
import { Properties } from "./Pages/Properties";
import { RCApp } from "./RcMain";
import { World } from "./Pages/World";
// import { PropertyDetails } from './Pages/PropertyDetails';
// import LandingPage from './Pages/Landing Page';

//Services
import UserService from "./services/UserService";

// Components
import RenderOnAnonymous from "./components/RenderOnAnonymous";
import RenderOnAuthenticated from "./components/RenderOnAuthenticated";
// import { Header } from './components/Header';
// import { Header } from './components/Header';
// import { NoMatch } from './components/NoMatch';
import { DataProvider } from "./components/DataContext";
import { VrTour } from "./Pages/VrTour";
import { VrShowcase } from "./Pages/VrShowcase";
// import { VirtualTour } from './Pages/VirtualTour';

// const container = document.getElementById("content");
// const root = ReactDOMClient.createRoot(container);

// import { getProperties } from './Pages/Home';
// import PrevDetails from './Pages/PrevDetails';
import { Contact } from "./Pages/Contact";
import { GT } from "./Pages/GT";
import { AddNewSpace } from "./Pages/Add New Space";
import { IntroPage } from "./Pages/IntroPage";
import { NewHomePage } from "./Pages/NewHomePage";
// import MiniDrawer from './components/MiniDrawer';
import { Header } from "./components/Header";
import { AgencyListing } from "./Pages/Agency Listing";
import { NetworkListing } from "./Pages/Network Listing";
import { Details } from "./Pages/Details";
import { Clients } from "./Pages/Clients";
import { ClientDetails } from "./Pages/ClientDetails";
import { ClientProfile } from "./Pages/ClientProfile";
import { PropertiesShortlist } from "./Pages/Properties Shortlist";
import { AgentProfile } from "./Pages/AgentProfile";
import { ProductFeature } from "./Pages/ProductFeature";
import { SellWithUs } from "./Pages/SellWithUs";
import { Pricing } from "./Pages/Pricing";
import { ComingSoon } from "./components/ComingSoon";
import { MyAgent } from "./Pages/MyAgent";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy";
import { TermsOfUse } from "./Pages/TermsOfUse";


const Navigations = () => {
  // console.log('process', process.env.REACT_APP_AGORA_APP_ID);
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // const handleDrawerToggle = () => {
  //   setIsDrawerOpen(!isDrawerOpen);
  // };
  console.log("indexrr", UserService.hasRole("agent"));

  return (
    <BrowserRouter>
      <div className=" h-screen overflow-y-auto flex items-center flex-col">
        {!UserService.isLoggedIn() && (
          <div className=" conatiner max-w-screen-2xl w-full bg-[#000524] ">
            <Header drawerOpen={true} />
          </div>
        )}

        <RenderOnAuthenticated>
          <Routes>
            {/* <Route path="/" element={<LandingPage />} /> */}
            {/* <Route path="/" element={<Properties />} /> */}

            {/* Routes for new Design */}

            <Route
              path="/"
              element={
                UserService.hasRole("agent") ? (
                  <AgencyListing />
                ) : (
                  <PropertiesShortlist />
                )
              }
            />
            <Route
              path="/agency_list"
              element={
                UserService.hasRole("agent") ? (
                  <AgencyListing />
                ) : (
                  <div>"Not Allowed to Client"</div>
                )
              }
            />
            <Route
              path="/network_list"
              element={
                UserService.hasRole("agent") ? (
                  <NetworkListing />
                ) : (
                  <div>"Not Allowed to Client"</div>
                )
              }
            />
            <Route
              path="/property_details"
              element={
                UserService.hasRole("agent") ? (
                  <Details />
                ) : (
                  <div>"Not Allowed to Client"</div>
                )
              }
            />
            <Route
              path="/clients"
              element={
                UserService.hasRole("agent") ? (
                  <Clients />
                ) : (
                  <div>"Not Allowed to Client"</div>
                )
              }
            />
            <Route
              path="/client_details"
              element={
                UserService.hasRole("agent") ? (
                  <ClientDetails />
                ) : (
                  <div>"Not Allowed to Client"</div>
                )
              }
            />

            <Route
              path="/profile"
              element={
                UserService.hasRole("agent") ? (
                  <AgentProfile />
                ) : UserService.hasRole("customer") ? (
                  <ClientProfile />
                ) : (
                  <div>"Not Allowed "</div>
                )
              }
            />

            <Route
              path="/properties_shortlist"
              element={
                UserService.hasRole("customer") ? (
                  <PropertiesShortlist />
                ) : (
                  <div>"Not Allowed to Agent"</div>
                )
              }
            />
            <Route
              path="/vr_tour"
              element={
                UserService.hasRole("customer") ? (
                  <VrTour />
                ) : (
                  <div>"Not Allowed to Agent"</div>
                )
              }
            />

            <Route
              path="/my_agent"
              element={
                UserService.hasRole("customer") ? (
                  <MyAgent />
                ) : (
                  <div>"Not Allowed to Agent"</div>
                )
              }
            />

            <Route
              path="/join_vr_tour"
              element={
                UserService.hasRole("customer") ? (
                  <VrTour />
                ) : (
                  <div>"Not Allowed to Agent"</div>
                )
              }
            />

            <Route path="/virtual-tour" element={<World Comp={<RCApp />} />} />

            {/* <Route path="/details" element={<PrevDetails />} /> */}
            {/* <Route path="/apartment" element={<Apartment />} /> */}
            <Route path="/gt" element={<GT />} />
            {/* <Route path="/world" element={ <World Comp={<RCApp/>}/>  } /> */}
            {/* <Route path="/properties" element={<Properties properties={properties} />} /> */}
            <Route path="/properties" element={<Properties />} />
            {/* <Route path="/property_details" element={<PropertyDetails />} /> */}

            {/* <Route path="/virtual-tour" element={<VirtualTour />} /> */}
            <Route path="/vr-showcase" element={<VrShowcase />} />

            <Route path="/add-new-space" element={<AddNewSpace />} />

            <Route path="/earnings" element={<ComingSoon />} />
            <Route path="/vr_tour_list" element={<ComingSoon />} />

            {/* <Route path='/intro-page' element= { <IntroPage />} /> */}

            {/* <Route path='/contact-us' element={<Contact />} />
            <Route path='/product_features' element={<ProductFeature />} />
            <Route path='/sell' element={<SellWithUs />} />
            <Route path='/pricing' element={<Pricing />} /> */}
            <Route
              path="*"
              element={
                UserService.hasRole("agent") ? (
                  <AgencyListing />
                ) : (
                  <PropertiesShortlist />
                )
              }
            />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/terms_and_conditions" element={<TermsOfUse />} />
          </Routes>
        </RenderOnAuthenticated>

        <RenderOnAnonymous>
          <>
            <Routes>
              {/* <Route path="/join_vr_tour" element={ <VrTour /> } /> */}

              <Route path="/product_features" element={<ProductFeature />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/sell" element={<SellWithUs />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/privacy_policy" element={<PrivacyPolicy />} />
              <Route path="/terms_and_conditions" element={<TermsOfUse />} />
              <Route path="/a" element={<IntroPage />} />

              {/* <Route path='*' element={<IntroPage />} /> */}
              {/* <Route path='/' element={<LandingPage />} /> */}

              <Route path="/" element={<NewHomePage />} />
              <Route path="*" element={<NewHomePage />} />
            </Routes>
            {/* <LandingPage /> */}
          </>
        </RenderOnAnonymous>
      </div>
    </BrowserRouter>
  );
};

// const NewNav = React.memo(Navigations)
// root.render(
//   <NewNav />
// );
// UserService.initKeycloak(root);

const renderApp = () =>
  createRoot(document.getElementById("content")).render(
    <StrictMode>
      <DataProvider>
        <Navigations />
      </DataProvider>
    </StrictMode>
  );

UserService.initKeycloak(renderApp);
