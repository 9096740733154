import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
// import { GrMore } from "react-icons/gr";
import {
  // IoIosAdd,
  IoMdSend,
} from "react-icons/io";
// import {
//   MdEmojiEmotions,
//   //  MdSearch
// } from "react-icons/md";
import { RiRadioButtonLine } from "react-icons/ri";

import axios from "axios";
import UserService from "rc-app/services/UserService";
import { getAgentById } from "rc-app/Pages/Home";

export const Messages = ({ customer, agentId, customerAgent }: any) => {
  const [isDisplay2, setIsDisplay2] = useState(false);
  // const [customerChat, setCustomerChat] = useState(customerList[0]);

  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [customerLastMessage, setCustomerLastMessage] = useState({is_active: true,
    created_at: "",
    updated_at: "",
    _id: "",
    agent_id: "string",
    customer_id: "string",
    message: {
      sender_type: "agent",
      sender_id: "string",
      content: "string",
      sent_at: "2024-09-02T11:11:25.404Z"
    }
  });
  const [agentLastMessage, setAgentLastMessage] = useState({});
  const [agentDetails, setAgentDetails] = useState({
    first_name: "",
    last_name: "",
  });

  console.log("restt", agentId);
  useEffect(() => {
    getAgentById(agentId).then((res) => {
      setAgentDetails(res.agent);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(fetchMessages, 5000); // Poll every 5 seconds
    return () => clearInterval(interval);
  }, []);

  console.log(
    "prevmessage",
    messages,
    agentLastMessage,
    setAgentLastMessage,
    agentDetails,
    setAgentDetails,
    customerLastMessage
  );

  const fetchMessages = async () => {
    try {
      const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_message?agent_id=${agentId}&customer_id=${customer._id}&sort_by=created_at&sort_order=-1`;
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      setMessages(response.data.re_message_list.sort((a:any, b:any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()));
      setMessagesLoading(true);
      // setLastMessage(
      //   response.data.re_message_list[response.data.re_message_list.length - 1]
      // );
      const customerMessages= response.data.re_message_list.filter((item:any)=>item.message.sender_type==="customer")
      console.log("clientLastmessage", customerMessages);
      setCustomerLastMessage(customerMessages[customerMessages.length-1])

    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const sendMessage = async (currentMessage: any) => {
    const PAYLOAD = {
      agent_id: agentId,
      customer_id: customer._id,
      message: {
        sender_type: `${
          UserService.hasRole("customer") ? "customer" : "agent"
        }`,
        sender_id: `${
          UserService.hasRole("customer") ? customer._id : agentId
        }`,
        content: currentMessage,
      },
    };
    console.log(PAYLOAD);

    try {
      const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/re_message`;
      const response = await axios.post(API_URL, PAYLOAD, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      console.log("testx", response.data.message);

      setMessages([...messages, response.data.message]);
      setCurrentMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;

    return `${day}-${month}-${year}, ${hours}:${minutes}${ampm}`;
  };

  const formatTime = (dateString: any) => {
    const date = new Date(dateString);

    // const day = date.getDate();
    // const month = date.toLocaleString("default", { month: "short" });
    // const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;

    return ` ${hours}:${minutes}${ampm}`;
  };


  console.log(
    "customerdd",
    customer,
    messages,
    currentMessage,
    setCurrentMessage,
    sendMessage,
    // lastMessage,
    formatTime
  );
  console.log("currentMessage", customer);

  return (
    <div className="bg-white p-3 flex flex-col gap-3 rounded-b-md w-full">
      <div className="grid grid-cols-12 w-full min-h-[80vh] shadow-md">
        <div
          className={`col-span-3 h-full bg-[#F0F0FB] p-2 flex flex-col gap-4 max-lg:col-span-12 ${
            isDisplay2 ? "max-lg:hidden" : ""
          }`}
        >
          {/* <div className="flex items-center bg-[#ededfa] relative p-2 rounded gap-1">
            <MdSearch className="text-gray-400" />
            <input
              type="search"
              name=""
              id=""
              placeholder="Find people and conversations"
              className="outline-none bg-transparent"
            />
            <div className="bg-[#283BBE] p-1 rounded-full text-white absolute -bottom-1 right-0">
              <IoIosAdd className="text-white" />
            </div>
          </div> */}
          {/* <div className="text-xs flex items-center gap-2 ">
            <span>Recent</span>
            <span>Unread</span>
          </div> */}
          {/* <div className="flex items-center">
            <hr className="border-b-[1px] border-[#283BBE] w-10" />
            <hr className="w-full" />
          </div> */}
          {/* client chat list */}
          {/* <div className="flex flex-col gap-4 max-h-[61vh] overflow-y-auto">
            {customerList.map((customer:any, index:any) => (
              <div
                className={`flex items-start gap-1 w-full p-1 cursor-pointer ${
                  index === 0 ? "bg-[#ededfa]" : ""
                }`}
                onClick={() => {
                  setIsDisplay2(true);
                  console.log(customer, " chat open")
                  alert(`client clicked: ${customer}`)
                  setCustomerChat(customer)
                }}
              >
                <div className="w-[40px] h-[40px] rounded-full bg-slate-300 relative">
                  <RiRadioButtonLine
                    className="text-green-700 absolute right-0 bottom-0"
                    size={12}
                  />
                </div>
                <div className="flex items-center justify-between w-full text-xs">
                  <div className="flex flex-col gap-1">
                    <h5 className="font-semibold"> {customer.first_name}{" "}</h5>
                    <span className="text-[#9095A4]">
                      Yeah, we all loved it!
                    </span>
                  </div>
                  <div className="flex flex-col gap-1 items-end">
                    <GrMore />
                    <span className="text-[#9095A4] text-[10px]">7:47 PM</span>
                  </div>
                </div>
              </div>
            ))}
          </div> */}

          <div className="flex flex-col gap-4 max-h-[61vh] items-center overflow-y-auto">
            {Array.from({ length: 1 }).map((_, index: any) => (
              <div
                className={`flex items-start gap-1 w-full p-1 cursor-pointer ${
                  index === 0 ? "bg-[#ededfa]" : ""
                }`}
                onClick={() => {
                  setIsDisplay2(true);
                  console.log(customer, "chat open");
                  // setCustomerChat(customer);
                }}
              >
                <div className="w-[40px] h-[40px] rounded-full bg-slate-300 relative">
                  <RiRadioButtonLine
                    className="text-green-700 absolute right-0 bottom-0"
                    size={12}
                  />
                </div>
                {UserService.hasRole("customer") && (
                  <div className="flex items-center justify-between w-full text-xs">
                    <div className="flex flex-col gap-1">
                      <h5 className="font-semibold">
                        {" "}
                        {customerAgent.first_name} {customerAgent.last_name}{" "}
                      </h5>
                      {/* <span className="text-[#9095A4]">
                        Yeah, we all loved it!
                      </span> */}
                    </div>
                    {/* <div className="flex flex-col gap-1 items-end">
                      <GrMore />
                      <span className="text-[#9095A4] text-[10px]">
                        7:47 PM
                      </span>
                    </div> */}
                  </div>
                )}
                {UserService.hasRole("agent") && (
                  <div className="flex items-center justify-between w-full text-lg">
                    <div className="flex flex-col gap-1 items-center h-full">
                      <h5 className="font-semibold">
                        {" "}
                        {customer.first_name} {customer.last_name}{" "}
                      </h5>
                      {/* <span className="text-[#9095A4]">
                      Yeah, we all loved it!
                      </span> */}
                    </div>
                    {/* <div className="flex flex-col gap-1 items-end">
                      <GrMore />
                      <span className="text-[#9095A4] text-[10px]">
                        7:47 PM
                      </span>
                    </div> */}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div
          className={`col-span-9 flex flex-col gap-3 relative max-lg:col-span-12  ${
            isDisplay2 ? "" : "max-lg:hidden"
          }`}
        >
          {UserService.hasRole("agent") && (
            <div className="px-3 py-2 text-xs text-gray-400">
              <h5 className="flex items-center gap-5">
                <BiArrowBack
                  className="cursor-pointer max-2xl:hidden max-lg:block"
                  onClick={() => setIsDisplay2(false)}
                />{" "}
                {customer.first_name} {customer.last_name}
                <RiRadioButtonLine className="text-green-700 " size={12} />
              </h5>
            </div>
          )}
          {UserService.hasRole("customer") && (
            <div className="px-3 py-2 text-xs text-gray-400">
              <h5 className="flex items-center gap-5">
                <BiArrowBack
                  className="cursor-pointer max-2xl:hidden max-lg:block"
                  onClick={() => setIsDisplay2(false)}
                />{" "}
                {customerAgent.first_name} {customerAgent.last_name}
                <RiRadioButtonLine className="text-green-700 " size={12} />
              </h5>
            </div>
          )}

          <hr />

          <div className="max-h-[61vh] overflow-y-scroll pb-10">
            {UserService.hasRole("agent") &&
              (!messagesLoading ? (
                <div className="w-full text-xs flex items-center flex-col gap-3 p-2 ">
                  <span>Loading ...</span>
                </div>
              ) : (
                messages.map((message, index) => (
                  <>
                    {/* customer message */}
                    {message.message.sender_type == "customer" && (
                      <div
                        key={index}
                        className="text-xs flex flex-col gap-2 w-fit text-[#9095A4] p-2"
                      >
                        {/* <p>Yesterday, 8:19 PM</p> */}
                        <p>{formatDate(message.created_at)}</p>
                        <h5 className="bg-[#F0F0FB] p-2 rounded-md">
                          {message.message.content}
                        </h5>
                      </div>
                    )}

                    {message.message.sender_type == "agent" && (
                      <>
                        {/* agent message div */}
                        <div
                          key={index + 1}
                          className="text-xs flex flex-col gap-2 items-end text-[#9095A4] p-2"
                        >
                          <div className="flex items-center gap-3">
                            <div className="w-[40px] h-[40px] rounded-full bg-slate-300 relative max-lg:hidden">
                              <RiRadioButtonLine
                                className="text-green-700 absolute right-0 bottom-0"
                                size={12}
                              />
                            </div>
                            <div className="w-fit flex flex-col gap-2">
                              {/* <p>Michael Wong, Yesterday 8:20 PM</p> */}
                              <p>
                                {agentDetails.first_name}{" "}
                                {agentDetails.last_name},{" "}
                                {formatDate(message.created_at)}
                              </p>
                              <h5 className="bg-[#283BBE] text-white p-2 rounded-md">
                                {message.message.content}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))
              ))}
            {UserService.hasRole("customer") &&
              (!messagesLoading ? (
                <div className="w-full text-xs flex items-center flex-col gap-3 p-2 ">
                  <span>Loading ...</span>
                </div>
              ) : (
                messages.map((message, index) => (
                  <>
                    {/* customer message */}
                    {message.message.sender_type == "agent" && (
                      <div
                        key={index}
                        className="text-xs flex flex-col gap-2 w-fit text-[#9095A4] p-2"
                      >
                        {/* <p>Yesterday, 8:19 PM</p> */}
                        <p>{formatDate(message.created_at)}</p>
                        <h5 className="bg-[#F0F0FB] p-2 rounded-md">
                          {message.message.content}
                        </h5>
                      </div>
                    )}

                    {message.message.sender_type == "customer" && (
                      <>
                        {/* agent message div */}
                        <div
                          key={index + 1}
                          className="text-xs flex flex-col gap-2 items-end text-[#9095A4] p-2"
                        >
                          <div className="flex items-center gap-3">
                            <div className="w-[40px] h-[40px] rounded-full bg-slate-300 relative max-lg:hidden">
                              <RiRadioButtonLine
                                className="text-green-700 absolute right-0 bottom-0"
                                size={12}
                              />
                            </div>
                            <div className="w-fit flex flex-col gap-2">
                              {/* <p>Michael Wong, Yesterday 8:20 PM</p> */}
                              <p>
                                {customer.first_name} {customer.last_name},{" "}
                                {formatDate(message.created_at)}
                              </p>
                              <h5 className="bg-[#283BBE] text-white p-2 rounded-md">
                                {message.message.content}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))
              ))}

            {/* <div className="flex items-center gap-2 text-xs text-[#9095A4]">
              <hr className="w-full" />
              <span>Today</span>
              <hr className="w-full" />
            </div> */}

            {/* <div className="text-xs flex flex-col gap-2 w-fit text-[#9095A4] p-2">
              <p>7:40 PM</p>
              <h5 className="bg-[#F0F0FB] p-2 rounded-md">
                Did you get that awesomesauce I sent?
              </h5>
            </div>
            <div className="text-xs flex flex-col gap-2 items-end text-[#9095A4] p-2">
              <div className="flex items-start gap-3">
                <div className="w-[40px] h-[40px] rounded-full bg-slate-300 relative max-lg:hidden">
                  <RiRadioButtonLine
                    className="text-green-700 absolute right-0 bottom-0"
                    size={12}
                  />
                </div>
                <div className="w-fit flex flex-col gap-2">
                  <p>Michael Wong, 7:46 PM</p>
                  <div className="flex items-end flex-col gap-2">
                    <h5 className="bg-[#283BBE] text-white p-2 rounded-md">
                      I did, our whole team rubbed it allll over their bread.
                    </h5>
                    <h5 className="bg-[#283BBE] text-white p-2 rounded-md">
                      It was delicious!
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-xs flex flex-col gap-2 w-fit text-[#9095A4] p-2">
              <p>7:47 PM</p>
              <h5 className="bg-[#F0F0FB] p-2 rounded-md">Awesome!</h5>
            </div> */}
            {/* <div className="text-xs flex flex-col gap-2 items-end text-[#9095A4] p-2">
              <div className="flex items-start gap-3">
                <div className="w-[40px] h-[40px] rounded-full bg-slate-300 relative max-lg:hidden">
                  <RiRadioButtonLine
                    className="text-green-700 absolute right-0 bottom-0"
                    size={12}
                  />
                </div>
                <div className="w-fit flex flex-col gap-2">
                  <p>Michael Wong, 7:48 PM</p>
                  <h5 className="bg-[#283BBE] text-white p-2 rounded-md">
                    Yeah, we all loved it!
                  </h5>
                </div>
              </div>
            </div> */}
          </div>
          <div className="absolute bottom-2 w-full text-xs flex items-center flex-col gap-3 p-2 ">
            {/* <span>Michael Wong is typing ...</span> */}
            <hr className="w-full" />
            <div className="flex items-center bg-[#F0F0FB] w-full gap-2 rounded-md px-2">
              <input
                type="text"
                placeholder="Message Michael"
                className="bg-transparent w-full p-3 outline-none"
                value={currentMessage}
                onChange={(e) => {
                  setCurrentMessage(e.target.value);
                }}
                onKeyDown={(e) => {
                  
                  if (e.key === "Enter"  && currentMessage!=="") {
                    sendMessage(currentMessage);
                    setCurrentMessage("");
                    console.log("keyenter", currentMessage);
                  }
                }}
              />
              <IoMdSend
                size={25}
                className="cursor-pointer"
                onClick={() => {
                  sendMessage(currentMessage);
                  console.log(currentMessage, "currentMessage1");
                  setCurrentMessage("");
                }}
              />
              {/* <MdEmojiEmotions size={25} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
