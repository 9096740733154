import React from "react";

interface AgentCardProps {
  className?: string;
  title?: string;
  agent_id?: string;
  data: { value: number | string; icon: React.ReactNode }[];
}

const AgentCard: React.FC<AgentCardProps> = ({
  title,
  agent_id,
  data,
  ...props
}) => {
  return (
    <div className={` flex  lg:gap-5   w-full p-4`} {...props}>
      <div className={`flex flex-col  w-full justify-center gap-3 flex-grow `}>
        {/* Agent */}
        <div className={`flex items-center w-full gap-5`}>
          <div className={`inline-block`}>
            <h1 className="font-bold text-[#1B191B]  text-2xl sm:text-2xl max-md:flex-wrap md:whitespace-nowrap ">
              {title}
            </h1>
          </div>

          <div
            className={` items-start flex  "justify-self-end"
            `}
          >
            <button
              className="text-[#283BBE]  cursor-pointer focus:outline-none font-semibold text-xl sm:text-xl "
              onClick={() => console.log("Clicked on Client ID")}
            >
             {agent_id}
            </button>
          </div>
        </div>

        <div className="grid grid-cols-auto-fit-client gap-1 w-full ">
          {data.map((item, index) => (
            <div
              key={index}
              className=" relative group flex flex-wrap flex-col  sm:items-start sm:flex-start  sm:justify-start lg:justify-start sm:mb-0"
            >
              {item &&
                item.icon &&
                typeof item.value !== "undefined" && ( // Check if item and icon exist, and value is defined
                  <span
                    className={`opacity-50 ${
                      index === 0
                        ? "text-[15px] xl:text-[16px]"
                        : "text-[14px] xl:text-[15px]"
                    } `}
                  >
                    {item.icon}
                  </span>
                )}
              {item && typeof item.value !== "undefined" && (
                <>
                  {/* Highlighted Code Start */}
                  <span
                    className={`text-[#283BBE] ${
                      index === 0
                        ? "lg:text-[15px] xl:text-[16px]"
                        : "lg:text-[14px] xl:text-[15px]"
                    } ${
                      typeof item.value === "string" && item.value.includes("@")
                        ? "truncate"
                        : ""
                    }`}
                    style={
                      typeof item.value === "string" && item.value.includes("@")
                        ? { maxWidth: "150px" } // Limit the width for email IDs
                        : {}
                    }
                    title={
                      typeof item.value === "string" && item.value.includes("@")
                        ? item.value
                        : ""
                    }
                  >
                    {typeof item.value === "string"
                      ? item.value.charAt(0).toUpperCase() + item.value.slice(1)
                      : item.value}
                  </span>
                  {/* Highlighted Code End */}
                </>
              )}
              {/* Tooltip for Email */}
              {typeof item.value === "string" && item.value.includes("@") && (
                <div className="absolute bottom-full mb-1 left-0 w-max bg-[#FF307A] text-white text-sm rounded-full px-3 py-2 whitespace-nowrap hidden group-hover:block">
                  {item.value}
                  <div className="absolute top-full left-4 w-2 h-2 bg-[#FF307A] transform rotate-45"></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AgentCard;
