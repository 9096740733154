import React, { useState, useEffect } from "react";

import axios from "axios";
import UserService from "rc-app/services/UserService";

import MiniDrawer from "rc-app/components/MiniDrawer";
import FilterSelect from "rc-app/components/Filter Select";
import SearchBar from "rc-app/components/Search Bar";
// import PropertyCardLarge from "rc-app/components/PropertyCardLarge";
import PaginationRounded from "rc-app/components/Pagination";
import Button from "rc-app/components/Button";
import { Link, useNavigate } from "react-router-dom";

import DataTable from "rc-app/components/DataTable";
import Modal from "@mui/material/Modal";
import Slider from "rc-app/components/Slider";
import AddClientForm from "rc-app/components/AddClientForm";
import {
  createReCustomer,
  deleteClient,
  getAgentByEmail,
  // getDigitalTwinByAgent,
} from "../Home";

import ClientCard from "rc-app/components/Card/clientCard";

export const Clients = () => {
  const [customers, setCustomers] = useState([]);
  const [filterCustomers, setFilterCustomers] = useState([]);
  const [agentId, setAgentId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  let pageLimit = 10;

  const navigate = useNavigate();

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectName = event.target.name;
    setSelectedRegionOption((prevState) => ({
      ...prevState,
      [selectName]: selectedValue,
    }));
    if (event.target.name === "agency") {
      const selectedValue = event.target.value;
      const selectName = event.target.name;
      setSelectedOptionForTable((prevState) => ({
        ...prevState,
        [selectName]: agency[selectedValue],
      }));
    } else {
      const selectedValue = event.target.value;
      const selectName = event.target.name;
      setSelectedOptionForTable((prevState) => ({
        ...prevState,
        [selectName]: selectedValue,
      }));
    }

    console.log("name", event.target.name);
  };

  const [selectedRegionOption, setSelectedRegionOption] = useState({
    region: "all",
  });

  const region: { [key: string]: string } = {
    Abruzzo: "abruzzo",
    "Aosta Valley": "aosta_valley",
    Puglia: "puglia",
    Calabria: "calabria",
    Campania: "campania",
    Lazio: "lazio",
    Liguria: "liguria",
    Marche: "marche",
    Sicily: "sicily",
  };

  const handleDetailButton = (customerId: string) => {
    console.log("Search term:", customerId);
    navigate(`/client_details?customerId=${customerId}`);
  };

  const handleTabButton = (searchTerm: string) => {
    console.log("Search term:", searchTerm);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // useEffect(() => {
  //     getAgentByEmail(UserService.getUseremail()).then((data) => {
  //         setAgentId(data.agent._id)
  //     })
  // }, [])

  async function fetchClients() {
    try {
      let regionKey = "";
      console.log("Selected region:", selectedRegionOption.region);
      if (
        selectedRegionOption.region !== "all" &&
        region[selectedRegionOption.region]
      ) {
        regionKey = region[selectedRegionOption.region];
      }

      const API_URL = `${
        process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL
      }/api/v1/realestate/views/re_customer/agent/customer/${UserService.getUseremail()}?sort_by=created_at&sort_order=-1&page=${
        currentPage - 1
      }&limit=${pageLimit}&province=${regionKey}`;
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      setCustomers(response.data.re_customer_list);

      setTotalPages(response.data.total_re_customers);

      setFilterCustomers(response.data.re_customer_list);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  }
  useEffect(() => {
    fetchClients();
  }, [selectedRegionOption.region, currentPage, filterCustomers]);

  const handleCustomerSearch = (searchTerm: string) => {
    console.log("Search term:", searchTerm);
    const searchTermLowerCase = searchTerm.toLowerCase();

    const filtered = customers.filter(
      (item) =>
        item.first_name.toLowerCase().includes(searchTermLowerCase) ||
        item.last_name.toLowerCase().includes(searchTermLowerCase)
      // ||  (item.rooms &&
      //   item.rooms.toString().includes(searchTermLowerCase))
    );
    setFilterCustomers(filtered);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [agency, setAgency] = useState<{ [key: string]: string }>({});
  // const [properties, setProperties] = useState([]);
  const [filterDigitalTwinRows, setFilterDigitalTwinRows] = useState([]);

  const [digitalTwin, setDigitalTwin] = useState([]);

  const [selectedOptionForTable, setSelectedOptionForTable] = useState({
    agency: "all",
    visibility: "all",
    location: "all",
    rooms: "all",
    price: 0,
  });

  const visibility: { [key: string]: string } = {
    Exclusive: "exclusive",
    Network: "network",
  };
  const rooms: { [key: string]: number } = {
    "2 Bedrooms": 2,
    "3 Bedrooms": 3,
    "4 Bedrooms": 4,
    "5 Bedrooms": 5,
    "6 Bedrooms": 6,
  };
  const location: { [key: string]: string } = {
    Abruzzo: "abruzzo",
    "Aosta Valley": "aosta_valley",
    Puglia: "puglia",
    Calabria: "calabria",
    Campania: "campania",
    Lazio: "lazio",
    Liguria: "liguria",
    Marche: "marche",
    Sicily: "sicily",
  };

  useEffect(() => {
    async function fetchProperties() {
      try {
        let agencyKey = "";
        let visibilityKey = "";
        let roomsKey = 0;
        let locationKey = "";
        let priceMinQueryParam = "";
        if (selectedOptionForTable.price > 0) {
          priceMinQueryParam = `&price=${selectedOptionForTable.price}`;
        }

        if (
          selectedOptionForTable.agency !== "all" &&
          selectedOptionForTable.agency
        ) {
          agencyKey = selectedOptionForTable.agency;
        }

        console.log("agencyKey", selectedOptionForTable.agency);

        if (
          selectedOptionForTable.visibility !== "all" &&
          visibility[selectedOptionForTable.visibility]
        ) {
          visibilityKey = visibility[selectedOptionForTable.visibility];
        }

        if (
          selectedOptionForTable.rooms !== "all" &&
          rooms[selectedOptionForTable.rooms]
        ) {
          roomsKey = rooms[selectedOptionForTable.rooms];
        }
        console.log("visi", roomsKey);

        if (
          selectedOptionForTable.location !== "all" &&
          location[selectedOptionForTable.location]
        ) {
          locationKey = location[selectedOptionForTable.location];
        }

        // if (selectedOptionForTable.contract_type !== 'all' && contract_type[selectedOptionForTable.contract_type]) {
        //     contractTypeKey = contract_type[selectedOptionForTable.contract_type];
        // }

        console.log(locationKey, roomsKey);

        const API_URL = `${
          process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL
        }/api/v1/realestate/views/mp_digital_twin/agent/properties/${UserService.getUseremail()}?sort_by=created_at&sort_order=-1&page=0&limit=100&agency_id=${agencyKey}&province=${locationKey}&property_visibility=${
          visibilityKey === "network" || visibilityKey === "exclusive"
            ? visibilityKey
            : "all"
        }&num_bedrooms=${
          roomsKey === 0 ? "all" : roomsKey
        }${priceMinQueryParam}&`;
        // &property_visibility=${visibilityKey}&province=${locationKey}&num_bedrooms=${roomsKey}
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${UserService.getToken()}`,
            "Content-Type": "application/json",
          },
        });
        // setProperties(response.data.mp_digital_twin_list);
        const digitalTwinRows = response.data.mp_digital_twin_list.map(
          (item: any) => {
            return {
              id: item._id,
              property_id: item.property_id,
              title: item.title,
              visibility: item.property_visibility,
              location: item.address.city,
              aria: item.characteristics.area_sqm,
              rooms: item.characteristics.num_bedrooms,
              price: item.pricing.price,
              commision: item.pricing.condominium_expenses,
              mp_digital_twin_id: item._id,
              seller_agency_id: item.agency?._id,
              buyer_agent_id: agentId,
              added_by:
                UserService.findRole("agent")[0] == "agent"
                  ? "agent"
                  : "customer",
            };
          }
        );
        setDigitalTwin(digitalTwinRows);

        setTotalPages(response.data.total_mp_digital_twin);
        setFilterDigitalTwinRows(digitalTwinRows);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    }

    getAgentByEmail(UserService.getUseremail()).then((data) => {
      setAgentId(data.agent._id);
      fetchProperties();
    });
  }, [
    selectedOptionForTable.agency,
    selectedOptionForTable.visibility,
    selectedOptionForTable.location,
    selectedOptionForTable.rooms,
    selectedOptionForTable.price,
  ]);

  useEffect(() => {
    async function fetchAgency() {
      try {
        const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_agency?sort_by=created_at&sort_order=-1`;
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${UserService.getToken()}`,
            "Content-Type": "application/json",
          },
        });
        const convertedObject: { [key: string]: string } = {};
        response.data.re_agency_list.forEach((item: any) => {
          convertedObject[item.name] = item._id;
        });
        setAgency(convertedObject);
        console.log("agency", convertedObject);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    }

    fetchAgency();
  }, []);

  const handlePriceChange = (newValue: number) => {
    setSelectedOptionForTable((prevState) => ({
      ...prevState,
      ["price"]: newValue,
    }));
    console.log("Slider value:", newValue);
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    mobile_number: "",
    city: "",
    province: "",
    country: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log("target", name);
  };
  console.log("eexx", formData);

  const [selectionModel, setSelectionModel] = React.useState([]);

  const jsonFormData = {
    first_name: formData.first_name,
    last_name: formData.last_name,
    email_id: formData.email_id,
    mobile_number: formData.mobile_number,
    address: {
      address_line1: "",
      address_line2: "",
      landmark: "",
      postal_code: "",
      city: formData.city,
      province: formData.province,
      country: formData.country,
    },
    property_shortlist: selectionModel,
    agent_id: [agentId],
  };
  console.log("jsonFormData", jsonFormData);

  const [agentDetails, setAgentDetails] = useState();
  const [isClientCreatedSuccess, setIsClientCreatedSuccess] = useState(false);
  const [isClientCreatedFailed, setIsClientCreatedFailed] = useState(true);

  useEffect(() => {
    getAgentByEmail(UserService.getUseremail).then((res: any) => {
      setAgentDetails(res.agent);
    });
  }, []);
  console.log("kkkkk", agentDetails);

  const handleSelectionChange = (newSelection: any) => {
    setSelectionModel(
      newSelection.map((item: any, index: any) => {
        console.log("rows", filterDigitalTwinRows[index]);
        return {
          mp_digital_twin_id: filterDigitalTwinRows[index].mp_digital_twin_id,
          seller_agency_id: filterDigitalTwinRows[index].seller_agency_id,
          buyer_agent_id: filterDigitalTwinRows[index].buyer_agent_id,
          added_by: filterDigitalTwinRows[index].added_by,
        };
      })
    );
  };

  console.log("selectionModel ", selectionModel);

  const handleCreateCustomer = () => {
    createReCustomer(jsonFormData).then((res) => {
      console.log("jsonres", res);

      setIsClientCreatedSuccess(res.success);
      setIsClientCreatedFailed(res.success);
      setTimeout(() => {
        setIsClientCreatedSuccess(false);
        setIsClientCreatedFailed(true);
        setTimeout(() => {
          fetchClients();
          handleClose();
        }, 2000);
      }, 3000);
    });
  };

  const handleSearch = (searchTerm: string) => {
    console.log("searchTerm", searchTerm);
    const searchTermLowerCase = searchTerm.toLowerCase();
    const filtered = digitalTwin.filter(
      (item) =>
        item.title.toLowerCase().includes(searchTermLowerCase) ||
        // || item.matterport_id.toString().includes(searchTermLowerCase)
        (item.rooms && item.rooms.toString().includes(searchTermLowerCase))
    );
    setFilterDigitalTwinRows(filtered);
  };

  console.log("filterCustomers", filterCustomers);

  return (
    <>
      <div className="container max-w-screen-2xl flex bg-[#F6F6F8] min-h-screen overflow-y-auto">
        <div>
          <MiniDrawer />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex-grow pt-[5rem] pb-[6rem] md:pt-[5rem] lg:pt-[6rem] px-2 lg:px-3 gap-2 justify-center">
            {/* all the page content here */}
            {/* flex flex-col flex-start  gap-2  */}
            <div className="flex flex-row items-center gap-2 w-full pb-2">
              <div
                className="flex items-center w-1/2 text-[18px] sm:text-[22px] md:text-[28px] lg:text-[32px] xl:text-[36px] gap-2"
                style={{ fontFamily: "Cabinet Grotesk, sans-serif" }}
              >
                <span className="font-extrabold text-[#1B0039]">
                  My Clients
                </span>
                <span className="font-extrabold text-[#283BBE]">
                  {filterCustomers.length}
                </span>
              </div>
              <div className="flex w-1/2 justify-end">
                <Button
                  children={`+ Add client`}
                  className="bg-[#FF307A] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[40px]"
                  onClick={handleOpen}
                />
              </div>
            </div>
            <div className="w-full  flex flex-start gap-5 px-2 py-3 sm:p-3 md:p-5 shadow-md bg-white rounded-md">
              <div className="w-full lg:w-[70%] xl:w-[60%] flex flex-start flex-col md:flex-row  gap-2">
                {/* <div className=""> */}
                <div className="w-full sm:w-full md:w-[40%] lg:w-1/3 px-2 sm:px-4 ">
                  <FilterSelect
                    name="region"
                    label="Region"
                    options={["All", ...Object.keys(region)]}
                    value={selectedRegionOption["region"]}
                    className="bg-white text-black"
                    handleChange={handleSelectChange}
                  />
                </div>
                <div className="w-full sm:w-full md:w-[60%] lg:w-2/3 px-2 sm:px-4 mt-auto">
                  <SearchBar onSearch={handleCustomerSearch} />
                </div>
                {/* </div> */}
              </div>
            </div>

            {filterCustomers.length > 0 &&
              filterCustomers.map((filterCustomer) => (
                <div
                  key={filterCustomer.client_id}
                  className="w-full mt-3 flex flex-col items-center md:gap-5 p-2 md:p-2 shadow-md bg-white rounded-md"
                >
                  <ClientCard
                    isProperty={false}
                    title={
                      filterCustomer.first_name + " " + filterCustomer.last_name
                    }
                    isClient={true}
                    client_id={
                      filterCustomer.client_id ? filterCustomer.client_id : ""
                    }
                    customer_id={filterCustomer._id}
                    handleDetailButton={handleDetailButton}
                    handleTabButton={handleTabButton}
                    property_shortlisted={
                      filterCustomer.property_shortlist.length
                    }
                    data={[
                      {
                        value:
                          filterCustomer.email_id !== null
                            ? filterCustomer.email_id
                            : "Not Available",
                        icon: "Email",
                      },
                      {
                        value:
                          filterCustomer.mobile_number !== null
                            ? filterCustomer.mobile_number
                            : "Not Available",
                        icon: "Phone Number",
                      },
                      {
                        value:
                          filterCustomer.address &&
                          filterCustomer.address.city !== null
                            ? filterCustomer.address.city
                            : "Not Available",
                        icon: "City",
                      },
                      {
                        value:
                          filterCustomer.address &&
                          filterCustomer.address.province !== null
                            ? filterCustomer.address.province
                            : "Not Available",
                        icon: "Region",
                      },
                      {
                        value:
                          filterCustomer.address &&
                          filterCustomer.address.country !== null
                            ? filterCustomer.address.country
                            : "Not Available",
                        icon: "Country",
                      },
                      // { value: "MM/DD/YYYY", icon: "Client Last Login" },
                      {
                        value:
                          filterCustomer.address &&
                          filterCustomer.address.joined_date !== null
                            ? filterCustomer.address.joined_date
                            : "Not Available",
                        icon: "Client Joined Date",
                      },
                    ]}
                    detailButton={
                      <Button
                        children="Client Details"
                        className="bg-[#283BBE] max-sm:w-full rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[40px] "
                        onClick={() => {
                          handleDetailButton(filterCustomer._id);
                        }}
                      />
                    }
                    removeButton={
                      <Button
                        children={`Remove`}
                        textColor="black"
                        className="text-[#EC0000] font-semibold border-2 border-[#EC0000] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] "
                        onClick={() => {
                          deleteClient(filterCustomer._id).then((res) => {
                            console.log("remove from shortlist", res);
                          });
                        }}
                      />
                    }
                  />
                </div>
              ))}

            {filterCustomers.length === 0 && <div>No clients found</div>}

            <div className="m-5">
              <PaginationRounded
                currentPage={currentPage}
                totalPages={totalPages}
                limit={pageLimit}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
          <div className="flex w-full py-6 flex-col lg:flex-row bg-[#202020] text-white lg:justify-center gap-6 px-2 opacity-85">
            <div className="  lg:text-right">
              <Link to="/privacy_policy">Privacy Policy</Link>
            </div>
            <div className=" lg:text-right">
              <Link to="/terms_and_conditions">Terms & Conditions</Link>
            </div>
            <div className=" lg:text-right">
              <p>Copyright © 2024 1Verse. All rights reserved.</p>
            </div>
          </div>
        </div>

        <div className="flex top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="fixed top-[10%] left-[15%] w-[70%] h-[80%] overflow-auto justify-center bg-white border-2 border-black shadow-md p-4 ">
                <div className={`flex `}>
                  <h1 className="font-bold text-[#FF307A] text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5">
                    Client Details
                  </h1>
                </div>

                <div className="flex flex-row items-center gap-2 w-full py-4 ">
                  <div className="flex w-full justify-end">
                    <AddClientForm
                      formData={formData}
                      onInputChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className={`flex `}>
                  <h1 className="font-bold text-[#FF307A] text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5">
                    Client property shortlist
                  </h1>
                </div>

                <div className="w-full flex flex-col flex-start md:items-center gap-1 p-4 shadow-md bg-white rounded-md mb-3 ">
                  <div className="w-full xl:w-full ">
                    <div className="w-full flex flex-wrap flex-col sm:flex-row md:flex-row justify-center">
                      <div className="w-full sm:w-1/2 md:w-1/4  px-2 mb-2 mx-auto">
                        <FilterSelect
                          name="agency"
                          label="Agency"
                          options={["All", ...Object.keys(agency)]}
                          value={selectedOptionForTable["agency"]}
                          className="bg-white text-black"
                          handleChange={handleSelectChange}
                        />
                      </div>

                      <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                        <FilterSelect
                          name="visibility"
                          label="Visibility"
                          options={["All", ...Object.keys(visibility)]}
                          value={selectedOptionForTable.visibility}
                          className="bg-white text-black"
                          handleChange={handleSelectChange}
                        />
                      </div>

                      <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                        <FilterSelect
                          name="location"
                          label="Location"
                          options={["All", ...Object.keys(location)]}
                          value={selectedOptionForTable["location"]}
                          className="bg-white text-black"
                          handleChange={handleSelectChange}
                        />
                      </div>

                      <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-2 mx-auto">
                        <FilterSelect
                          name="rooms"
                          label="Rooms"
                          options={["All", ...Object.keys(rooms)]}
                          value={selectedOptionForTable["rooms"]}
                          className="bg-white text-black"
                          handleChange={handleSelectChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-full lg:w-full xl:w-full max-sm:gap-2 flex flex-wrap flex-col sm:flex-row md:flex-row md:items-center sm:flex-start md:justify-between px-1 md:px-2">
                    <div className="flex w-full flex-wrap sm:w-[100%] md:w-[50%] lg:w-[50%] xl:w-1/2 sm:pl-2 md:pl-0 justify-center">
                      <Slider
                        name="price"
                        label="Price of property"
                        value={selectedOptionForTable["price"]}
                        onChange={handlePriceChange}
                      />
                    </div>
                    <div className="flex flex-grow  sm:w-[100%] md:w-[100%] lg:w-[40%] xl:w-1/2 sm:pl-2">
                      <SearchBar onSearch={handleSearch} />
                    </div>
                  </div>
                </div>

                <div className="flex mt-4">
                  <DataTable
                    rows={filterDigitalTwinRows}
                    handleSelectionChange={handleSelectionChange}
                  />
                </div>

                <div className=" mt-4 flex items-center justify-center flex-col  sm:flex-row gap-3 w-full  ">
                  <div
                    className="flex w-1/3 cursor-pointer text-white items-center justify-center  bg-[#283BBE] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] "
                    onClick={() => {
                      handleCreateCustomer();
                    }}
                  >
                    {/* {`Save & Send via Email `} */}
                    {` Save `}
                  </div>

                  {/* <div
                    className="flex w-1/3 cursor-pointer items-center justify-center text-black  border border-[#20B038] bg-[#20B038] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]  "
                    onClick={() => {
                      // handleCreateCustomer()
                      console.log("clikced");
                    }}
                  >
                    Save & Send via Whatsapp
                  </div> */}

                  {isClientCreatedSuccess && (
                    <div className="w-1/3 flex items-center justify-center flex-col  sm:flex-row gap-3 ">
                      <div className="flex cursor-pointer text-white items-center justify-center border border-[##008C27]  bg-[#31B957] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
                        {`Client created successfully `}
                      </div>
                    </div>
                  )}
                  {!isClientCreatedFailed && (
                    <div className="w-1/3 flex items-center justify-center flex-col  sm:flex-row gap-3   ">
                      <div className="flex cursor-pointer text-white items-center justify-center border border-[#6D0000]  bg-[#EC0000] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
                        {`Failed... , Please Retry! `}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};
