import * as React from "react";
// import Box from '@mui/material/Box';
import Drawer from "@mui/material/Drawer";
// import Button from '@mui/material/Button';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { NavLink, useMatch } from "react-router-dom";
import { FaListUl, FaRegUserCircle } from "react-icons/fa";
import { PiShareNetworkDuotone } from "react-icons/pi";
import { TbUsers } from "react-icons/tb";
import { TfiWallet } from "react-icons/tfi";
import { GiProtectionGlasses } from "react-icons/gi";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import UserService from "rc-app/services/UserService";

import Button from "../Button";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MobileDrawer({ open, setOpen }: any) {
  //   const [open, setOpen] = React.useState(false);

  //   const toggleDrawer = (newOpen: boolean) => () => {
  //     setOpen(newOpen);
  //   };

  // const DrawerList = (
  //   <Box sx={{ width: 240 }} role="presentation" onClick={()=>setOpen(!open)}>
  //     <List>
  //       {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton>
  //             <ListItemIcon>
  //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //     <Divider />
  //     <List>
  //       {['All mail', 'Trash', 'Spam'].map((text, index) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton>
  //             <ListItemIcon>
  //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // );

  const Agency = useMatch("/agency_list") || useMatch("/");
  const Network = useMatch("/network_list");
  const Clients = useMatch("/clients");
  const Earnings = useMatch("/earnings");
  const Virtual = useMatch("/vr_tour_list");

  const MyAgent = useMatch("/my_agent");
  const PropertiesShortlist = useMatch("/properties_shortlist");
  const JoinVrTour = useMatch("/join_vr_tour");

  const Profile = useMatch("/profile");

  

  const ProductFeatures = useMatch("/product_features")
  const Pricing = useMatch("/pricing");
  const SellWithUs = useMatch("/sell_with_us");
  const AboutUS = useMatch("/about_us");

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const path = url.pathname;

  const DrawerList = (
    <nav className=" w-[240px] ">
      {UserService.isLoggedIn() && (
        <ul className="flex flex-col gap-2">
          {UserService.hasRole("agent") && (
            <>
              <li
                className={`flex items-center ${
                  Agency
                    ? "border-l-[4px] border-[#283BBE]  bg-[#E3E7FF] text-[#283BBE] font-semibold"
                    : ""
                }`}
              >
                <NavLink
                  to="/agency_list"
                  className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
                >
                  <FaListUl /> My Agency Listings
                </NavLink>
              </li>
              <li
                className={`flex items-center ${
                  Network
                    ? "border-l-[4px] border-[#283BBE]  bg-[#E3E7FF] text-[#283BBE] font-semibold"
                    : ""
                }`}
              >
                <NavLink
                  to="/network_list"
                  className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
                >
                  <PiShareNetworkDuotone /> Network Listings
                </NavLink>
              </li>
              <li
                className={`flex items-center ${
                  Clients
                    ? "border-l-[4px] border-[#283BBE]  bg-[#E3E7FF] text-[#283BBE] font-semibold"
                    : ""
                }`}
              >
                <NavLink
                  to="/clients"
                  className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
                >
                  <TbUsers /> My Clients
                </NavLink>
              </li>
              <li
                className={`flex items-center ${
                  Earnings
                    ? "border-l-[4px] border-[#283BBE]  bg-[#E3E7FF] text-[#283BBE] font-semibold"
                    : ""
                }`}
              >
                <NavLink
                  to="/earnings"
                  className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
                >
                  <TfiWallet /> My Earnings
                </NavLink>
              </li>
              <li
                className={`flex items-center ${
                  Virtual
                    ? "border-l-[4px] border-[#283BBE]  bg-[#E3E7FF] text-[#283BBE] font-semibold"
                    : ""
                }`}
              >
                <NavLink
                  to="/vr_tour_list"
                  className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
                >
                  <GiProtectionGlasses /> My Virtual Tours
                </NavLink>
              </li>
            </>
          )}

          {UserService.hasRole("customer") && (
            <>
              
              <li
                className={`flex items-center ${
                  PropertiesShortlist
                    ? "border-l-[4px] border-[#283BBE]  bg-[#E3E7FF] text-[#283BBE] font-semibold"
                    : ""
                }`}
              >
                <NavLink
                  to="/properties_shortlist"
                  className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
                >
                  <HomeOutlinedIcon /> Properties Shortlist
                </NavLink>
              </li>
              <li
                className={`flex items-center ${
                  JoinVrTour
                    ? "border-l-[4px] border-[#283BBE]  bg-[#E3E7FF] text-[#283BBE] font-semibold"
                    : ""
                }`}
              >
                <NavLink
                  to="/join_vr_tour"
                  className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
                >
                  <PhotoCameraFrontIcon /> Join VR Tour
                </NavLink>
              </li>
              <li
                className={`flex items-center ${
                  MyAgent
                    ? "border-l-[4px] border-[#283BBE]  bg-[#E3E7FF] text-[#283BBE] font-semibold"
                    : ""
                }`}
              >
                <NavLink
                  to="/my_agent"
                  className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
                >
                  <SupportAgentIcon /> My Agent
                </NavLink>
              </li>
            </>
          )}
          <>
          <li
            className={`flex items-center ${
              Profile
                ? "border-l-[4px] border-[#283BBE]  bg-[#E3E7FF] text-[#283BBE] font-semibold"
                : ""
            }`}
          >
            <NavLink
              to="/profile"
              className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
            >
              <FaRegUserCircle /> My Profile
            </NavLink>
          </li>

          </>
          
        </ul>
      )}
      {!UserService.isLoggedIn() && (
        <ul className="flex flex-col gap-2">
          <li
            className={`flex items-center ${
              ProductFeatures || path === "/"
                ? "text-[#FF307A] font-semibold"
                : "text-white"
            }`}
          >
            <NavLink
              to="/product_features"
              className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
            >
              Product Features
            </NavLink>
          </li>
          <li
            className={`flex items-center ${
              Pricing ? " text-[#FF307A] font-semibold" : "text-white"
            }`}
          >
            <NavLink
              to="#"
              className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
              onClick={(e) => {
                e.preventDefault(); // Prevent default NavLink behavior
                window.open('https://italia.1verse.live/pages/pricing-1', '_blank', 'noopener noreferrer');
              }}
            >
              Pricing
            </NavLink>
          </li>
          <li
            className={`flex items-center ${
              SellWithUs ? "text-[#FF307A] font-semibold" : "text-white"
            }`}
          >
            <NavLink
              to="/sell_with_us"
              className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
            >
              Sell With Us
            </NavLink>
          </li>
          <li
            className={`flex items-center ${
              AboutUS ? "text-[#FF307A] font-semibold" : "text-white"
            }`}
          >
            <NavLink
              to="/#"
              className="flex items-center gap-4 py-2 pl-5 pr-3 w-full"
              onClick={(e) => {
                e.preventDefault(); // Prevent default NavLink behavior
                window.open('https://italia.1verse.live/', '_blank', 'noopener noreferrer');
              }}
            >
              About US
            </NavLink>
          </li>

          <div className="w-full h-full flex pl-5">
            <Button
              children="Login"
              className="bg-[#FF307A] h-full font-semibold px-4 rounded-sm"
              onClick={() => UserService.doLogin()}
            />
          </div>
          <li className="flex items-center"></li>
        </ul>
      )}
    </nav>
  );

  return (
    <div className="">
      {/* <Button onClick={toggleDrawer(true)}>Open drawer</Button> */}
      <Drawer
        open={open}
        onClose={() => setOpen(!open)}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: !UserService.isLoggedIn() ? "#000524" : "#ffffff",
          },
        }}
      >
        <DrawerHeader sx={{ paddingY: ".8rem" }}>
          <div className="h-[8vh]  flex items-center justify-center">
            <Link to="/" className="flex items-center">
              {/* display on large and md */}
              <img
                alt="1verse"
                src="https://storage.googleapis.com/oneverse-dev/realestate/1verse_logo_pink.png"
                className="h-12 w-36 flex  "
              />
              {/* <h5 className=" text-[#1B191B] text-2xl font-bold flex">
                1verse
              </h5> */}
              {/* <img
                    alt="1verse"
                    src="https://storage.googleapis.com/oneverse-dev/realestate/1verse-logo-2.png"
                    className="h-12 w-12 flex md:hidden "
                  />
                  <h5 className=" text-[#1B191B] text-2xl font-bold flex md:hidden">
                    1verse
                  </h5> */}
            </Link>
          </div>
        </DrawerHeader>
        {DrawerList}
      </Drawer>
    </div>
  );
}
