import React, { useState, useEffect } from "react";
import axios from "axios";
import UserService from "rc-app/services/UserService";

// import { Box } from "@mui/material";
import MiniDrawer from "rc-app/components/MiniDrawer";

import Button from "rc-app/components/Button";
// import { ComingSoon } from "rc-app/components/ComingSoon";
import { getAgentById } from "../Home";
import { Messages } from "rc-app/components/Messages";
import AgentCard from "rc-app/components/Card/AgentCard";

export const MyAgent = () => {
  const [customer, setCustomer] = useState({});
  const [agentId, setAgentId] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  console.log("agentEmail", agentEmail);
  const [customerAgent, setCustomerAgent] = useState({
    first_name: "",
    last_name: "",
    _id: "",
    agent_id: "",
    email_id: "",
    country_code: "",
    mobile_number: "",
    gender: "",
    date_of_birth: "",
    agency: {},
    profile_pic: "",
    subscription_type: "",
    address: {},
    property_updates: "",
    joined_date: "",
    locations_served: [],
  });

  async function getCustomerByEmail(email: any) {
    try {
      const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_customer/profile/${email}`;
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          "Content-Type": "application/json",
        },
      });
      setAgentId(response.data.customer.agent_id[0]);
      getAgentById(response.data.customer.agent_id[0]).then((res) => {
        setAgentEmail(res.agent.email_id);
        setCustomerAgent(res.agent);
      });
      setCustomer(response.data.customer);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  }
  useEffect(() => {
    getCustomerByEmail(UserService.getUseremail());
  }, []);
  const [activeTab, setActiveTab] = useState("myAgent");
  console.log("customermm", customer);

  return (
    <>
      <div className="container max-w-screen-2xl flex bg-[#F6F6F8] min-h-screen overflow-y-auto">
        <div>
          <MiniDrawer />
        </div>
        <div className="flex-grow pt-[5rem] md:pt-[5rem] lg:pt-[6rem] px-2 lg:px-3">
          {/* // all the page content here */}

          <div className="w-full mt-5 flex items-center md:gap-5  bg-[#F6F6F8] rounded-md">
            <div className="flex flex-col w-full  flex-grow ">
              <div className="flex flex-row w-full  flex-grow gap-3">
                <div
                  className={`flex items-center justify-center px-2 py-2 w-1/4 shadow-md rounded-t-lg ${
                    activeTab === "myAgent"
                      ? "bg-[#283BBE] text-white"
                      : "text-[#283BBE] bg-white"
                  }`}
                >
                  <Button
                    onClick={() => {
                      setActiveTab("myAgent");
                    }}
                    className={` text-[13px] sm:text-[12px] md:text-[13px] lg:text-[15px] xl:text-[16px] rounded-[3px]  `}
                    textColor="white"
                  >
                    My Agent
                  </Button>
                </div>

                <div
                  className={`flex items-center justify-center px-2 py-2 w-1/4 shadow-md rounded-t-lg ${
                    activeTab === "agentMessages"
                      ? "bg-[#283BBE] text-white "
                      : "text-[#283BBE]  bg-white"
                  }`}
                >
                  <Button
                    onClick={() => {
                      setActiveTab("agentMessages");
                    }}
                    className={` text-[13px] sm:text-[12px] md:text-[13px] lg:text-[15px] xl:text-[16px] rounded-[3px]   `}
                    textColor="white"
                  >
                    Agent Messages
                  </Button>
                </div>
              </div>

              <div className="w-full md:w-auto rounded-l  bg-white">
                {activeTab === "myAgent" && (
                  <div className="w-full shadow-md py-3 px-3 ">
                    {/* <div className="flex justify-end">
                        <Button
                          children={`+ Add property to shortlist`}
                          className="ml-auto bg-[#FF307A]  rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] "
                          onClick={handleOpen}
                        />
                      </div> */}
                    <div className="grid gap-3 grid-cols-1">
                      <AgentCard
                        title={customerAgent.first_name + " " + customerAgent.last_name}
                        agent_id={customerAgent.agent_id}
                        data={[
                          {
                            value:
                              customerAgent.email_id !== null
                                ? customerAgent.email_id
                                : "Not Available",
                            icon: "Email",
                          },
                          {
                            value:
                              customerAgent.mobile_number !== null
                                ? customerAgent.mobile_number
                                : "Not Available",
                            icon: "Phone Number",
                          },
                          {
                            value:
                              customerAgent.gender &&
                              customerAgent.gender !== null
                                ? customerAgent.gender
                                : "Not Available",
                            icon: "Gender",
                          },
                          {
                            value:
                              customerAgent.date_of_birth &&
                              customerAgent.date_of_birth !== null
                                ? customerAgent.date_of_birth
                                : "Not Available",
                            icon: "Date Of Birth",
                          },
                          {
                            value:
                              customerAgent.subscription_type &&
                              customerAgent.subscription_type !== null
                                ? customerAgent.subscription_type
                                : "Not Available",
                            icon: "Subscription Type",
                          },
                          { value: "MM/DD/YYYY", icon: "Client Last Login" },
                          {
                            value:
                              customerAgent &&
                              customerAgent.joined_date !== null
                                ? customerAgent.joined_date
                                : "Not Available",
                            icon: "Agent Joined Date",
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}

                {/* {activeTab === "clientPurchases" && (
                  <div className="w-full shadow-md py-1 px-3">
                    <div className="w-full mt-3 flex flex-col items-center md:gap-5 p-1 shadow-md bg-white rounded-md"></div>
                    <div className="w-full mt-3 flex flex-col items-center md:gap-5 p-1 shadow-md bg-white rounded-md"></div>
                  </div>
                )} */}

                {activeTab === "agentMessages" && (
                  <div>
                    <Messages
                      customer={customer}
                      agentId={agentId}
                      customerAgent={customerAgent}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
