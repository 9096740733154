import React, {
    useEffect, useState
} from "react"

import {
    Typography, Grid, Paper, Box, TextField,
    InputBase, IconButton, Slider,
    Container, CircularProgress, Divider
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
// import Footer from 'rc-app/components/Footer/footer'
import { getAgencyByAgentId, getProperties } from "../Home";
import UserService from "rc-app/services/UserService";

// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

export const VrShowcase = () => {
    const [loading, setLoading] = useState(true);
    const [showcaseProperties, setShowcaseProperties] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [budget, setBudget] = useState([0, 1000000]);

    useEffect(() => {
        const { email } = UserService.getTokenParsed()

        getAgencyByAgentId(email).then(res => {
            console.log('aaaa',res);
            setLoading(false);
        })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        getProperties().then(res => {
            console.log('zzz', res);
            setLoading(false);
            setShowcaseProperties(res.mp_digital_twin_list)
            setFilteredProperties(res.mp_digital_twin_list)
        }).catch(err => console.log(err))
    }, []);

    const handlePropertyClick = (index: number) => {
        console.log(`Property clicked: ${showcaseProperties[index].title}`);
    };


    const handleSearch = () => {
        const searchTermLowerCase = searchTerm.toLowerCase();
        const filtered = showcaseProperties && showcaseProperties.filter(
            (item) =>
                item.title.toLowerCase().includes(searchTermLowerCase)
                ||
                item.matterport_id.toString().includes(searchTermLowerCase)
                ||
                (item.num_bathrooms &&
                    item.num_bathrooms.toString().includes(searchTermLowerCase))
            // ||
            // (item.num_bathrooms &&
            //     item.num_bathrooms.toString().includes(searchTermLowerCase))) &&
            // (selectedCategory === '' || item.num_bathrooms === selectedCategory)
            // ||
            // item.description.toLowerCase().includes(searchTermLowerCase)
        );
        setFilteredProperties(filtered);
    };

    useEffect(() => {
        const timerId = setTimeout(() => {
            handleSearch();
        }, 300); 
        return () => clearTimeout(timerId);
    }, [searchTerm]);

    const handleBudgetChange = (event: any, newValue: any) => {
        console.log(event);
        setBudget(newValue);
    };


    return (
        <>
            <Box sx={{ height: '100vh', overflow: "scroll", pb: 6, mb: 2 }}>
                <Container sx={{ flexGrow: 1 }}  >
                    <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', height: '100%', minHeight: '80vh', mt: 2, mb: 2 }}>
                        <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-end' }} >
                            <Grid item xs={12} md={6} lg={6}>
                                <Box mt={2} sx={{ mt: 2, width: '80%' }}>
                                    <Typography variant="h5">Budget Range</Typography>
                                    <Box>
                                        <Slider
                                            value={budget}
                                            onChange={handleBudgetChange}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={1000000}
                                            sx={{ width: '75%' }}
                                        />
                                    </Box>
                                    <Box >
                                        <TextField
                                            label="Min Budget"
                                            value={budget[0]}
                                            onChange={(e) => setBudget([+e.target.value, budget[1]])}
                                            disabled
                                            sx={{ width: '80%', margin: 1 }}
                                        />
                                        {/* </Box>
                                    <Box> */}
                                        <TextField
                                            label="Max Budget"
                                            value={budget[1]}
                                            onChange={(e) => setBudget([budget[0], +e.target.value])}
                                            disabled
                                            sx={{ width: '80%', margin: 1 }}
                                        />
                                    </Box>

                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} >
                                <Typography>Map View Section</Typography>

                                {/* <MapContainer
                                    center={center}
                                    zoom={zoom}
                                    style={{ height: '400px', width: '100%' }}
                                >
                                    <TileLayer
                                         url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
                                         id="mapbox/streets-v11"
                                         accessToken="YOUR_MAPBOX_ACCESS_TOKEN"
                                    />
                                    {markers.map((marker) => (
                                        <Marker key={marker.id} position={[marker.lat, marker.lon]}>
                                            <Popup>{marker.propertyDetails}</Popup>
                                        </Marker>
                                    ))}
                                </MapContainer> */}

                            </Grid>
                        </Grid>


                        <Divider sx={{margin:2}}/>

                        <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-end', mt:2 }} >
                            <Grid item xs={12} md={8} lg={8}>
                                <Typography variant="h4" >
                                    Showcase Properties
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3} >
                                <InputBase
                                    placeholder="Search by title..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    sx={{ ml: 1, flex: 1 }}
                                />
                                <IconButton
                                    onClick={handleSearch}
                                    size="small"
                                    sx={{ p: '10px' }}
                                >

                                    <SearchIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {
                            loading ? (
                                <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                <Grid container spacing={3} style={{ marginTop: '20px' }}>
                                    {

                                        (filteredProperties && filteredProperties.length > 0) ? (
                                            filteredProperties.map((property, index) => (
                                                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>

                                                    <div onClick={() => handlePropertyClick(index)} style={{ cursor: 'pointer' }}>
                                                        <img
                                                            src={property.property_thumbnail}
                                                            alt={property.title}
                                                            style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                                        />
                                                        <Typography variant="body1" style={{ marginTop: '10px' }}>
                                                            {property.title}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Grid item xs={12} sm={6} md={4} lg={3} >
                                                <Typography variant="body1" style={{ padding: '10px', textAlign: 'center' }}>Not found</Typography>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            )
                        }
                    </Paper>
                </Container>
                {/* <Footer darkMode={true} /> */}
            </Box>
        </>
    )
}