import React, {
    useEffect, useState
} from "react"

import {
    Typography, Grid, Paper, Box, InputBase,
    IconButton, Container,
    CircularProgress, Button,
    Select, MenuItem
    // TextField
    // LinearProgress
    //  FormControl, InputLabel,  Select, MenuItem,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

// import Footer from 'rc-app/components/Footer/footer'
import CardComponent from "rc-app/components/CardComponent/CardComponent";
import {
    // eventUpdateStream, getAgentByEmail,
    getProperties
} from "../Home";
// import OnlineStatusChecker from "rc-app/components/OnlineStatusChecker";
// import UserService from "rc-app/services/UserService";

// import UserService from "rc-app/services/UserService";

import { IntlProvider, FormattedMessage } from 'react-intl';
import translations, { Translations } from "rc-app/services/Translations";
// import DrawerMenu from "rc-app/components/DrawerMenu";

export const Properties = () => {
    const [digitalTwin, setDigitalTwin] = useState([])
    const [filteredDigitalTwin, setFilteredDigitalTwin] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);

    // const [selectedCategory, setSelectedCategory] = useState('');
    // const [progress, setProgress] = useState(0);



    // const getProperties1 = () => {
    //     const API_URL = 'https://realestate.dev.1verse.live/api/v1/realestate/views/mp_digital_twin?sort_by=created_at&sort_order=-1&page=0&limit=20'
    //     axios.get(API_URL, {
    //         headers: {
    //             'Authorization': `Bearer ${UserService.getToken()}`,
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //         .then((res) => {
    //             // const temp = res.data.mp_digital_twin_list.map((digital_twin: any) => digital_twin.matterport_id)
    //             setDigitalTwin(res.data.mp_digital_twin_list)
    //             setFilteredDigitalTwin(res.data.mp_digital_twin_list);
    //             setLoading(false);
    //             // setProgress(100);
    //             // console.log(res, 'temp');
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching data:', error);
    //             setLoading(false);
    //         });
    // }

    useEffect(() => {
        // getProperties1();
        getProperties().then(res => {
            setDigitalTwin(res.mp_digital_twin_list);
            setFilteredDigitalTwin(res.mp_digital_twin_list);
            setLoading(false);
        })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });

    }, []);

    console.log(searchTerm, 'searchTerm');
    const handleSearch = () => {
        const searchTermLowerCase = searchTerm.toLowerCase();
        const filtered = digitalTwin.filter(
            (item) =>
                item.title.toLowerCase().includes(searchTermLowerCase)
                ||
                item.matterport_id.toString().includes(searchTermLowerCase)
                ||
                (item.num_bathrooms &&
                    item.num_bathrooms.toString().includes(searchTermLowerCase))

            // ||
            // (item.num_bathrooms &&
            //     item.num_bathrooms.toString().includes(searchTermLowerCase))) &&
            // (selectedCategory === '' || item.num_bathrooms === selectedCategory)
            // ||
            // item.description.toLowerCase().includes(searchTermLowerCase)

        );
        setFilteredDigitalTwin(filtered);
        console.log('digifilt',filtered);
        
    };

    useEffect(() => {
        const timerId = setTimeout(() => {
            handleSearch();
        }, 300); // Adjust the debounce time as needed

        return () => clearTimeout(timerId);
    }, [searchTerm, {/* selectedCategory*/ }]);


    // const [agentId, setAgentId] = React.useState()
    // const [login,] = React.useState(UserService.isLoggedIn())
    // const { email } = UserService.getTokenParsed()


    // React.useEffect(() => {

    //     getAgentByEmail(email).then(res => {
    //         const agent = res.agent
    //         // setAgentId(agent._id)
    //         eventUpdateStream('RELogin', agent._id, email, 'user_login', login).then(res => {
    //             console.log("eventStream", res);
    //         }).catch(err => console.log(err))
    //     }).catch(err => console.log(err))
    // }, [])

    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        // OnlineStatyusChecker(isOnline, setIsOnline)
        const handleOnline = () => {
            setIsOnline(true);
        };

        const handleOffline = () => {
            setIsOnline(false);
        };

        // Add event listeners
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup: Remove event listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);
    const handleManageProperty = () => {
        const googleFormUrl = 'https://docs.google.com/forms/d/e/...';
        // Open the Google Form in a new tab
        window.open(googleFormUrl, '_blank');
    }

    const [currentLocale, setCurrentLocale] = useState<keyof Translations>('en')

    // const handleLanguageChange = (newLocale:any) => {
    //     setCurrentLocale(newLocale);
    // };
    const handleLanguageChange = (event: any) => {
        setCurrentLocale(event.target.value);
    };
    console.log("language", navigator.language.split('-')[0]);

    //   useEffect(()=>{
    //     fetch('https://ipinfo.io/json').then(res=>{
    //         console.log('countryCode',res);
    //     })
    //   },[])
    // console.log('_kc1', process.env)


    return (
        <>
            {/* <Box sx={{ display: 'flex' }}>
                <div><DrawerMenu /></div>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    
                </Box>
            </Box> */}
            <IntlProvider locale={currentLocale} messages={translations[currentLocale]}>
                        {isOnline && (
                            <Box sx={{ height: '100vh', overflow: "scroll", pb: 6, mb: 2 }}>
                                <div style={{
                                    position: 'fixed',
                                    top: '10px',
                                    right: '80px',
                                    zIndex: 1000,
                                }}>
                                    <Select value={currentLocale} onChange={handleLanguageChange}
                                        sx={{
                                            backgroundColor: 'gray', // Change the background color when closed
                                        }}
                                    >
                                        <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="es">Español</MenuItem>
                                        <MenuItem value="it">Italiano</MenuItem>
                                    </Select>
                                </div>
                                <Container sx={{ flexGrow: 1 }}  >
                                    <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', height: '100%', minHeight: '80vh', mt: 2, mb: 2 }} >
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item xs={12}>
                                                <Typography variant="h4" align="center">
                                                    {/* My Properties */}
                                                    <FormattedMessage id="properties.title" defaultMessage="" />
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                                                <InputBase
                                                    placeholder="Search by title..."
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    sx={{ ml: 1, flex: 1 }}
                                                />
                                                <IconButton
                                                    onClick={handleSearch}
                                                    size="small"
                                                    sx={{ p: '10px' }}
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </Grid>
                                            {
                                                loading ? (
                                                    <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                                                        <CircularProgress />
                                                    </Grid>
                                                    //     <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                                                    //     <LinearProgress variant="determinate" value={progress} />
                                                    //     <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                                    //       Loading... {progress}%
                                                    //     </Typography>
                                                    //   </Grid>
                                                ) : (
                                                    filteredDigitalTwin && filteredDigitalTwin.map((item, index) => (
                                                        <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                                                            <CardComponent
                                                                matterport_id={item.matterport_id}
                                                                index={index}
                                                                digitalTwinId={item._id}
                                                                title={item.title}
                                                                description={item.description}
                                                                thumbnail={item.property_thumbnail}
                                                            />
                                                        </Grid>
                                                    ))
                                                )}
                                        </Grid>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                border: '2px solid',
                                                borderColor: 'divider',
                                                borderRadius: 2,
                                                bgcolor: 'background.paper',
                                                color: 'text.secondary',
                                                '& svg': {
                                                    m: 1,
                                                },
                                                paddingTop: 1,
                                                paddingBottom: 1,
                                                marginTop: '20px',
                                                // textAlign: 'center'

                                            }}
                                        >
                                            <Grid container spacing={3} style={{ textAlign: 'center', padding: '5px' }} >
                                                {/* <Paper elevation={0} style={{  textAlign: 'center' }}> */}
                                                <Grid item xs={6} >
                                                    <Typography variant="h5">
                                                        {/* You can easily add a */}
                                                        <FormattedMessage id="properties.addText1" defaultMessage="" />
                                                    </Typography>
                                                    <Typography variant="h5">
                                                        {/* new property by  */}
                                                        <FormattedMessage id="properties.addText2" defaultMessage="" />
                                                    </Typography>
                                                    <Typography variant="h5">
                                                        {/* clicking here! */}
                                                        <FormattedMessage id="properties.addText3" defaultMessage="" />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <Button variant="contained" onClick={handleManageProperty}  >
                                                            {/* Add New Space */}
                                                            <FormattedMessage id="properties.addButton" defaultMessage="" />
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                {/* </Paper> */}
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Container>
                                {/* <Footer darkMode={true} /> */}
                            </Box>
                        )}

                        {!isOnline && (
                            <p>
                                {/* No Internet Connection */}
                                <FormattedMessage id="properties.noInternet" />
                            </p>

                        )}

                    </IntlProvider>

            {/* <OnlineStatusChecker> */}

        </>
    )
}