import React, { useState } from 'react'
import AgoraUIKit, { PropsInterface, StylePropInterface } from 'agora-react-uikit'
import { eventUpdateStream, getAgentByEmail } from 'rc-app/Pages/Home';
import UserService from 'rc-app/services/UserService';
// import { useLocation } from 'react-router-dom';

const AgoraIOVideoCall: React.FunctionComponent<{appId:string; channel:string; token:string, userId:string}> = (props) =>  {
  const [videocall, setVideocall] = useState(true)

  /*
  const props: PropsInterface = {
    rtcProps: {
      appId: 'd09f53486e9d4de9b1c0c0321ca0d5d9',
      channel: 'testvideo',
      token: "007eJxTYBCMTDfq42G2n3dOxlJ8SZG8wutv3AFOTD/bD87/Xj9jpbYCQ4qBZZqpsYmFWapliklKqmWSYbJBsoGxkWFyokGKaYql0onjKQ2BjAzL73azMDJAIIjPyVCSWlxSlpmSms/AAAAmrSAt", // pass in channel token if the app is in secure mode
    },
    callbacks: {
      EndCall: () => setVideocall(false)
    }
  }
  */
  const {email } = UserService.getTokenParsed()
  const agora_props: PropsInterface = {
    rtcProps: {
      appId: props.appId,
      channel: props.channel,
      token: props.token,
      uid: 0
    },
    callbacks: {
      
      EndCall: () => {
        setVideocall(false)
        getAgentByEmail(email).then((data) => {
          const agent = data.agent
          eventUpdateStream('ReAgent' , agent['_id'], email, 'leave_agora', 'true')     
        })
      }
    }
  }
  console.log('agora22',props.token);
  // const location = useLocation()
  // const queryParams = new URLSearchParams(location.search);
  // const channel = queryParams.get('channel');
  // console.log("channel", channel);
  // console.log("queryParams", queryParams);

  const styleProps: StylePropInterface = {
    videoMode: {
      max: 'cover', // You can experiment with different values like 'cover', 'contain', etc.
      min: 'contain', // You can experiment with different values like 'cover', 'contain', etc.
    },
    theme: '#4285F4', // Google's blue color as an example
    iconSize: 24, // Adjust the icon size based on your design preference
    // customIcon: {
    //   // Customize icons as needed
    //   endCall: {
    //     color: '#FF0000', // Red color for the end call icon
    //     size: 28, // Adjust the size of the end call icon
    //   },
    //   // Add more customizations for other icons if needed
    // },
    BtnTemplateStyles: {
      backgroundColor: '#4285F4', // Google's blue color as an example
      color: '#FFFFFF', // White color for text
      borderRadius: 8, // Adjust the border radius for button corners
    },
    maxViewStyles: {
      // Style for the big view in pinned layout
      border: '2px solid #4285F4', // Add a border to the big view
      borderRadius: 12, // Adjust the border radius for corners
    },
    minViewStyles: {
      // Style for the small view in pinned layout
      border: '2px solid #4285F4', // Add a border to the small view
      borderRadius: 8, // Adjust the border radius for corners
    },
    minViewContainer: {
      // Style for the small view container
      padding: 8, // Add padding to the small view container
    },
    maxViewContainer: {
      // Style for the big view container
      padding: 16, // Add padding to the big view container
    },
    // minViewOverlayContainer: {
    //   // Style for the overlay on small user view when pressed in pinned layout
    //   backgroundColor: 'rgba(66, 133, 244, 0.5)', // Semi-transparent blue overlay
    // },
    maxViewOverlayContainer: {
      // Style for the overlay on big user view when pressed in pinned layout
      backgroundColor: 'rgba(66, 133, 244, 0.5)', // Semi-transparent blue overlay
    },

    remoteBtnContainer: {
      // Style for the remote button container
      margin: 8, // Add margin to the remote button container
    },

    localBtnContainer: {
      // Style for the local button container
      margin: 8, // Add margin to the local button container
    },
    gridVideoCells: {
      // Style for the individual cell (view) containing the video in the grid layout
      border: '2px solid #4285F4', // Add a border to the grid video cells
      borderRadius: 8, // Adjust the border radius for corners
    },
    gridVideoContainer: {
      // Style for the grid container
      padding: 2, // Add padding to the grid container
    },
    pinnedVideoContainer: {
      // Style for the pinned video container
      padding: 2, // Add padding to the pinned video container
    },
    scrollViewContainer: {
      // Style for the pinned scrollview container
      padding: 2, // Add padding to the pinned scrollview container
    },
    UIKitContainer: {
      // Style for the global view containing the UI Kit
      backgroundColor: '#F0F0F0', // Light gray background color
      padding: 2, // Add padding to the UI Kit container
    },
    popUpContainer: {
      // Style for the pop up container for remote mute requests
      backgroundColor: 'red', // White background color
      borderRadius: 2, // Adjust the border radius for corners
      padding: 2, // Add padding to the pop up container
    },
    usernameText: {
      // Style for the displayed username text container
      color: '#333333', // Dark gray text color
      fontSize: 2, // Adjust the font size for the username text
    },
  };



  const handleJoinVideo=()=>{
    console.log("handlejoinvideo");
    setVideocall(true)    
    getAgentByEmail(email).then((data) => {
      const agent = data.agent
      eventUpdateStream('ReAgent', agent['_id'], email, 'join_agora', 'true')     
    })
  }
  
  return (
    <div className='video_call_box m-2' style={styles.agora}>
      {videocall ? (
        <AgoraUIKit
          rtcProps={agora_props.rtcProps}
          callbacks={agora_props.callbacks}
          styleProps={styleProps} />
      ) : (
        <div onClick={handleJoinVideo} 
          className='w-full bg-black text-white p-1 text-xs flex justify-center items-center '
          // style={{  position: 'fixed', top: '15vh', right: '3vh' }}
        >Join Video</div>
      )}
    </div>
  )
}

const styles = {
  agora: { 
    width: '20vw', height: '30vh', 
      display: 'flex',  flex: 1,
      top: '10vh',
      right: '20vh',
  },
  joinNow:{
    position: 'fixed',
    top: '10vh',
    right: '3vh',
  },
  
}

export default AgoraIOVideoCall