import React, { Component } from 'react';

import {
  // Typography, Button, Box, Grid,

} from '@mui/material';

import { Mode } from '../types';
// import RenderOnRole from './RenderOnRole';
// import UserService from 'rc-app/services/UserService';
import { eventUpdateStream, getAgentByEmail } from 'rc-app/Pages/Home';

interface Props {
  onSelected?: (mode: Mode) => void;
  joinCode:string
  email: string
  agentId: string
}

interface State {
  mode: string | null;
}

export class SelectMode extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    
    // this.state = {};
    this.state = {
      mode: '',
      // channel: ''
    };
  }
  
  
  // private email: string = UserService.getTokenParsed().email ;
  async componentDidMount() {
    // The code inside this block will run when the component mounts
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    // const channel = urlParams.get('channel');
    this.setState({ mode });


    getAgentByEmail(this.props.email).then(async (data) => {
      const ageentId=(data.agent._id)
      console.log('componentDidMount',);
      await eventUpdateStream('JoinTour', ageentId,  this.props.email, 'role_selection_page', 'true')
    })

    if(mode==='host'){
      this.props.onSelected(Mode.HostMode);
      console.log('moll',this.state.mode);
    }

    if(mode==='client'){
      this.props.onSelected(Mode.ObserverMode);
      console.log('moll',this.state.mode);
    }
    
    
    // if (this.props.onSelected) {
    //   console.log("mol", mode);
    //   this.props.onSelected(mode);
      
    //   // event logging
    //   getAgentByEmail(this.props.email).then(() => {
    //     // const agent = data.agent
    //     // console.log("HostClick111", agent)
    //     eventUpdateStream('VirtualTour', this.props.joinCode, this.props.email, mode==1?'join_as_host':'join_as_client', 'true')
    //   })
    // }
    // const handleRedirect = () => {
      // Check if the page has been redirected
      // const navigationEntries = window.performance.getEntriesByType('navigation');
      // if (navigationEntries.length === 1 && navigationEntries[0].entryType === 'navigate') {
      //   console.log('Page is loaded for the first time');
      // }
      // if (location.pathname !== window.location.pathname) {
      //   console.log('Page redirected');
      // } else{
        
        
      // }
    // };
    // handleRedirect();
    // For example, you can fetch some initial data here
    // this.fetchInitialData();
  }
  private onClick(mode: Mode) {    
    
    if (this.props.onSelected) {
      console.log("mol", mode);
      this.props.onSelected(mode);
      
      // event logging
      getAgentByEmail(this.props.email).then(() => {
        // const agent = data.agent
        // console.log("HostClick111", agent)
        eventUpdateStream('VirtualTour', this.props.joinCode, this.props.email, mode==1?'join_as_host':'join_as_client', 'true')
      })
    }
  }


  
  
  

  render(): JSX.Element {
    return (
      <>
        <div className=" flex flex-col gap-6 w-1/2 rounded-md overflow-auto justify-center bg-white  shadow-md p-4 ">

              <div className='w-full flex flex-col gap-2'>
                <div className={`flex items-center justify-center`} >
                  <h1 className="font-bold text-black text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5">SELECT A ROLE</h1>
                </div>

              </div>
              <div className='w-full flex flex-col gap-3'>
                <div className='flex justify-center gap-2'>
                  <button type="button" className="mr-1 bg-[#FF307A] rounded-md px-2 h-[40px] w-full text-[1rem] text-white"
                    onClick={() => this.onClick(Mode.HostMode)}
                    >
                      Host
                    
                  </button>
                  <button type="button" className="mr-1 bg-[#FF307A] rounded-md px-2 h-[40px] w-full text-[1rem] text-white"
                    onClick={() => this.onClick(Mode.ObserverMode)}
                    >
                      Client
                    
                  </button>
                </div>
                {/* <div className='flex justify-center'>
                  
                </div> */}
              </div>
              
              

            </div>
        {/* <div className='flex'>
          <div>
            <h1> SELECT A ROLE</h1>
          </div>
          <div className='flex w-full  justify-center gap-2'>
              

              <button type="button" className="mr-1 bg-[#FF307A] rounded-md px-2 h-[40px] w-full text-[1rem] text-white"
                onClick={() => this.onClick(Mode.HostMode)}
                >
                Host
                
              </button>

              <button type="button" className="mr-1 bg-[#FF307A] rounded-md px-2 h-[40px] w-full text-[1rem] text-white"
                onClick={() => this.onClick(Mode.ObserverMode)}
                >
                Client
                
              </button>
            </div>

          <div>

          </div>
          

        </div> */}
        {/* <RenderOnRole roles={['agent']}>
          <Box sx={{
            textAlign: 'center',
            mt: '40vh', 
          }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              SELECT A ROLE
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.onClick(Mode.HostMode)}
                >
                  HOST
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.onClick(Mode.ObserverMode)}
                >
                  CLIENT
                </Button>
              </Grid>
            </Grid>
          </Box>
        </RenderOnRole> */}
        {/* <RenderOnRole roles={['customer']}>
          <Box sx={{
            textAlign: 'center',
            mt: '40vh', 
          }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.onClick(Mode.ObserverMode)}
                >
                  START
                </Button>
              </Grid>
            </Grid>
          </Box>
        </RenderOnRole> */}
      </>


      // <div className="select-mode-container">
      //   <div className="select-mode-title">
      //     SELECT A ROLE
      //   </div>
      //   <div>
      //     <button className="select-mode-button" onClick={(e) => this.onClick(Mode.HostMode)}>
      //       HOST
      //     </button>
      //     <button className="select-mode-button" onClick={(e) => this.onClick(Mode.ObserverMode)}>
      //       CLIENT
      //     </button>
      //   </div>
      // </div>
    );
  }
}
