import React, { useState } from "react";
import Button from "../Button";

import FileCopyIcon from '@mui/icons-material/FileCopy';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';

interface PropertyCardLargeProps {
  property_images?: string;
  location?: string;
  contract_type?: string;
  children?: React.ReactNode;
  bedrooms?: number;
  area_sqm?: number;
  type?: string;
  lifestyle?: string;
  listing_date?: string;
  price?: number;
  className?: string;
  isAgencyList?: Boolean;
  property_visibility?: string;
  property_id?: string;
  property_link?: string;
  title?: string;
  description?: string;
  agent_commission?: number
  agency_name?: string;
  digital_twin_id?: string;
  matterport_id?: string;
  isClient: boolean;
  isProperty: boolean;
  client_id?: string;
  isClientDetails?: boolean;
  customer_id?: string;
  data: { value: number | string; icon: React.ReactNode }[];
  handleDetailButton?: (digital_twin_id: string, matterport_id: string) => void
  handleTabButton?: (digital_twin_id: string, matterport_id: string, tab: string) => void
  removeButton?: React.ReactNode;
  detailButton?: React.ReactNode;
}

const PropertyCardLarge: React.FC<PropertyCardLargeProps> = ({
  property_images,
  location,
  contract_type,
  children,
  bedrooms,
  area_sqm,
  type,
  lifestyle,
  listing_date,
  price,
  isAgencyList,
  property_visibility,
  property_id,
  property_link,
  title,
  description,
  agent_commission,
  agency_name,
  digital_twin_id,
  matterport_id,
  handleDetailButton,
  handleTabButton,
  className = "",
  isProperty,
  isClient,
  client_id,
  isClientDetails,
  customer_id,
  data,
  removeButton,
  detailButton,
  ...props
}) => {
  
  const [activeTab, setActiveTab] = useState("digitalTwin");
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);

  const handlePropertyLinkClick = () => {
    console.log("ggh", window.location.origin);
    window.open(property_link, '_blank');
  };

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(window.location.origin + `${property_link}`)
      .then(() => {
        setIsLinkCopied(true);
        setTimeout(() => setIsLinkCopied(false), 3000);
      })
      .catch(error => console.error('Failed to copy property link:', error));
  };

  


  return (
    <div className={` flex  lg:gap-5  flex-col ${isClientDetails && 'lg:flex-row'} xl:flex-row  w-full`} {...props}>
      {
        (isProperty) && (
          <div className={`flex flex-col w-full gap-2 ${isClientDetails && 'lg:w-1/3'} xl:w-1/3 flex-grow `}>
            <div className={`flex items-center justify-between border  border-[#283BBE] rounded-[4px] opacity-1  py-1 px-1${className}`} {...props}>
              <Button onClick={() => {
                setActiveTab("digitalTwin")
                handleTabButton(digital_twin_id, matterport_id, 'digitalTwin')
              }
              }
                className={`px-3 text-[13px] sm:text-[14px] md:text-[14px] ${isClientDetails ? 'lg:text-[13px]' : "lg:text-[17px]"} xl:text-[13px] rounded-[3px]  ${activeTab === "digitalTwin" ? "bg-[#283BBE] text-white" : "text-[#283BBE]"}`} textColor="white">
                Digital Twin
              </Button>
              <Button onClick={() => {
                setActiveTab("images")
                handleTabButton(digital_twin_id, matterport_id, 'images')
              }
              }
                className={`px-4 text-[13px] sm:text-[14px] md:text-[14px] ${isClientDetails ? 'lg:text-[13px]' : "lg:text-[17px]"}  xl:text-[13px] rounded-[3px]  ${activeTab === "images" ? "bg-[#283BBE] text-white " : "text-[#283BBE]"}`} textColor="white">
                Images
              </Button>
              <Button onClick={() => {
                setActiveTab("videos")
                handleTabButton(digital_twin_id, matterport_id, 'videos')
              }
              }
                className={`px-4 text-[13px] sm:text-[14px] md:text-[14px] ${isClientDetails ? 'lg:text-[13px]' : "lg:text-[17px]"} xl:text-[13px] rounded-[3px]   ${activeTab === "videos" ? "bg-[#283BBE] text-white " : "text-[#283BBE]"}`} textColor="white">
                Videos
              </Button>
            </div>
            <div className="w-full flex-1 md:w-auto rounded-l items-center ">
              {activeTab === "images" && <img src={property_images} alt="Your Image" className="  rounded  w-full" />}
              {/* Render other content based on activeTab */}
              {activeTab === "digitalTwin" && (
                // <div>

                <img src={property_images} alt="Your Image" className="rounded w-full " />
                // </div>
              )}
              {activeTab === "videos" && (
                <div>
                  {/* Render videos content here */}
                  <img src={property_images} alt="Your Image" className="rounded w-full" />
                </div>
              )}
            </div>
          </div>
        )
      }

      <div className={`flex flex-col lg:gap-2  w-full justify-center ${isClientDetails && 'lg:w-2/3'} xl:w-2/3 gap-2 flex-grow `} >
        {/* Property details */}
        <div className={`flex items-center ${isProperty && 'justify-between'}  w-full`}>
          <div className={`flex `} >
            <h1 className="font-bold text-[#1B191B] text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] mr-5">{title}</h1>
            {
              isAgencyList ? <Button children={property_visibility} textColor="#664400" className="bg-[#FFC248] font-semibold text-[1.063rem] rounded-md px-3 py-1" /> : null
            }
          </div>
          {
            isProperty && (
              <div className=" items-center hidden lg:flex relative ">
                <button className="text-[#283BBE]  cursor-pointer focus:outline-none font-semibold text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px]" onClick={() => console.log("Clicked on property_id")}>{property_id}</button>
                <button className="ml-4 text-[#FF307A] cursor-pointer focus:outline-none font-semibold text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px]" onClick={handlePropertyLinkClick}>Property Link</button>
                <button className="ml-4 text-[#FFADCA] cursor-pointer focus:outline-none text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px]" onClick={handleCopyButtonClick}><FileCopyIcon />
                  {isLinkCopied && (
                    <span className="absolute top-full right-0 mt-1  text-[#FF307A] ">Copied !</span>
                  )}
                </button>

              </div>
            )
          }

          {
            isClient && (
              <div className=" items-start flex  ">
                <button className="text-[#283BBE]  cursor-pointer focus:outline-none font-semibold text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px]" onClick={() => console.log("Clicked on Client ID")}>{client_id}</button>
              </div>
            )
          }
          {
            isClient && (!isClientDetails) && (
              <div className=" ml-auto text-[#283BBE] flex flex-wrap items-center sm:items-start sm:flex-start  sm:justify-start lg:justify-start mb-2 sm:mb-0">
                <span className={`opacity-50 text-[8px] sm:text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px] mr-1 `}><NotificationsIcon /></span>
                <span className={` text-[#283BBE] font-medium  text-[10px] sm:text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px] `}>New Message</span>
              </div>
            )
          }
        </div>

        {
          description && (
            <div className="text-left  md:max-w-[700px] lg:max-w-[1000px] text-[11px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[17px]">
              {description.slice(0, 70)}
              <button onClick={() => {
                handleDetailButton(digital_twin_id, matterport_id)
              }
              }>.... Read More</button>
            </div>
          )
        }

        {
          isProperty && (
            <div className="max-w-[500px] md:max-w-[900px] lg:max-w-[1000px] flex items-center justify-end lg:hidden relative mb-2 sm:mb-0">
              <button className="text-[#283BBE] text-lg cursor-pointer focus:outline-none font-semibold text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px]" >{property_id}</button>
              <button className="ml-4 text-[#FF307A] text-lg cursor-pointer focus:outline-none font-semibold text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px]" onClick={handlePropertyLinkClick}>Property Link</button>
              <button className="ml-4 text-[#FFADCA] cursor-pointer focus:outline-none" onClick={handleCopyButtonClick}><FileCopyIcon style={{ fontSize: '22px' }} />
                {isLinkCopied && (
                  <span className="absolute top-full right-0 mt-1  text-[#FF307A] ">Copied !</span>)}
              </button>
            </div>
          )
        }

        {/* <div className={`grid xs:rid-cols-1  sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-col-4 md:justify-center  lg:max-w-[1040px] `}>
          <div className=" flex flex-wrap  items-center ">
            <span className="opacity-50 mr-2 text-[2.25] md:text-[1.375rem]"><LocationOnIcon /></span>
            <span className="ml-1 text-[#283BBE] text-[2.25] md:text-[1.375rem]">{location.charAt(0).toUpperCase() + location.slice(1)}</span>
          </div>
          <div className=" flex flex-wrap  items-center ">
            <span className="opacity-50 mr-2"><HomeIcon /></span>
            <span className="ml-1 text-[#283BBE] text-[2] md:text-[1.063rem]">{contract_type === "sale" ? "Sell" : contract_type === "rent" ? "Rent" : ""}</span>
          </div>
          <div className="flex flex-wrap items-center ">
            <span className="opacity-50 mr-2"><KingBedIcon /></span>
            <span className="ml-1 text-[#283BBE] text-[2] md:text-[1.063rem]">{`${bedrooms} Bedrooms`}</span>
          </div>
          <div className=" flex flex-wrap  items-center ">
            <span className="opacity-50 mr-2"> <OpenInFullIcon /></span>
            <span className="ml-1 text-[#283BBE] text-[2] md:text-[1.063rem]">{`${area_sqm} Sq. Mtr`}</span>
          </div>
          <div className=" flex flex-wrap  items-center ">
            <span className="opacity-50 mr-2">Type</span>
            <span className="ml-1 text-[#283BBE] text-[2] md:text-[1.063rem]">{toPascalCase(type)}</span>
          </div>
          <div className=" flex flex-wrap  items-center ">
            <span className="opacity-50 mr-2">Lifestyle</span>
            <span className="md:ml-1 text-[#283BBE] text-[2] md:text-[1.063rem] ">{toPascalCase(lifestyle)}</span>
          </div>
          <div className=" flex flex-wrap  items-center    sm:col-span-1">
            <span className="opacity-50 mr-2">Listed On</span>
            <span className="ml-1 text-[#283BBE] text-[2] md:text-[1.063rem]">{listing_date}</span>
          </div>
          <div className=" flex flex-wrap  items-center   sm:col-span-1 ">
            <span className="opacity-50 mr-2">Agency</span>
            <span className="md:ml-1 sm:ml-1 text-[#283BBE] ttext-[2] md:text-[1.063rem]">{agency_name}</span>
          </div>
          <div className=" flex flex-wrap  items-center ">
            <span className="opacity-50 mr-2">Price</span>
            <span className="md:ml-1 sm:ml-1 text-[#283BBE] xs:text-[14px] md:text-[1.25rem] font-bold">{price}</span>
          </div>
        </div> */}

       

        {
          isProperty && (
            <>
              <div className="grid grid-cols-auto-fit gap-1 w-full ">
                {
                  data.map((item, index) => (
                    <div key={index} className=" flex flex-wrap flex-col sm:flex-row items-center justify-center sm:justify-start mb-2 sm:mb-0">
                      <span className={`opacity-50 mr-2 ${index === 0 ? 'text-[10px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[16px]' : 'text-[12px] sm:text-[12px] md:text-[13px] lg:text-[14px] xl:text-[15px]'} `}>{item.icon}</span>
                      <span className={`ml-1 text-[#283BBE] ${index === 0 ? 'text-[10px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[16px]' : 'text-[12px] sm:text-[12px] md:text-[13px] lg:text-[14px] xl:text-[15px]'} ${item.value === 'Agency' ? "col-span-2" : "text"} `}>{typeof item['value'] === 'string' ? item['value'].charAt(0).toUpperCase() + item['value'].slice(1) : item['value']}</span>
                    </div>
                  ))
                }
              </div>
              <div className=" flex flex-wrap  flex-col sm:flex-row items-center justify-center sm:justify-start ">
                <span className="opacity-50 mr-2 text-[9px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[14px]">Price</span>
                <span className="md:ml-1 sm:ml-1 text-[#283BBE] text-[9px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[14px] font-bold"> <EuroSymbolIcon style={{ fontSize: 18 }}/> {price}  </span>
              </div>
            </>
          )
        }

        {
          isClient && (
            <div className="grid grid-cols-auto-fit-client gap-2 w-full ">
              {
                data.map((item, index) => (
                  <div key={index} className=" flex flex-wrap flex-col items-center sm:items-start sm:flex-start  sm:justify-start lg:justify-start mb-2 sm:mb-0">
                    {item && item.icon && typeof item.value !== 'undefined' && ( // Check if item and icon exist, and value is defined
              <span className={`opacity-50 mr-2 ${index === 0 ? 'text-[10px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[16px]' : 'text-[12px] sm:text-[12px] md:text-[13px] lg:text-[14px] xl:text-[15px]'} `}>{item.icon}</span>
            )}
            {item && typeof item.value !== 'undefined' && ( // Check if item exists and value is defined
              <span className={`ml-1 text-[#283BBE] ${index === 0 ? 'text-[10px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[16px]' : 'text-[12px] sm:text-[12px] md:text-[13px] lg:text-[14px] xl:text-[15px]'} ${item.value === 'Agency' ? "col-span-2" : "text"} `}>
                {typeof item.value === 'string' ? item.value.charAt(0).toUpperCase() + item.value.slice(1) : item.value}
              </span>
            )}
                  </div>
                ))
              }
              {
                isClientDetails && (
                  <div className="hidden lg:flex items-center justify-center flex-col  sm:flex-row gap-3 w-[440px]  ">
                    <div className="flex text-white items-center justify-center  bg-[#FF307A] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
                      {`5 Property Tours completed `}
                    </div>
                    <div className="flex  items-center justify-center text-black  border border-[#283BBE] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]  " >
                      5 Property shortlisted
                    </div>
                  </div>
                )
              }
            </div>
          )
        }

        {
          isClient && isClientDetails && (
            <div className="flex lg:hidden w-full items-center justify-center flex-row gap-3   ">
              <div className="flex text-white items-center justify-center  bg-[#FF307A] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
                {`5 Property Tours completed `}
              </div>
              <div className="flex  items-center justify-center text-black  border border-[#283BBE] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]  " >
                5 Property shortlisted
              </div>
            </div>
          )
        }


        <div className={`flex items-center w-full flex-col sm:flex-row  justify-between ${isProperty && (isClientDetails) && "justify-end"}  gap-2 sm:gap-2 md:gap-2  sm:w-full `}>
          {
            (agent_commission >= 0) && (
              <div className="flex items-center justify-center flex-col sm:flex-row gap-2  ">
                <div className="flex text-white items-center justify-center bg-[#FF307A] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[13px] px-4 h-[45px] sm:h-[35px] ">
                  {`Commission Amount : ${Math.floor(agent_commission)}`}
                </div>
                <div className="flex  items-center justify-center text-black  border border-[#283BBE] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[13px] px-4 h-[45px] sm:h-[35px] " >
                  {`20 tours completed by 16 Clients`}
                </div>
              </div>
            )
          }

          {
            removeButton && (
              removeButton
            )
          }
          {
            isClient && (!isClientDetails) && (
              <div className="flex items-center justify-center flex-col sm:flex-row gap-2  ">
                <div className="flex items-center justify-center text-white bg-[#FF307A] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] " >
                  <span className="flex items-center">5 Property Tours completed</span>
                </div>
                <div className="flex text-black items-center border border-[#283BBE] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
                  555 Property shortlisted
                </div>
              </div>
            )
          }
          <div className="flex items-center justify-center lg:justify-end ">
            {
              // isProperty && (
              //   <Button children="Details" className="bg-[#283BBE] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[40px] xl:h-[50px]"
              //     onClick={() => {
              //       handleDetailButton(digital_twin_id, matterport_id)
              //     }
              //     }
              //   />
              // )
            }

            {
              // isClient && (!isClientDetails) && (
              //   <Button children="33Details" className="bg-[#283BBE] rounded-md text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] px-4 h-[40px] xl:h-[50px]"
              //     onClick={() => {
              //       handleDetailButton(customer_id, matterport_id)
              //     }
              //     }
              //   />
              // )
              detailButton && (
                detailButton
              )

            }

          </div>
        </div>

      </div>

    </div>

  );
};

export default PropertyCardLarge;