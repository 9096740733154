import React from "react"

import Footer from "rc-app/components/Footer";
import Button from "rc-app/components/Button";

export const NewHomePage = () => {
  // const pages = ['properties', 'vr-tour', 'vr-showcase', "add", 'q', 'e'];

  return (<>
    <div className="container max-w-screen-2xl " >
      <div className=" mt-15 w-full bg-[url('https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:2400/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/7WDiVNt-cjJ1Pczd.jpg')]
        ">
        <div className="flex w-full bg-white bg-opacity-65 ">
          <div className="flex flex-col md:flex-col flex-start lg:items-center px-4 md:px-32 py-2 md:py-8 lg:gap-3 xl:gap-8 ">
            <div className=" flex  gap-8 items-center">
              <div className="w-1/2 flex">
                <h1 className="text-[#000000] text-[24px] md:text-[48px] lg:text-[40px] xl:text-[60px] text-left font-bold " style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                  The Real Estate Technology Platform of 1verse Italia
                </h1>
              </div>
              <div className="w-1/2 rounded-sm">
                  <iframe src="https://videos.ctfassets.net/icnj41gkyohw/6sb0vVHFI4dO3KytmWDWqg/cdf4c5620537dbe0f34c975cae6f2750/homepage-ai-3.mp4"
                    className="w-full bg-cover lg:min-h-[270px] xl:min-h-[360px] rounded-md"
                    allow="autoplay;  xr-spatial-tracking"
                    allowFullScreen
                  ></iframe>
              </div>
            </div>

            <div className="w-full flex ">
              <p className="text-[#330F3E] text-[12px] md:text-[24px] lg:text-[20px] xl:text-[24px] letter-spacing: 0.05px " style={{ fontFamily: 'Inter, sans-serif' }} >
                We reinvent the way in which properties are rented and sold, thanks to immersive virtual
                experiences and the collaboration of agents all over the world.
              </p>
            </div>
            <div className="w-full flex">
              <div className="flex gap-4 ">
                <Button children={`Start`} className="bg-[#4950bc] rounded-md font-semibold text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] py-3 px-4"
                // onClick={handleOpen}
                />
                <div className="flex  items-center justify-center cursor-pointer bg-white text-[#4950bc]  border-2 border-[#4950bc] rounded-md font-semibold text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] py-3 px-4 "
                  onClick={() => { console.log("ttttt") }}
                >
                  <span >Join 1verse Italy</span>
                </div>
              </div>
            </div>

            {/* <div className=" flex flex-col lg:w-[60%] gap-6">
              
              <p className="text-[#330F3E] text-[12px] md:text-[24px] lg:text-[20px] xl:text-[24px] letter-spacing: 0.05px " style={{ fontFamily: 'Inter, sans-serif' }} >
                We reinvent the way in which properties are rented and sold, thanks to immersive virtual
                experiences and the collaboration of agents all over the world.
              </p>
              <div className="flex gap-4 ">
                <Button children={`Start`} className="bg-[#4950bc] rounded-md font-semibold text-[12px] sm:text-[11px] md:text-[15px] lg:text-[17px] xl:text-[18px] py-3 px-4"
                // onClick={handleOpen}
                />
                <div className="flex  items-center justify-center cursor-pointer bg-white text-[#4950bc]  border-2 border-[#4950bc] rounded-md font-semibold text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] py-3 px-4 "
                  onClick={() => { console.log("ttttt") }}
                >
                  <span >Join 1verse Italy</span>
                </div>
              </div>
            </div> */}

            {/* <div className=" w-full flex flex-col  lg:w-[40%] ">
              <div className="w-full rounded-sm">
                <img
                  alt="1verse"
                  src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1200/https://cdn.gamma.app/8i5r7bg6yii6htm/2da0f45f16c7447d8bd514e4cb221b91/original/aaya071o.png'
                  className="lg:h-[480px] xl:h-[600px] object-cover flex rounded-md"
                />
                <iframe src="https://videos.ctfassets.net/icnj41gkyohw/6sb0vVHFI4dO3KytmWDWqg/cdf4c5620537dbe0f34c975cae6f2750/homepage-ai-3.mp4"
                  className="w-full bg-cover min-h-[50vh] rounded-md"
                  allow="autoplay;  xr-spatial-tracking"
                  allowFullScreen
                ></iframe>
              </div>
            </div> */}

          </div>
        </div>

      </div>

      {/* <div className="w-full flex flex-col bg-white px-4 md:px-32 py-2 md:py-8 gap-3">
        <div className="text-[#000000] text-[24px] md:text-[40px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
          Clients with agents globally
        </div>
        <div className="w-full flex flex-row gap-6">
          <div className="w-1/3 flex flex-col gap-2" >
            <div className=" flex flex-col gap-[30px] ">
              <img
                src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/N2uMwrKezTc6tMXk.jpg'
                className="h-40 xl:h-60 rounded-md opacity-1 "
              />
            </div>
            <div className=" flex flex-col ">
              <div className="text-[#000000] text-[14px] md:text-[24px] lg:text-[20px] xl:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                Our Team
              </div>
              <p className="text-[#330F3E] text-[12px] md:text-[22px] lg:text-[16px] xl:text-[20px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                Our dynamic and experienced team includes
                real estate experts, software engineers
                and visionaries passionate about transforming the sector.
              </p>
            </div>
          </div>
          <div className="w-1/3 flex flex-col gap-2" >
            <div className=" flex flex-col gap-[30px] ">
              <img
                src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/uC9qNTGLnlYvVx6O.jpg'
                className="h-40 xl:h-60 rounded-md opacity-1 "
              />
            </div>
            <div className=" flex flex-col ">
              <div className="text-[#000000] text-[14px] md:text-[24px] lg:text-[20px] xl:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                Our technology
              </div>
              <p className="text-[#330F3E] text-[12px] md:text-[22px] lg:text-[16px] xl:text-[20px] letter-spacing: 0.05px" style={{ fontFamily: 'Inter, sans-serif' }}>
                We offer cutting-edge technological solutions for real estate agencies
                that simplify the sales process and offer an unparalleled viewing experience for customers.
              </p>
            </div>
          </div>
          <div className="w-1/3 flex flex-col gap-2" >
            <div className=" flex flex-col gap-[30px] ">
              <img
                src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/UHCJZe82QTrLNP6H.jpg'
                className="h-40 xl:h-60 rounded-md opacity-1 "
              />
            </div>
            <div className=" flex flex-col ">
              <div className="text-[#000000] text-[14px] md:text-[24px] lg:text-[20px] xl:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                Remote Assisted Visits
              </div>
              <p className="text-[#330F3E] text-[12px] md:text-[22px] lg:text-[16px] xl:text-[20px]  letter-spacing: 0.05px" style={{ fontFamily: 'Inter, sans-serif' }}>
                Agents can guide customers
                through the virtual tour, highlighting the
                main features and answering questions in real time.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="w-full flex flex-col bg-white px-4 md:px-32 py-2 md:py-8 gap-3">
        <div className="text-[#000000] text-[24px] md:text-[40px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
          Clients with agents globally
        </div>
        <div className="w-full flex flex-row gap-6">
          <div className="w-[50%] xl:w-[40%] flex flex-col gap-2" >
            <div className=" flex flex-col gap-[30px] ">
              <img
                src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/2bWM9WQwhHFhqgYG.jpg'
                className="h-60 xl:h-72 rounded-md opacity-1 "
              />
            </div>
            <div className=" flex flex-col ">
              <div className="text-[#000000] text-[14px] md:text-[24px]  lg:text-[22px] xl:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                Remote Assisted Visits
              </div>
              <p className="text-[#330F3E] text-[12px]  md:text-[22px] lg:text-[18px] xl:text-[22px] letter-spacing: 0.05px" style={{ fontFamily: 'Inter, sans-serif' }}>
                Agents can guide customers
                through the virtual tour, highlighting the
                main features and answering questions in real time.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="w-full flex flex-col px-4 md:px-32 py-2 md:py-8 gap-8">
        <div className="text-[#000000] text-[24px] md:text-[40px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
          Upgrade Real Estate Agencies
        </div>
        <div className="w-full flex flex-col md:flex-row gap-3 md:gap-8  ">
          <div className="w-full md:w-1/2 border border-[#e5e0df] bg-[#dadbf1] flex flex-col items-start md:gap-2 p-6 md:p-6 shadow-md rounded-md">
            <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
              <span className="break-words">Expand Your Scope</span>
            </div>
            <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
              <span className="break-words">Our platform connects your listings with a vast global network
                and other Italian regions of potential buyers and tenants, extending the scope of your operations
                beyond local borders and in the international market.
              </span>
            </div>
          </div>
          <div className="w-full md:w-1/2  flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md border border-[#e5e0df] bg-[#dadbf1] rounded-md">
            <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
              <span className="break-words">Optimize your business</span>
            </div>
            <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
              <span className="break-words">Take advantage of our cutting-edge technology to efficiently manage the
                sales process, reducing the time and costs associated with visits in person. Get valuable
                information from our platform analysis to foster faster growth.
              </span>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row gap-3 md:gap-8  ">
          <div className="w-full md:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md border border-[#e5e0df] bg-[#dadbf1] rounded-md">
            <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
              <span className="break-words">Enhance Customer Experience</span>
            </div>
            <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
              <span className="break-words">Offer your customers an engaging and compelling way to view properties,
                distinguishing yourself from the competition and improving their overall satisfaction.
              </span>
            </div>
          </div>
          <div className="w-full md:w-1/2  flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md border border-[#e5e0df] bg-[#dadbf1] rounded-md">
            <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
              <span className="break-words">Stay in step with Innovation</span>
            </div>
            <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
              <span className="break-words">Embrace the latest technological innovations in the real estate
                sector with 1verse Italia, giving your studio a competitive advantage on the market and
                allowing you to achieve greater success.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col px-4 md:px-32 py-2 md:py-8 gap-8 bg-[#f2f2f2] ">
        {/* <div className="flex flex-col md:flex-col lg:flex-row flex-start lg:items-center lg:gap-3 xl:gap-8 opacity-1 ">
          <div className=" flex flex-col lg:w-[50%] xl:w-[60%] gap-6 ">
            <h1 className="text-[#000000] text-[24px] md:text-[48px] lg:text-[36px] xl:text=[48px] text-left font-semibold " style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
              Our Scan Services
            </h1>
            <div className="w-full flex flex-col xl:flex-row gap-3 md:gap-8  ">
              <div className="w-full xl:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md border border-[#e5e0df] bg-[#dadbf1] rounded-md">
                <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                  <span className="break-words">Precise 3D scan</span>
                </div>
                <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
                  <span className="break-words">Our team of experienced professionals uses cutting-edge 3D
                    scanning technology to capture every detail of your listings, creating highly accurate
                    digital cufflinks.
                  </span>
                </div>
              </div>
              <div className="w-full xl:w-1/2  flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md border border-[#e5e0df] bg-[#dadbf1] rounded-md">
                <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                  <span className="break-words">Problem Free trial</span>
                </div>
                <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
                  <span className="break-words">We manage the entire scanning process, from planning to post-production,
                    ensuring a smooth and efficient experience for your agency.
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-3 md:gap-8  ">
              <div className="w-full  flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md border border-[#e5e0df] bg-[#dadbf1] rounded-md">
                <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
                  <span className="break-words">Unparalleled quality</span>
                </div>
                <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
                  <span className="break-words">The digital cufflinks we create are of the highest quality, allowing an
                    engaging and realistic virtual viewing experience.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex flex-col  lg:w-[50%] xl:w-[40%] ">
            <div className="rounded-sm">
              <img
                alt="1verse"
                src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1200/https://cdn.gamma.app/8i5r7bg6yii6htm/90b8d8757a3e4773b807e6f14520a2d1/original/3D-SCANNING.png'
                className="lg:h-[480px] xl:h-[600px] flex rounded-md"
              />
            </div>
          </div>
        </div> */}

        <div className="w-full flex flex-col gap-3">
          <div className="text-[#000000] text-[24px] md:text-[40px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
            Benefits of Our Service
          </div>
          <div className="w-full flex lg:flex-col xl:flex-row gap-6">
            <div className="w-full flex gap-6 xl:w-1/2 ">
              <div className="lg:w-1/2  flex flex-col gap-2" >
                <div className=" flex flex-col gap-[30px] ">
                  <img
                    src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/zsQRp66GJoVa99xd.jpg'
                    className="lg:h-60 xl:h-48 rounded-md opacity-1 "
                  />
                </div>
                <div className=" flex flex-col ">
                  <div className="text-[#000000] text-[14px] md:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                    Our Team
                  </div>
                  <p className="text-[#330F3E] text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]  " style={{ fontFamily: 'Inter, sans-serif' }}>
                    Our dynamic and experienced team includes
                    real estate experts, software engineers
                    and visionaries passionate about transforming the sector.
                  </p>
                </div>
              </div>
              <div className="lg:w-1/2  flex flex-col gap-2" >
                <div className=" flex flex-col gap-[30px] ">
                  <img
                    src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/AUC95ye1WpNiQN7Y.jpg'
                    className="lg:h-60 xl:h-48 rounded-md opacity-1 "
                  />
                </div>
                <div className=" flex flex-col ">
                  <div className="text-[#000000] text-[14px] md:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                    Our technology
                  </div>
                  <p className="text-[#330F3E] text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]" style={{ fontFamily: 'Inter, sans-serif' }}>
                    We offer cutting-edge technological solutions for real estate agencies
                    that simplify the sales process and offer an unparalleled viewing experience for customers.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full flex gap-6 xl:w-1/2">
              <div className="lg:w-1/2 flex flex-col gap-2" >
                <div className=" flex flex-col gap-[30px] ">
                  <img
                    src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/Wz2zaUS3zfB0PwWK.jpg'
                    className="lg:h-60 xl:h-48 rounded-md opacity-1 "
                  />
                </div>
                <div className=" flex flex-col ">
                  <div className="text-[#000000] text-[14px] md:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                    Remote Assisted Visits
                  </div>
                  <p className="text-[#330F3E] text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]" style={{ fontFamily: 'Inter, sans-serif' }}>
                    Agents can guide customers
                    through the virtual tour, highlighting the
                    main features and answering questions in real time.
                  </p>
                </div>
              </div>
              <div className="lg:w-1/2 flex flex-col gap-2" >
                <div className=" flex flex-col gap-[30px] ">
                  <img
                    src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/3wNPtSVjVQU5dnGN.jpg'
                    className="lg:h-60 xl:h-48 rounded-md opacity-1 "
                  />
                </div>
                <div className=" flex flex-col ">
                  <div className="text-[#000000] text-[14px] md:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                    Remote Assisted Visits
                  </div>
                  <p className="text-[#330F3E] text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]" style={{ fontFamily: 'Inter, sans-serif' }}>
                    Agents can guide customers
                    through the virtual tour, highlighting the
                    main features and answering questions in real time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="w-full flex flex-col px-4 md:px-32 py-2 md:py-8 gap-8 bg-[#fcec99]">
        <div className="text-[#000000] text-[24px] md:text-[40px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
          Immersive Property Viewing Experience
        </div>
        <div className="w-full flex flex-col md:flex-row gap-3 md:gap-8  ">
          <div className="w-full md:w-1/2 border border-[#e5e0df] bg-[#dadbf1] flex flex-col items-start md:gap-2 p-6 md:p-6 shadow-md rounded-md">
            <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
              <span className="break-words">Explore</span>
            </div>
            <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
              <span className="break-words">Customers can freely navigate through the property, acquiring a true
                feeling of space and layout. We also add views to 360° outside the scanned properties to give a
                better idea of the surrounding environments.
              </span>
            </div>
          </div>
          <div className="w-full md:w-1/2  flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md border border-[#e5e0df] bg-[#dadbf1] rounded-md">
            <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
              <span className="break-words">Interact</span>
            </div>
            <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
              <span className="break-words">Customers can interact with the virtual environment, opening doors,
                inspecting features and viewing the furnishings.
              </span>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row gap-3 md:gap-8  ">
          <div className="w-full md:w-1/2   flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md border border-[#e5e0df] bg-[#dadbf1] rounded-md">
            <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
              <span className="break-words">Get involved</span>
            </div>
            <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
              <span className="break-words">The immersive experience allows customers to feel as if they
                were physically present on the property.
              </span>
            </div>
          </div>
          <div className="w-full md:w-1/2  flex flex-col items-start md:gap-2 p-4 md:p-4 shadow-md border border-[#e5e0df] bg-[#dadbf1] rounded-md">
            <div className=" text-[#000000] font-semibold text-[14px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px] " style={{ fontFamily: 'Inter, sans-serif' }}>
              <span className="break-words">Close Business</span>
            </div>
            <div className="text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]">
              <span className="break-words">Agents can create virtual tours and invite customers,
                owners and other agents to visit, discuss and close transactions in a virtual way.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col px-8 md:px-48 py-2 md:py-8 gap-8 ">
        <div className="text-[#000000] text-[24px] md:text-[40px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
          Show your listings and save time
        </div>
        <div>
          {/* Render videos content here */}
          <div className="flex items-center justify-center mt-1 w-full">
            {/* <iframe src={iframeSrc} width="100%" height={525} title="wall_iframe" allow="xr-spatial-tracking" allowFullScreen /> */}
            <iframe className="w-full aspect-video " src="https://www.youtube.com/embed/08ED2ktU_i4?si=UnUQkQK41IpWLEfK"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

            ></iframe>

          </div>
        </div>

      </div>


      <div className="w-full flex flex-col px-4 md:px-32 gap-3 pb-4 bg-[#edeff7]">
        <div className="text-[#000000] text-[24px] md:text-[40px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
          Rationalization of the Real Estate Sale Process
        </div>
        <div className="w-full flex lg:flex-col xl:flex-row gap-6">
          <div className="w-full flex gap-6 xl:w-1/2">
            <div className="lg:w-1/2  flex flex-col gap-2" >
              <div className=" flex flex-col gap-[30px] ">
                <img
                  src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/-9daHn7AeE0Q9N3W.jpg'
                  className="h-48 rounded-md opacity-1 "
                />
              </div>
              <div className=" flex flex-col ">
                <div className="text-[#000000] text-[14px] md:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                  Faster Visits
                </div>
                <p className="text-[#330F3E] text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]  " style={{ fontFamily: 'Inter, sans-serif' }}>
                  Eliminate the need for visits in person, saving time and resources.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2  flex flex-col gap-2" >
              <div className=" flex flex-col gap-[30px] ">
                <img
                  src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/r0np_GibDHnVo7tS.jpg'
                  className="h-48 rounded-md opacity-1 "
                />
              </div>
              <div className=" flex flex-col ">
                <div className="text-[#000000] text-[14px] md:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                  Greater Accessibility
                </div>
                <p className="text-[#330F3E] text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]" style={{ fontFamily: 'Inter, sans-serif' }}>
                  Customers can view properties at their convenience, from anywhere in the world.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full flex gap-6 xl:w-1/2 ">
            <div className="lg:w-1/2  flex flex-col gap-2" >
              <div className=" flex flex-col gap-[30px] ">
                <img
                  src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/Spba-oruaCkztlIz.jpg'
                  className="h-48 rounded-md opacity-1 "
                />
              </div>
              <div className=" flex flex-col ">
                <div className="text-[#000000] text-[14px] md:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                  Increased transparency
                </div>
                <p className="text-[#330F3E] text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]" style={{ fontFamily: 'Inter, sans-serif' }}>
                  Provide customers with a complete understanding of the property before visiting it.

                </p>
              </div>
            </div>
            <div className="lg:w-1/2  flex flex-col gap-2" >
              <div className=" flex flex-col gap-[30px] ">
                <img
                  src='https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1000/height:1000/https://cdn.gamma.app/8i5r7bg6yii6htm/generated-images/IgIU5JntzmkOAShf.jpg'
                  className="h-48 rounded-md opacity-1 "
                />
              </div>
              <div className=" flex flex-col ">
                <div className="text-[#000000] text-[14px] md:text-[24px] text-left font-bold" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
                  Increase in Conversion Rates
                </div>
                <p className="text-[#330F3E] text-[11px] font-medium sm:text-[13px] md:text-[14px] lg:text-[18px] xl:text-[20px]" style={{ fontFamily: 'Inter, sans-serif' }}>
                  Immersive viewing leads to more informed and secure purchasing decisions.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <footer className="bg-[#000630] relative">
        <Footer />
      </footer>

    </div>
  </>)
}