import "./main.css";

import React, { useState, useEffect } from "react";

// import { useLocation } from 'react-router-dom';
import { PhotonClient } from "./PhotonClient";
import { ClientContext } from "./components/Context";
import { Main } from "./components/Main";
import { IContext } from "./types";
import { RemoteSimulator } from "./simulator/RemoteSimulator";
import { LocalSimulator } from "./simulator/LocalSimulator";
import { sdkKey } from "@mp/common";

import "./style.css";

import UserService from "./services/UserService";

import {
  // eventUpdateStream,
  getAgentByEmail,
  getAgoraAppId,
  getDigitalTwinById,
  getRtcToken,
} from "./Pages/Home";
import AgoraIOVideoCall from "./components/Agora";

const urlParams = new URLSearchParams(window.location.search);
const applicationKey = urlParams.get("applicationKey") || sdkKey;
console.log("applicationKey", applicationKey);

const photonClient = new PhotonClient();
const hostSimulator = new LocalSimulator(photonClient, applicationKey);
const clientSimulator = new RemoteSimulator(photonClient, applicationKey);

const context: IContext = {
  photonClient: photonClient,
  sdkClient: clientSimulator,
  sdkHost: hostSimulator,
};

import RenderOnRole from "./components/RenderOnRole";
import { toPascalCase } from "./utils";

// export const RCApp= ({properties} : { properties: any[] } ) => {

export const RCApp = () => {
  const [agentId, setAgentId] = useState();
  const [selectAnotherProperty, setSelectAnotherProperty] = useState(false);

  const { email } = UserService.getTokenParsed();

  const queryParams = new URLSearchParams(window.location.search);
  const joinCode = queryParams.get("channel").split("_")[1];
  const propertyId = queryParams.get("propertyId");

  const digitalTwinId = queryParams.get("digitalTwin");
  const [digitalTwinDetails, setDigitalTwinDetails] = useState({
    name: "",
    title: "",
  });

  useEffect(
    () => {
      UserService.hasRole("agent") &&
        getAgentByEmail(email).then((data) => {
          setAgentId(data.agent._id);
        });

      getDigitalTwinById(digitalTwinId, true)
        .then((res) => {
          console.log("digitalres", res);
          setDigitalTwinDetails(res);
        })
        .catch((err) => console.log(err));

      const handleRedirect = () => {
        // Check if the page has been redirected
        // const navigationEntries = window.performance.getEntriesByType('navigation');
        // if (navigationEntries.length === 1 && navigationEntries[0].entryType === 'navigate') {
        //   console.log('Page is loaded for the first time');
        // }
        // if (location.pathname !== window.location.pathname) {
        //   console.log('Page redirected');
        // } else{
        console.log("Page loaded without redirection");
        // eventUpdateStream('JoinTour', agentId,  email, 'role_selection_page', 'true')
        // }
      };
      handleRedirect();
      // return () => {
      //   // Perform any cleanup if needed
      // };
    },
    [
      // location
    ]
  );

  const channel = queryParams.get("channel");

  const [agoraToken, setAgoraToken] = useState();

  const [agoraAppId, setAgoraAppId] = useState();

  useEffect(() => {
    getRtcToken(channel)
      .then((res) => {
        setAgoraToken(res.rtcToken);
      })
      .catch((err) => console.log("err", err));
    getAgoraAppId("RL-ESTATE").then((res) => {
      setAgoraAppId(res.network["agora_app_id"]);
    });
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);

  const handleCopyButtonClick = () => {
    navigator.clipboard
      .writeText(`${joinCode}`)
      .then(() => {
        setIsLinkCopied(true);
        setTimeout(() => setIsLinkCopied(false), 3000);
      })
      .catch((error) => console.error("Failed to copy property link:", error));
  };

  return (
    <ClientContext.Provider value={context}>
      {/* <Main property={property} propertyId={propertyId} /> */}
      <div className="flex w-full h-full">
        <div className="flex w-3/4">
          <Main
            propertyId={propertyId}
            email={email}
            joinCode={joinCode}
            channel={channel}
            agentId={agentId}
            selectAnotherProperty={selectAnotherProperty}
            setSelectAnotherProperty={setSelectAnotherProperty}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        </div>
        <div className="w-1/4 flex p-3 flex-col bg-white shadow-md rounded-md">
          <div className="w-full flex flex-col gap-3 ">
            {/* <div className="w-full flex justify-center gap-2 xs:text-[9px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[17px]">
              <span >{props.tourProperty.host ? 'HOSTING' : 'OBSERVING'}</span>
              <span className='text-[#283BBE] font-semibold' >{props.tourProperty.userCount} {props.tourProperty.host && "Client(s)"}</span>
              <div className="w-full flex justify-center ">{props.tourProperty.host ? 'HOSTING' : 'OBSERVING'}</div>
              <div className=''>{props.tourProperty.userCount} {props.tourProperty.host && "Clients"} </div>
            </div> */}
            <RenderOnRole roles={["agent"]}>
              <div className="flex w-full  justify-center">
                {/* <Button children="STOP"
                              className="bg-[#283BBE] rounded-md px-2 h-[40px] w-full text-[0.938rem]"
                              onClick={props.tourProperty.onStopped} /> */}

                <button
                  type="button"
                  className="mr-1 bg-[#FF307A] rounded-md px-2 h-[40px] w-full text-[1rem] text-white"
                  onClick={handleCopyButtonClick}
                >
                  Share
                  {/* <ShareIcon fontSize="small" /> */}
                </button>
                {isLinkCopied && (
                  <span className="absolute top-20 right-5 mt-1  text-[#283BBE] font-bold">
                    Copied !
                  </span>
                )}
              </div>
            </RenderOnRole>

            <div className="w-full flex flex-col gap-3 ">
              <RenderOnRole roles={["agent"]}>
                <div className="w-full  flex flex-col gap-1 font-bold">
                  <span className="text-[#283BBE] ">Property</span>
                  <span className=" font-semibold">
                    {toPascalCase(digitalTwinDetails.title)}
                  </span>
                </div>

                <div className="flex justify-center">
                  <button
                    type="button"
                    className="text-[#EC0000] font-semibold border-2 border-[#EC0000] rounded-md text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]"
                    onClick={() => {
                      // props.tourProperty.onStopped()
                      // handleOpen()
                      setOpen(true);
                      console.log("handleOpen", open);
                    }}
                  >
                    Choose another property
                  </button>
                </div>
              </RenderOnRole>
            </div>
          </div>
          <div className="flex w-full">
            <AgoraIOVideoCall
              token={agoraToken}
              appId={agoraAppId}
              channel={channel}
              userId={email}
            />
          </div>
        </div>
      </div>
    </ClientContext.Provider>
  );
};
