
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ pdfUrl } : any) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);

  const onDocumentLoadSuccess = ({ numPages } : any) => {
    setNumPages(numPages);
  };

  const changePage = (offset : any) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const onError = (err : any) => {
    setError(err);
  };

  return (
    <div >
      {error && <div>Error: {error.message}</div>}
      <Document
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onError={onError}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={() => changePage(-1)}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={() => changePage(1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PDFViewer;
