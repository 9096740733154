import MiniDrawer from "rc-app/components/MiniDrawer";
import UserService from "rc-app/services/UserService";
import React from "react";

export const PrivacyPolicy = () => {
  console.log("privacy", UserService.isLoggedIn);
  
  return (
    <>

      {!UserService.isLoggedIn() && (
        <div className="flex-grow pt-[2rem] md:pt-[2rem] lg:pt-[2rem] px-2 lg:px-3">
          <div className="privacy-policy container mx-auto p-2">
            <h1 className="text-3xl font-bold mb-4">
              Privacy and Personal Data Protection Policy
            </h1>

            <p>
              This information is provided, in compliance with art. 13 and 14 of
              EU Regulation 679/2016 (hereinafter "Regulation"), to users
              (hereinafter: "Users" or "User") of the website in desktop and
              mobile versions and 1verse.live and italia.1verse.live
              applications, (hereinafter: "Website and App") owned by Oneverse
              Italia RealEstate S.p.A., Data Controller of personal data
              (hereinafter: "Data Controller") and has the purpose of describing
              the methods of management of the Website and App with reference to
              the processing of personal data.
            </p>
            <p>
              As specified in the General Conditions and Terms of Service, the
              services offered by the Data Controller are aimed at people over
              the age of 18. Should the Data Controller become aware of the
              processing of data of minors under 18 years of age without the
              valid consent of the parents or legal guardian, it reserves the
              right to unilaterally interrupt the use of the service offered as
              well as to delete the data acquired.
            </p>
            <h2 className="text-2xl font-bold mt-6">
              Principles applicable to the Processing of Personal Data
            </h2>
            <p>
              The Data Controller, pursuant to and for the purposes of the
              Regulation, informs that the aforementioned legislation provides
              for the protection of natural persons with regard to the
              processing of personal data, and that such processing will be
              based on the principles of correctness, lawfulness, transparency,
              and protection of confidentiality and fundamental rights.
            </p>

            <h2 className="text-2xl font-bold mt-6">Type of users</h2>
            <p>
              In relation to the use of the Website and App, the following types
              of Users are distinguished:
            </p>
            <ul className="list-disc ml-6">
              <li>
                <strong>Simple Users:</strong> can access some services (e.g.,
                property search) using their login credentials provided by
                Professional Users.
              </li>
              <li>
                <strong>Professional Users:</strong> upon signing a Service
                Agreement, have access to certain services intended for them.
              </li>
            </ul>

            <h2 className="text-2xl font-bold mt-6">
              Purpose, legal basis of the Processing and Optional Provision
            </h2>
            <p>
              The personal data provided by Users through the use of the Website
              and App will be processed with their consent, for the purposes
              described below:
            </p>
            <ul className="list-decimal ml-6">
              <li>
                <strong>Provision of the service for Simple Users:</strong>
                <ul className="list-disc ml-6">
                  <li>
                    In order to provide certain services such as saving ads,
                    saving a search, and receiving information on new ads
                    automatically, it is necessary for the User to register. The
                    data necessary for registration include Name, Surname, Email
                    address, and Phone Number.
                  </li>
                  <li>
                    Additional data like date of birth, gender, and residence
                    may also be provided voluntarily by the User in their
                    profile.
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  Provision of Advertiser Contact and Search Forwarding
                  services:
                </strong>
                Users may be required to enter their requirements which will be
                forwarded to real estate agencies or professionals based on the
                user’s preferences.
              </li>
              <li>
                <strong>Provision of the Mortgage Advisory service:</strong>
                Users can receive advice on financing from banks or financial
                intermediaries.
              </li>
              <li>
                <strong>
                  Information, promotional, and profiling activities:
                </strong>
                Users can subscribe to receive sector information, news, and
                promotional materials.
              </li>
              <li>
                <strong>Provision of services for Professional Users:</strong>
                Operators need to provide personal data to qualify as
                Professional Users.
              </li>
            </ul>

            <h2 className="text-2xl font-bold mt-6">
              Methods of Processing and Storing Personal Data
            </h2>
            <p>
              The Data Controller ensures that personal data are processed in
              full compliance with the Regulation, using manual, computer, or
              telematic systems.
            </p>

            <h2 className="text-2xl font-bold mt-6">
              Recipients of Personal Data
            </h2>
            <p>
              The personal data collected may be processed by subjects or
              categories of subjects who act as Data Processors or authorized to
              process data pursuant to the Regulation.
            </p>

            <h2 className="text-2xl font-bold mt-6">
              Transfer of Data to a Third Country or International Organization
            </h2>
            <p>
              Personal data collected through the Website and App may be
              transferred outside the national territory, only for the execution
              of services requested.
            </p>

            <h2 className="text-2xl font-bold mt-6">
              Browsing Data Collection
            </h2>
            <p>
              The computer systems of the Website and App acquire personal data
              during their normal operation, including IP addresses, domain
              names, and browser data.
            </p>

            <h2 className="text-2xl font-bold mt-6">
              Cookies, Search Engines, and Location Data
            </h2>
            <p>
              Cookies are used to speed up Internet traffic analysis and
              facilitate access to the services offered. Users may disable
              cookies in their browser settings.
            </p>

            <h2 className="text-2xl font-bold mt-6">
              Rights of the Data Subject
            </h2>
            <p>
              Users have various rights including confirmation of data
              existence, rectification, deletion, and restriction of processing.
            </p>

            <h2 className="text-2xl font-bold mt-6">
              Data Controller and Data Protection Officer
            </h2>
            <p>
              For any questions or to exercise rights, Users may contact the
              Data Controller at datipersonali@1verse.live or the Data
              Protection Officer at support_re@1verse.live.
            </p>

            <h2 className="text-2xl font-bold mt-6">Whistleblowing</h2>
            <p>
              Reports related to breaches of law can be made through the
              platform accessible at
              <a
                href="https://italia.1verse.live/whistleblowing"
                className="text-blue-600"
              >
                https://italia.1verse.live/whistleblowing
              </a>
            </p>

            <h2 className="text-2xl font-bold mt-6">Changes</h2>
            <p>
              This policy may be subject to change. Any material changes will be
              published prominently on our pages.
            </p>
          </div>
        </div>
      )}      

      {UserService.isLoggedIn() && (
        <div className="container max-w-screen-2xl flex bg-[#F6F6F8] min-h-screen overflow-y-auto">
          <div>
            <MiniDrawer />
          </div>
          <div className="flex-grow pt-[4rem] md:pt-[3rem] lg:pt-[4rem] px-2 lg:px-3">
            {/* all the page content here */}
            <div className="flex-grow pt-[2rem] md:pt-[2rem] lg:pt-[2rem] px-2 lg:px-3">
              <div className="privacy-policy container mx-auto p-2">
                <h1 className="text-3xl font-bold mb-4">
                  Privacy and Personal Data Protection Policy
                </h1>

                <p>
                  This information is provided, in compliance with art. 13 and
                  14 of EU Regulation 679/2016 (hereinafter "Regulation"), to
                  users (hereinafter: "Users" or "User") of the website in
                  desktop and mobile versions and 1verse.live and
                  italia.1verse.live applications, (hereinafter: "Website and
                  App") owned by Oneverse Italia RealEstate S.p.A., Data
                  Controller of personal data (hereinafter: "Data Controller")
                  and has the purpose of describing the methods of management of
                  the Website and App with reference to the processing of
                  personal data.
                </p>
                <p>
                  As specified in the General Conditions and Terms of Service,
                  the services offered by the Data Controller are aimed at
                  people over the age of 18. Should the Data Controller become
                  aware of the processing of data of minors under 18 years of
                  age without the valid consent of the parents or legal
                  guardian, it reserves the right to unilaterally interrupt the
                  use of the service offered as well as to delete the data
                  acquired.
                </p>
                <h2 className="text-2xl font-bold mt-6">
                  Principles applicable to the Processing of Personal Data
                </h2>
                <p>
                  The Data Controller, pursuant to and for the purposes of the
                  Regulation, informs that the aforementioned legislation
                  provides for the protection of natural persons with regard to
                  the processing of personal data, and that such processing will
                  be based on the principles of correctness, lawfulness,
                  transparency, and protection of confidentiality and
                  fundamental rights.
                </p>

                <h2 className="text-2xl font-bold mt-6">Type of users</h2>
                <p>
                  In relation to the use of the Website and App, the following
                  types of Users are distinguished:
                </p>
                <ul className="list-disc ml-6">
                  <li>
                    <strong>Simple Users:</strong> can access some services
                    (e.g., property search) using their login credentials
                    provided by Professional Users.
                  </li>
                  <li>
                    <strong>Professional Users:</strong> upon signing a Service
                    Agreement, have access to certain services intended for
                    them.
                  </li>
                </ul>

                <h2 className="text-2xl font-bold mt-6">
                  Purpose, legal basis of the Processing and Optional Provision
                </h2>
                <p>
                  The personal data provided by Users through the use of the
                  Website and App will be processed with their consent, for the
                  purposes described below:
                </p>
                <ul className="list-decimal ml-6">
                  <li>
                    <strong>Provision of the service for Simple Users:</strong>
                    <ul className="list-disc ml-6">
                      <li>
                        In order to provide certain services such as saving ads,
                        saving a search, and receiving information on new ads
                        automatically, it is necessary for the User to register.
                        The data necessary for registration include Name,
                        Surname, Email address, and Phone Number.
                      </li>
                      <li>
                        Additional data like date of birth, gender, and
                        residence may also be provided voluntarily by the User
                        in their profile.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>
                      Provision of Advertiser Contact and Search Forwarding
                      services:
                    </strong>
                    Users may be required to enter their requirements which will
                    be forwarded to real estate agencies or professionals based
                    on the user’s preferences.
                  </li>
                  <li>
                    <strong>Provision of the Mortgage Advisory service:</strong>
                    Users can receive advice on financing from banks or
                    financial intermediaries.
                  </li>
                  <li>
                    <strong>
                      Information, promotional, and profiling activities:
                    </strong>
                    Users can subscribe to receive sector information, news, and
                    promotional materials.
                  </li>
                  <li>
                    <strong>
                      Provision of services for Professional Users:
                    </strong>
                    Operators need to provide personal data to qualify as
                    Professional Users.
                  </li>
                </ul>

                <h2 className="text-2xl font-bold mt-6">
                  Methods of Processing and Storing Personal Data
                </h2>
                <p>
                  The Data Controller ensures that personal data are processed
                  in full compliance with the Regulation, using manual,
                  computer, or telematic systems.
                </p>

                <h2 className="text-2xl font-bold mt-6">
                  Recipients of Personal Data
                </h2>
                <p>
                  The personal data collected may be processed by subjects or
                  categories of subjects who act as Data Processors or
                  authorized to process data pursuant to the Regulation.
                </p>

                <h2 className="text-2xl font-bold mt-6">
                  Transfer of Data to a Third Country or International
                  Organization
                </h2>
                <p>
                  Personal data collected through the Website and App may be
                  transferred outside the national territory, only for the
                  execution of services requested.
                </p>

                <h2 className="text-2xl font-bold mt-6">
                  Browsing Data Collection
                </h2>
                <p>
                  The computer systems of the Website and App acquire personal
                  data during their normal operation, including IP addresses,
                  domain names, and browser data.
                </p>

                <h2 className="text-2xl font-bold mt-6">
                  Cookies, Search Engines, and Location Data
                </h2>
                <p>
                  Cookies are used to speed up Internet traffic analysis and
                  facilitate access to the services offered. Users may disable
                  cookies in their browser settings.
                </p>

                <h2 className="text-2xl font-bold mt-6">
                  Rights of the Data Subject
                </h2>
                <p>
                  Users have various rights including confirmation of data
                  existence, rectification, deletion, and restriction of
                  processing.
                </p>

                <h2 className="text-2xl font-bold mt-6">
                  Data Controller and Data Protection Officer
                </h2>
                <p>
                  For any questions or to exercise rights, Users may contact the
                  Data Controller at datipersonali@1verse.live or the Data
                  Protection Officer at support_re@1verse.live.
                </p>

                <h2 className="text-2xl font-bold mt-6">Whistleblowing</h2>
                <p>
                  Reports related to breaches of law can be made through the
                  platform accessible at
                  <a
                    href="https://italia.1verse.live/whistleblowing"
                    className="text-blue-600"
                  >
                    https://italia.1verse.live/whistleblowing
                  </a>
                </p>

                <h2 className="text-2xl font-bold mt-6">Changes</h2>
                <p>
                  This policy may be subject to change. Any material changes
                  will be published prominently on our pages.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};
