import React from "react";
import ImageCard from "../ImageCard";

// const LandingPageFeature = ({ imageUrl, className, ...props }) => {
const LandingPageFeature = () => {
  return (
    <div className="m-10 gap-[38px] ">
      <div className="flex flex-col md:flex-col lg:flex-row flex-start lg:items-center gap-[12px] lg:gap-[38px] pb-16 lg:1 opacity-1 ">
        <div className=" flex flex-col  lg:w-[50%]">
          <ImageCard
            imageUrl="https://storage.googleapis.com/oneverse-dev/realestate/image1.png"
            className="rounded-[12px] sm:rounded-[24px] md:rounded-[48px] opacity-1"
          />
        </div>
        <div className=" flex flex-col lg:w-[50%] ">
          <h1 className="text-[#FF307A] text-[24px] md:text-[40px] text-left font-bold "  style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
            Clients experience property in the Metaverse
          </h1>
          <p className="text-[#330F3E] text-[12px] md:text-[24px] text-left text-justify letter-spacing: 0.05px " style={{ fontFamily: 'Inter, sans-serif' }} >
            We provide you various environments, interactions, and activities
            that your clients can engage with in digital space. Clients can
            customise virtual properties and get a finer look of the property
            tailored for them
          </p>
        </div>
      </div>

      <div className=" flex flex-col md:flex-col lg:flex-row flex-start lg:items-center gap-[12px] lg:gap-[38px] pb-16 lg:1 opacity-1 ">
        <div className=" flex flex-col gap-[30px] lg:w-[50%] lg:hidden">
          <ImageCard
            imageUrl="https://storage.googleapis.com/oneverse-dev/realestate/image2.png"
            className="rounded-[12px] sm:rounded-[24px]  md:rounded-[48px] opacity-1 lg:hidden"
          />
        </div>
        <div className=" flex flex-col lg:w-[50%] lg:hidden">
          <h1 className="text-[#FF307A] text-[24px] md:text-[40px] text-left font-bold lg:hidden" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
            Clients with agents globally
          </h1>
          <p className="text-[#330F3E] text-[12px] md:text-[24px] text-left text-justify lg:hidden letter-spacing: 0.05px" style={{ fontFamily: 'Inter, sans-serif' }}>
            Our agents are spread across the globe to be able to market and
            sell virtual real estate or properties to a global audience.
            Through this global marketplace, sellers can reach a much larger
            audience than if they were limited to selling locally, potentially
            increasing their chances of finding buyers and getting a higher
            price for their virtual assets
          </p>
        </div>        
        
        <div className=" flex flex-col lg:w-[50%] hidden md:flex ">
          <h1 className="text-[#FF307A] text-[24px] md:text-[40px] text-left font-bold hidden lg:flex" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }} >
            Clients with agents globally
          </h1>
          <p className="text-[#330F3E] text-[12px] md:text-[24px] text-left text-justify hidden lg:flex letter-spacing: 0.05px" style={{ fontFamily: 'Inter, sans-serif' }}>
            Our agents are spread across the globe to be able to market and
            sell virtual real estate or properties to a global audience.
            Through this global marketplace, sellers can reach a much larger
            audience than if they were limited to selling locally, potentially
            increasing their chances of finding buyers and getting a higher
            price for their virtual assets
          </p>
        </div>
        <div className=" flex flex-col gap-[30px] lg:w-[50%] hidden lg:flex">
          <ImageCard
            imageUrl="https://storage.googleapis.com/oneverse-dev/realestate/image2.png"
            className="rounded-rounded-[12px] sm:rounded-[24px]  md:rounded-[48px] opacity-1 hidden lg:flex"
          />
        </div>
      </div>

      <div className=" flex flex-col md:flex-col lg:flex-row flex-start lg:items-center gap-[12px] lg:gap-[38px] opacity-1 ">
        <div className=" flex flex-col gap-[12px] lg:gap-[30px] lg:w-[50%]">
          <ImageCard
            imageUrl="https://storage.googleapis.com/oneverse-dev/realestate/image3.png"
            className="rounded-[12px] sm:rounded-[24px] md:rounded-[48px] opacity-1"
          />
        </div>
        <div className=" flex flex-col gap-[12px] lg:gap-[30px] lg:w-[50%]">
          <h1 className="text-[#FF307A] text-[24px] md:text-[40px] text-left font-bold " style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }}>
            Track your earnings
          </h1>
          <p className="text-[#330F3E] text-[12px] md:text-[24px] text-left text-justify  letter-spacing: 0.05px" style={{ fontFamily: 'Inter, sans-serif' }}>
            By tracking their earnings, it would be possible to assess the
            performance and success of agents, determine commission payments,
            and provide insights into the overall economic activity within the
            metaverse.
          </p>
        </div>
      </div>
    </div>
  );
};


export default LandingPageFeature;