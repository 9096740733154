import React from "react";
import { MdLink } from "react-icons/md";
import { FaHome, FaBed, FaCheckCircle } from "react-icons/fa";
import { BsArrows } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { toPascalCase } from "rc-app/utils";
import UserService from "rc-app/services/UserService";
// import { Tabs } from "./tabs";

export const PropertyCard = ({ propertyInfo, handleDetailButton }: any) => {
  const {
    digital_twin_id,
    matterport_id,
    title,
    propertyId,
    propertyLink,
    description,
    location,
    contractType,
    bedrooms,
    area,
    type,
    lifestyle,
    listedOn,
    price,
    isExclusive,
    property_visibility,
    // Exclusive,
    isClientDetails,
  } = propertyInfo;

  return (
    <>
      <div className="flex items-center justify-between flex-wrap max-lg:justify-end max-lg:gap-2">
        <span className="font-bold max-lg:w-full text-lg max-sm:text-base ">
          {toPascalCase(title)}
          {!isExclusive && (
            // <span
            //   className={`ml-2 p-1 px-2 rounded border text-[11px] max-sm:text-[8px] ${
            //     isExclusive === true
            //       ? "border-[#CA8D16] bg-[radial-gradient(ellipse_at_center,#FFE8BA,#FFD787,#FCC046)]"
            //       : isExclusive === false
            //       ? "border-[#ca2961] bg-[radial-gradient(ellipse_at_center,#F9B6CE,#FC7EAB,#FE518F)]"
            //       : ""
            //   }`}
            // >
            //   {Exclusive}
            // </span>
            <span
              className={`ml-2 p-1 px-2 rounded border text-[11px] max-sm:text-[8px] ${
                property_visibility === "exclusive"
                  ? "border-[#CA8D16] bg-[radial-gradient(ellipse_at_center,#FFE8BA,#FFD787,#FCC046)]"
                  : "border-[#ca2961] bg-[radial-gradient(ellipse_at_center,#F9B6CE,#FC7EAB,#FE518F)]"
              } `}
            >
              {property_visibility === "exclusive" ? "EXCLUSIVE" : "NETWORK"}
            </span>
          )}
        </span>
        <span className="flex items-center gap-3 2xl:text-xl text-[#283BBE] font-medium max-md:text-[11px] max-lg:text-[13px]">
          {propertyId}
          {UserService.hasRole("agent") && <NavLink
            to={propertyLink} 
            className="flex items-center gap-2 text-[#FF307A] decoration-dashed"
          >
            Property_link <MdLink size={20} />
          </NavLink>}
        </span>
      </div>
      <p className="text-base max-sm:text-sm">
        {toPascalCase(description.slice(0, 35))}
        <button
          onClick={() => {
            handleDetailButton(digital_twin_id, matterport_id);
          }}
        >
          ....
        </button>
      </p>
      <div className="grid grid-cols-4 gap-3 max-xl:flex max-xl:flex-wrap max-xl:justify-between max-lg:grid-cols-2 items-center max-sm:grid-cols-6">
        <span className="flex items-center gap-2 text-[#283BBE] font-medium max-sm:col-span-2 max-xl:text-sm text-[14px]">
          <FaLocationDot className="text-gray-400" /> {toPascalCase(location)}
        </span>
        <span className="flex items-center gap-2 text-[#283BBE] font-medium max-sm:col-span-2 max-xl:text-sm text-[14px]">
          <FaHome className="text-gray-400" /> {toPascalCase(contractType)}
        </span>
        <span className="flex items-center gap-2 text-[#283BBE] font-medium max-sm:col-span-2 max-xl:text-sm text-[14px]">
          <FaBed className="text-gray-400" /> {bedrooms}
        </span>
        <span className="flex items-center gap-2 text-[#283BBE] font-medium max-sm:col-span-3 max-xl:text-sm text-[14px]">
          <BsArrows className="text-gray-400" /> {area} Sq.Mtr
        </span>
        <span className="text-gray-400 flex items-center font-medium max-sm:col-span-3 max-xl:text-sm text-[14px]">
          Type
          <span className="text-[#283BBE] font-medium flex items-center gap-2">
            <FaCheckCircle className="text-green-700" /> {type}
          </span>
        </span>
        <span className="text-gray-400 flex items-center font-medium max-sm:col-span-3 max-xl:text-sm text-[14px]">
          Life Style
          <span className="text-[#283BBE] font-medium flex items-center gap-2">
            <FaCheckCircle className="text-green-700" /> {lifestyle}
          </span>
        </span>
        <span className="text-gray-400 font-medium max-sm:col-span-3 max-xl:text-sm text-[14px]">
          Listed On
          <span className="text-[#283BBE] font-medium ml-1">{listedOn}</span>
        </span>
        {isClientDetails && (
          <span className="text-gray-400 font-bold max-xl:text-sm text-[14px]">
            {contractType === "sale"
              ? "Price"
              : contractType === "rent" && "Rent"}
            <span className="text-[#283BBE]  font-bold max-xl:text-sm text-[14px] ml-1">
              {"\u20AC"}
              {price}
            </span>
          </span>
        )}
      </div>

      {/* <div className="bg-white shadow-md grid   max-xl:grid-cols-1 max-2xl:grid-cols-7 2xl:grid-cols-7  p-4 gap-3 rounded-md">
        <div className="  max-xl:col-span-1  max-2xl:col-span-2 2xl:col-span-2 flex flex-col gap-2">
          <Tabs
            tabs={[
              { label: "Digital Twin" },
              { label: "Images" },
              { label: "Video(s)" },
            ]}
          />
          <div className="w-full h-full max-h-52 2xl:max-h-80 overflow-hidden rounded-md">
            <img
              src={
                "https://storage.googleapis.com/oneverse-dev/realestate/no_property_image.jpg"
              }
              alt="house"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
        <div className="  max-xl:col-span-1 max-2xl:col-span-5 2xl:col-span-5 flex flex-col justify-between 2xl:gap-8 gap-5">
          <div className="flex items-center justify-between flex-wrap max-lg:justify-end max-lg:gap-2">
            <span className="font-bold max-lg:w-full text-lg max-sm:text-base ">
              {title}
              {isExclusive !== null && (
                <span
                  className={`ml-2 p-1 px-2 rounded border text-[11px] max-sm:text-[8px] ${
                    isExclusive === true
                      ? "border-[#CA8D16] bg-[radial-gradient(ellipse_at_center,#FFE8BA,#FFD787,#FCC046)]"
                      : isExclusive === false
                      ? "border-[#ca2961] bg-[radial-gradient(ellipse_at_center,#F9B6CE,#FC7EAB,#FE518F)]"
                      : ""
                  }`}
                >
                  {Exclusive}
                </span>
              )}
            </span>
            <span className="flex items-center gap-3 2xl:text-xl text-[#283BBE] font-medium max-md:text-[11px] max-lg:text-[13px]">
              {propertyId}
              <NavLink
                to={propertyLink}
                className="flex items-center gap-2 text-[#FF307A] decoration-dashed"
              >
                Property_link <MdLink size={20} />
              </NavLink>
            </span>
          </div>
          <p className="text-base max-sm:text-sm">{description}</p>
          <div className="grid grid-cols-4 gap-3 max-xl:flex max-xl:flex-wrap max-xl:justify-between max-lg:grid-cols-2 items-center max-sm:grid-cols-6">
            <span className="flex items-center gap-2 text-[#283BBE] font-medium max-sm:col-span-2 max-xl:text-sm text-[14px]">
              <FaLocationDot className="text-gray-400" /> {location}
            </span>
            <span className="flex items-center gap-2 text-[#283BBE] font-medium max-sm:col-span-2 max-xl:text-sm text-[14px]">
              <FaHome className="text-gray-400" /> {type}
            </span>
            <span className="flex items-center gap-2 text-[#283BBE] font-medium max-sm:col-span-2 max-xl:text-sm text-[14px]">
              <FaBed className="text-gray-400" /> {bedrooms} Bedrooms
            </span>
            <span className="flex items-center gap-2 text-[#283BBE] font-medium max-sm:col-span-3 max-xl:text-sm text-[14px]">
              <BsArrows className="text-gray-400" /> {area} Sq.Mtr
            </span>
            <span className="text-gray-400 flex items-center font-medium max-sm:col-span-3 max-xl:text-sm text-[14px]">
              Type
              <span className="text-[#283BBE] font-medium flex items-center gap-2">
                <FaCheckCircle className="text-green-700" /> Lorem ipsum
              </span>
            </span>
            <span className="text-gray-400 flex items-center font-medium max-sm:col-span-3 max-xl:text-sm text-[14px]">
              Life Style
              <span className="text-[#283BBE] font-medium flex items-center gap-2">
                <FaCheckCircle className="text-green-700" /> {lifestyle}
              </span>
            </span>
            <span className="text-gray-400 font-medium max-sm:col-span-3 max-xl:text-sm text-[14px]">
              Listed On
              <span className="text-[#283BBE] font-medium ml-1">
                {listedOn}
              </span>
            </span>
            <span className="text-gray-400 max-xl:text-sm text-[14px]">
              Price
              <span className="text-[#283BBE] font-medium 2xl:font-bold max-xl:text-sm text-[14px] ml-1">
                {price}
              </span>
            </span>
          </div>
          <div className="flex items-center justify-between max-xl:items-end  max-xl:flex-col  max-xl:gap-2">
            <div className="flex items-center gap-4 max-xl:justify-between max-xl:items-start  max-xl:w-full">
              <CommissionAmount amount="30,000" />
              <ToursCompleted tours="20" clients="16" />
              <span className="bg-[#FF639A] text-white border-[#FF307A] border rounded-full px-3 py-1 max-xl:text-[11px] text-[16px] text-center">
                Commission Amount{" "}
                <span className="font-semibold">30600</span>
              </span>
              <span className="border-[#283BBE] border rounded-full px-3 py-1 font-semibold max-xl:text-[11px] text-[16px] text-center">
                <span className="text-[#283BBE]">20</span> tours completed by{" "}
                <span className="text-[#283BBE]">16</span> Clients
              </span>
            </div>
            <div className="max-xl:w-full">
              <Button
                title={"Detail"}
                path={`/account/agency-detail/${12}`}
                color="bg-[#283BBE]"
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
