import UserService from "rc-app/services/UserService";

// const RenderOnAuthenticated = ({ children }) =>(UserService.isLoggedIn()) ? children : null;
// {
//     return (
//         <>
//        { UserService.isLoggedIn() ? children : null}
//         </>
//     )
// } 

const RenderOnAuthenticated = ({ children }) => {
    console.log("children", children);
    if (UserService.isLoggedIn()) {
      // Multiple lines of code to execute when condition is true
      // For example:
    //   UserService.doLogin()
      return children;
    } else {
      return (
        // <>
            null
        // </>
      );
    }
  };

export default RenderOnAuthenticated
