import React from "react";

export const CommissionAmount = ({ amount,contractType }:any) => {
  return (
    <span className="bg-[#FF639A]  text-white border-[#FF307A] font-bold border rounded-full px-6 py-1 max-xl:text-[11px] text-[16px] text-center gap-3">
      {
        contractType==='sale' ? "Price" : "Rent"
      } <span className="font-semibold">  {"\u20AC"} {amount}</span>
    </span>
  );
};
