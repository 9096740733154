import React from "react";
// import Button from "../Button";

// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import KingBedIcon from "@mui/icons-material/KingBed";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { toPascalCase } from "rc-app/utils";

interface PropertyCardProps {
  imageUrl: string;
  className?: string;
  title: string;
  commission?: number;
  bedrooms?: String;
  area?: number;
  city?: string;
  contractType?: string;
  price?: number;
  digitalTwinId?: string;
  matterportId?: string;
  exploreButton?: React.ReactNode;
  handleDetailButton?: (digital_twin_id: string, matterport_id: string) => void;
}

const NetworkPropertyCardSquare: React.FC<PropertyCardProps> = ({
  imageUrl,
  className,
  exploreButton,
  title,
  commission,
  bedrooms,
  area,
  city,
  contractType,
  price,
  digitalTwinId,
  matterportId,
  handleDetailButton,
  ...props
}) => {
  console.log("cin", price);
  return (
    <div
      className={`${className} shadow-md`}
      {...props}
      onClick={() => {
        handleDetailButton(digitalTwinId, matterportId);
      }}
    >
      <img
        src={
          imageUrl !== null
            ? imageUrl
            : "https://storage.googleapis.com/oneverse-dev/realestate/no_property_image.jpg"
        }
        className={` rounded ${className}`}
        {...props}
      />
      <div className="opacity-1 overflow-hidden ">
        <div className="px-2">
          {!exploreButton && (
            <>
              <h1 className="text-2x1 md:text-[24px] font-semibold text-[#1B191B] text-left py-3 gap-2">
                {title}
              </h1>
              <div className="grid grid-cols-2  sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 gap-2 mb-4">
                {/* <div className=" flex flex-wrap items-center mb-2 xs:text-[8px] sm:text-[10px] md:text-[12px] lg:text-[13px] xl:text-[15px] ">
                  <span className="text-[#283BBE] mr-1 ">{"Commission  " } </span>
                  <span className=" text-[#010101]  xl:text-[13px] ">
                     {commission.toFixed(1)}
                  </span>
                </div> */}
                <div className="  flex items-center mb-2 font-semibold">
                  <span className=" text-[#283BBE]">
                    <LocationOnIcon />
                  </span>
                  <span className="ml-1 text-[#010101] xs:text-[8px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[17px] ">
                    {toPascalCase(city)}
                  </span>
                </div>
                <div className="  flex  items-center mb-2  xs:text-[8px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[17px] ">
                  <span className=" text-[#283BBE]">
                    <KingBedIcon />
                  </span>
                  <span className=" ml-1 text-[#010101]  ">
                    {bedrooms} {" Bedrooms"}
                  </span>
                </div>
                <div className=" flex  items-center mb-2  xs:text-[8px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[17px] ">
                  <span className=" text-[#283BBE]">
                    <OpenInFullIcon />
                  </span>
                  <span className="ml-1 text-[#010101]  xl:text-[14px] ">
                    {area} Sq.Mtr
                  </span>
                </div>
              </div>
            </>
          )}
          {exploreButton && (
            <>
              <h1 className="text-2x1 md:text-[22px] font-semibold text-[#1B191B] text-left py-3 gap-2">
                {title}
              </h1>
              <div className="grid grid-cols-2  sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2 mb-4">
                <div className=" flex  items-center mb-2  xs:text-[8px] sm:text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px] ">
                  <span className=" text-[#010101]">
                    <LocationOnIcon className="text-[#283BBE]" />
                  </span>
                  <span className="ml-1 text-[#010101]  xl:text-[14px] ">
                    {city}
                  </span>
                </div>
                <div className="  flex  items-center mb-2  xs:text-[8px] sm:text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px]">
                  <span className=" text-[#010101]">
                    <KingBedIcon className="text-[#283BBE]" />
                  </span>
                  <span className=" ml-1 text-[#010101]  ">
                    {bedrooms} Rooms
                  </span>
                </div>
                <div className="  flex items-center mb-2 font-semibold xs:text-[8px] sm:text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px]">
                  <span className=" text-[#010101]">
                    <OpenInFullIcon className="text-[#283BBE]" />
                  </span>
                  <span className="ml-1 text-[#010101] xs:text-[8px] sm:text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px] ">
                    {area} Sq.Mtr
                  </span>
                </div>
              </div>
              {/* <div className="flex flex-wrap gap-2">
                  <div className="flex-1 ">
                    fsdfsdhfsdhgfhsdgfj
                  </div>
                  <div className="flex-1 ">
                    fsdfsdhfsdhgfh
                  </div>
                  <div className="flex-1 ">
                    fsdfsdhfsdhgfhsdgfj
                  </div>
                  <div className="flex-1 ">
                    fsdfsdhfsdhgfhsdgfj
                  </div>

                </div> */}
            </>
          )}
        </div>
        {!exploreButton && (
          <div className="flex mb-4 px-2">
            <div className="  flex items-center w-[40%] xs:text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[16px]">
              {/* <span className="font-bold text-[#001CDD] xs:text-[12px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[24px] ">
                {" "}
                {"\u20AC"}{" "}
              </span> */}
              <span className=" text-[#001CDD] font-bold ">
                {contractType === "rent"
                  ? "Rent " + "\u20AC " + price
                  : "Price " + "\u20AC " + price}
              </span>
            </div>
            {/* <div className=" w-[60%] flex justify-end items-center">
              <Button
                children="Sell on metaverse"
                className="rounded-1 opacity-1 bg-[#FF307A] w-[98%] xs:w-[196px] sm:w-[260px] h-[51px] sm:h-[51px]"
              />
            </div> */}
          </div>
        )}

        {exploreButton && <div className="flex ">{exploreButton}</div>}
      </div>
    </div>
  );
};

export default NetworkPropertyCardSquare;
