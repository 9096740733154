import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./styles.css";

// interface CustomDataGridProps extends DataGridProps {
//   selectionModel: any[];
// }

const columns: GridColDef[] = [
  { field: "property_id", headerName: "Property ID", width: 70 },
  { field: "title", headerName: "Title", width: 230 },
  { field: "visibility", headerName: "Visibility", width: 130 },
  {
    field: "location",
    headerName: "Location",
    type: "number",
    width: 90,
  },
  {
    field: "area",
    headerName: "Area in Sq. Mtr",
    // description: 'This column has a value getter and is not sortable.',
    // sortable: false,
    width: 160,
    // valueGetter: (value :any, row:any) => `${row.firstName || ''} ${row.lastName || ''}`,
  },
  { field: "rooms", headerName: "Rooms", width: 70 },
  { field: "price", headerName: "Price", width: 70 },
  { field: "commision", headerName: "Commision", width: 70 },
];

// const rows1 = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];
// interface Props {
//   digitalTwin: any[]; // Define digitalTwin prop as an array of any
// }

export default function DataTable({ rows, handleSelectionChange }: any) {
  console.log("agencyxx", rows, handleSelectionChange);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        onRowSelectionModelChange={handleSelectionChange}
      />
    </div>
  );
}
