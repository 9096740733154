import Button from "rc-app/components/Button"
import React, {
  // useState,
} from "react"



import {
  FaCheckCircle
} from "react-icons/fa"




// import { useLocation, useNavigate } from "react-router-dom";








export const Pricing = () => {


  return (
    <>
      <div className="w-full h-full flex container max-w-screen-2xl bg-[#F6F6F8]">
        <div className="flex w-full flex-col items-center py-4 md:py-6 px-4  gap-3 md:gap-8 ">
          <div className="flex flex-col flex-start  gap-2 " >
            <div className="flex items-center text-[18px] sm:text-[22px] md:text-[28px] lg:text-[32px] xl:text-[36px] gap-2" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }}>
              <span className="font-extrabold text-[#1B0039] ">Pricing to deliver value for each agent</span>
            </div>
          </div>
          <div className="w-[100%] flex flex-col md:flex-row container max-w-screen-lg gap-3 md:gap-8  ">
            <div className="w-full md:w-1/2   flex flex-col  md:gap-2 px-4 md:px-4 pb-4 md:pb-4 shadow-md bg-white rounded-md">
              <div className="w-full flex  flex-col justify-center items-center">
                <div className=" w-1/2 flex items-center">
                  <Button
                    children="Sell on metaverse"
                    className="rounded-1 opacity-1 bg-[#283BBE] w-[98%] xs:w-[196px] sm:w-[260px] h-[51px] sm:h-[51px]"
                  />
                </div>
                <div className="flex items-center text-[18px] sm:text-[22px] md:text-[28px] lg:text-[32px] xl:text-[36px] gap-2" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }}>
                  <span className="font-semibold text-[#E400A8] ">$100/month</span>
                </div>
              </div>
              <div className="w-full flex flex-col justify-center items-center gap-4" >

                <div className="flex w-full  gap-2">
                  <div className="w-15%">
                    <FaCheckCircle className="icon-outlined fa-regular text-lg text-[#31B957]"/>
                  </div>
                  <div className=" text-start text-[11px] font-semibold sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                    <span className="break-words just">Monthly platform fee with unlimited property listings.</span>
                  </div>
                </div>

                <div className="flex w-full  gap-2">
                  <div className="w-15%">
                    <FaCheckCircle className="icon-outlined fa-regular text-lg text-[#31B957]"/>
                  </div>
                  <div className="text-start text-[11px] font-semibold sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                    <span className="break-words just">Monthly hardware fee to provide cutting edge hardware Oculus quest 3 or equivalent and 43" to 55" 
                      Smart TV for impactful presentations</span>
                  </div>
                </div>

                <div className="flex w-full  gap-2">
                  <div className="w-15%">
                    <FaCheckCircle className="icon-outlined fa-regular text-lg text-[#31B957]"/>
                  </div>
                  <div className=" text-start text-[11px] font-semibold sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                    <span className="break-words just">Monthly support services fee</span>
                  </div>
                </div>

              </div>
            </div>
            <div className="w-full md:w-1/2  flex flex-col items-center md:gap-2 px-4 md:px-4 pb-4 md:pb-4 shadow-md bg-white rounded-md">
              <div className="w-full flex  flex-col justify-center items-center">
                <div className=" w-1/2 flex items-center">
                  <Button
                    children="Sell on metaverse"
                    className="rounded-1 opacity-1  bg-gradient-to-t from-[#20B038] to-[#60D66A] w-[98%] xs:w-[196px] sm:w-[260px] h-[51px] sm:h-[51px]"
                  />
                </div>
                <div className="flex items-center text-[18px] sm:text-[22px] md:text-[28px] lg:text-[32px] xl:text-[36px] gap-2" style={{ fontFamily: 'Cabinet Grotesk, sans-serif' }}>
                  <span className="font-semibold text-[#E400A8] ">$150/month</span>
                </div>
              </div>
              <div className="w-full flex flex-col justify-center items-center gap-4" >

                <div className="flex w-full  gap-2">
                  <div className="w-15%">
                    <FaCheckCircle className="icon-outlined fa-regular text-lg text-[#31B957]"/>
                  </div>
                  <div className=" text-start text-[11px] font-semibold sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                    <span className="break-words just">Monthly platform fee with access to Network properties globally for sale to prospective clients.</span>
                  </div>
                </div>

                <div className="flex w-full  gap-2">
                  <div className="w-15%">
                    <FaCheckCircle className="icon-outlined fa-regular text-lg text-[#31B957]"/>
                  </div>
                  <div className="text-start text-[11px] font-semibold sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                    <span className="break-words just">Monthly hardware fee to provide cutting edge hardware (Oculus quest 3 or equivalent and 43 to 55”
                     Smart TV for impactful presentations</span>
                  </div>
                </div>

                <div className="flex w-full  gap-2">
                  <div className="w-15%">
                    <FaCheckCircle className="icon-outlined fa-regular text-lg text-[#31B957]"/>
                  </div>
                  <div className=" text-start text-[11px] font-semibold sm:text-[13px] md:text-[12px] lg:text-[13px] xl:text-[14px]">
                    <span className="break-words just">Monthly support services fee</span>
                  </div>
                </div>

              </div>

            </div>
          </div>

         

        </div>
      </div>
    </>
  )
}