import React from "react";

// import NotificationsIcon from "@mui/icons-material/Notifications";

interface ClientCardProps {
  //   property_images?: string;
  //   location?: string;
  //   contract_type?: string;
  //   children?: React.ReactNode;
  //   bedrooms?: number;
  //   area_sqm?: number;
  //   type?: string;
  //   lifestyle?: string;
  //   listing_date?: string;
  //   price?: number;
  className?: string;
  //   isAgencyList?: Boolean;
  //   property_visibility?: string;
  //   property_id?: string;
  //   property_link?: string;
  title?: string;
  //   description?: string;
  //   agent_commission?: number
  //   agency_name?: string;
  //   digital_twin_id?: string;
  //   matterport_id?: string;
  isClient: boolean;
  isProperty: boolean;
  client_id?: string;
  isClientDetails?: boolean;
  customer_id?: string;
  property_shortlisted?: number;
  data: { value: number | string; icon: React.ReactNode }[];
  handleDetailButton?: (digital_twin_id: string, matterport_id: string) => void;
  handleTabButton?: (
    digital_twin_id: string,
    matterport_id: string,
    tab: string
  ) => void;
  removeButton?: React.ReactNode;
  detailButton?: React.ReactNode;
}

const ClientCard: React.FC<ClientCardProps> = ({
  //   property_images,
  //   location,
  //   contract_type,
  //   children,
  //   bedrooms,
  //   area_sqm,
  //   type,
  //   lifestyle,
  //   listing_date,
  //   price,
  //   isAgencyList,
  //   property_visibility,
  //   property_id,
  //   property_link,
  title,
  //   description,
  //   agent_commission,
  //   agency_name,
  //   digital_twin_id,
  //   matterport_id,
  handleDetailButton,
  handleTabButton,
  className = "",
  isProperty,
  isClient,
  client_id,
  isClientDetails,
  customer_id,
  property_shortlisted,
  data,
  removeButton,
  detailButton,
  ...props
}) => {
  return (
    <div
      className={` flex  lg:gap-5  flex-col ${
        isClientDetails && "lg:flex-row"
      } xl:flex-row  w-full p-4`}
      {...props}
    >
      <div
        className={`flex flex-col lg:gap-2  w-full justify-center ${
          isClientDetails && "lg:w-2/3"
        } xl:w-2/3 gap-3 flex-grow `}
      >
        {/* Property details */}
        <div className={`flex items-center w-full gap-5`}>
          <div className={`inline-block`}>
            <h1 className="font-bold text-[#1B191B]  text-2xl sm:text-2xl max-md:flex-wrap md:whitespace-nowrap ">
              {title}
            </h1>
          </div>

          {isClient && (
            <div
              className={` items-start flex  ${
                isClientDetails ? "justify-self-end " : "justify-self-end"
              }`}
            >
              <button
                className="text-[#283BBE]  cursor-pointer focus:outline-none font-semibold text-xl sm:text-xl "
                onClick={() => console.log("Clicked on Client ID")}
              >
                {client_id}
              </button>
            </div>
          )}
          {/* {isClient && !isClientDetails && (
            <div className="whitespace-nowrap ml-auto text-[#283BBE] flex  items-center justify-center sm:items-start sm:flex-start  sm:justify-start lg:justify-start mb-2 sm:mb-0">
              <span
                className={`opacity-50 text-[8px] sm:text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px] mr-1 `}
              >
                <NotificationsIcon /> 
              </span>
              <span
                className={` text-[#283BBE] font-medium  text-[10px] sm:text-[10px] md:text-[10px] lg:text-[12px] xl:text-[14px] `}
              >
                New Message
              </span>
            </div>
          )} */}
        </div>

        {isClient && (
          <div className="grid grid-cols-auto-fit-client gap-1 w-full ">
            {data.map((item, index) => (
              <div
                key={index}
                className=" relative group flex flex-wrap flex-col  sm:items-start sm:flex-start  sm:justify-start lg:justify-start sm:mb-0"
              >
                {item &&
                  item.icon &&
                  typeof item.value !== "undefined" && ( // Check if item and icon exist, and value is defined
                    <span
                      className={`opacity-50 ${
                        index === 0
                          ? "text-[15px] xl:text-[16px]"
                          : "text-[14px] xl:text-[15px]"
                      } `}
                    >
                      {item.icon}
                    </span>
                  )}
                {item && typeof item.value !== "undefined" && (
                  <>
                    {/* Highlighted Code Start */}
                    <span
                      className={`text-[#283BBE] ${
                        index === 0
                          ? "lg:text-[15px] xl:text-[16px]"
                          : "lg:text-[14px] xl:text-[15px]"
                      } ${
                        typeof item.value === "string" &&
                        item.value.includes("@")
                          ? "truncate"
                          : ""
                      }`}
                      style={
                        typeof item.value === "string" &&
                        item.value.includes("@")
                          ? { maxWidth: "150px" } // Limit the width for email IDs
                          : {}
                      }
                      title={
                        typeof item.value === "string" &&
                        item.value.includes("@")
                          ? item.value
                          : ""
                      }
                    >
                      {typeof item.value === "string"
                        ? item.value.charAt(0).toUpperCase() +
                          item.value.slice(1)
                        : item.value}
                    </span>
                    {/* Highlighted Code End */}
                  </>
                )}
                {/* Tooltip for Email */}
                {typeof item.value === "string" && item.value.includes("@") && (
                  <div className="absolute bottom-full mb-1 left-0 w-max bg-[#FF307A] text-white text-sm rounded-full px-3 py-2 whitespace-nowrap hidden group-hover:block">
                    {item.value}
                    <div className="absolute top-full left-4 w-2 h-2 bg-[#FF307A] transform rotate-45"></div>
                  </div>
                )}
                {/* {item &&
                  typeof item.value !== "undefined" && ( // Check if item exists and value is defined
                    <span
                      className={` text-[#283BBE] ${
                        index === 0
                          ? "lg:text-[15px] xl:text-[16px]"
                          : "lg:text-[14px] xl:text-[15px]"
                      } ${item.value === "Agency" ? "col-span-2" : "text"} `}
                    >
                      {typeof item.value === "string"
                        ? item.value.charAt(0).toUpperCase() +
                          item.value.slice(1)
                        : item.value}
                    </span>
                  )} */}
              </div>
            ))}
          </div>
        )}
        {/* {isClientDetails && (
              <div className="hidden lg:flex items-center justify-center flex-col  sm:flex-row gap-3 w-[440px]  ">
                <div className="flex text-white items-center justify-center  bg-[#FF307A] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
                  {`5 Property Tours completed `}
                </div>
                <div className="flex  items-center justify-center text-black  border border-[#283BBE] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]  ">
                  5 Property shortlisted xxx
                </div>
              </div>
            )} */}

        {isClient && isClientDetails && (
          <div className="flex  w-full items-center justify-end flex-row gap-3   ">
            {/* <div className="flex text-white items-center justify-center  bg-[#FF307A] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
              {`5 Property Tours completed `}
            </div> */}
            <div className="flex  items-center justify-center text-black  border border-[#283BBE] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px]  ">
              {property_shortlisted === 0
                ? " No Property shortlisted"
                : `${property_shortlisted} Property shortlisted`}
            </div>
          </div>
        )}

        <div
          className={`flex items-center w-full flex-col sm:flex-row  justify-between ${
            isProperty && isClientDetails && "justify-end"
          }  gap-2 sm:gap-2 md:gap-2  sm:w-full `}
        >
          {isClient && !isClientDetails && (
            <div className="flex max-sm:w-full items-center justify-center sm:flex-row gap-2  ">
              {/* <div className="flex max-sm:w-full items-center justify-center text-white bg-[#FF307A] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
                <span className="flex items-center">
                  5 Property Tours completed
                </span>
              </div> */}
              <div className="flex max-sm:w-full text-black items-center justify-center border border-[#283BBE] rounded-full text-[10px] sm:text-[10px] md:text-[12px] lg:text-[15px] xl:text-[15px] px-4 h-[45px] sm:h-[40px] ">
                {property_shortlisted === 0
                  ? " No Property shortlisted"
                  : `${property_shortlisted} Property shortlisted`}
              </div>
            </div>
          )}
          {/* <div className="flex max-xl:w-[55%] items-center flex-col sm:flex-row gap-2 xl:justify-end ">
                                    
                                  </div> */}
          <div className="flex max-md:w-full items-center justify-center md:justify-end gap-1 ">
            {detailButton && detailButton}
            {removeButton && removeButton}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
