import UserService from "rc-app/services/UserService";
import React from "react"; // useEffect, useState

import axios from "axios";
// import Footer from 'rc-app/components/Footer/footer';

import { Box } from "@mui/material";
UserService.role();

export const getProperties = async () => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/mp_digital_twin?sort_by=created_at&sort_order=-1&page=0&limit=20`;
    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching....", error);
    return [""];
  }
};

export const getDigitalTwinByAgent = async (email: any) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/mp_digital_twin/agent/properties/${email}?page=0&limit=20&sort_by=created_at&sort_order=-1`;
    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching....", error);
    return [""];
  }
};

export const getAgentList = async () => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_agent?sort_by=created_at&sort_order=-1&page=0&limit=200`;
    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [];
  }
};

export const getAgentByEmail = async (email: any) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/realestate/re_agent/profile/${email}`;
    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [];
  }
};

export const getAgentById = async (id: any) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_agent/${id}`;
    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [];
  }
};

export const getDigitalTwinById = async (
  digitalTwinId: any,
  reveal_address: boolean
) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/mp_digital_twin/${digitalTwinId}?reveal_address=${reveal_address}`;
    // const response = await axios.get(API_URL, {
    //   headers: {
    //     'Authorization': `Bearer ${UserService.getToken()}`,
    //     'Content-Type': 'application/json',
    //   }
    // })
    // return response
    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    const digital_twin = data.digital_twin;
    return digital_twin;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [""];
  }
};

export const createVirtualToor = async (agentId: any, propertyId: any) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/re_virtual_tour`;
    const PAYLOAD = {
      agent_id: agentId,
      matterport_id: propertyId,
    };
    const response = await axios.post(API_URL, PAYLOAD, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    // const data = await response;
    // console.log("json post", data);
    // const vm_agency_list= data.vm_agency_list
    return response.data;

    // Assuming the API response is an array of objects with 'label' and 'value' properties
    //   return Array.isArray(vm_agency_list)
    //     ? vm_agency_list.map((option) => ({ label: option.name, value: option._id }))
    //     : [];
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [""];
  }
};

export const getVirtualTourByAgent = async (email_id: any, agent_id: any) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_virtual_tour/email_id/${email_id}/${agent_id}?page=0&limit=20&sort_by=created_at&sort_order=-1`;
    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    const re_virtual_tour_list = data.re_virtual_tour_list;
    return re_virtual_tour_list;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [""];
  }
};

export const joinVirtualTourByJoinCode = async (
  joinCode: any,
  channel: any
) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/action/re_virtual_tour/${joinCode}`;
    const PAYLOAD = {
      tokenType: "rtc",
      channel: channel,
      role: "subscriber",
      expire: 1800000,
    };
    const response = await axios.post(API_URL, PAYLOAD, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [""];
  }
};

export const getAgencyList = async () => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_agency?sort_by=created_at&sort_order=-1&page=0&limit=20`;
    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [];
  }
};

export const createReCustomer = async (jsonData: any) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/re_customer`;
    const PAYLOAD = {
      ...jsonData,
    };
    console.log("jsonpayload", jsonData);

    const response = await axios.post(API_URL, PAYLOAD, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [""];
  }
};

export const getAgencyByAgentId = async (email_id: any) => {
  try {
    return getAgentByEmail(email_id).then(async (res) => {
      const agencyId = res.agent.agency["id"];
      const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/landing/showcase_properties?agency_id=${agencyId}&sort_by=created_at&sort_order=-1&page=0&limit=20`;
      const response = await axios.get(API_URL);
      return response.data;
    });
  } catch (error) {
    console.error("Error fetching....", error);
    return [""];
  }
};

export const getRtcToken = async (channelName: any) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/utils/actions/getRtcTokenForSelf`;
    const PAYLOAD = {
      tokenType: "rtc",
      channel: channelName,
      // "channel": 'zaid123',
      role: "subscriber",
      expire: 360000,
    };
    const response = await axios.post(API_URL, PAYLOAD, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [""];
  }
};

export const getAgoraAppId = async (networkCode: any) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/network_manager/views/vm_networks/code/${networkCode}`;

    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching....", error);
    return [""];
  }
};

export const eventUpdateStream = async (
  entity_type: any,
  entity_id: any,
  actor: any,
  action: any,
  is_action_success: any
) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/event_update_stream_log`;
    const PAYLOAD = {
      entity_type: entity_type,
      entity_id: entity_id,
      actor: actor,
      action: action,
      is_action_success: is_action_success,
    };
    console.log("eventUpdateStreamPayload", PAYLOAD);
    const response = await axios.post(API_URL, PAYLOAD, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    // const data = await response;
    // console.log("json post", data);
    // const vm_agency_list= data.vm_agency_list
    return response.data;

    // Assuming the API response is an array of objects with 'label' and 'value' properties
    //   return Array.isArray(vm_agency_list)
    //     ? vm_agency_list.map((option) => ({ label: option.name, value: option._id }))
    //     : [];
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [""];
  }
};

export const addPropertyToShortlist = async (
  customerId: any,
  jsonData: any
) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/agent/add_property_to_client_property_shortlist/${customerId}`;
    const PAYLOAD = [...jsonData];
    console.log("jsonpayload", PAYLOAD);

    const response = await axios.put(API_URL, PAYLOAD, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [""];
  }
};

export const getCrouselProperties = async () => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/landing/carousel_properties?sort_by=created_at&sort_order=-1&page=0&limit=20`;
    const response = await axios.get(API_URL);
    console.log("lll", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching....", error);
    return [""];
  }
};

export const getVirtualtourByJoincode = async (joincode: any) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/views/re_virtual_tour/join_code/${joincode}`;

    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching....", error);
    return [""];
  }
};

export const deleteClient = async (clientId: any) => {
  try {
    const API_URL = `${process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL}/api/v1/realestate/actions/re_customer/${clientId}`;

    const response = await axios.delete(API_URL, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
        "Content-Type": "application/json",
      },
    });
  
    return response.data;
  } catch (error) {
    console.error("Error fetching gender options:", error);
    return [""];
  }
};


// export const OnlineStatyusChecker=(isOnline, setIsOnline)=>{

//       const handleOnline = () => {
//           setIsOnline(true);
//       };

//       const handleOffline = () => {
//           setIsOnline(false);
//       };

//       // Add event listeners
//       window.addEventListener('online', handleOnline);
//       window.addEventListener('offline', handleOffline);

//       // Cleanup: Remove event listeners on component unmount
//       return () => {
//           window.removeEventListener('online', handleOnline);
//           window.removeEventListener('offline', handleOffline);
//       };

// }

export const Home = () => {
  // console.log('agentId',agentId);
  // agentId && createVirtualToor(agentId).then(response=>{
  //     console.log('res123', response);
  // })

  return (
    <Box sx={{ height: "100vh", overflow: "scroll", pb: 6, mb: 2 }}>
      <div className="bg-space h-[100%] w-[100%]">HomePage</div>
      <footer>{/* <Footer darkMode={true} /> */}</footer>
    </Box>
  );
};

// export const Home = () => {
//   return (
//     <Box p={3}  sx={{ height: '100vh', overflow: "scroll", }}> {/* Use Box component for scrolling */}
//     <Paper elevation={3}> {/* Wrap your content in Paper */}
//     <Container>
//     <Typography variant="subtitle1">Home Page</Typography>
//     </Container>
//     </Paper>
//     </Box>
//   );
// };
