import Keycloak from "keycloak-js";
// console.log('process', process.env);
// let realm, url;

// if (process.env.NODE_ENV === 'production') {
//   realm = process.env.REACT_APP_KEYCLOAK_REAL_PROD;
//   url = process.env.REACT_APP_KEYCLOAK_URL;
// } else {
//   realm = process.env.REACT_APP_KEYCLOAK_REALM_DEV;
//   url = process.env.REACT_APP_KEYCLOAK_URL;
// }

const _kc = new Keycloak({
  realm: process.env.REACT_APP_ONEVERSE_KEYCLOAK_REALM,
  url: process.env.REACT_APP_ONEVERSE_KEYCLOAK_URL,
  clientId: 're-agent',
});


// const _kc = new Keycloak({
//   realm: '1verse-dev-realestate',
//   url: 'https://keycloak.dev.1verse.live',
//   clientId: 'react-admin',
// });


/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 * 
 * @param onAuthenticatedCallback
 */


const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    // onLoad: 'login-required',
    onLoad: 'check-sso',
    checkLoginIframe: false,
    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    // pkceMethod: 'S256',
  })
    .then(() => {
      // if (!authenticated) {
      //   console.log("user is not authenticated..!");
      //   // doLogin()
      // }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getUseremail = () => _kc.tokenParsed?.email;


// const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));
const checkRole = (roles) => Array.isArray(roles) && roles.some((role) => _kc.hasRealmRole(role));
const hasRole = (role) => _kc.hasRealmRole(role)

const findRole = (role)=> _kc.realmAccess.roles.filter(temp=>role===temp)

const role= (role) =>{
  console.log('_kc', process.env.REACT_APP_ONEVERSE_KEYCLOAK_URL);
  console.log('_kc', process.env.REACT_APP_ONEVERSE_KEYCLOAK_REALM);
  console.log('_kc', process.env.REACT_APP_ONEVERSE_REALESTATE_SERVER_URL);
  console.log('_kc1', _kc);
 

     
  return _kc.realmAccess?.roles.filter(temp=>role===temp)
}

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  getUseremail,
  hasRole,
  role,
  findRole,
  checkRole
};

export default UserService;