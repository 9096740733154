import * as React from "react";

interface FormData {
  first_name: string;
  last_name: string;
  email_id: string;
  mobile_number: string;
  city: string;
  province: string;
  country: string;

  // gender: string,
  // profile_pic:string,

  // address: {
  //     address_line1: string,
  //     address_line2: string,
  //     landmark: string,
  //     postal_code: string,
  //     city: string,
  //     province: string,
  //     country: string,
  // },
  // property_shortlist: any[];
  // property_shortlist: {
  //     mp_digital_twin_id:string,
  //     has_viewed:Boolean,
  //     has_completed_virtual_tour: Boolean,
  //     seller_agency_id: string,
  //     buyer_agent_id:string,
  //     added_by:string,
  //     is_removed:Boolean

  // }[],

  // agent_id: [],
  // joined_date: ""

  // Add other form fields here with their types
}

interface Props {
  formData: FormData;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // setFormData: ()=> void
}

const AddClientForm: React.FC<Props> = ({ formData, onInputChange }) => {
  console.log("eee", formData);
  // const handleInputChange = (e : any ) => {
  //     const { name, value } = e.target;
  //     setFormData({ ...formData, [name]: value });
  //   };

  return (
    <div className="w-full mx-auto ">
      <form className="flex flex-col gap-3 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full gap-2">
          <div className="flex flex-col">
            <label
              htmlFor="first_name"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              className="w-full mt-1 focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
              onChange={onInputChange}
              required
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="last_name"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={onInputChange}
              required
              className="w-full mt-1 focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="email_id"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email_id"
              name="email_id"
              value={formData.email_id}
              onChange={onInputChange}
              required
              className="w-full mt-1 focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="mobile_number"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile_number"
              name="mobile_number"
              value={formData.mobile_number}
              required
              onChange={onInputChange}
              className="w-full mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="city_input"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              City
            </label>
            <input
              type="text"
              id="city_input"
              name="city"
              value={formData.city}
              onChange={onInputChange}
              className="w-full mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="province_input"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              Province
            </label>
            <input
              type="text"
              id="province_input"
              name="province"
              value={formData.province}
              onChange={onInputChange}
              className="w-full mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="country_input"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              Country
            </label>
            <input
              type="text"
              id="country_input"
              name="country"
              value={formData.country}
              onChange={onInputChange}
              className="w-full mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text-[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
            />
          </div>
        </div>

        {/* <div className="flex flex-row w-full gap-2">
          <div className="flex flex-col w-full ">
            <label
              id="first_name"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              className=" w-[70%] mt-1 focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
              onChange={onInputChange}
              required
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              id="last_name"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={onInputChange}
              required
              className="w-[70%] mt-1 focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              id="email"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              Email Address
            </label>
            <input
              type="email_id"
              id="email_id"
              name="email_id"
              value={formData.email_id}
              onChange={onInputChange}
              required
              className="w-[70%] mt-1 focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              id="mobile_number"
              className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold"
            >
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile_number"
              name="mobile_number"
              value={formData.mobile_number}
              required
              onChange={onInputChange}
              className="w-[70%] mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]"
            />
          </div>
        </div> */}

        {/* <div className='flex flex-row w-full gap-2'>                     */}
        {/* <div className="flex flex-col w-1/4">
                        <label id="gender_label" className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">Gender</label>
                        <select name="gender" className="w-[70%] mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]" >
                            <option value="male" selected>Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label id="date_of_birth" className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">Date of Birth</label>
                        <input type="date" id="date_of_birth" name="date_of_birth" value="1994-08-16" onChange={onInputChange}
                            className=" w-[70%] mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]" />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label id="profile_pic" className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">Profile Picture</label>
                        <input type="file" id="profile_pic" name="profile_pic" onChange={onInputChange} 
                            className=" w-[100%] mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]" />
                    </div>
                    */}
        {/* <div className="flex flex-col w-1/4">
                        <label id="city_label" className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">City</label>
                        <input type="city_input" id="city_input" name="city" value={formData.city} 
                        onChange={onInputChange} className="w-[70%] mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]" />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label id="province_label" className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">province</label>
                        <input type="province_label" id="province_label" name="province" value={formData.province} 
                        onChange={onInputChange} className="w-[70%] mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]" />
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label id="country_label" className="xs:text-[9px] sm:text-[13px] md:text-[13px] lg:text-[15px] xl:text-[15px] text-gray-400 font-semibold">Country</label>
                        <input type="country_label" id="country_label" name="country" value={formData.country} 
                        onChange={onInputChange} className="w-[70%] mt-1 px-3 py-2 rounded-lg outline-none text-black bg-transparent duration-200 border border-gray-300 xs:text-[9px] sm:text[11px] md:text-[13px] lg:text-[15px] xl:text-[15px]" />
                    </div> */}

        {/* </div> */}

        {/* <div className="flex justify-end">
                    <button type="submit" className="bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600">Submit</button>
                </div> */}
      </form>
    </div>
  );
};

export default AddClientForm;
