import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export class Connecting extends Component<{}, {}> {
  constructor(props: {}) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    return (
      <div className="connecting-container">
        {/* <div className="connecting-title">
          CONNECTING...
        </div> */}
        <CircularProgress color="inherit" />
      </div>
    );
  }
}
