import React from "react";

interface ImageCardProps {
  imageUrl: string;
  className?: string;
  [key: string]: any; // to allow any other props
}

const ImageCard: React.FC<ImageCardProps> = ({ imageUrl, className, ...props }) => {
  return (
    <div className={` ${className}`} {...props}>
      <img src={imageUrl} className={`  ${className}`} {...props} />
    </div>
  );
};

export default ImageCard;


// import React from "react";

// const ImageCard = ({ imageUrl, className, ...props }) => {
//   return (
//     <div
//       className={` ${className}`}
//       {...props}
//     >
//       <img src={imageUrl} className={`  ${className}`} {...props} />
//     </div>
//   );
// };

// export default ImageCard;