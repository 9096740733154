import React from 'react';
import { useState } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";

export default function MapView({ geo_location }: any) {

  const position = geo_location && { lat: parseFloat(geo_location[0]), lng: parseFloat(geo_location[1]) };

  const [open, setOpen] = useState(false);

  console.log('location', position);
  
  return (
    <div>
      {
        position !== null ? (
          <div style={{ height: "60vh", maxWidth:'100%' }}>
            <APIProvider apiKey={'AIzaSyB5Exq1_qnljop8j_vjRNB_9kWXGU3fMYg'}>

              <Map
                style={{width: '68vw', height: '100%'}}
                defaultZoom={10}
                center={position}
                // defaultCenter={position}
                gestureHandling={'auto'}
                disableDefaultUI={true}
                // mapId={'301a8c47f16bbfc'}
                mapId={'99de2d9702db241'}
                scaleControl={true}
              >
                <AdvancedMarker
                  position={position}
                  title={'AdvancedMarker with customized pin.'}
                  onClick={()=>setOpen(true)}
                  >
                  <Pin
                    background={'#22ccff'}
                    borderColor={'#1e89a1'}
                    glyphColor={'#0f677a'}
                    ></Pin>
                </AdvancedMarker>

                {open && (
                  <InfoWindow position={position} onCloseClick={() => setOpen(false)} maxWidth={200}>
                    <p>
                      This is the content for another infowindow with <em>HTML</em>
                      -elements.
                    </p>
                  </InfoWindow>
                )}

              </Map>
           
          </APIProvider >
          </div>
          
          // <div style={{ height: "60vh", maxWidth:'100%', overflow:'hidden'}}>
          // <APIProvider apiKey={'AIzaSyB5Exq1_qnljop8j_vjRNB_9kWXGU3fMYg'}>
            
          //     <Map
          //       style={{width: '68vw', height: '100vh'}}
          //       defaultCenter={position}
          //       defaultZoom={5}
          //       gestureHandling={'greedy'}
          //       disableDefaultUI={true}
          //     />
            
           
          // </APIProvider>
          // </div>
        ) : (
          <div>Location not available</div>
        )
      }
    </div>

    // <div className='flex w-full'>
    //   {
    //     position!== null ? (
    //       <iframe
    //             // src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15762.411164781402!2d38.74740455!3d9.00864745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2set!4v1720697346820!5m2!1sen!2set"
    //             src = {`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15762.411164781402!2d${position.lng}!3d${position.lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2set!4v1720697346820!5m2!1sen!2set`}
    //             width="100%"
    //             height="450"
    //             loading="lazy"
    //             className="max-sm:h-[300px]"
    //           />
    //     ) : (
    //       <div>
    //         Location Not Available
    //       </div>
    //     )
    //   }
    // </div>

  )
}